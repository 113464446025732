<div class="general-carousel-cntr row ml-0 mr-0">

    <carousel [cellWidth]="viewModel.cardWidth" [height]="400" [arrows]="viewModel.displayArrows" [cellsToShow]="cellsToShow()">

        <ng-container *ngIf="ItemList.length > 0">

            <div class="carousel-cell" *ngFor="let Item of ItemList">

                <app-general-card [cardType]="CardType" [SingleFeed]="Item">
                </app-general-card>

            </div>

        </ng-container>

    </carousel>
</div>
