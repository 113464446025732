<div class="treedom-cntr" [ngClass]="{'noMargin': isPage}">
  <div class="txt-cntr">
    <h1>
      MYINSURER <BR /><br>INSIEME PER <BR />UN FUTURO<BR />SOSTENIBILE
    </h1>
    <button *ngIf="!isPage" class="new-button" [routerLink]="['/treedom']">SCOPRI DI PIU</button>
  </div>
  <div class="img-cntr">
    <img
      src="../../../../../assets/images/Home/Generic/Treedom_logo.png"
    />
  </div>
</div>
