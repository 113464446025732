
<div class="insurance-cntr">


<ng-container *ngIf="!NoIncidents">
    <div *ngFor="let Incident of Incidents" class="cardCntr">

        <div class="insurance" (click)="dropdown(Incident)" [class.insurance-active]="Incident.IsCollapsed" [attr.aria-expanded]="Incident.IsCollapsed" aria-controls="collapseExample">

          <div class="header-cntr">
            <div class="w-100">
          <div class="policy-reference col-3 mt-3">
                Polizza di riferimento: <br><strong>{{Incident.UserPolicyCode}}</strong>
            </div>
            <div class="date-compiling col-3 mt-3">
                Data denuncia:<br><strong>{{Incident.CompilingDate | date : 'dd/MM/yyyy'}}</strong>
            </div>
            <div class="stato-polizza col-3"[ngClass]="appGeneralService.getComplaintStatusColor(Incident.State).class"><h3>STATO: {{appGeneralService.getComplaintStatusColor(Incident.State).text}}</h3></div>
          </div>
          <div class="w-100">
            <div class="icon col-12">
                <fa-icon [icon]="Incident.arrow || faChevron"></fa-icon>
            </div>
          </div>
        </div>

        </div>

        <div id="collapseExample" [ngbCollapse]="!Incident.IsCollapsed">
            <div class="card">


                {{Incident.Description}}<br><br>

                <strong>CLASSIFICAZIONE LEGALE:</strong><br>
                <div innerHtml="Incident.policyInfo.LegalClassification"></div>

                <strong>DENUNCIA SINISTRO:</strong><br>
                Polizza di riferimento: {{Incident.UserPolicyId}}<br>
                Data: {{Incident.IncidentDate}}<br>
                Luogo: {{Incident.IncidentPlace}}


                <div *ngFor="let Damaged of Incident.Damaged" class="DamagedList">
                    <strong>DANNEGGIATO:</strong><br> Nome e Cognome: {{Damaged.Name}} {{Damaged.Surname}}<br> Codice fiscale: {{Damaged.CF}}
                </div>

                <div *ngFor="let Witness of Incident.Witness" class="WitnessList">
                    <strong>TESTIMONE:</strong><br> Nome e Cognome: {{Witness.Name}} {{Witness.Surname}}<br> Codice fiscale: {{Witness.CF}}
                </div>
                <div class="separatore"></div>
                <form (submit)="insertFiles(Incident.Id,FileArr)">
                <div class="docsCntr">
                    <h2>Documenti</h2>
                    <div class="row input-cntr">
                    <div class="col-lg-9 col-sm-12 custom-file">
                      <input id="file" class="custom-file-input" type="file" multiple name="fileuploadinputmemo" accept="image/*,.pdf" (change)="fileChange($event)" lang="it">
                      <label class="custom-file-label"  for="file">{{inputPlaceHolder}}</label>
                    </div>
                    </div>

                    <div class="row">

                      <ng-container *ngFor="let doc of Incident.DocumentInfo">

                        <button class="new-button document-button" (click)="appGeneralService.GoToExternalURL(doc.Url)" >{{doc.Name}} <img src="../../../../../assets/images/Configuratore/pdf_scaricabili.svg"></button>

                      </ng-container>

                    </div>
                    <div class="col-lg-12 col-sm-12" style="text-align: end;">
                      <button  class="new-button">
                        SALVA</button>
                      </div>

                  </div>

              </form>

            </div>

        </div>
    </div>

</ng-container>


<ng-container *ngIf="NoIncidents">
    <h2 class="mt-4">Non ci sono Denunce di sinistro</h2>
</ng-container>
</div>
