import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubUserProfile } from 'src/app/core/classes/SubUserClasses/sub-user';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { FamilyType } from 'src/app/core/classes/SubUserClasses/family-type';

import { MatDatepicker } from '@angular/material/datepicker';
import { Observable } from 'rxjs';
import * as ITProvinces from 'src/app/core/helpers/provinces.json';
@Component({
  selector: 'app-my-family',
  templateUrl: './my-family.component.html',
  styleUrls: ['./my-family.component.scss']
})
export class MyFamilyComponent implements OnInit {

  public formFamilyMembers: UntypedFormArray = this.formBuilder.array([]);

  availableProvinces: string[] = ITProvinces['default'].map(province => province.sigla);
  filteredProvinces: Observable<string[]>;
  CallData
  today: Date = new Date();
  calendarStartDate: Date = new Date(this.today.getFullYear() - 18, this.today.getMonth(), this.today.getDate());
  FamilyType: FamilyType = new FamilyType();

  constructor(
    public appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder
    ) {}

  ngOnInit() {
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  setFamilyMembers(familyMembers: SubUserProfile[]){
    if(familyMembers != null && familyMembers != undefined && familyMembers.length > 0){
      familyMembers.forEach((result:SubUserProfile) => {
        this.formFamilyMembers.push(result.getFormGroup());
      })
    }else{
      this.formFamilyMembers.push(new SubUserProfile().getFormGroup());
    }
  }

  deleteData(i){

    if (this.formFamilyMembers.controls[i].invalid) {
      return;
    }
    let dialogConfirm = this.appGeneralService.showDialog(null,"Sei sicuro di voler rimuovere il membro familiare?", "Attenzione", [{ name: 'OK', action: () => { this.deleteFamilyMember(i); dialogConfirm.close(); } }, { name: 'Annulla', action: () => {  dialogConfirm.close() } }]);

  }

  deleteFamilyMember(i){

    const subUserProfileData = this.formFamilyMembers.controls[i].value as SubUserProfile;
    this.apiService.callApiProd("/api/SubUserProfile/Delete", new CallParameter("POST", {SubUserProfileID: subUserProfileData.Id})).subscribe(ret => {
      if(ret.InError){
        this.appGeneralService.showDialog(ret.ErrorCode);
      }
      this.formFamilyMembers.controls.splice(i, 1); 
    });
  }

  addNewFamilyMember(){
    this.formFamilyMembers.push(new SubUserProfile().getFormGroup());
  }

  saveData(i) {
    if (this.formFamilyMembers.controls[i].invalid) {
      return;
    }

    const subUserProfileData = this.formFamilyMembers.controls[i].value as SubUserProfile;
    
    subUserProfileData.Type = 0;

    this.CallData = {
      subUserProfileData
    };

    this.apiService.callApiProd('/api/SubUserProfile/Save', new CallParameter('POST', this.CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
        return;
      }
      this.appGeneralService.showDialog( null, "Salvataggio completato", "Esito");
    });
  }

}
