<div class="story-cntr">

    <div class="img-cntr">
    
        <img src="{{item.ImgUrl}}">
        
    </div>

    <div class="txt-cntr">

        <h1>{{item.Name}}</h1>
    </div>

    <div class="star-cntr">
        
        <ng-container *ngFor="let star of stars">

            <img src="../../../../../../../assets/images/Home/Story/star.svg">

        </ng-container>

    </div>

    <div class="txt-cntr">

        <h3>{{item.Description}}</h3>

    </div>

</div>
