import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CtMetaService } from '@ctsolution/ct-meta';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

constructor(
  @Inject(DOCUMENT) private dom: Document,
  private router: Router,
  private metaService: CtMetaService
  ) { }

  async update_title(object?: any) {

    // const translation = await this.getTranslation(key, object);
    this.metaService.setTitle(object);

    this.update_tag('og:type', 'website');

  }

  async update_description(object?: any) {

    this.update_tag('description', object);

  }

  update_canonical() {

    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null = this.dom.querySelector(`link[rel='canonical']`) || null;

    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    let var1 = environment.originDomain + this.router.url;

    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', var1);
    this.update_tag('og:url', var1);

  }

  update_tag(key: string, value: string) {

    this.metaService.setTag(key, value);

  }

  general_product_tags: Array<{ property: string, value: string }> = [
    { property: 'og:type', value: 'website' },
    { property: 'product:price:currency', value: 'EUR' },
    { property: 'og:condition', value: 'used' },
    { property: 'product:condition', value: 'used' },
    { property: 'og:availability', value: 'in stock' },
    { property: 'product:availability', value: 'in stock' }
  ];

  update_general_product_tags(id: number, pictures: Array<{ path: string }>, address?: string) {

    this.general_product_tags.forEach(element => { this.update_tag(element.property, element.value) });

    this.update_tag('og:id', id.toString());
    this.update_tag('og:link', environment.api.URL + this.router.url);

    this.update_tag('og:image', pictures[0].path)
    this.update_tag('og:image_link', pictures[0].path)

    // pictures.forEach((element: { path: string }) => this.update_tag('og:image', element.path));
    // pictures.forEach((element: { path: string }) => this.update_tag('og:image_link', element.path));

    if (address) {
      this.update_tag('og:custom_label_0', address);
    }

    // this.social.seo_cache();

  }

  remove_general_product_tags() {

    this.general_product_tags.forEach(element => { this.remove_tag(element.property) });
    this.remove_tag('og:id');
    this.remove_tag('og:link');
    this.remove_tag('og:price');
    this.remove_tag('og:price:amount');
    this.remove_tag('product:plural_title');
    this.remove_tag('product:availability');
    this.remove_tag('product:condition');
    this.remove_tag('product:price:currency');
    this.remove_tag('og:custom_label_0');
    this.remove_image_tags();

  }

  remove_image_tags() {

    this.remove_tag('og:image');
    this.remove_tag('og:image_link');

  }

  remove_tag(property: string) {

    this.metaService.removeTag('property="' + property + '"');
    this.metaService.removeTag('name="' + property + '"');

  }

  update_image(url: string) {

    this.update_tag('og:image', url);
    this.update_tag('og:image_link', url);

  }

}
