import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-delete-policy-dialog',
  templateUrl: './delete-policy-dialog.component.html',
  styleUrls: ['./delete-policy-dialog.component.scss']
})
export class DeletePolicyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeletePolicyDialogComponent>,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit() {  }

  close() {
    this.dialogRef.close(false);
  }

  deletePolicy() {
    this.dialogRef.close(true);
    const UserPolicyID = new ToBeDeletedPolicy(this.data);
    this.apiService.callApiProd('/api/Policy/DeleteUserPolicy', new CallParameter('POST', UserPolicyID)).subscribe((result) => {
        if (result.InError) {
          this.dialogRef.close(false);
          this.appGeneralService.showDialog(result.ErrorCode);
        }
    });
  }

}
export class ToBeDeletedPolicy {
  userPolicyID: number;
  constructor(
    userPolicyID: number
    ) {
    this.userPolicyID = userPolicyID;
  }
}