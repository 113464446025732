import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SingleConvention } from 'src/app/core/classes/convention-carousel';
import { SiteType } from 'src/app/core/enum/siteType.enum';
import { SecurityService } from 'src/app/core/services/security.service';
import { SiteManagementService } from 'src/app/core/services/site-management.service';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
import { ShoppingCenterAgencyDetailComponent } from 'src/app/pages/shoppingCenter/shopping-center-agency-detail/shopping-center-agency-detail.component';

@Component({
  selector: 'app-single-convention',
  templateUrl: './single-convention.component.html',
  styleUrls: ['./single-convention.component.scss']
})
export class SingleConventionComponent implements OnInit {

  isShoppingCenter : boolean;

  constructor(
    private router: Router,
    private securityService: SecurityService,
    private urlBuilderService: UrlBuilderService,
    private siteManagementService : SiteManagementService
  ) {

    this.isShoppingCenter = this.siteManagementService.SiteManagement.Type === SiteType.SHOPPINGCENTER;

  }

  @Input() SingleConvention: SingleConvention;

  ngOnInit() {}

  goToDetail(convention: any) {

    if (this.securityService.IsLogged) {
      this.router.navigate(['/assicurazioni'])
    } else {
      this.urlBuilderService.navigateToConventionPath(convention.Id);
    }

  }

  // goToAgency(convention : any){
  //   let queryParameters = {
  //     id : convention.Id
  //   }
  //   this.router.navigate(['shoppingCenterDetail'],{queryParams : queryParameters});
  // }

  goToProfile() {

    this.router.navigate(['/profilo'])

  }

  getText(isPlus: boolean): string {
    return isPlus ? "<strong>+</strong> Aggiungi" : this.securityService.IsLogged ? "Polizze" : "Scopri di più"
  }

}
