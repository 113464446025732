<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12 mb-3">
            <h3>Contatti</h3>
            <p>
                <a href="tel:010565582" title="Telefono">010 565582</a><br> Via Ippolito d'Aste 1/2,<br> 16121 Genova<br><br>
                <a href="mailto:info@buzziinsurancegroup.com" title="Scrivici">info@buzziinsurancegroup.com</a><br>
                <a href="https://www.buzziassicurazioni.com" target="_blank" title="Sito web">www.buzziassicurazioni.com</a>
            </p>

        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Informazioni</h3>
            <p>
                PEC: <a href="mailto:buzzisnc@pec.it" title="Buzzi Assicurazioni PEC">buzzisnc@pec.it</a><br> Soggetto a vigilanza <a href="https://www.ivass.it" title="IVASS">IVASS</a><br> IVASS per reclami:<a href="tel:800042042" title="Telefono reclami IVASS">800-042-042</a>                <br><br> Ti ricordiamo di prendere visione dei seguenti documenti:
                <br>

                <ng-container *ngFor="let doc of Files">
                    <a  [href]="doc.Url" title="Clicca per scaricare il file">{{doc.Name}}</a>, &nbsp;
                </ng-container>
            </p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Azienda</h3>
            <p>
                <a href="https://www.buzziassicurazioni.com/chi-siamo/" title="Clicca per scoprire chi siamo">Buzzi Insurance Group s.r.l.</a> &copy;{{CopyYear}}<br> Tutti i diritti riservati<br> P.IVA 03317980104<br>
                <br>Iscrizione al RUI: <a href="https://servizi.ivass.it/RuirPubblica/DetailResult.faces?id=152796" title="Visualizza Iscrizione">Buzzi Insurance Group s.r.l. N. A000070111 del 12/02/2007</a>
                <br><br> Developed by <a href="https://www.ctsolution.it" target="_blank" title="CT Solution - Sito Web">CT Solution</a>
            </p>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Acquisto online sicuro</h3>
            <p>
                15 giorni per il recesso<br> Recesso gratuito <a style="cursor: pointer;" (click)="RecessInfo()" title="Informazioni sul recesso">clicca qui</a><br> Garanzia certificata<br> Per eventuali reclami <a href="https://www.italiana.it/reclami"
                    target="_blank" title="Informazioni sui reclami">clicca qui</a>
            </p>


            <a target="_blank"><img src="./assets/images/SPID/spid-agid-logo-bb.png" width="240px" alt="spid-agid-logo"></a>

        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Modalità di pagamento</h3><br>
            <div class="paymentCntr">
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/maestro.png" alt="Maestro icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/Mastercard.png" alt="Mastercard icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/nexi-image.png" alt="Nexi icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/pago.jpg" alt="PagoBancomat icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/Visa.png" alt="Visa icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/Paypal.png" alt="Paypal icon"></div>
                <div class="payment-cntr"><img src="../../../assets/images/pagamenti2/american_express.png" alt="American Express icon"></div>
             
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Sicurezza certificata</h3>
            <img width="60" src="./assets/images/sicurezza-certificata_myinsurerfooter.png" alt="Visa icon">
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
            <h3>Altro</h3>
            <a [routerLink]="['/documentazione-contrattuale']" title="Informazioni contrattuali" style="text-decoration: underline;">Informazioni contrattuali</a><BR>
            <a [routerLink]="['/faq']" title="Informazioni contrattuali" style="text-decoration: underline;">FAQ</a>

        </div>
        <div class="col-lg-3 col-md-4 col-sm-12" style="align-self: self-end;">

        <div class="socialCntr" style="float: right;">
          <a href="https://www.facebook.com/buzziassicurazioni/" target="_blank" title="Buzzi Assicurazioni Facebook"><img src="./assets/images/icons/facebook_footer.svg" alt="Facebook icon"></a>
          <a href="https://www.linkedin.com/company/buzzi-s-a-s/" target="_blank" title="Buzzi Assicurazioni Linkedin"><img src="./assets/images/icons/linkedin_footer.svg" alt="Linkedin icon"></a>
          <a href="https://instagram.com/myinsurer" target="_blank" title="Buzzi Assicurazioni Linkedin"><img src="./assets/images/icons/instagram_footer.svg" alt="Instagram icon"></a>
          <a href="https://www.youtube.com/@buzziinsurtechservice" target="_blank" title="Buzzi Assicurazioni youtube"><img src="./assets/images/icons/youtube.svg" alt="Youtube icon"></a>

      </div>
        </div>
    </div>
</div>
