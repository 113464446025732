<h3 mat-dialog-title class="dialogTitle">Recesso servizio</h3>
<div mat-dialog-content class="recessInformativeContainer">
    <p>Sei sicuro di voler recedere questo servizio?</p>
    <p>ATTENZIONE: Il recesso non avverrà se si ha usufruito del servizio</p>
    <p>Nei contratti stipulati a distanza è previsto il diritto del contraente di recedere unilateralmente dal contratto senza incorrere in penali e senza alcun obbligo di spiegazione dei propri motivi nei confronti dell’intermediario: nel caso delle polizze
        danni, tale diritto va esercitato entro 14 giorni dalla data di conclusione del contratto o dalla comunicazione al cliente dell’avvenuta conclusione. Per le polizze vita, nel caso in cui il contraente e l’intermediario si scambiano una proposta-polizza
        che produce immediatamente gli effetti del contatto non appena viene sottoscritta, il contraente può esercitare il diritto di recesso, fino ai 30 giorni successivi a quando è stato informato della stipula. Ti ricordo corriamo che nella sezione
        prodotti acquistati è presente il tasto per recedere automaticamente dal contratto. </p>
</div>
<div mat-dialog-actions>
    <button class="new-button"   (click)="close()">ANNULLA</button>
    <button class="new-button"   (click)="recessService()">RECEDI</button>
</div>
