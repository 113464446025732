
<div class="header-contact-cntr">

  <div class="img-cntr">

    <img class="dont-show-mobile" src="../../../../assets/images/Contatti/desktop-.webp">
    <img class="dont-show-desktop" src="../../../../assets/images/Contatti/mobile-contatti.webp">

  </div>

  <div class="txt-cntr">

    <img src="../../../../assets/images/Contatti/contatti_telefono.svg" alt="">
    <h1 class="duplicate" title="Contatti"></h1>

  </div>

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">


</div>
