import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { FeedPolicyType } from "../enum/policy-feed-type.enum";
import { JsonLoaderService } from "./json-loader.service";
import { Location } from "@angular/common"

@Injectable({
  providedIn: "root",
})
export class UrlBuilderService {
  private groups: UrlPolicyGroupElement[] = [];
  private policies: UrlPolicyGroupElement[] = [];
  private groupsFilePath: string = "/assets/group-policies-seo.json";
  private policiesFilePath: string = "/assets/policies-seo.json";
  private servicesFilePath: string = "/assets/services-seo.json";
  private conventionsFilePath: string = "/assets/conventions-seo.json";

  constructor(private jsonLoader: JsonLoaderService, private router: Router,private location : Location) { }

  loadData(path: string): Promise<UrlPolicyGroupElement[]> {
    return new Promise((resolve) => {
      if (this.groups.length > 0) {
        resolve(this.groups);
        return;
      }

      this.jsonLoader
        .getJsonData(path)
        .subscribe((result: UrlPolicyGroupElement[]) => {
          resolve(result as UrlPolicyGroupElement[]);
        });
    });
  }

  navigateToServicePath(
    serviceId: number
  ) {

    let finalPath: string[] = ["/servizi"];
    let queryParameters: any = {};

    this.loadData(this.servicesFilePath).then(

      (result: UrlPolicyGroupElement[]) => {
        let service: UrlPolicyGroupElement | undefined = result.find(
          (element) => element.ID === serviceId
        );

        if (service) {
          finalPath.push(this.getFormattedUrlKey(service.title));
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "ServiceID",
            service.ID
          );

        } else {

          finalPath.push(serviceId.toString());
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "ServiceID",
            serviceId
          );

        }

        this.navigate(finalPath, queryParameters);

      }
    );
  }

  navigateToConventionPath(
    conventionId: number
  ) {

    let finalPath: string[] = ["/convenzioni"];
    let queryParameters: any = {};

    this.loadData(this.conventionsFilePath).then(

      (result: UrlPolicyGroupElement[]) => {
        let convention: UrlPolicyGroupElement | undefined = result.find(
          (element) => element.ID === conventionId
        );

        if (convention) {
          finalPath.push(this.getFormattedUrlKey(convention.title));
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "ConventionID",
            convention.ID
          );

        } else {

          finalPath.push(conventionId.toString());
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "ConventionID",
            conventionId
          );

        }

        this.navigate(finalPath, queryParameters);

      }
    );
  }

  navigateToFeed(feedId: FeedPolicyType) {

    let finalPath: string[] = ["/assicurazioni/categoria", this.getFeedName(feedId)];

    let queryParameters: any = {
      FeedID: feedId,
    };

    this.navigate(finalPath, queryParameters);

  }

  navigateToPath(
    groupId: number,
    policyId?: number,
    proposalId?: string,
    scroll?: boolean
  ) {

    let finalPath: string[] = ["/assicurazioni"];
    let queryParameters: any = {};

    this.loadData(this.groupsFilePath).then(

      (result: UrlPolicyGroupElement[]) => {
        let group: UrlPolicyGroupElement | undefined = result.find(
          (element) => element.ID === groupId
        );

        if (group) {
          finalPath.push(this.getFormattedUrlKey(group.title));
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "GroupID",
            group.ID
          );

        } else {

          finalPath.push(groupId.toString());
          queryParameters = this.addKeyToQueryParameters(
            queryParameters,
            "GroupID",
            groupId
          );

        }

        if (proposalId) {

          let proposal: UrlPolicyGroupElement | undefined = result.find(
            (element) => element.code === proposalId
          );
          if(scroll){
          queryParameters = this.addKeyToQueryParameters(queryParameters,"Scroll",true);
          }
          if (proposal) {

            queryParameters = this.addKeyToQueryParameters(
              queryParameters,
              "ProposalID",
              proposal.code
            );

          } else {

            queryParameters = this.addKeyToQueryParameters(
              queryParameters,
              "ProposalID",
              proposalId
            );

          }

        }

        if (policyId) {

          this.loadData(this.policiesFilePath).then(
            (result: UrlPolicyGroupElement[]) => {
              let policy: UrlPolicyGroupElement | undefined = result.find(
                (element) => element.ID === policyId
              );

              if (policy) {

                finalPath.push(this.getFormattedUrlKey(policy.title));
                queryParameters = this.addKeyToQueryParameters(
                  queryParameters,
                  "PolicyID",
                  policy.ID
                );
                this.navigate(finalPath, queryParameters);

              } else {

                finalPath.push(policyId.toString());
                queryParameters = this.addKeyToQueryParameters(
                  queryParameters,
                  "PolicyID",
                  policyId
                );
                this.navigate(finalPath, queryParameters);

              }
            }
          );

        } else {

          this.navigate(finalPath, queryParameters);

        }

      }


    );
  }

  private getFormattedUrlKey(key: string) {

    let result: string = "";

    // replace degli slash nell'url con dei traattini
    result = key.replace(/\//g, "-");
    // replace di tutte le lettere accentate con lettere normali
    result = key.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    // replace degli spazi con trattino
    result = result.replace(/\s/g, "-");

    return result.toLowerCase();
  }

  private addKeyToQueryParameters(qp: any, newKey: string, newValue: any) {

    qp[newKey] = newValue;

    return qp;
  }

  private getFeedName(id: number) {
    // replace degli underscore con trattino
    return FeedPolicyType[id].replace(/_/g, "-").toLowerCase();
  }

  private navigate(finalPath: string[], qp: any) {
    this.router.navigate(finalPath, { queryParams: qp });
  }
}

interface UrlPolicyGroupElement {
  ID: number;
  code?: string;
  title: string;
  description: string;
}
