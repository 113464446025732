import { Component, ViewChild } from "@angular/core";
import { MatDrawer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationEnd, Router, Routes } from "@angular/router";
import { CtWebviewService } from '@ctsolution/ct-webview';
import * as $ from "jquery";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { CallParameter } from "./core/classes/system/call-parameter";
import { ApiService } from "./core/services/api.service";
import { AppGeneralService } from "./core/services/app-general.service";
import { LocalStorageService } from "./core/services/localstorage.service";
import { SecurityService } from "./core/services/security.service";
import { SeoService } from "./core/services/seo.service";
declare function init_analytics_scripts(): any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @ViewChild("drawer") public drawer: MatDrawer;

  title = "General_FE";
  userLang = "it";


  childRoutes: Routes;
  url: string;

  drawerState: boolean = false;
  is_logged: boolean = false;
  hisName: string;
  cartItems: string;
  snackBarPrivacy: MatSnackBar;
  fno: boolean;

  logoUrl;

  constructor(
    public securityService: SecurityService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appGeneralService: AppGeneralService,
    private cookieService: CookieService,
    private apiService: ApiService,
    private seoService: SeoService,
    private storageService: LocalStorageService,
    private ctWebviewService: CtWebviewService
  ) {
    this.activatedRoute.snapshot.queryParams;

    router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.url = event["url"];

        if (this.activatedRoute.snapshot.firstChild) {
          this.childRoutes =
            this.activatedRoute.snapshot.firstChild.routeConfig.children;
        }

        let foundUrl = this.appGeneralService.MemoryRoutes.find(
          (z) => this.url.includes(z)
        );

        if ((foundUrl == null || foundUrl == undefined) || foundUrl.length < 1) {
          this.storageService.setLastPageSeen(this.url);
        }

        this.seoService.update_canonical();

      }

    });

    this.securityService.logged_state.subscribe((logged: boolean) => {
      this.is_logged = logged;
    });

    this.securityService.user_name.subscribe((hisName: string) => {
      if (hisName) {
        this.hisName = hisName.trim().split(" ")[0];
      } else {
        this.hisName = "";
      }
    });

    this.securityService.cart_Items.subscribe((cartItems: string) => {
      if (cartItems) {
        this.cartItems = cartItems;
      } else {
        this.cartItems = "0";
      }
    });

    this.securityService.fno_check.subscribe((fno) => {
      if (fno) {
        this.fno = fno;
      } else {
        this.fno = false;
      }
    });

    this.activatedRoute.queryParams.subscribe((params) => {

      if (params['fno']) {

        let valueFno: boolean = JSON.parse(params['fno']);

        this.storageService.setQueryFnoFlag(valueFno);
        this.storageService.setFnoFlag(valueFno);
        this.securityService.check_log_state();
        this.router.navigate(['login']);

      }

    });

  }

  ngOnInit() {
    this.appGeneralService.loadingPanel.Setup();
    this.getSiteType();
    let root = document.documentElement;

    // let primaryColor = window.localStorage.getItem(this.appGeneralService.ConstantString.primaryColor);
    // let secondaryColor = window.localStorage.getItem(this.appGeneralService.ConstantString.secondaryColor);
    let primaryColor = this.storageService.getPrimaryColor();
    let secondaryColor = this.storageService.getSecondaryColor();

    if (primaryColor != null || primaryColor != undefined) {
      root.style.setProperty("--myinsurer-primary", primaryColor);
    }

    if (secondaryColor != null || secondaryColor != undefined) {
      root.style.setProperty("--myinsurer-secondary", secondaryColor);
    }

    this.securityService.check_log_state();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      setTimeout(function () {
        var $GoTo = $("html, body");
        $GoTo.animate(
          {
            scrollTop: $(".scroll-touch").offset().top,
          },
          500
        );
      }, 0);
    });

    if (environment.production) {

      init_analytics_scripts();

    }

  }

  getSiteType(){
    let callparam = new CallParameter("GET");
    this.apiService.callApiProd("/api/SiteManagement/GetSiteTypeConf",callparam,true,true)
    .subscribe((res)=>{

      let type  = res.ResultData as unknown as string;
      this.storageService.setSiteType(type);
      switch(type) {
        case "OnlineAgency": {
           this.getSiteConfiguration();
           break;
        }
        default: {
           //statements;
           break;
        }
     }

    })

  }


  getSiteConfiguration() {

    let callparam = new CallParameter("GET");

    this.apiService
      .callApiProd(
        "/api/SiteManagement/GetSiteConfiguration",
        callparam,
        true,
        true
      )
      .subscribe((res) => {
        if (
          res.ResultData["LogoUrl"] != null &&
          res.ResultData["LogoUrl"] != undefined &&
          res.ResultData["LogoUrl"] != ""
        ) {
          // window.localStorage.setItem(this.appGeneralService.ConstantString.LogoUrl, res.ResultData["LogoUrl"]);
          this.storageService.setLogoUrl(res.ResultData["LogoUrl"]);
        } else {
          // window.localStorage.removeItem(this.appGeneralService.ConstantString.LogoUrl);
          this.storageService.removeLogoUrl();
        }

        if (
          res.ResultData["PrimaryColor"] != null &&
          res.ResultData["PrimaryColor"] != undefined &&
          res.ResultData["PrimaryColor"] != ""
        ) {
          this.storageService.setPrimaryColor(res.ResultData["PrimaryColor"]);
        } else {
          this.storageService.removePrimaryColor();
        }

        if (
          res.ResultData["SecondaryColor"] != null &&
          res.ResultData["SecondaryColor"] != undefined &&
          res.ResultData["SecondaryColor"] != ""
        ) {
          this.storageService.setPrimaryColor(res.ResultData["PrimaryColor"]);
        } else {
          this.storageService.removeSecondaryColor();
        }

        if (
          res.ResultData["BrokerManagement"] != null &&
          res.ResultData["BrokerManagement"] != undefined &&
          res.ResultData["BrokerManagement"] != ""
        ) {
          this.storageService.setBrokerManagement(
            res.ResultData["BrokerManagement"]
          );
        } else {
          this.storageService.removeBrokerManagement();
        }
      });
  }

  onOpenedChange(event) {
    this.drawerState = event;
  }

  toggleDrawer(event) {
    if (event) {
      this.drawer.toggle();
    }
  }

  get ShowCookiePolicy() {
    return this.appGeneralService.showCookies;
  }
}
