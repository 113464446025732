import { Component, HostListener, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Compare, OptionDTO } from 'src/app/core/classes/Policy/compare';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
import { OptionInfoDialogComponent } from './option-info-dialog/option-info-dialog.component';

@Component({
  selector: 'app-policy-price-boxes',
  templateUrl: './policy-price-boxes.component.html',
  styleUrls: ['./policy-price-boxes.component.scss']
})
export class PolicyPriceBoxesComponent implements OnInit{

  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    private urlBuilderService: UrlBuilderService
  ) { }

  @Input() GroupId: number;
  @Input() ProposalId: string;

  comparePolicies: Compare;
  faInfoCircle = faInfoCircle;

   ngOnInit() {
    this.apiService.callApiProd("/api/Policy/GetCompare", new CallParameter("POST", { PolicyGroupId: this.GroupId, ProposalId: Number(this.ProposalId.split('_')[1]) })).subscribe(res => {
      this.comparePolicies = res.ResultData as Compare ;
    });
  }



  openPolicyDetail(policyId: number) {
    this.urlBuilderService.navigateToPath(this.GroupId, policyId, this.ProposalId);
  }

  openInfoDialog(Description: string, Title: string) {
    this.dialog.open(OptionInfoDialogComponent, {
      panelClass: 'SurveyModalCntr',
      width: '400px',
      maxHeight: '90vh',
      data: {Description, Title},
      autoFocus: false
    });
  }

  tdValue(option: OptionDTO, policyCheckId : number):string {

    var optionFind = option.Values.find(x => x.PoliciesData.find(f => f.Id == policyCheckId));
    if(optionFind)
    {
      let allMaximal = optionFind.SubValue.map(x => x.Maximal);
      if(allMaximal.length > 0)
        return allMaximal.join(' | ');
      // let allPrice = optionFind.SubValue.map(x => x.Price);
      // if(allPrice.length > 0)
      //   return allPrice.join(' | ');
    }
    return " - ";
  }

  calculateHeight(): number {

    let count = 0;

    this.comparePolicies.Sections.forEach(element => {

      count = count + element.OptionDTOs.length;

    });
    if(count>1){
    return count * 60 + 400;
    }
    else return 350;

  }

  @HostListener("window:resize")
  cellsToShow(){
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return "";
    }
      return 1.3;

  }


}
