<h3 mat-dialog-title class="dialogTitle">Seleziona profilo</h3>
<form [formGroup]="formProfile">
    <p>Prima di continuare devi selezionare un profilo al quale associare la polizza:</p>
    <mat-form-field class="full-width" appearance="outline">
        <mat-label for="Profile">Seleziona profilo</mat-label>
        <mat-select matNativeControl formControlName="Profile" required>
            <mat-option *ngFor="let profile of data" [value]="profile.Profiles[0].UserProfileID">
                {{ profile.Profiles[0].Name }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="formProfileControls['Profile'].hasError('required')">
            Selezionare un <strong>profilo lavorativo</strong>
        </mat-error>
    </mat-form-field>
</form>

<div mat-dialog-actions>
    <button class="new-button"   (click)="close()">ANNULLA</button>
    <button class="new-button"   (click)="renew_policy()">RINNOVA</button>
</div>
