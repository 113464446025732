import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralCarouselComponent } from './general-carousel.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { GeneralCardModule } from '../general-card/general-card.module';
import { FeedPanelModule } from '../feed-carousel/feed-panel/feed-panel.module';

@NgModule({
  imports: [
    CommonModule,
    IvyCarouselModule,
    GeneralCardModule,
    FeedPanelModule
  ],
  declarations: [GeneralCarouselComponent],
  exports: [GeneralCarouselComponent]
})
export class GeneralCarouselModule { }
