<div class="homeCarouselCntr">

    <h2 class="MobileFirstHomeCarouselBox">SERVIZI</h2>

    <div class="first-column">

        <div class="firstHomeCarouselBox">
            <h2>I nostri servizi</h2>
            <img src="../../../../assets/images/freccia.png">
        </div>

    </div>

    <div class="second-column">

        <carousel [cellWidth]="175" [height]="270" [margin]="15">
            <div class="carousel-cell" *ngFor="let Item of ServiceCarousel.Results">
                <app-single-service-carousel [SingleServiceCarousel]="Item"></app-single-service-carousel>
            </div>
        </carousel>

    </div>

</div>