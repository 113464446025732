export class FeedbackDTO{

 UserPolicyID : number;
 Note : string;
 FeedbackValue : number;

 constructor(UserpolicyID : number,note : string, feedbackvalue : number){

  this.FeedbackValue = feedbackvalue;
  this.Note = note;
  this.UserPolicyID = UserpolicyID;

 }


}
