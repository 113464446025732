import { Component, OnInit } from '@angular/core';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.scss']
})
export class MyProductsComponent implements OnInit {

  constructor(
    private seoService: SeoService
  ) {

    this.seoService.update_title('');
    this.seoService.update_description('');
    
  }

  ngOnInit() {
  }

}
