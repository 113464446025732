import { Component, OnInit } from '@angular/core';
import { faFileDownload, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  faFileDownload = faFileDownload;
  faFileAlt = faFileAlt;
  AllFilesResult;

  constructor(
    private appGeneralService: AppGeneralService,
    private apiService: ApiService
    ) { }

  ngOnInit() {

    this.GetAllPoliciesFiles();

  }

  GetAllPoliciesFiles() {
    this.apiService.callApiProd('/api/Policy/GetAllPoliciesFiles', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.AllFilesResult = result['ResultData']['PolicyFiles'];
      }

    });
  }

}
