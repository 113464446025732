import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-cancel-policy-dialog',
  templateUrl: './cancel-policy-dialog.component.html',
  styleUrls: ['./cancel-policy-dialog.component.scss']
})
export class CancelPolicyDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CancelPolicyDialogComponent>,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  cancelPolicy(data) {
    this.dialogRef.close();
    const UserPolicyID = new ToBeCanceledPolicy(this.data);
    this.apiService.callApiProd('/api/Policy/CancelUserPolicy', new CallParameter('POST', UserPolicyID)).subscribe((result) => {
      result => {
        
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        }
      }
    });
  }

}

export class ToBeCanceledPolicy {
  userPolicyID: number;
  constructor(
    userPolicyID: number
  ) {
    this.userPolicyID = userPolicyID;
  }
}