//System core
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

//Custom components
import { CtMetaGuardService } from "@ctsolution/ct-meta";
import { LoginComponent } from "./auth/login/login.component";
import { CompleteRegistrationComponent } from "./auth/registration/complete-registration/complete-registration.component";
import { RegistrationConfirmComponent } from "./auth/registration/registration-confirm/registration-confirm.component";
import { RegistrationMailSentComponent } from "./auth/registration/registration-mail-sent/registration-mail-sent.component";
import { RegistrationComponent } from "./auth/registration/registration.component";
import { ResetPasswordComponent } from "./auth/registration/reset-password/reset-password.component";
import { BuyPolicyComponent } from "./pages/cart/buy-policy/buy-policy.component";
import { BuyServiceComponent } from "./pages/cart/buy-service/buy-service.component";
import { CartComponent } from "./pages/cart/cart.component";
import { ChiSiamoComponent } from "./pages/chi-siamo/chi-siamo/chi-siamo.component";
import { ConfiguratorComponent } from "./pages/configurator/configurator.component";
import { ContactsComponent } from "./pages/contacts/contacts.component";
import { ConventionDetailComponent } from "./pages/conventions/convention-carousel/convention-detail/convention-detail.component";
import { ConventionsComponent } from "./pages/conventions/conventions.component";
import { CookieComponent } from "./pages/cookie/cookie.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { HomePoliciesComponent } from "./pages/home-policies/home-policies.component";
import { HomeComponent } from "./pages/home/home.component";
import { NexiRetryPaymentComponent } from "./pages/nexi-retry-payment/nexi-retry-payment.component";
import { PaymentConfirmedComponent } from "./pages/payment-confirmed/payment-confirmed.component";
import { PoliciesComponent } from "./pages/policies/policies.component";
import { PolicyGroupComponent } from "./pages/policies/policy-group/policy-group.component";
import { SinglePackageDetailPageComponent } from "./pages/policies/policy-group/policy-price-boxes/single-package-detail-page/single-package-detail-page.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { CanceledAreaComponent } from "./pages/profile/canceled-area/canceled-area/canceled-area.component";
import { InfoComponent } from "./pages/profile/info/info.component";
import { InsuranceAreaComponent } from "./pages/profile/insurance-area/insurance-area.component";
import { MailConfirmComponent } from "./pages/profile/mail-confirm/mail-confirm.component";
import { MyProductsComponent } from "./pages/profile/my-products/my-products.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { ServiceDetailComponent } from "./pages/services/service-detail/service-detail.component";
import { ServicesComponent } from "./pages/services/services.component";
import { ShoppingCenterAgencyDetailComponent } from "./pages/shoppingCenter/shopping-center-agency-detail/shopping-center-agency-detail.component";
import { SpidRedirectComponent } from "./pages/spid-redirect/spid-redirect.component";
import { TreedomPageComponent } from "./pages/treedom-page/treedom-page.component";
import { FeedbackAreaComponent } from "./shared/feedback-area/feedback-area.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    canActivate: [CtMetaGuardService],
    data: {
      meta: {
        title:
          "Servizi e assicurazioni online acquistabili comodamente in pochi click!",
        description:
          "MyInsurer è la piattaforma online che ti permette di acquistare servizi e polizze direttamente da casa tua.",
      },
    },
  },
  {
    path: "convenzioni",
    children: [
      { path: ":conventionid", component: ConventionDetailComponent, pathMatch: "full" },
      {
        path: "", component: ConventionsComponent, canActivate: [CtMetaGuardService],
        data: {
          meta: {
            title: "Convenzioni",
            description:
              "Lista delle federazioni e associazioni convenzionate su MyInsurer, i nostri migliori prodotti assicurativi per ogni tipo di esigenza.",
          },
        },
      },
    ],
  },
  {
    path: "chi-siamo",
    component: ChiSiamoComponent

  },
  {
    path: "privacy",
    component: PrivacyComponent

  },
  {
    path: "configuratore",
    component: ConfiguratorComponent
  },
  {
    path: "assicurazioni",

    children: [
      {
        path: "categoria/:feedid", component: PoliciesComponent, pathMatch: "full"
      },
      {
        path: ":groupid",
        children: [
          { path: ":type", component: SinglePackageDetailPageComponent },
          { path: "", component: PolicyGroupComponent, pathMatch: "full" },
        ],
      },
      {
        path: "",
        component: PoliciesComponent,
        canActivate: [CtMetaGuardService],
        data: {
          meta: {
            title: "Tutte le assicurazioni acquistabili su MyInsurer",
            description:
              "Scopri tutte le assicurazioni su MyInsurer, ampia scelta per ogni tua esigenza acquistabili in pochi click.",
          },
        },
        pathMatch: "full",
      },
    ],
  },
  {
    path: "servizi",

    children: [
      { path: ":serviceid", component: ServiceDetailComponent, pathMatch: "full" },
      {
        path: "",
        component: ServicesComponent,
        canActivate: [CtMetaGuardService],
        data: {
          meta: {
            title: "Tutti i servizi acquistabili su MyInsurer",
            description: "Scopri tutti i servizi su MyInsurer, ampia scelta per ogni tua esigenza acquistabili in pochi click.",
          },
        },
      },
    ],
  },

  {
    path: "contatti",
    component: ContactsComponent,
    canActivate: [CtMetaGuardService],
    data: {
      meta: {
        title: "Contatti",
        description:
          "Se hai bisogno di informazioni puoi scriverci o passare nei nostri uffici, ecco i nostri contatti.",
      },
    },
  },

  {
    path: "profilo",
    component: ProfileComponent
  },
  {
    path: "profilo/:id",
    component: ProfileComponent
  },
  {
    path: "prodotti-acquistati",
    component: MyProductsComponent
  },
  {
    path: "area-sinistro",
    component: InsuranceAreaComponent,
    data: {
      canActivate: [CtMetaGuardService],
    },
  },
  {
    path: "feedback",
    component: FeedbackAreaComponent,
    data: {
      canActivate: [CtMetaGuardService],
    },
  },
  {
    path: "area-disdetta",
    component: CanceledAreaComponent,
    data: {
      canActivate: [CtMetaGuardService],
    },
  },

  {
    path: "documentazione-contrattuale",
    component: InfoComponent,
    canActivate: [CtMetaGuardService],
    data: {
      meta: {
        title: "Documentazione contrattuale",
        description:
          "Tutta la documentazione contrattuale dei nostri servizi e assicurazioni consultabile in completa trasparenza.",
      },
    },
  },

  {
    path: "carrello",
    component: CartComponent
  },
  {
    path: "faq",
    component: FaqComponent
  },
  {
    path: "acquista-polizza/:id",
    component: BuyPolicyComponent
  },
  {
    path: "acquista-servizio/:id",
    component: BuyServiceComponent
  },
  { path: "login", component: LoginComponent },
  {
    path: "spid-redirect",
    component: SpidRedirectComponent
  },
  {
    path: "conferma-mail",
    component: MailConfirmComponent
  },
  {
    path: "email-inviata",
    component: RegistrationMailSentComponent
  },
  {
    path: "treedom",
    component: TreedomPageComponent
  },
  {
    path: "completa-registrazione",
    component: CompleteRegistrationComponent
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent
  },
  {
    path: "registrazione",
    children: [
      { path: "external", component: RegistrationComponent, pathMatch: "full" },
      {
        path: "",
        component: RegistrationComponent
      },
    ],
  },
  {
    path: "registrazione-confermata",
    component: RegistrationConfirmComponent
  },
  {
    path: "informativa-cookies",
    component: CookieComponent
  },
  {
    path: "conferma-pagamento/:id/:userPolicyId",
    component: PaymentConfirmedComponent
  },
  {
    path: "nexi-retry-payment",
    component: NexiRetryPaymentComponent
  },
  {

    path : "shoppingCenterDetail",
    component : ShoppingCenterAgencyDetailComponent

  },
  {

    path : "per-il-tuo-lavoro/:title/:id",
    component : HomePoliciesComponent,
    data: {
      key: "lavoro"
  },
},
{

  path : "per-te-casa-salute/:title/:id",
  component : HomePoliciesComponent,
  data: {
    key: "casa"
},
},
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
