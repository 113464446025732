import { Component, Input, OnInit } from '@angular/core';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';

@Component({
  selector: 'app-policy-group-proposal-scheda',
  templateUrl: './policy-group-proposal-scheda.component.html',
  styleUrls: ['./policy-group-proposal-scheda.component.scss']
})
export class PolicyGroupProposalSchedaComponent implements OnInit {

  @Input() Title;
  @Input() Scheda;

  constructor(private urlBuilderService : UrlBuilderService) { }

  ngOnInit() {
  }

  ShowProposalBoxes() {

    // this.SingleProposal = this.PolicyFullDetail.ProposalInfos[index];

    // this.tabTitle = this.PolicyFullDetail.ProposalInfos[index].Label;
    // $(".selected").removeClass("selected");
    // $("#ProposalBox_" + index).addClass("selected");

    let id = this.Scheda.PolicyGroupID + '_' + this.Scheda.ProposalId;
    this.urlBuilderService.navigateToPath(this.Scheda.PolicyGroupID, 0, id,true);

  }

}
