import { Injectable } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { FeedPanel } from 'src/app/core/classes/Feed/feed-carousel';
import { isFunction } from 'util';


@Injectable()
export class FeedService {

  private dataSource = new BehaviorSubject<FeedPanel>(new FeedPanel(0, null, null, null));
  data = this.dataSource.asObservable();
  private OldComponent;

  constructor() {}

  ChangeCollapsible(data, component) {
    
    let NewComponent = component;
    if ((this.OldComponent != null || this.OldComponent != undefined) && NewComponent != this.OldComponent) {      
      if (this.OldComponent.faChevron == faChevronUp) {
        this.ChevronModify(this.OldComponent);
      }
      this.ChevronModify(NewComponent);
    }else{
      this.ChevronModify(NewComponent);
    }
    this.OldComponent = component;
    this.dataSource.next(data);
  }

  ChevronModify(component){
    if (component.faChevron == faChevronUp) {
      component.faChevron = faChevronDown; 
    }else {
      component.faChevron = faChevronUp; 
    }
  }

  DestroyFeedPanelOpened(){
    if(this.OldComponent){
      this.ChangeCollapsible(new FeedPanel(0,null,null,null), this.OldComponent);
    }
  }
}