<div class="contact-us-cntr">

  <div class="img-cntr">

    <img class="absolute" src="../../../../assets/images/Home/Generic/location.webp">
    <img class="mobile" src="../../../../assets/images/Home/Generic/location.webp">

  </div>

  <div class="txt-cntr">

    <h1 class="duplicate" title="Per ogni tua esigenza"></h1><br>
    <h3>Vieni a trovarci o contattaci per parlare con un nostro operatore.</h3>
    <button (click)="navigate()" class="new-button">CONTATTACI</button>

  </div>

</div>
