import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-registration-confirm',
  templateUrl: './registration-confirm.component.html',
  styleUrls: ['./registration-confirm.component.scss']
})
export class RegistrationConfirmComponent implements OnInit {

  code: string;
  constructor(
    private appGeneralService: AppGeneralService,
    private router: Router,
    public apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['code'];
    });
  }

  ngOnInit() {
    this.appGeneralService.loadingPanel.Show();

    this.apiService.callApiProd("/api/Registration/PutStep2",new CallParameter("Post",{ 'Code': this.code })).subscribe(data => {
        if (data.InError) {
          this.router.navigate(['/']);
          if(data.ErrorCode == 399){
            this.router.navigate(['/login']);
          }else{
            this.appGeneralService.showDialog(data.ErrorCode);
          }
        }
    });
  }

}
