

//CLASSE CAROUSEL DI CONVENZIONI

export class ConventionCarousel {
    // Title: string;
    // Description: string;
    conventionInfos: SingleConvention[];
}

//CLASSE SINGOLA CONVENZIONE

export class SingleConvention {
    ID: number;
    Image: string;
    Label: string;
    Description: string;
    IsPlus: boolean = false;
}