import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import CodiceFiscale from 'codice-fiscale-js'; //https://github.com/lucavandro/CodiceFiscaleJS
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RegistrationStep } from 'src/app/core/enum/registration-step.enum';
import * as ITProvinces from 'src/app/core/helpers/provinces.json';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { RegistrationStepService } from 'src/app/core/services/registration.service';
import { AppGeneralService } from './../../core/services/app-general.service';

@Injectable({
  providedIn: 'root'
})
export class SplitRegistrationService {
  SocialUser: SocialUser;
  completeStep = false;
  hide: boolean = true;
  hideConfirm: boolean = true;
  availableProvinces: string[] = ITProvinces['default'].map(province => province.sigla);
  filteredProvinces: Observable<string[]>;

  readonly ForeignState = 'Estero';
  readonly Italy = 'Italia';
  States: string[] = [this.Italy, this.ForeignState];

  constructor(
    private authService: SocialAuthService,
    private storageService: LocalStorageService,
    private registrationStepService: RegistrationStepService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService
  ) {

    let registrationStep : RegistrationStep = Number(this.storageService.getRegistrationStep()) as RegistrationStep;
    this.registrationStepService.checkRegistrationCompleted(registrationStep,false);

   }

  /**
   * *This function is used to setup the form with the data coming from the login service.*
   * @param ret - The result of the API call.
   */
  setupForm(ret, formRegistration: FormGroup) : boolean {
    let foundOne = false;
    var a = formRegistration.valueChanges.subscribe((data) => {
      Object.entries(data).forEach((value) => {
        if (value[1] && value[0] != "Gender" && foundOne == false) {
          this.appGeneralService.showDialog(null, "Completa la registrazione inserendo i dati mancanti", "Completa la registrazione");
          foundOne = true;
        }
      })
    });

    formRegistration.contains("Email") && formRegistration.controls["Email"].setValue(ret.ResultData["Email"]);
    formRegistration.contains("CF") && formRegistration.controls["CF"].setValue(ret.ResultData["CF"]);
    formRegistration.contains("Phone") && formRegistration.controls["Phone"].setValue(ret.ResultData["Phone"]);
    formRegistration.contains("Name") && formRegistration.controls["Name"].setValue(ret.ResultData["Name"]);
    formRegistration.contains("Surname") && formRegistration.controls["Surname"].setValue(ret.ResultData["Surname"]);
    formRegistration.contains("birthState") && formRegistration.controls["BirthState"].setValue(ret.ResultData["BirthState"]);
    formRegistration.contains("birthCity") && formRegistration.controls["BirthCity"].setValue(ret.ResultData["BirthCity"]);
    formRegistration.contains("birthProvince") && formRegistration.controls["BirthProvince"].setValue(ret.ResultData["BirthProvince"]);
    formRegistration.contains("birthDate") && formRegistration.controls["BirthDate"].setValue(ret.ResultData["BirthDate"]);
    formRegistration.contains("State") && formRegistration.controls["State"].setValue(ret.ResultData["State"]);
    formRegistration.contains("City") && formRegistration.controls["City"].setValue(ret.ResultData["City"]);
    formRegistration.contains("Province") && formRegistration.controls["Province"].setValue(ret.ResultData["Province"]);
    formRegistration.contains("Address") && formRegistration.controls["Address"].setValue(ret.ResultData["Address"]);
    formRegistration.contains("BuildingNumber") && formRegistration.controls["BuildingNumber"].setValue(ret.ResultData["BuildingNumber"]);
    formRegistration.contains("Gender") && formRegistration.controls["Gender"].setValue(ret.ResultData["Gender"]);
    formRegistration.contains("Rea") && formRegistration.controls["Rea"].setValue(ret.ResultData["Rea"]);
    formRegistration.contains("CompanyName") && formRegistration.controls["CompanyName"].setValue(ret.ResultData["CompanyName"]);
    formRegistration.contains("CompanyType") && formRegistration.controls["CompanyType"].setValue(ret.ResultData["CompanyTypeEnum"]);
    formRegistration.contains("Piva") && formRegistration.controls["Piva"].setValue(ret.ResultData["Piva"]);

    a.unsubscribe();
    return true;
  }


  /**
   * * Open the Google registration page
   */
  openGoogleRegistration(formRegistration: FormGroup) {
    let result = this.socialSignIn(GoogleLoginProvider.PROVIDER_ID);

    result.then(value => {
      if (value != null) {
        this.SocialUser = value;
        formRegistration.controls["Email"].setValue(value["email"]);
        formRegistration.controls["Email"].disable();
        formRegistration.controls["Name"].setValue(value["firstName"]);
        formRegistration.controls["Surname"].setValue(value["lastName"]);
      }
    });
  }

  openFacebookRegistration(formRegistration: FormGroup) {
    let result = this.socialSignIn(FacebookLoginProvider.PROVIDER_ID);

    result.then(value => {
      if (value != null) {
        this.SocialUser = value;
        formRegistration.controls["Email"].setValue(value["email"]);
        formRegistration.controls["Email"].disable();
        formRegistration.controls["Name"].setValue(value["firstName"]);
        formRegistration.controls["Surname"].setValue(value["lastName"]);
      }
    });
  }

  setCompleteStep(value: boolean, formRegistration: FormGroup) {
    if(formRegistration.valid)
      this.completeStep = value;
  }

  /**
   * This function is called when the user pastes something into the CF field.
   * It prevents the default action of the paste event, gets the text pasted, splits it when a
   * semicolon, comma, or newline is found, and then loops through the values and sets the CF value to
   * the value if it's not empty
   * @param {ClipboardEvent} event - ClipboardEvent - The event that was triggered.
   */
  onCfPaste(event: ClipboardEvent, formRegistration: FormGroup, UpdateCF: boolean): void {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/;|,|\n/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          formRegistration.get('CF').setValue(value.trim().toUpperCase());
          UpdateCF = false;
        }
      })
  }

  /**
   * Generate a valid CF code from the form data
   */
  generateCF(formRegistration: FormGroup, UpdateCF: boolean) {
    if (UpdateCF
      && formRegistration.get('Name').valid
      && formRegistration.get('Surname').valid
      && formRegistration.get('Gender').valid
      && formRegistration.get('BirthDate').valid
      && formRegistration.get('BirthCity').valid
      && formRegistration.get('BirthProvince').valid
      && formRegistration.get('BirthState').valid) {

      var BirthState = formRegistration.get('BirthState').value != null ? formRegistration.get('BirthState').value.toUpperCase() : "";
      var BirthCity = formRegistration.get('BirthCity').value != null ? formRegistration.get('BirthCity').value.toUpperCase() : "";
      var BirthProvince = formRegistration.get('BirthProvince').value != null ? formRegistration.get('BirthProvince').value.toUpperCase() : "";

      try {
        const CF = new CodiceFiscale({
          name: formRegistration.get('Name').value.toUpperCase(),
          surname: formRegistration.get('Surname').value.toUpperCase(),
          gender: formRegistration.get('Gender').value.toUpperCase(),
          day: new Date(formRegistration.get('BirthDate').value).getDate(),
          month: new Date(formRegistration.get('BirthDate').value).getMonth() + 1,
          year: new Date(formRegistration.get('BirthDate').value).getFullYear(),
          birthplace: (BirthState === "ITALIA") ? BirthCity : BirthState,
          birthplaceProvincia: BirthProvince // Optional
        });

        formRegistration.get('CF').setValue(CF.cf);
      } catch (error) {
        console.info("Error generating CF code: " + error);
      }
    }

  }
  /**
   * If the password and password confirmation fields don't match, set the password confirmation field
   * to have the error passwordMismatch
   */
  onPasswordInput(formRegistration: FormGroup) {
    if (formRegistration.hasError('passwordMismatch'))
      formRegistration.get('PasswordConfirm').setErrors([{ 'passwordMismatch': true }]);
    else
      formRegistration.get('PasswordConfirm').setErrors(null);
  }
    /**
   * Given a string, return the corresponding gender enum
   * @param {string} gender - The gender of the person.
   * @returns The gender enum value.
   */
    getGenderEnum(gender: string): number {
      switch (gender) {
        case 'M':
          return 0;
        case 'F':
          return 1;
        case 'NS':
          return 2;
        default:
          return 3;
      }
    }

    /**
     * Given a gender value, return the string representation of the gender
     * @param {number} gender - The gender of the person.
     * @returns The gender value for the gender number.
     */
    getGenderValue(gender: number): string {
      switch (gender) {
        case 0:
          return 'M';
        case 1:
          return 'F';
        case 2:
          return 'NS';
        default:
          return 'NS';
      }
    }

  /**
   * * Filter the available provinces by the value of the search input
   * @param {string} value - string - The value of the input element.
   * @returns The filtered list of provinces.
   */
  private _filter(value: string): string[] {
    if (value != null && value.length > 0) {
      const filterValue = value.toLowerCase();
      return this.availableProvinces.filter(province => province.toLowerCase().includes(filterValue));
    }
  }
  /**
 * * Get the value of the form field and store it in a variable.
 */
  setFormChanges(formRegistration: FormGroup, CodiceFiscaleRef: any) {
    this.filteredProvinces = formRegistration.get('BirthProvince').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

    formRegistration.get('CF').valueChanges.subscribe(val => {

      const elRef = CodiceFiscaleRef.nativeElement;
      // get stored original unmodified value (not including last change)
      const orVal = elRef.getAttribute('data-model-value') || '';
      if (val) {
        // modify new value to be equal to the original input (including last change)
        val = val.replace(orVal.toUpperCase(), orVal);
        // store original unmodified value (including last change)
        elRef.setAttribute('data-model-value', val);
        // set view value using DOM value property
        elRef.value = val.toUpperCase();
        // update module without emitting event and without changing view model
        formRegistration.get('CF').setValue(val, {
          emitEvent: false,
          emitModelToViewChange: false
        });
      }
    });
  }

  /**
   * Check if the selected province is in the list of available provinces. If not, clear the province
   * field
   */
  CheckExistingProvince(formRegistration: FormGroup) {
    if (!this.availableProvinces.includes(formRegistration.get('BirthProvince').value.toUpperCase())) {
      formRegistration.get('BirthProvince').setValue('');
    }
  }

  isForeignState: boolean = false;
  /**
   * *This function is used to set the value of the BirthState field in the form.*
   * @param value - The value of the dropdown.
   */
  checkState(value, formRegistration: FormGroup) {
    this.isForeignState = value == this.ForeignState;
    formRegistration.controls["BirthState"].setValue(value);
    this.generateCF(formRegistration, true);
  }

    /**
   * `socialSignIn` is a function that takes a LoginProvider as an argument and returns a promise
   * @param LoginProvider - The provider you want to sign in with.
   * @returns The promise of the signIn() method.
   */
  private socialSignIn(LoginProvider) {
    return this.authService.signIn(LoginProvider);
  }

  private getRegistrationType(){

  }


}
