<div class="registration-cntr">

  <div class="title-cntr">

    <h1 class="duplicate" title="Registrati"></h1>

  </div>
  <ng-container [ngSwitch]="registrationType">
      
    <app-default-registration *ngSwitchCase="'default'"></app-default-registration>
    
    <app-lite-registration *ngSwitchCase="'lite'"></app-lite-registration>
    
    <app-business-registration *ngSwitchCase="'business'"></app-business-registration>
  
  </ng-container>

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>
<app-prefooter></app-prefooter>
