<div class="login-cntr">

  <div class="first-panel-cntr">

    <div class="first-panel">

      <h1>Entra su<br>MyInsurer</h1>
      <br>
      <h3 style="font-weight: unset!important;">Crea il tuo account e entra nella <br> nostra Community</h3>
      <br><br>
      <h3 style="font-weight: unset!important;">Non hai un account? <span (click)="openRegistration()">Registrati qui</span></h3>


    </div>

    <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">


  </div>

  <div class="second-panel-cntr">

    <div class="second-panel">


        <div class="logo-cntr">

            <img src="../../../assets/images/Home/Login/myinsurer_.svg">

        </div>


      <h2>Ciao! Benvenuto</h2>
      <form [formGroup]="loginForm" class="form-cntr">
        <mat-form-field class="col-sm-12">
            <div class="mat-label-txt">Email</div>
                <input matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" class="mat-form-input">

                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    *Inserire un Email valida
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    *Email è un campo richiesto <strong></strong>
                </mat-error>
        </mat-form-field>
          <mat-form-field class="col-sm-12">
              <div class="mat-label-txt">Password</div>
              <div class="form-pwd-cntr">
                <input title="password" matInput [formControl]="passwordFormControl" [type]="hide ? 'password' : 'text'" class="mat-form-input">
                <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </div>
                <mat-error *ngIf="passwordFormControl.hasError('required')">
                    *Password è un campo richiesto <strong></strong>
                </mat-error>
                <a class="pwd-forgot" (click)="openDialog()" title="Hai dimenticato la password?">Hai dimenticato la password?</a>

          </mat-form-field>
          <div class="col-sm-12" style="margin-top: 35px;">

          <button class="new-button" (click)="login()">ACCEDI</button>

          </div>
    </form>
    <div class="link-cntr">
    <h3 style="font-weight: unset!important;">Non hai un account? <span (click)="openRegistration()">Registrati qui</span></h3>
  </div>
    <!-- <div class="social-cntr">
        <div class="social-line"></div>
        <div class="social-txt">Oppure accedi con</div>
        <div class="social-line"></div>
    </div>

    <div class="loghi-cntr">
        <div class="logo-cntr">
            <img (click)="signInWithGoogle()" src="../../../assets/images/Home/Login/logo_google.svg">
        </div>
        <div class="logo-cntr">
            <img (click)="signInWithFacebook()" src="../../../assets/images/Home/Login/facebook.svg">
    </div>


    </div> -->

  </div>

</div>

</div>

<app-prefooter></app-prefooter>
