import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderBackgroundFilter, PolicyHomeDTO } from '../home-policies.component';

declare var require: any;


@Component({
  selector: 'app-header-background',
  templateUrl: './header-background.component.html',
  styleUrls: ['./header-background.component.scss']
})
export class HeaderBackgroundComponent implements OnInit {


  @Input() PolicyHome : PolicyHomeDTO;

  constructor(){}

  ngOnInit() {
  }

}

