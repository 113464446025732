<h3 style="font-weight: initial!important; margin-left: 10px;">cliccando sul prossimo step i documenti si scaricheranno in automatico</h3>
<div class="docs-cntr">

    <ng-container *ngFor="let InfoDoc of data">

        <div class="document-scheda-cntr" [ngClass]="{'readed': InfoDoc.WasAlreadyUploaded || InfoDoc.IsRead}" (click)="setDocAsReaded(InfoDoc)">

          <div class="img-cntr">

            <img src="../../../../../../../assets/images/Configuratore/pdf_scaricabili.svg">

          </div>

          <div class="txt-cntr">

            <h3>{{InfoDoc.Name ? InfoDoc.Name : InfoDoc.OriginalFileName}}</h3>

          </div>

        </div>

    </ng-container>

</div>
