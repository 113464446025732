<ng-container *ngIf="!isShoppingCenter">
  <div
    class="personalProfile"
    (click)="isCollapsed1 = !isCollapsed1"
    [attr.aria-expanded]="isCollapsed1"
    [class.personalProfile-active]="!isCollapsed1"
    aria-controls="collapseExample"
  >
    <img
      class="profile-tab-icon"
      src="../../../../assets/images/icons/profili lavorativi .svg"
    />
    PROFILI LAVORATIVI
    <fa-icon [icon]="faAngleUp" [size]="'2x'" *ngIf="!isCollapsed1"></fa-icon>
    <fa-icon [icon]="faAngleDown" [size]="'2x'" *ngIf="isCollapsed1"></fa-icon>
  </div>
  <div
    id="collapseExample"
    class="personalProfileCollapsable"
    [ngbCollapse]="isCollapsed1"
  >
    <app-edit-jobprofile></app-edit-jobprofile>
  </div>
</ng-container>
<div
  class="personalProfile"
  (click)="isCollapsed2 = !isCollapsed2"
  [attr.aria-expanded]="isCollapsed2"
  [class.personalProfile-active]="!isCollapsed2"

  aria-controls="collapseExample"
>
  <img
    class="profile-tab-icon"
    src="../../../../assets/images/icons/modifica i tuoi dati .svg"
  />
  MODIFICA I TUOI DATI
  <fa-icon [icon]="faAngleUp" [size]="'2x'" *ngIf="!isCollapsed2"></fa-icon>
  <fa-icon [icon]="faAngleDown" [size]="'2x'" *ngIf="isCollapsed2"></fa-icon>
</div>
<div
  id="collapseExample"
  class="personalProfileCollapsable"
  *ngIf="profileType == profileTypesEnum.Business"
  [ngbCollapse]="isCollapsed2"
>
<app-edit-data-business></app-edit-data-business>
</div>
<div
  id="collapseExample"
  class="personalProfileCollapsable"
  *ngIf="profileType == profileTypesEnum.Default"
  [ngbCollapse]="isCollapsed2"
>
<app-edit-data></app-edit-data>
</div>
<div
  class="personalProfile"
  (click)="isCollapsed3 = !isCollapsed3"
  [class.personalProfile-active]="!isCollapsed3"
  [attr.aria-expanded]="isCollapsed3"
  aria-controls="collapseExample"
>
<img
class="profile-tab-icon"
src="../../../../assets/images/icons/modificapw.svg"
/>
  MODIFICA PASSWORD - EMAIL
  <fa-icon [icon]="faAngleUp" [size]="'2x'" *ngIf="!isCollapsed3"></fa-icon>
  <fa-icon [icon]="faAngleDown" [size]="'2x'" *ngIf="isCollapsed3"></fa-icon>
</div>
<div
  id="collapseExample"
  class="personalProfileCollapsable"
  [ngbCollapse]="isCollapsed3"
>
  <app-edit-password></app-edit-password>
</div>
<!-- ACTIVATE DELETE ACCOUNT BY UNCOMMENT DOWN HERE -->

<div
  class="personalProfile"
  (click)="isCollapsed4 = !isCollapsed4"
  [class.personalProfile-active]="!isCollapsed4"
  [attr.aria-expanded]="isCollapsed4"
  aria-controls="collapseExample"
>
<img
class="profile-tab-icon"
src="../../../../assets/images/icons/elimina account .svg"
/>
  ELIMINA IL TUO ACCOUNT
  <fa-icon [icon]="faAngleUp" [size]="'2x'" *ngIf="!isCollapsed4"></fa-icon>
  <fa-icon [icon]="faAngleDown" [size]="'2x'" *ngIf="isCollapsed4"></fa-icon>
</div>
<div
  id="collapseExample"
  class="personalProfileCollapsable"
  [ngbCollapse]="isCollapsed4"
>
  <app-delete-account></app-delete-account>
</div>
