import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent, RecessInfoComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { WhiteFooterComponent } from './white-footer/white-footer.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule,
    SharedModule,
    FontAwesomeModule,
    MatDialogModule
  ],
  declarations: [MenuComponent, FooterComponent, RecessInfoComponent, WhiteFooterComponent],
  exports: [MenuComponent, FooterComponent, RecessInfoComponent, WhiteFooterComponent]
})
export class LayoutModule { }
