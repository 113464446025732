import { Component, OnInit } from '@angular/core';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  formEditMail: UntypedFormGroup;
  formEditPassword: UntypedFormGroup;
  CallData;
  hide = true;
  hideOld = true;
  hideNew = true;


  constructor(
    private appGeneralService: AppGeneralService,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private router: Router
  ) {

    this.formEditMail = this.formBuilder.group({
      NewMail: new UntypedFormControl('', Validators.required),
      Password: new UntypedFormControl('', Validators.required)
    });

    this.formEditPassword = this.formBuilder.group({
      NewPassword: new UntypedFormControl('', Validators.required),
      OldPassword: new UntypedFormControl('', Validators.required),
      PasswordConfirm: new UntypedFormControl('', Validators.required)
    });
  }

  ngOnInit() {
  }

  get formEditMailControls() { return this.formEditMail.controls }
  get formEditPasswordControls() { return this.formEditPassword.controls }

  onPasswordInput() {
    if (this.formEditPassword.hasError('passwordMismatch'))
      this.formEditPassword.get('PasswordConfirm').setErrors([{ 'passwordMismatch': true }]);
    else
      this.formEditPassword.get('PasswordConfirm').setErrors(null);
  }

  saveEditedEmail() {
    if (this.formEditMail.invalid) {
      return;
    }

    this.CallData = {
      email: this.formEditMail.get('NewMail').value,
      password: this.formEditMail.get('Password').value
    };

    this.apiService.callApiProd('/api/Account/RequestEmailUpdate', new CallParameter('POST', this.CallData)).subscribe((result) => {
      if(result.InError){
        this.appGeneralService.showDialog(null, result.Message, "Attenzione!");
      }else{
        let dialog = this.appGeneralService.showDialog(null,
          "Modifica avvenuta con successo",
          "Successo!",
          [{ name: 'OK', action: () => { dialog.close() } }]
        )
      }
    });
  }

  saveEditedPassword() {
    if (this.formEditPassword.invalid) {
      return;
    }

    if(this.formEditPassword.get('NewPassword').value == this.formEditPassword.get('OldPassword').value){
      this.appGeneralService.showDialog(null,"La nuova password non può essere uguale alla precedente");
      return;
    }

    this.CallData = {
      oldPassword: this.formEditPassword.get('OldPassword').value,
      newPassword: this.formEditPassword.get('NewPassword').value
    };

    this.apiService.callApiProd('/api/Account/UpdatePassword', new CallParameter('POST', this.CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.router.navigate(['/login']);
      }

    });

  }

}
