export class Chat {
    Messages: Array<MessageInfo> = [];
    lstPolicyInfo: Array<PurchasedPolicyInfoPreview> = [];
    AutomaticReplyMessages: Array<AutomaticReplyMessage> = [];
}

export class MessageInfo {
    SentByUser:boolean = false;
    MessageContent:string = "";
    Date:Date = new Date();
    UserRead:boolean = false;
    AdminRead:boolean = false;
}

export class PurchasedPolicyInfoPreview{
    ID: Number;
    Title: string;
    Name: string;
    Surname: string;
}

export class AutomaticReplyMessage{
    Id: Number;
    Message: string;
}