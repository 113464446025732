<div [@openClose]="this.visible ? 'open' : 'closed'" class="chatContainer" [hidden]="!this.visible">
  <div class="ChatHead"><h2>Chatta con noi!</h2></div>

  <div class="ChatBody" [style.height]="userPolicies.length > 0 ? '65%' : '75%'">
    <div *ngFor='let message of this.messages.Messages'>
      <div [class]="message.SentByUser == false ? 'Incoming': 'Outgoing'">
        <div>{{message.MessageContent}}</div>
        <div style='font-size: 10px;'>{{message.Date | date}}</div>
      </div>
    </div>
    <div class='faqContainer' *ngIf='!hideFaqMessage'>
      <h2 class='faqTitle'>FAQ</h2>
      <div *ngFor="let autoMessage of this.messages.AutomaticReplyMessages; let i = index">
        <div class="faqMessage" (click)="newFaq(autoMessage.Id)"><span style="font-weight: bold;">{{i+1}}.</span> {{autoMessage.Message}}</div>
      </div>
    </div>
  </div>

  <div class="ChatFoot" [style.height]="userPolicies.length > 0 ? '25%' : '15%'">
    <mat-form-field *ngIf="userPolicies.length > 0">
      <mat-select [(ngModel)]="selectedUserPolicy" required placeholder="Polizza di riferimento" autocomplete="none" name="policy">
        <mat-option *ngFor="let userPolicy of userPolicies" [value]="userPolicy.Id">
            {{userPolicy.Name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="UserMessageInput">
      <input matInput type='text' #NewMessage placeholder="Scrivi e premi invio per inviare il messaggio" (keyup)='setHideFaqMessage(NewMessage.value)'>
    </mat-form-field>
  </div>
</div>
