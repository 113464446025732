import { ConfiguratorSection } from "./ConfiguratorSection";
import { OptionData } from "./OptionData";
import { SubValueData } from "./SubValueData";

export class UserPolicyValueData {
    public ValueID: number;
    public ValueText: string;

    public OptionID: number;
    public OptionTitle: string;
    public OptionSubtitle:string;

    public Category: number; 
    public Price: number;
    public SubValue: SubValueData;
    constructor(section: ConfiguratorSection, option: OptionData, subValue: SubValueData){
        this.Category= section.SectionName == "Garanzie" ? 1 : 2,
        this.OptionID= option.OptionId,
        this.OptionTitle= option.Title,
        this.OptionSubtitle= option.SubTitle,
        this.Price= subValue.Price,
        this.SubValue= subValue,
        this.ValueID= subValue.ID,
        this.ValueText= option.SubTitle
    }
}

export class UserPolicyValueDataSectionDTO{
    public SectionName: string;
    public UserPolicyValueDatas: Array<UserPolicyValueData>;
}