import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardInfos } from 'src/app/core/classes/Feed/card-infos';
import { FeedCarousel, SingleFeed } from 'src/app/core/classes/Feed/feed-carousel';
import { FeedFilterInfo } from 'src/app/core/classes/Feed/feed-filter-info';
import { FeedInfo } from 'src/app/core/classes/Feed/feed-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { CardType } from 'src/app/core/enum/card-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss']
})
export class PoliciesComponent implements OnInit {

  constructor(
    private appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private securityService: SecurityService,
    private location: Location,
    private seoService: SeoService,
    private urlBuilderService: UrlBuilderService
  ) { }

  FeedTypes = [];
  FeedResult: FeedInfo[] = [];
  FeedFilters: FeedFilterInfo[] = [];
  Feeds: FeedCarousel[] = [];
  isWithoutFeed: boolean = false;
  Policies = [];
  GroupedPolicies = [];
  fno: boolean = false;
  displayArrows: boolean = true;
  isSearch: boolean = false;
  searchedValue: string = "";
  selectedFilter: number = 0;
  allpolicyFilter: number = -1;
  cardType: string = CardType.Home;

  ngOnInit() {

    this.FeedFilters = [
      new FeedFilterInfo([10], 'In Offerta', 'in-offerta.webp'),
      new FeedFilterInfo([11], 'Le Più Vendute', 'le_piu_vendute.svg').setIsSelected(true),
      new FeedFilterInfo([this.allpolicyFilter], 'Tutte Le Polizze', 'tutte-lepolizze.webp'),
      new FeedFilterInfo([2,4], 'Casa & Famiglia', 'okcasa.webp'),
      new FeedFilterInfo([12,13], 'Salute', 'saluteok.webp'),
      new FeedFilterInfo([8,9], 'Viaggi', 'viaggi.webp'),
      new FeedFilterInfo([5], 'Studio & Attività Professionisti', 'studioattivita-professionisti.webp'),
      new FeedFilterInfo([16,18,19,20,17], 'Commerciante', 'commerciante.webp'),
      new FeedFilterInfo([14,15], 'Protezione Digitale', 'protezione-digitale.webp'),
    ];

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.activatedRoute.queryParams.subscribe((params) => {

      if (params["ricerca"] != null && params["ricerca"] != undefined) {
        this.searchedValue = params["ricerca"];
        this.location.replaceState("/ricerca");
        this.selectedFilter = this.allpolicyFilter;
        this.FeedFilters.forEach(a => a.setIsSelected(false));
        this.FeedFilters.filter(x => x.FeedIds.some(s => s == this.allpolicyFilter))[0].setIsSelected(true);
      }

      if (params["FeedID"]) {
        this.FeedTypes.push(+params["FeedID"]);
      }

    });

    this.securityService.fno_check.subscribe(fno => {
      this.fno = fno;
    })
    this.getFeed();

    this.getAllPolicies();
  }

  getFeed() {

    if (this.FeedTypes.length < 1) {
      this.FeedTypes = this.FeedFilters.find(x => x.IsSelected == true ).FeedIds;
    }

    this.apiService.callApiProd('/api/Feed/GetPolicyFeed', new CallParameter('POST', { Types: this.FeedTypes, Search: this.searchedValue })).subscribe((result) => {

      if (result.InError) {

        this.appGeneralService.showDialog(result.ErrorCode);

      } else {

        this.FeedResult = result['ResultData']['Feed'];
        this.isWithoutFeed = true;

        this.FeedResult.forEach(z => {
          if (z.CardInfos.map(y => y.Detail).length > 0 && z.CardInfos.map(y => y.Detail).length > 0) {
            this.isWithoutFeed = false;
            z.details = z.CardInfos.map(y => y.Detail);
          }

          // SETUP SEO
          if (this.FeedTypes.length == 1) {
            let policyLabel = z.Label.toLowerCase();
            let titleDynamic: string = 'Tutte le assicurazioni acquistabili su MyInsurer ' + policyLabel;
            let descriptionDynamic: string = 'Scopri tutte le  assicurazioni su MyInsurer ' + policyLabel + ', ampia scelta per ogni tua esigenza acquistabili in pochi click.';
            this.seoService.update_title(titleDynamic);
            this.seoService.update_description(descriptionDynamic);
            this.seoService.update_image('');
          }

        });
        this.Feeds = [];
        this.FeedResult.forEach(feed => {
          this.Feeds.push(new FeedCarousel(feed));
        });
      }

    });
  }

  goToPolicyDetail(GroupID: number) {

    this.urlBuilderService.navigateToPath(GroupID, 0, null);

  }

  filterPolicy(feed: FeedFilterInfo){
    if(feed.FeedIds.some(s => s == this.allpolicyFilter)){
      this.FeedFilters.forEach(a => a.setIsSelected(false));
      feed.setIsSelected(true);
      this.Feeds = [];
      this.selectedFilter = feed.FeedIds[0];
      this.getAllPolicies();
      return;
    }

    this.searchedValue = "";

    this.apiService.callApiProd('/api/Feed/GetPolicyFeed', new CallParameter('POST', { Types: feed.FeedIds, Search: this.searchedValue })).subscribe((result) => {

      if (result.InError) {

        this.appGeneralService.showDialog(result.ErrorCode);

      } else {

        this.FeedResult = result['ResultData']['Feed'];
        this.isWithoutFeed = true;

        this.FeedResult.forEach(z => {
          if (z.CardInfos.map(y => y.Detail).length > 0 && z.CardInfos.map(y => y.Detail).length > 0) {
            this.isWithoutFeed = false;
            z.details = z.CardInfos.map(y => y.Detail);
          }

          // SETUP SEO
          if (this.FeedTypes.length == 1) {
            let policyLabel = z.Label.toLowerCase();
            let titleDynamic: string = 'Tutte le assicurazioni acquistabili su MyInsurer ' + policyLabel;
            let descriptionDynamic: string = 'Scopri tutte le  assicurazioni su MyInsurer ' + policyLabel + ', ampia scelta per ogni tua esigenza acquistabili in pochi click.';
            this.seoService.update_title(titleDynamic);
            this.seoService.update_description(descriptionDynamic);
            this.seoService.update_image('');
          }

        });


        this.Feeds = [];
        this.FeedResult.forEach(feed => {
          this.Feeds.push(new FeedCarousel(feed));
        });

        this.FeedFilters.forEach(a => a.setIsSelected(false));
        feed.setIsSelected(true);
        this.selectedFilter = feed.FeedIds[0];
      }


    });
  }

  getAllPolicies() {
    this.apiService.callApiProd('/api/Policy/ListAvailableGroups', new CallParameter('POST', { Search: this.searchedValue })).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.GroupedPolicies = [];
        this.Policies = result['ResultData']['Results'];
        let totalGroups = this.Policies.length / 6;
        let i = 0;
        for(i; i < totalGroups; i++){
          if(i == 0){
            var preparedCardInfos:CardInfos[] = [];
            this.Policies.slice(i * 6, (i + 1) * 6).forEach(x => {
              let cardInfos = new CardInfos();
              cardInfos.Order = 0;
              cardInfos.Detail = <SingleFeed>{
                ID: x.ID,
                ImageUrls: x.ImageUrls,
                TileTitle: x.TileTitle,
                Title: x.Title,
                Price: x.Price,
                FakePrice: x.FakePrice,
                ExtendedDescription: x.ExtendedDescription
              };
              preparedCardInfos.push(cardInfos);
            });

            this.GroupedPolicies.push([
              new FeedCarousel(<FeedInfo>{
                Id: -1,
                Label: "Tutte le polizze",
                CardInfos: preparedCardInfos,
                details: preparedCardInfos.map(y => y.Detail)
              })]);
            continue;
          }

          this.GroupedPolicies.push(this.Policies.slice(i * 6, (i + 1) * 6));
        }
        if(this.searchedValue != ""){
          this.FeedFilters.forEach(a => a.setIsSelected(false));
          this.FeedFilters.find(a => a.FeedIds.some(s => s == this.allpolicyFilter)).setIsSelected(true);
          this.selectedFilter = this.allpolicyFilter;
        }
      }
      this.appGeneralService.loadingPanel.Hide();

    });
  }

  getSelectedFilter(){
    return this.FeedFilters.filter(x => x.IsSelected)[0];
  }

  @HostListener("window:resize")
  cellsToShow(){
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return "";
    }
      return 2.5;

  }
}

export class FeedTypeArray {

  types: FeedType[];

  constructor(
    types: FeedType[]
  ) {
    this.types = types;
  }

}

export class FeedType {
  type: number;
  constructor(
    type: number
  ) {
    this.type = type
  }

}

