import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dati-marchi',
  templateUrl: './dati-marchi.component.html',
  styleUrls: ['./dati-marchi.component.scss']
})
export class DatiMarchiComponent implements OnInit {

  @Input() tit;
  @Input() num;


  constructor() { }

  ngOnInit() {
  }

}
