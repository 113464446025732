<div class="five-quality-cntr">
    <h1 class="duplicate" title="Perché contare su di noi"></h1>
    <img class="dont-show-mobile" src="../../../../../assets/images/Home/Generic/immagine-perche.webp" >


    <div class="show-mobile">

      <button class="new-button qualità" (click)="openInfoDialog(1)">qualità'</button>
      <button class="new-button velocità" (click)="openInfoDialog(2)">velocità'</button>
      <button class="new-button semplicità" (click)="openInfoDialog(3)">semplicità'</button>
      <button class="new-button sostenibilità" (click)="openInfoDialog(5)">sostenibilità'</button>
      <button class="new-button affidabilità" (click)="openInfoDialog(4)">affidabilità'</button>


    <img src="../../../../../assets/images/Home/Generic/contare_su_di_noi_mobile.webp" >



  </div>
    <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

  </div>
