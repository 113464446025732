
<h3 mat-dialog-title [innerHTML]="modalBuilder.title">
</h3>
<div mat-dialog-content>
    <p [innerHTML]="modalBuilder.message"></p>
</div>
<div mat-dialog-actions>
    <ng-container *ngFor="let button of modalBuilder.buttons">
        <button class="new-button popup-button" (click)="button.action()">{{button.name}}</button>
    </ng-container>
</div>
