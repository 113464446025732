import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faChevronDown, faChevronUp, faClipboardList, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { AddToCartParameter } from 'src/app/core/classes/Configurator/AddToCartParameter';
import { ConfiguratorSection } from 'src/app/core/classes/Configurator/ConfiguratorSection';
import { OptionData } from 'src/app/core/classes/Configurator/OptionData';
import { UserPolicyValueData, UserPolicyValueDataSectionDTO } from 'src/app/core/classes/Configurator/UserPolicyValueData';
import { PolicyInfo } from 'src/app/core/classes/Policy/policy-info';
import { PolicyGroupInfo } from 'src/app/core/classes/PolicyGroup/PolicyGroupInfo';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { CardType } from 'src/app/core/enum/card-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { AddedToCartComponent } from 'src/app/shared/dialogs/added-to-cart/added-to-cart.component';
import { ChooseProfilePolicyComponent } from 'src/app/shared/dialogs/choose-profile-policy/choose-profile-policy.component';
import { OptionInfoDialogComponent } from '../policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';
import { CallApiProdParameter } from './../../core/services/api.service';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss']
})
export class ConfiguratorComponent implements OnInit {

  PolicyGroupInfos: Array<PolicyGroupInfo>;
  PolicyInfos: Array<PolicyInfo>;
  PolicyInfosConfigurator: Array<PolicyInfo>;
  showConfguration: boolean = false;
  PolicyConfiguration: PolicyGroupInfo;
  ChoosenConfigurationSections: Array<UserPolicyValueDataSectionDTO> = [];
  Total: number = 0;
  faInfoCircle = faInfoCircle;
  faClipboardList = faClipboardList;
  faTimes = faTimes;
  cartItems: number = 0;
  cartListHidden: boolean = false;
  cartListCloseHidden: boolean = true;
  @ViewChild('buttonMaximal') buttonMaximal;
  minWidth: number = 768;
  cardType: string = CardType.Generic;

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private router: Router,
    private securityService: SecurityService
  ) { }

  ngOnInit() {

    this.getPolicyGroups();

    if(window.innerWidth <= this.minWidth){
      this.cartListHidden = true;
      this.cartListCloseHidden = false;
    }
  }

  getCardFunction(id){
    return () => {this.getPolicyDetail(id)};
  }

  getPolicyGroups() {
    this.apiService.callApiProd('/api/Configurator/ListPolicyGroups', new CallParameter("POST")).subscribe(res => {
      if(res.InError){
        this.appGeneralService.showDialog(res.ErrorCode);
        return;
      }
      this.PolicyGroupInfos = res.ResultData["Results"];

    });
  }

  selectedCardCallback(id: number){
    let selectedItem = this.PolicyGroupInfos.find(z => z.ID == id);
    if(selectedItem == null || selectedItem == undefined){
      return;
    }
    this.PolicyGroupInfos.filter(f => f.ID != id).forEach(z => z.IsSelected = false);
    selectedItem.IsSelected = !selectedItem.IsSelected;
  }

  removeNotConfigurationPolicy(){

    this.PolicyInfosConfigurator = this.PolicyInfos.filter(e => e.ConfiguratorSections?.length >0);
  }

  getPolicyDetail(Id){
    this.PolicyInfos = [];
    if(this.showConfguration == false){
      this.showConfguration = true;
    }

    var callParameter : CallApiProdParameter= CallApiProdParameter.create("/api/Configurator/ListPolicies", new CallParameter("POST", {PolicyGroupId: Id}) ,"POST");

    this.apiService.callApiProduction(callParameter).subscribe(res => {
      if(res.InError){
        this.appGeneralService.showDialog(res.ErrorCode);
        return;
      }
      this.PolicyInfos = res.ResultData["policyInfos"];
      this.removeNotConfigurationPolicy();
    });


    this.ChoosenConfigurationSections = [];
    this.PolicyConfiguration = this.PolicyGroupInfos.find(z => z.ID == Id);
    this.PolicyConfiguration.sections.forEach(section => {
      section.Options
      .forEach((option:OptionData) => {
        this.setOption(option);
      });

      section.Options = section.Options.filter(z => z.OnConfiguratorVisible == true);
    })

  }

  setOption(option: OptionData){
    option.Collapsed = true;
    option.Chevron = faChevronDown;
    option.SubValueDatas.forEach(subValueData => {
      subValueData.Disabled = false;
      subValueData.Maximal = subValueData.Maximal.replace(",",".");
    })
    option.SubValueDatas = option.SubValueDatas.sort((a, b) => Number(a.Maximal) > Number(b.Maximal) ? 1 : -1);
  }

  getPolicyConfiguration(Id){
    return () => {

      this.resetCart();

      this.PolicyInfos.find(z => z.ID == Id).ConfiguratorSections.forEach(section => {
        section.Options.forEach(option => {
          option.SubValueDatas.forEach(subValueData => {
            let configSection = this.PolicyConfiguration.sections.find(z => z.SectionName == section.SectionName);
            if(configSection != null){
              let configOption = configSection.Options.find(z => z.OptionId == option.OptionId);
              if(configOption != null){
                configOption.Collapsed = false;
                configOption.Chevron = faChevronUp;
                let configSubValueData = configOption.SubValueDatas.find(z => z.ID == subValueData.ID);
                if(configSubValueData != null){
                  this.setNewPrice(configSection, configOption.OptionId, configSubValueData.ID);
                }
              }
            }
          });
        });
      });
      this.goToConfigurator();
    };
  }

  resetCart(){
    this.ChoosenConfigurationSections = [];
    this.PolicyConfiguration.sections.forEach(section => {
      section.Options
      .forEach((option: OptionData) => {
        this.setOption(option);
      });
    });
  }

  setNewPrice(section: ConfiguratorSection, optionID, SubValueId){
    let existingSection = this.ChoosenConfigurationSections.find(choosenSection => choosenSection.SectionName == section.SectionName);
    if (existingSection == null || existingSection == undefined){
      existingSection = new UserPolicyValueDataSectionDTO ();
      existingSection.SectionName = section.SectionName;
      existingSection.UserPolicyValueDatas = [];
      this.ChoosenConfigurationSections.push(existingSection);
    }

    this.ChoosenConfigurationSections.sort((a, b) => {
      if(a.SectionName == "Garanzie")
        return -1;
      if(b.SectionName == "Garanzie")
        return 1;
    });

    let existingOption = existingSection.UserPolicyValueDatas.find(configuration => configuration.OptionID == optionID);

    if((existingOption != null || existingOption != undefined) && existingOption.SubValue.ID == SubValueId){
      existingOption.SubValue.Disabled = false;
      existingSection.UserPolicyValueDatas.splice(existingSection.UserPolicyValueDatas.findIndex(z => z.SubValue.ID == SubValueId), 1);
      if(existingSection.UserPolicyValueDatas.length <= 0){
        this.ChoosenConfigurationSections.splice(this.ChoosenConfigurationSections.findIndex(z => z.SectionName == existingSection.SectionName), 1);
      }
      this.TotalRefresh();
      return;
    }

    let option = section.Options.find(z => z.OptionId == optionID);

    if(existingOption != null || existingOption != undefined){
      option.SubValueDatas.find(subValue => subValue.ID == existingOption.SubValue.ID).Disabled = false;
      existingOption.SubValue = option.SubValueDatas.find(subValue => subValue.ID == SubValueId);
      option.SubValueDatas.find(subValue => subValue.ID == SubValueId).Disabled = true;
      this.TotalRefresh();
      return;
    }

    let subValue = option.SubValueDatas.find(vd => vd.ID == SubValueId);
    subValue.Disabled = true;
    var newOption = new UserPolicyValueData(section, option, subValue);
    existingSection.UserPolicyValueDatas.push(newOption);
    this.TotalRefresh();
  }

  TotalRefresh(){
    this.Total = 0;
    this.cartItems = 0;
    this.ChoosenConfigurationSections.forEach(element => {
      element.UserPolicyValueDatas.forEach(z => {
        this.Total = this.Total + z.SubValue.Price;
        this.cartItems++;
      })
    });
  }

  buyCart(isBuyNow: boolean = false){
    let CallData: AddToCartParameter = new AddToCartParameter();
    CallData.PolicyGroupId = this.PolicyConfiguration.ID;
    this.ChoosenConfigurationSections.forEach(section => {
      section.UserPolicyValueDatas.forEach(valueData => {
        CallData.UserPolicyValueDatas.push(valueData);
      })
    });

    if(this.ChoosenConfigurationSections.length < 1){
      this.openInfoDialog("Non hai selezionato alcuna garanzia tra quelle disponibili. Per poter procedere all'acquisto seleziona una o più garanzie.","ATTENZIONE");
      return;
    }

    if(this.securityService.IsLogged){


    let ChooseProfileDialog = this.dialog.open(ChooseProfilePolicyComponent, {
      panelClass: 'custom-dialog-container',
      width:'500px',
      data: {
        Profiles: this.PolicyConfiguration.Profiles
      }
    });

    ChooseProfileDialog.afterClosed().subscribe(ret => {
      if(ret.choice == false){
        return;
      }

      CallData.ProfileID = ret.profileID;
      this.addToCart(CallData,isBuyNow);

    });
    }
    else{

      this.addToCart(CallData,isBuyNow);

    }

  }



  addToCart(CallData : AddToCartParameter, isBuyNow : boolean){

  this.apiService.callApiProd("/api/Configurator/AddPolicyToCart", new CallParameter("POST",CallData)).subscribe(res => {
    if(res.InError){
      this.appGeneralService.showDialog(res.ErrorCode);
      return;
    }

    if(!isBuyNow){
      const dialogRef = this.dialog.open(AddedToCartComponent,{ panelClass: 'custom-dialog-container', width:'500px'});
      return;
    }else{
      this.router.navigate(['acquista-polizza/' + res.ResultData['UserPolicyId']]);
    }
  })

  }


  openInfoDialog(Description: string,Title?: string) {

    var _this = this;
      let dialogRef = _this.dialog.open(OptionInfoDialogComponent, {
        panelClass: 'SurveyModalCntr',
        maxHeight: '90vh',
        data: {Description: Description, Title : Title},
        autoFocus: false
      });
  }

  toggleCollapseOption(option: OptionData){
    option.Collapsed = !option.Collapsed;
    option.Chevron = option.Collapsed ? faChevronDown : faChevronUp;
  }

  toggleCart(){
    this.cartListHidden = !this.cartListHidden;
  }

  @HostListener('window:resize')
    onWindowResize(){
      if(window.innerWidth <= this.minWidth){

        this.cartListCloseHidden = false;
        this.cartListHidden = true;
      }else{

        this.cartListCloseHidden = true;
        this.cartListHidden = false;
      }
      this.calculateArrows();
    }

    calculateArrows() {

      let a: number;
      if (this.PolicyGroupInfos && this.PolicyGroupInfos.length) {
         a = (this.PolicyGroupInfos?.length + this.PolicyGroupInfos.length) * 170;
      } else {
        a = this.PolicyGroupInfos.length * 170;
      }

      let b = $(".carousel-container").width();
      return b - a <= 0;
    }



  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 400;
    }
      return 280;

  }


  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 480;
    }
      return 380;

  }

  goToConfigurator(){

    setTimeout(() => {
      const element = document.getElementById("destination");
      if (element) element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 600);

  }


}
