<ng-container *ngIf="ReadmoreCarouselItems != null && ReadmoreCarouselItems != undefined && ReadmoreCarouselItems.length > 0">
<div class="readmore-cntr">
  <h1 style="margin-bottom: 30px; padding-top: 50px;" >Tieniti informato</h1>

  <carousel [cellWidth]="calculateWidth()" [height]="260" [margin]="15" *ngIf="ReadmoreCarouselItems">
    <div class="carousel-cell" style="border-radius: 10px;" *ngFor="let Item of ReadmoreCarouselItems">

        <app-single-readmore [SingleReadmore]="Item"></app-single-readmore>

    </div>
</carousel>
</div>
</ng-container>



