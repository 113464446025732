import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { threadId } from 'worker_threads';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-password-recovery-dialog',
  templateUrl: './password-recovery-dialog.component.html',
  styleUrls: ['./password-recovery-dialog.component.scss']
})
export class PasswordRecoveryDialogComponent implements OnInit {

  formPasswordRecover: UntypedFormGroup;

  constructor(
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private appGeneralService: AppGeneralService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PasswordRecoveryDialogComponent>,
  ) { }

  ngOnInit() {

    this.formPasswordRecover = this.formBuilder.group({
      Email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  
  }

  get formPasswordRecoverControls() { return this.formPasswordRecover.controls }

  passwordRecovery() {
    const paramsRecovery = new Email(this.formPasswordRecover.get('Email').value);
    this.apiService.callApiProd('/api/Account/PasswordRecovery', new CallParameter('POST', paramsRecovery)).subscribe((result) => {
      if(result.InError){
        this.dialogRef.close({res: false, error: result.ErrorCode});
        return;
      }
      this.dialogRef.close({res:true});
    });
  }

}

export class Email {
  email: string;
  constructor(
    email: string
    ) {
    this.email = email;
  }
}