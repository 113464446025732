<div class="configurator-header-cntr">

  <div class="img-cntr">

    <img src="../../../../assets/images/Configuratore/crealatuapolizza.webp">

  </div>

  <div class="txt-cntr">

    <h1 class="duplicate" title="Configura la tua Polizza"></h1>
    <h3>Scegli la copertura assicurativa che desideri tra quelle selezionate e personalizzala in base alle tue esigenze!
      Configura la tua tranquillità!
      </h3>

  </div>

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">


</div>
