import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-story',
  templateUrl: './feedback-story.component.html',
  styleUrls: ['./feedback-story.component.scss']
})
export class FeedbackStoryComponent implements OnInit {

  constructor() { }

  Stories : Array<Story> =[

    {
      Name: "Sabrina",
      Star: 4,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ImgUrl : "../../../../../../../assets/images/Home/Story/img-story1.webp"
    },
    {
      Name: "Matteo",
      Star: 5,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ImgUrl : "../../../../../../../assets/images/Home/Story/img-story2.webp"
    },
    {
      Name: "Alessio",
      Star: 3,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ImgUrl : "../../../../../../../assets/images/Home/Story/img-story3.webp"
    },
    {
      Name: "Serena",
      Star: 3,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ImgUrl : "../../../../../../../assets/images/Home/Story/img-story4.webp"
    },
    {
      Name: "Pier Giorgio",
      Star: 5,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      ImgUrl : "../../../../../../../assets/images/Home/Story/img-story5.webp"
    },


  ];

  ngOnInit(): void {
  }



}
export class Story{

  Name : string
  Star : number
  Description : string
  ImgUrl : string
}
