import { BoxDTO } from "./BoxDTO";

export class ShoppingCenterDTO{


  public BoxArray : Array<BoxDTO>;

  public constructor(){

    this.BoxArray = new Array<BoxDTO>();

  }




}
