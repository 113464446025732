<h1>Polizze
    <div class="closeBtn" (click)="close()">
        <fa-icon [icon]="faTimes"></fa-icon>
    </div>
</h1>

<div class="row">

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box blue">
            <div class="guideNumber yellow">1</div>
            <div class="guideText">
                <h2>VISUALIZZA</h2>
                Tutte le polizze sono nella home e nella pagina dedicata. Guarda i dettagli e i pacchetti disponibili per ciascuna.
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box blue">
            <div class="guideNumber yellow">2</div>
            <div class="guideText">
                <h2>SCEGLI</h2>
                Seleziona il pacchetto che preferisci o personalizzalo grazie al configuratore. Una volta trovata, scegli la polizza più adatta a te!
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box blue">
            <div class="guideNumber yellow">3</div>
            <div class="guideText">
                <h2>ACQUISTA</h2>
                Carica e/o scarica i documenti necessari. Paga con bonifico o carta di credito. Una volta acquistate le polizze le troverai nella tua area personale.
            </div>
        </div>
    </div>

</div>

<h1 style="justify-content: left;">Servizi</h1>

<div class="row">

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box lightBlue">
            <div class="guideNumber blue">1</div>
            <div class="guideText">
                <h2>VISUALIZZA</h2>
                I servizi sono nella home e nella pagina dedicata. Guarda i dettagli e scegli quello più adatto a te.
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box lightBlue">
            <div class="guideNumber blue">2</div>
            <div class="guideText">
                <h2>ACQUISTA</h2>
                Tramite bonifico o carta di credito. I servizi acquistati sono nella tua area personale, lì potrai prenotare la data di utilizzo.
            </div>

        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="box lightBlue">
            <div class="guideNumber blue">3</div>
            <div class="guideText">
                <h2>PRENOTA</h2>
                Cliccando su "utilizza" verrai indirizzato al link per la prenotazione.
            </div>
        </div>
    </div>

</div>