<div class="carello-cntr">


    <h1 class="duplicate" title="CARRELLO"></h1>

    <div class="text-center" *ngIf="CartItems == 0">
        <h2>Non ci sono elementi nel tuo carrello<br /></h2>
        <button class="new-button" [routerLink]="['/']">TORNA ALLA HOME</button>
    </div>

    <ng-container *ngIf="ListCartItems && ListCartItems.Policies && ListCartItems.Servicies">
        <ng-container *ngIf="ListCartItems.Policies.length > 0;">
            <ng-container *ngFor="let CartPolicy of ListCartItems.Policies">

                <app-cart-item [IsPolicy]="true" [CartItem]="CartPolicy" (onPolicyDeleted)="onPolicyChange()"></app-cart-item>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="ListCartItems.Servicies.length > 0;">
            <ng-container *ngFor="let CartService of ListCartItems.Servicies">

                <app-cart-item [IsPolicy]="false" [CartItem]="CartService" (onServiceDeleted)="onServiceChange()"></app-cart-item>

            </ng-container>
        </ng-container>

        <h2 *ngIf="BoughtTogether.length > 0">Prodotti che potrebbero interessarti</h2>
        <app-bought-together [BoughtTogether]="BoughtTogether"></app-bought-together>

    </ng-container>

    <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>
<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>