import { Component, Input, OnInit } from '@angular/core';
import * as internal from 'events';

@Component({
  selector: 'app-configurator-price',
  templateUrl: './configurator-price.component.html',
  styleUrls: ['./configurator-price.component.scss']
})
export class ConfiguratorPriceComponent implements OnInit {

  @Input()
  price: number | undefined;

  constructor() { }

  ngOnInit() {
  }

}
