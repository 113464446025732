import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsletterMailchimpComponent } from './newsletter-mailchimp.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    NewsletterMailchimpComponent
  ],
  exports:[
    NewsletterMailchimpComponent
  ]
})
export class NewsletterMailchimpModule { }
