import { Injectable } from '@angular/core';
import { IUserTypeValue } from '../classes/User/UserType';
import { ProfileTypes } from '../enum/SubUserProfileType.enum';
import { RegistrationStep } from '../enum/registration-step.enum';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  readonly ConstantString = {
    familyString: `${ProfileTypes.family}`,
    businessString: `${ProfileTypes.business}`,
    userString: `${ProfileTypes.user}`,
    registrationStep: "RegistrationStep",
    RegistrationCompleted: RegistrationStep.STEP4,
    lastPageSeen: "lastPageSeen",
    primaryColor: "primaryColor",
    secondaryColor: "secondaryColor",
    logoUrl: "logoUrl",
    brokerManagement: "BrokerManagement",
    facebookLike: "FacebookLike",
    queryFnoFlag: "QueryFnoFlag",
    fnoFlag: "FnoFlag",
    bearerCookie: "",
    registrationConvention: "RegistrationConvention",
    loginConvention: "LoginConvention",
    userData: "UserData",
    authenticationLevel: "WorkAuthenticationLevel",
    sessionExpireDate: "SessionExpireDate",
    sessionID: "SessionID",
    cartItems: "CartItems",
    itemsInCart: "ItemsInCart",
    registrationIncomplete: "RegistrationIncomplete",
    siteType: "SiteType",
    userType: "UserType"
  }
  constructor() { }

  private getLocal = (key: string): any => localStorage.getItem(key);
  private setLocal = (key: string, value: any): any => localStorage.setItem(key, value);
  private removeLocal = (key: string): any => localStorage.removeItem(key);


  public getSiteType =(): string => this.getLocal(this.ConstantString.siteType);
  public setSiteType = (value : string) => this.setLocal(this.ConstantString.siteType, value);

  public getRegistrationStep = (): string => this.getLocal(this.ConstantString.registrationStep);
  public setRegistrationStep = (value: string) => this.setLocal(this.ConstantString.registrationStep, value);


  public getBrokerManagement = (): boolean => this.getLocal(this.ConstantString.brokerManagement) ?? false;
  public getBrokerManagementLabel(isUpperCase: boolean, isSingular: boolean): string {
    let singularConsultant = isUpperCase ? "Consulente" : "consulente";
    let pluralConsultant = isUpperCase ? "Consulenti" : "consulenti";
    let singularConvention = isUpperCase ? "Convenzione" : "convenzione";
    let pluralConvention = isUpperCase ? "Convenzioni" : "convenzioni";
    if (this.getBrokerManagement()) {
      return isSingular ? singularConsultant : pluralConsultant;
    }
    return isSingular ? singularConvention : pluralConvention;
  }
  public setBrokerManagement = (value: boolean) => this.setLocal(this.ConstantString.brokerManagement, value);
  public removeBrokerManagement = () => this.removeLocal(this.ConstantString.brokerManagement);


  public getPrimaryColor = (): string => this.getLocal(this.ConstantString.primaryColor);
  public setPrimaryColor = (value: string) => this.setLocal(this.ConstantString.primaryColor, value);
  public removePrimaryColor = () => this.removeLocal(this.ConstantString.primaryColor);


  public getSecondaryColor = (): string => this.getLocal(this.ConstantString.secondaryColor);
  public setSecondaryColor = (value: string) => this.setLocal(this.ConstantString.secondaryColor, value);
  public removeSecondaryColor = () => this.removeLocal(this.ConstantString.secondaryColor);


  public getLogoUrl = (): string => this.getLocal(this.ConstantString.logoUrl);
  public setLogoUrl = (value: string) => this.setLocal(this.ConstantString.logoUrl, value);
  public removeLogoUrl = () => this.removeLocal(this.ConstantString.logoUrl);


  public getLastPageSeen = (): string => this.getLocal(this.ConstantString.lastPageSeen);
  public setLastPageSeen = (value: string) => this.setLocal(this.ConstantString.lastPageSeen, value);
  public getOrSetDefaultLastPageSeen(defaultValue: string): string {
    let value = this.getLastPageSeen();
    if (value != null)
      return value;
    this.setLastPageSeen(defaultValue);
    return defaultValue;
  }


  public getFacebookLike(): boolean {

    let facebookLikeValue = this.getLocal(this.ConstantString.facebookLike);

    if (facebookLikeValue) {

      let facebookLikeDate = new Date(facebookLikeValue);

      let today = new Date();
      let expires = new Date(facebookLikeDate.setMonth(facebookLikeDate.getMonth() + 1));

      if (expires < today)
        return false;
      else
        return true;

    }

    return false;

  }
  public setFacebookLike = (value: Date) => this.setLocal(this.ConstantString.facebookLike, value);


  public getQueryFnoFlag = (): boolean => this.getLocal(this.ConstantString.queryFnoFlag) ?? false;
  public setQueryFnoFlag = (value: boolean) => this.setLocal(this.ConstantString.queryFnoFlag, value);


  public getFnoFlag = (): boolean => this.getLocal(this.ConstantString.fnoFlag) ?? false;
  public setFnoFlag = (value: boolean) => this.setLocal(this.ConstantString.fnoFlag, value);


  public getBearerCookie = (): string => this.getLocal(this.ConstantString.bearerCookie);
  public setBearerCookie = (value: string) => this.setLocal(this.ConstantString.bearerCookie, value);
  public removeBearerCookie = () => this.removeLocal(this.ConstantString.bearerCookie);


  public getRegistrationConvention = (): string => this.getLocal(this.ConstantString.registrationConvention);
  public setRegistrationConvention = (value: string) => this.setLocal(this.ConstantString.registrationConvention, value);


  public getLoginConvention = (): string => this.getLocal(this.ConstantString.loginConvention);
  public setLoginConvention = (value: string) => this.setLocal(this.ConstantString.loginConvention, value);


  public getUserData = (): string => this.getLocal(this.ConstantString.userData);
  public setUserData = (value: string) => this.setLocal(this.ConstantString.userData, value);
  public removeUserData = () => this.removeLocal(this.ConstantString.userData);



  public getAuthenticationLevel = (): string => this.getLocal(this.ConstantString.authenticationLevel);
  public setAuthenticationLevel = (value: string) => this.setLocal(this.ConstantString.authenticationLevel, value);


  public getSessionExpireDate = (): string => this.getLocal(this.ConstantString.sessionExpireDate);
  public setSessionExpireDate = (value: string) => this.setLocal(this.ConstantString.sessionExpireDate, value);


  public getSessionID = (): string => this.getLocal(this.ConstantString.sessionID);
  public setSessionID = (value: string) => this.setLocal(this.ConstantString.sessionID, value);


  public getRegistrationIncomplete (): boolean {

    return(this.getLocal(this.ConstantString.registrationIncomplete) == "true")

  }
  public setRegistrationIncomplete = (value: boolean) => this.setLocal(this.ConstantString.registrationIncomplete, value?? false);


  public getCartItems = (): any => this.getLocal(this.ConstantString.cartItems);
  public setCartItems = (value: any) => this.setLocal(this.ConstantString.cartItems, value);


  public getItemsInCart = (): any => this.getLocal(this.ConstantString.itemsInCart);
  public setItemsInCart = (value: any) => this.setLocal(this.ConstantString.itemsInCart, value);


  public getUserType = (): IUserTypeValue => <IUserTypeValue>JSON.parse(this.getLocal(this.ConstantString.userType));
  public setUserType = (value: string) => this.setLocal(this.ConstantString.userType, value);

}
