<div class="main-contact-cntr">

  <div class="panel-cntr">

    <div class="panel">

      <h3 style="font-weight: unset!important;">Orari:</h3><br>
      <h3>Lunedì Martedì Mercoledì Giovedì:</h3>
      <h3 style="font-weight: unset!important;">08:30-12:30 / 14:30-18:30</h3><br>
      <h3>Venerdì:</h3>
      <h3 style="font-weight: unset!important;">08:30-14:00</h3><br>
      <h3>Sabato Domenica:</h3>
      <h3 style="font-weight: unset!important;">chiuso</h3><br>
      <button class="new-button mb-2" (click)="chiama()" >010565582</button>
      <button class="new-button mb-2" (click)="email('info@buzziinsurancegroup.com')">info@buzziinsurancegroup.com</button>
      <button class="new-button"(click)="email('buzzisnc@pec.it')">buzzisnc@pec.it</button>

    </div>
  </div>
  <div class="panel-cntr">
    <div class="panel">

      <h3 style="font-weight: unset!important;">Nome società:</h3>
      <h3>Buzzi Insurance Group s.r.l.</h3><br>
      <h3 style="font-weight: unset!important;">Indirizzo:</h3>
      <h3>Via Ippolito d'Aste, 1/2, 16121 Genova</h3><br>
      <h3 style="font-weight: unset!important;">P.IVA:</h3>
      <h3>03317980104</h3><br>
      <h3 style="font-weight: unset!important;">Iscrizione RUI:</h3>
      <h3>Buzzi Insurance Group s.r.l. N. A000070111 del 12/02/2007</h3><br>
      <h3 style="font-weight: unset!important;">Informazioni:</h3>
      <h3 style="font-weight: unset!important;">Soggetto a vigilanza: <a href="https://www.ivass.it" title="IVASS">IVASS</a></h3>
      <h3 style="font-weight: unset!important;">Numero per reclami: <a href="tel:800042042" title="Telefono reclami IVASS">800-042-042</a></h3>
      <h3 style="font-weight: unset!important;">Form reclami: <a href="https://www.italiana.it/reclami" target="_blank" rel="noopener" title="Informazioni sui reclami">clicca qui</a></h3>
    </div>
  </div>

  <img class="dont-show-mobile" src="../../../../assets/images/Contatti/CERCHIO_SFONDO.svg">
</div>
