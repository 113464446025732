<div class="feedback-cntr">
<div class="text-center feedback">

  <h2>La tua opinione per noi è importante.</h2>
  <h2>Com'è stata la tua esperienza per l'acquisto?</h2>
  <h3 style="font-size: 15px!important; font-weight: initial!important;">Lasciaci un tuo feedback</h3>


<div class="d-flex emoji-cntr">
<img src="../../../assets/images/emoji/arrabbiato.svg" (click)="changeSelected(0)" class="emoji" [ngClass]="{'sel': selectedImage === 0 }">
<img src="../../../assets/images/emoji/indifferente.svg" (click)="changeSelected(25)" class="emoji" [ngClass]="{'sel': selectedImage === 25 }">
<img src="../../../assets/images/emoji/medio.svg" (click)="changeSelected(50)" class="emoji" [ngClass]="{'sel': selectedImage === 50 }">
<img src="../../../assets/images/emoji/felice.svg" (click)="changeSelected(75)" class="emoji" [ngClass]="{'sel': selectedImage === 75 }">
<img src="../../../assets/images/emoji/moltofelice.svg" (click)="changeSelected(100)" class="emoji" [ngClass]="{'sel': selectedImage === 100 }">
</div>


<h3 style="float:left; font-size: 15px!important;text-align: left; margin: 0;">Lasciaci un commento</h3>

<form [formGroup]="form" (submit)="sendFeedback()">
          <mat-form-field style="width: 100%;">
            <textarea matInput formControlName="Note" style="border: 1px solid; border-radius: 20px;min-height: 15vh; padding: 10px;"></textarea>
          </mat-form-field>

          <button class="new-button"> INVIA</button>

</form>
</div>
</div>
