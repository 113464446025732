<div class="PriceBoxCntr">
    <div class="bodyCntr">
        <ng-container *ngFor="let Section of ConfiguratorSections">
            <h4>{{Section.SectionName}}</h4>
            <div class="sectionContainer">
                <div class="singleRow" *ngFor="let Option of Section.Options">
                    <div class="d-inline">
                        <div class="detailText">
                            {{Option.Title}}
                        </div>
                        <div class="info-icon">
                            <a (click)="openInfoDialog(Option.Description, Option.Title)" title="Info">
                                <fa-icon [icon]="faInfoCircle"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <ng-container *ngIf="Option.SubValueDatas && Option.SubValueDatas.length > 0">
                        <span class="subPrice">{{Option.SubValueDatas[0].Maximal | price}}</span>
                    </ng-container>
                    <ng-container *ngIf="!Option.SubValueDatas && Option.SubValueDatas.length > 0 && Option.ChosenValue != null && Option.ChosenValue != undefined ">
                        <span class="subPrice">{{Option.ChosenValue}} &euro;</span>
                    </ng-container>
                    <ng-container *ngIf="Option.ChosenValue == null && Option.ChosenValue == undefined && Option.SubValueDatas.length <= 0">
                        <span class="subPrice">0 &euro;</span>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>