import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
  selector: 'app-long-text-container',
  templateUrl: './long-text-container.component.html',
  styleUrls: ['./long-text-container.component.scss']
})
export class LongTextContainerComponent implements OnInit, AfterViewInit {

  longEnough;
  faChevronUp = faChevronUp;
  isUp = true;
  @Input() hideHeight;
  @Input() toHideText: string;
  @ViewChild('ExtendedDescription') extendedDescription: ElementRef<any>;
  realHeight;

  constructor(  ) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {

    this.IsLongEnough();
  }


  toggleLenght(){
    let elm = $(this.extendedDescription.nativeElement);
    if(this.longEnough){
      return;
    }

    if (elm.height() == this.realHeight) {
      elm.height(this.hideHeight);
      this.isUp = true;
      setTimeout(function () {

        $('html, body').animate({
          scrollTop: elm.offset().top
      }, 2000);
      }, 0);
    }else{
      elm.height(this.realHeight);
      this.isUp = false;
    }
  }

  @HostListener('window:resize')
  IsLongEnough(){
      if(this.realHeight == undefined || this.realHeight == null || this.realHeight == 0){
        this.realHeight = $(this.extendedDescription.nativeElement).height();
      }
      this.longEnough = false;
      $(this.extendedDescription.nativeElement).height(this.hideHeight);

      if (this.realHeight < this.hideHeight) {
        this.longEnough = true;
        $(this.extendedDescription.nativeElement).height(this.realHeight);
      }
  }

}
