import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { OptionInfoDialogComponent } from 'src/app/pages/policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';

@Component({
  selector: 'app-configurator-grid',
  templateUrl: './configurator-grid.component.html',
  styleUrls: ['./configurator-grid.component.scss']
})
export class ConfiguratorGridComponent implements OnInit {

  @Input() ConfiguratorSections: [];
  faInfoCircle = faInfoCircle;
  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }


  openInfoDialog(Description: string, Title: string) {

    var _this = this;
      let dialogRef = _this.dialog.open(OptionInfoDialogComponent, {
        panelClass: 'SurveyModalCntr',
        minWidth: '400px',
        width:'400px',
        maxHeight: '90vh',
        data: {Description, Title},
        autoFocus: false
      });

      // dialogRef.afterClosed().subscribe(result => {
      //   _this.GetDocuments();
      // });
  }

}
