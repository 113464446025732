import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { faBars, faChevronDown, faChevronUp, faSearch, faShoppingCart, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faSearch = faSearch;
  faBars = faBars;
  faTimes = faTimes;
  faChevronDownDocuments = faChevronDown;
  initialName = "";

  isAdmin = false;

  visible: boolean = false;
  visibleProfile: boolean = true;
  faChevronDown = faChevronDown;

  userName: string;
  profileTypesString: string;
  configuratore: boolean | string = false;

  @Input('routes') routes: Routes;
  @Input('url') url: string;

  @Input() logged: boolean = false;
  @Input() hisName: string;
  @Input() cartItems;

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  fno: boolean = false;
  logoUrl;

  hasServices: boolean = false;
  isAccordionOpen = false;

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
  }
  toggle() {
    this.visible = !this.visible;
    if (this.visible) {
      this.open.emit(null);
    } else {
      this.close.emit(null);
    }
  }

  toggleProfile() {
    this.visibleProfile = !this.visibleProfile;
    if (this.visibleProfile) {
      this.faChevronDown = faChevronUp;
      this.open.emit(null);
    } else {
      this.faChevronDown = faChevronDown;
      this.close.emit(null);
    }
  }

  constructor(
    public appGeneralService: AppGeneralService,
    public storageService: LocalStorageService,
    private router: Router,
    private apiService: ApiService,
    private securityService: SecurityService,
    private environmentService: EnvironmentService,
    private authService: AuthService
  ) {

    this.securityService.check_log_state();

    this.securityService.fno_check.subscribe(fno => {
      this.fno = fno;
    });

    this.securityService.user_name.subscribe(userName => {  if (userName) {
      this.hisName = userName.trim().split(" ")[0];
      this.initialName = this.hisName.charAt(0);
    } else {
      this.hisName = "";
    }});


  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.logoUrl = './assets/images/italiana_assicurazioni.jpg';

    let item = this.storageService.getLogoUrl();

    if (item != null && item != undefined && item != '') {
      this.logoUrl = item;
    }

    this.apiService.callApiProd('/api/Service/ListAvailableServices', new CallParameter('POST')).subscribe((result) => {
      if (result.InError == false) {
        this.hasServices = result.ResultData["Results"].length > 0;
      }
    });

    this.apiService.callApiProd("/api/Configurator/CheckConfigurations", new CallParameter("POST"), true, true).subscribe(ret => {
      this.configuratore = this.environmentService.Configuratore ? ret.ResultData["IsAvailable"] : this.environmentService.Configuratore;
    });
  }

  searchHide: boolean = true;
  ToggleSearch(){
    this.searchHide = !this.searchHide;
  }

  logout() {
    this.authService.logout()
  }
  navigateToExternalUrl(path : string){

    window.location.href =path;

  }

}

