import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as $ from 'jquery';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-file-delete',
  templateUrl: './file-delete.component.html',
  styleUrls: ['./file-delete.component.scss']
})
export class FileDeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FileDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appGeneralService: AppGeneralService,
    private apiService: ApiService
    ) { }

  ngOnInit() {

  }

  delete() {
    let param = new CallParameter("POST");
    let url = ""

    url = '/api/Service/DeleteServiceFile';
    param.CallData = this.data;

    this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));
    this.apiService.callApiProd(url, param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          this.close(true);
        }
      });
  }

  close(refresh): void {
    this.dialogRef.close(refresh);
  }

}
