<div class="container profile-single-accordion">
  <form [formGroup]="formEditData" class="row" autocomplete="off">
      <div class="col-md-12">
          <div class="EditDataPnl data row">

            <div class="col-md-12">

                <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                    <input class="mat-form-input" type="text" matInput placeholder="Ragione Sociale" required formControlName="CompanyName" name="user-name" autocomplete="none">
                    <mat-error *ngIf="formEditDataControls['CompanyName'].hasError('required')">
                        Inserire <strong>ragione sociale</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                    <mat-label for="CompanyType">Tipo società</mat-label>
                    <mat-select class="mat-form-input" matNativeControl required formControlName="CompanyType">
                        <mat-option [value]="company.Id" *ngFor="let company of CompanyType.TypeArray">
                            {{company.Name}}</mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="formRegistrationCompleteControls['JobProfileID'].hasError('required')">
                    Selezionare <strong>profilo lavorativo</strong>
                </mat-error> -->
                </mat-form-field>
            </div>

              <div class="col-md-12  ">

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="text" matInput placeholder="Nome (non modificabile)" required formControlName="Name" name="user-name" autocomplete="none">
                      <mat-error *ngIf="formEditDataControls['Name'].hasError('required')">
                          Inserire <strong>nome</strong>
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="text" matInput placeholder="Codice Fiscale" #CodiceFiscale required name="fiscal-code" formControlName="CF" (paste)="onCfPaste($event)" autocomplete="none">
                      <mat-error *ngIf="formEditDataControls['CF'].hasError('required')">
                          Inserire <strong>Codice Fiscale</strong>
                      </mat-error>
                      <mat-error *ngIf="!formEditDataControls['CF'].hasError('required') && formEditDataControls['CF'].hasError('pattern')">
                          <strong>Codice Fiscale</strong> non valido
                      </mat-error>
                  </mat-form-field>

              </div>

              <div class="col-md-12  ">

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="text" matInput placeholder="Cognome (non modificabile)" required formControlName="Surname" name="surname" autocomplete="none">
                      <mat-error *ngIf="formEditDataControls['Surname'].hasError('required')">
                          Inserire <strong>cognome</strong>
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="tel" matInput placeholder="Numero di telefono" required formControlName="Phone" name="user-phone" autocomplete="none">
                      <mat-error *ngIf="formEditDataControls['Phone'].hasError('required')">
                          Inserire <strong>numero</strong> </mat-error>
                  </mat-form-field>

              </div>

              <div class="col-md-12  ">

                <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                    <input class="mat-form-input" type="text" matInput placeholder="P. Iva (non modificabile)" required formControlName="Iva" name="Iva" autocomplete="none">
                    <mat-error *ngIf="formEditDataControls['Iva'].hasError('required')">
                        Inserire <strong>P. IVA</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                    <input class="mat-form-input" type="text" matInput placeholder="REA" required formControlName="REA" name="REA" autocomplete="none">
                    <mat-error *ngIf="formEditDataControls['REA'].hasError('required')">
                        Inserire <strong>REA</strong> </mat-error>
                </mat-form-field>

            </div>

              <div class="col-md-12  ">

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="text" matInput placeholder="Indirizzo" required formControlName="Address" name="user-address" autocomplete="none">
                  </mat-form-field>

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" type="text" matInput placeholder="N. Civico" required formControlName="AddressNumber" name="user-number-address" autocomplete="none">
                  </mat-form-field>

              </div>

              <div class="col-md-12  ">

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" matInput type="text" placeholder="Citt&agrave;" required formControlName="ResidenceCity" name="residence-city" autocomplete="none">
                      <mat-error *ngIf="formEditDataControls['ResidenceCity'].hasError('required')">
                          Inserire <strong>citt&agrave; di residenza</strong>
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field class="col-lg-6 col-md-6 col-sm-12">
                      <input class="mat-form-input" matInput type="text" placeholder="Provincia" required formControlName="ResidenceProvince" name="residence-province" autocomplete="none" [matAutocomplete]="auto" maxlength="2" (blur)="CheckExistingProvince()">
                      <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let province of filteredProvinces | async" [value]="province">
                              {{province}}
                          </mat-option>
                      </mat-autocomplete>
                      <mat-error *ngIf="formEditDataControls['ResidenceProvince'].hasError('required')">
                          Selezionare <strong>provincia</strong> dalla lista proposta
                      </mat-error>
                      <mat-error *ngIf="!formEditDataControls['ResidenceProvince'].hasError('required') || formEditDataControls['ResidenceProvince'].hasError('minLength') || formEditDataControls['ResidenceProvince'].hasError('maxLength')">
                          Selezionare <strong>provincia</strong> dalla lista proposta
                      </mat-error>
                  </mat-form-field>

              </div>

              <div class="col-12  ">

                  <div class="col-sm-12 col-md-6">
                      <mat-checkbox formControlName="RequirePaperPolicy" [checked]="informativePrivacyCheck.RequirePaperPolicy.checked" (change)="informativePrivacyCheck.RequirePaperPolicy.checked = $event.checked">
                          {{informativePrivacyCheck.RequirePaperPolicy.label}}</mat-checkbox><br>

                  </div>

              </div>

              <div class="col-12  ">

                  <div class="col-sm-12 col-md-6 float-left">
                      Confermo di aver preso visione del <a href="https://api2.myinsurer.it/api/policy/GetPrivacySpecialDocument" target="_blank" title="Visualizza il modulo sulla Privacy">modulo sulla Privacy</a> e di:<br>
                      <mat-checkbox formControlName="CheckBox1" [checked]="informativePrivacyCheck.CheckBox1.checked" (change)="informativePrivacyCheck.CheckBox1.checked = $event.checked">
                          {{informativePrivacyCheck.CheckBox1.label}}</mat-checkbox><br>
                      <mat-checkbox formControlName="CheckBox2" [checked]="informativePrivacyCheck.CheckBox2.checked" (change)="informativePrivacyCheck.CheckBox2.checked = $event.checked">
                          {{informativePrivacyCheck.CheckBox2.label}}</mat-checkbox><br>
                      <mat-checkbox formControlName="CheckBox3" [checked]="informativePrivacyCheck.CheckBox3.checked" (change)="informativePrivacyCheck.CheckBox3.checked = $event.checked">
                          {{informativePrivacyCheck.CheckBox3.label}}</mat-checkbox><br><br> Questi campi sono facoltativi.
                  </div>

                  <div class="col-sm-0 col-md-6 float-left">

                  </div>

              </div>

          </div>
      </div>

      <div class="col-12">
          <div class="buttonArea">
              <button class="new-button float-right"   (click)="saveEditedData()">SALVA</button>
          </div>
      </div>
  </form>
</div>

