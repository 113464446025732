import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-edit-jobprofile',
  templateUrl: './edit-jobprofile.component.html',
  styleUrls: ['./edit-jobprofile.component.scss']
})
export class EditJobprofileComponent implements OnInit {

  formJobProfile: UntypedFormGroup;
  CallData;

  availableProfiles: Array<Object> = [];
  Bulletins: Array<{ label: string, id: number }> = [];
  Conventions = [];
  JobProfiles = [];
  filteredJobProfiles = new Observable<string[]>();
  profiles: Array<object> = [];
  availableBulletins: any;
  profileselected: any;
  isProfileSubmitted: boolean;
  faTrash = faTrashAlt;

  constructor(
    public storageService: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private appGeneralService: AppGeneralService,
    private apiService: ApiService
  ) {

    this.formJobProfile = this.formBuilder.group({
      JobProfile: new UntypedFormArray([])
    });

  }


  ngOnInit() {
    this.getProfileConfigurations();
    this.getUserJobProfiles();
    
  }

  onJobProfileChange(event) {
    this.filteredJobProfiles = this.JobProfileControls.JobProfile.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(event.target.value))
    );
  }

  filter(val: string): string[] {
    return this.JobProfiles.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  get JobProfileControls() { return this.formJobProfile.controls }
  get ProfileControls() { return this.JobProfileControls.JobProfile as UntypedFormArray; }
  get ProfileControlsValidation() { return this.ProfileControls.controls }

  addJobProfileForm(BulletinID?: number, ConventionID?: number, JobProfileID?: number, PEC?: string, Piva?: string, Employed?: boolean, Freelance?: boolean, ReadOnly?: boolean) {
    this.ProfileControls.push(this.formBuilder.group({
      BulletinID: new UntypedFormControl(BulletinID ? BulletinID : ''),
      ConventionID: new UntypedFormControl(ConventionID ? ConventionID : ''),
      JobProfileID: new UntypedFormControl(JobProfileID ? JobProfileID : '', Validators.required),
      PEC: new UntypedFormControl(PEC ? PEC : '', Validators.email),
      Piva: new UntypedFormControl(Piva ? Piva : '', [Validators.minLength(11), Validators.maxLength(11)]),
      Employed: new UntypedFormControl(Employed ? Employed : false),
      Freelance: new UntypedFormControl(Freelance ? Freelance : false),
      ReadOnly: new UntypedFormControl(ReadOnly ? ReadOnly : false)
    }));


  }

  availableBulletins_Conventions(profile) {
    this.resetBulletinsAndConventionsAvailability();
    const relativeProfiles = this.availableProfiles.filter(elm => elm['JobProfileName'] === profile.target.value);
    if(relativeProfiles.length == 0) relativeProfiles.push({ConventionID: null});
    relativeProfiles.forEach(rProfile => {
      this.Conventions.filter(convention => convention.id === rProfile['ConventionID']).forEach(c => c.available = true);
    });
    if(this.Conventions.some(convention => convention.available) == false) {
      this.ProfileControls.controls[0].get('ConventionID').setValue(null);
    }
  }

  resetBulletinsAndConventionsAvailability() {
    this.Conventions.forEach(convention => convention.available = false);
  }

  available_Conventions(profile, i) {
    const relativeProfiles = this.availableProfiles.filter(elm => elm['JobProfileName'] === profile.controls.JobProfileID.value);
    let availableConventions = [];
    relativeProfiles.forEach(rProfile => {
      this.Conventions.filter(convention => { if (convention.id === rProfile['ConventionID']) { convention.available = true; availableConventions.push(convention) } });
    });
    return availableConventions;
  }

  getProfileConfigurations() {

    this.CallData = {};

    this.apiService.callApiProd('/api/Profile/ListAvailable', new CallParameter('POST', this.CallData)).subscribe((data) => {
      if (data.InError) {
        this.appGeneralService.showDialog(data.ErrorCode);
      } else {
        this.availableProfiles = data.ResultData['Profiles'];
        this.availableBulletins = data.ResultData['Bulletins'];
        this.Bulletins = this.availableBulletins.map(
          bulletin => { if (bulletin['Name'] && bulletin['ID']) return { label: bulletin['Name'], id: bulletin['ID'] } }).filter(
            (elm, index, self) => index === self.findIndex(
              (e) => (e && elm && e['id'] === elm['id'] && e['label'] === elm['label'])));

        this.Conventions = this.availableProfiles.map(
          convention => { if (convention['ConventionName'] && convention['ConventionID']) return { label: convention['ConventionName'], id: convention['ConventionID'], available: false } }
        ).filter(
          (elm, index, self) => index === self.findIndex(
            (e) => (e && elm && e['id'] === elm['id'] && e['label'] === elm['label'])
          )
        );

        let FilteredConventions = this.availableProfiles.filter(z => this.Conventions.find(y => y.id == z["ConventionID"]) || z["IsAllPolicy"] == true || z["ConventionID"] == null || z["ConventionID"] == undefined);

        let MappedConventions = FilteredConventions.map(profile => {
          if (profile['JobProfileName'] && profile['JobProfileID'] && (profile['ConventionID'] || profile['IsAllPolicy'] == true || profile["ConventionID"] == null || profile["ConventionID"] == undefined)) {
            return { label: profile['JobProfileName'], id: profile['JobProfileID'], conventionId: profile['ConventionID'] ?? 0 }
          }
        });

        this.JobProfiles = MappedConventions.filter((elm, index, self) => {
          return index === self.findIndex(
            (e) => { return (e && elm && e['id'] === elm['id'] && e['label'] === elm['label']) }
          )
        }
        );
      }
    });
  }

  profileCheck = {
    employed: {
      label: 'Sei un dipendente?',
      checked: false
    },
    freelance: {
      label: 'Sei un libero professionista?',
      checked: false
    }
  };

  saveJobProfile() {
    let convenzione = 0;
    if (this.formJobProfile.invalid) {
      return;
    }

    this.CallData = { "UserProfiles": [] }

    this.formJobProfile.value.JobProfile.forEach(element => {

      if (element.ConventionID == "") {
        element.ConventionID = 0;
      }
      if (element.BulletinID == "") {
        element.BulletinID = null;
      }
      
      let jobProfile = this.JobProfiles.find(jp => jp.label == element.JobProfileID);
      if (jobProfile == null && jobProfile == undefined) {
        jobProfile = { "id": 0, "label": element.JobProfileID }
      }

      let obj = {
        jobProfileID: jobProfile.id,
        jobProfileName: jobProfile.label,
        bulletinID: element.BulletinID == 0 ? null : element.BulletinID, //albo
        conventionID: element.ConventionID == 0 ? null : element.ConventionID,
        employed: element.Employed,
        freelance: element.Freelance,
        pec: element.PEC,
        piva: element.Piva,
        readOnly: true
      };
        
      this.CallData.UserProfiles.push(obj);
    });
    this.apiService.callApiProd('/api/Account/UpdateUserProfileData', new CallParameter('POST', this.CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
        return;
      }
      this.appGeneralService.showDialog(null, "Salvataggio completato", "Esito");
    });
  }

  getUserJobProfiles() {
    this.apiService.callApiProd('/api/Account/GetUserData', new CallParameter('POST')).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.profiles = result.ResultData["UserData"]["UserProfiles"];
        if (this.profiles.length > 0) {
          this.profiles.forEach((element) => {
            this.addJobProfileForm(element["BulletinID"], element["ConventionID"], element["JobProfileName"], element["PEC"], element["PIVA"], element["Employed"], element["Freelance"], element["ReadOnly"])
          })
        } else {
          this.addJobProfileForm()
        }
      }
    });
  }

  delete(Profile, formJobProfile, i) {

    if (!Profile['controls']['ReadOnly'].value) {
      formJobProfile.value.JobProfile.splice(i, 1); formJobProfile.get('JobProfile')['controls'].splice(i, 1)
    }

  }
}
