<div class="canceled-policy-cntr">

<ng-container *ngIf="UserPoliciesListResultSorted.length != 0">
  <div *ngFor="let policy of UserPoliciesListResultSorted" class="cardCntr">


      <div class="insurance" (click)="dropdown(policy)" [class.insurance-active]="policy.IsCollapsed" [attr.aria-expanded]="policy.IsCollapsed" aria-controls="collapseExample">

        <div class="header-cntr">
          <div class="w-100">
        <div class="policy-reference col-3 mt-3">
              Polizza di riferimento: <br><strong>{{policy.Code}}</strong>
          </div>
          <div class="date-compiling col-3 mt-3">
            Titolo:<br><strong>{{policy.GroupName}} {{policy.Name}}</strong>
          </div>
          <div class="date-compiling col-3 mt-3">
            Scadenza:<br><strong>{{policy.ValidTo | date:'dd/MM/yyyy'}}</strong>
        </div>
          <div class="stato-polizza col-3"[ngClass]="appGeneralService.getComplaintStatusColor(policy.State).class"><h3>STATO: {{appGeneralService.getComplaintStatusColor(policy.State).text}}</h3></div>
        </div>
        <div class="w-100">
          <div class="icon col-12">
              <fa-icon [icon]="policy.arrow || faChevron"></fa-icon>
          </div>
        </div>
      </div>

      </div>



      <div id="collapseExample" [ngbCollapse]="!policy.IsCollapsed">
        <div class="card" [ngStyle]="{'border-color': appGeneralService.getComplaintStatusColor(policy.State).color}">


            <strong>CLASSIFICAZIONE LEGALE:  </strong> <span [innerHTML]="policy.LegalInfo"></span>
            <strong>Intestatario:  </strong> {{policy.UserName}} {{policy.UserSurname}}



        </div>

    </div>


  </div>

</ng-container>

</div>
