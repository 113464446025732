<h2 mat-dialog-title class="dialogTitle">Rinnova la tua polizza<div class="closeDialog" (click)="close()"><fa-icon [icon]="faTimes"></fa-icon></div></h2>

<h3 class="subtitle">Migliora la tua copertura. Sali di livello e scegli il pacchetto pi&ugrave; adatto alle tue esigenze!</h3>
<div mat-dialog-content class="FeedCntr">
    <div class="row">
        <carousel [cellWidth]="250" [height]="333" [arrows]="displayArrows">
            <div class="carousel-cell" (click)="renewUpgrade(Policy.ID)" *ngFor="let Policy of Policies">
                <div class="imageCntr"
                    [ngStyle]="{'background-image': 'url('+ Policy.ImageUrls[0] +')'}">
                </div>

                <div class="priceCntr">
                    <div class="cxPart"><div class="discount" *ngIf="Policy.FakePrice">{{Policy.FakePrice | price}}</div> {{Policy.Price | price}}</div>
                    <div class="dxPart"></div>
                </div>

                <h3 [innerHTML]="Policy.Title" style=" padding: 10px;"></h3>
                <button class="new-button" style="margin-top: 5px; border-radius: 0;">{{Policy.IsOldPolicy ? 'Rinnova':'Acquista'}}</button>
            </div>
        </carousel>
    </div>
</div>
