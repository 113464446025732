<div class="insurance-cntr">

  <div class="header-cntr">
    <div class="img-cntr">

      <img src="../../../../assets/images/Area-sinistro/area_sinistro.webp">

    </div>
    <div class="txt-cntr">
      <h1 class="duplicate" title="Area sinistro"></h1>
    </div>

</div>
<div class="tabs-cntr">
  <ngb-tabset [destroyOnHide]="false" class="InsuranceTabs MYI-tabs">

    <ngb-tab title="DENUNCIA SINISTRO">
      <ng-template ngbTabContent>

        <app-insurance-complaint></app-insurance-complaint> 

      </ng-template>
    </ngb-tab>

    <ngb-tab>
      <ng-template ngbTabTitle>GESTIONE SINISTRI</ng-template>

      <ng-template ngbTabContent>

        <app-insurance-memo></app-insurance-memo>

      </ng-template>

    </ngb-tab>

  </ngb-tabset>

</div>
</div>
