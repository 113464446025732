<!-- <h1>CONTATTI</h1>

<div class="row">

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Nome società</strong><br>
    <span>Buzzi Insurance Group s.r.l.</span>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>P.IVA</strong><br>
    <span></span>
  </div>

</div>

<div class="row">

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Numero</strong><br>
    <span>010 565582</span>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Mail</strong><br>
    <span style="overflow-wrap: break-word;">info@buzziinsurancegroup.com</span>
  </div>

</div>

<div class="row">

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Indirizzo</strong><br>
    <span></span>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>PEC</strong><br>
    <span>buzzisnc@pec.it</span>
  </div>

</div>

<div class="row">

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Orari</strong><br>
    <div class="">Lunedì Martedì Mercoledì Giovedì:</div>
    <div class="mb-2">08:30-12:30 / 14:30-18:30</div>
    <div class="">Venerdì:</div>
    <div class="mb-2">08:30-14:00</div>
    <div class="">Sabato Domenica:</div>
    <div class="mb-2">chiuso</div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 contact-content">
    <strong>Informazioni</strong><br>
    <div>Soggetto a vigilanza <a href="https://www.ivass.it" title="IVASS">IVASS</a></div>
    <div>Numero per reclami: <a href="tel:800042042" title="Telefono reclami IVASS">800-042-042</a></div>
    <div>Form reclami: <a href="https://www.italiana.it/reclami" target="_blank" rel="noopener" title="Informazioni sui reclami">clicca qui</a></div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 contact-content">
    <strong>Iscrizione RUI</strong><br>
    <span>Buzzi Insurance Group s.r.l. N. A000070111 del 12/02/2007</span>
  </div>
</div> -->

<div class="contact-cntr">

<app-header-contact></app-header-contact>
<app-main-contact></app-main-contact>

<div class="maps-cntr">

  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1198.4791703178398!2d8.94323774330228!3d44.402796926728136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d343c13dabb81b%3A0x682628442bb113b4!2sVia%20Ippolito%20D&#39;Aste%2C%201%2F2%2C%2016121%20Genova%20GE!5e0!3m2!1sit!2sit!4v1696600198848!5m2!1sit!2sit" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<app-newsletter-mailchimp></app-newsletter-mailchimp>
<app-prefooter></app-prefooter>

</div>
