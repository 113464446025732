import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FeedPanel } from 'src/app/core/classes/Feed/feed-carousel';
import { PolicyToCart } from 'src/app/core/classes/policy-to-cart';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
import { ChooseProfilePolicyComponent } from '../../dialogs/choose-profile-policy/choose-profile-policy.component';
import { FeedService } from '../feed.service';

@Component({
  selector: 'app-feed-panel',
  templateUrl: './feed-panel.component.html',
  styleUrls: ['./feed-panel.component.scss']
})
export class FeedPanelComponent implements OnInit {

  faChevronDown = faChevronDown;
  public isCollapsed = true;
  rotation: string = "default";

  PolicyFullDetail = null;
  ProposalInfo = null;

  OldProposalId = null;
  OldPolicyId = null;
  OldPolicyGroupId = null;

  LeftPanelClass = "col-lg-7 col-md-12 col-sm-12 feed-left-panel";

  @Input() FeedPanel: FeedPanel;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private feedService: FeedService,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private urlBuilderService: UrlBuilderService
  ) { }

  ngOnInit() {
    this.getPanelInfo();
  }

  private resetHistory() {
    this.OldProposalId = null;
    this.OldPolicyId = null;
    this.OldPolicyGroupId = null;
  }

  checkFeedCollapse(data: FeedPanel): boolean {

    if (this.FeedPanel.Id != data.Id) {
      this.isCollapsed = true;
      this.resetHistory();
      return false;
    } else {
      this.isCollapsed = false;
    }

    if (this.OldPolicyGroupId === data.PolicyGroupId && this.OldPolicyId === data.PolicyId && this.OldProposalId === data.ProposalId) {
      this.isCollapsed = true;
      this.resetHistory();
      return false;
    }

    this.OldProposalId = data.ProposalId;
    this.OldPolicyId = data.PolicyId;
    this.OldPolicyGroupId = data.PolicyGroupId;
    return true;
  }

  getPanelInfo() {
    var _this = this;

    _this.feedService.data.subscribe(data => {

      if ((data.PolicyId == null || data.PolicyId == undefined || data.PolicyId == 0) && (data.PolicyGroupId == null || data.PolicyGroupId == undefined || data.PolicyGroupId == 0)) {
        return;
      }

      if (!this.checkFeedCollapse(data)) {
        return;
      }

      if (data.ProposalId != null && data.ProposalId != undefined) {
        _this.PolicyFullDetail = null;
        _this.ProposalInfo = null;
        _this.apiService.callApiProd("/api/Policy/GetSingleProposal", new CallParameter("POST", { ProposalId: data.ProposalId }), true).subscribe(res => {
          _this.ProposalInfo = res.ResultData['ProposalInfo'];
          
          _this.ProposalInfo.PolicyInfoPreviews = _this.ProposalInfo.PolicyInfoPreviews.filter(item => item.ID != data.PolicyId);

          _this.getLeftPanelClass();
        })
      }

      if (data.PolicyId != null && data.PolicyId != undefined) {
        _this.PolicyFullDetail = null;
        _this.ProposalInfo = null;
        _this.apiService.callApiProd("/api/Policy/AvailablePolicyDetail", new CallParameter("POST", { PolicyId: data.PolicyId }), true).subscribe(res => {
          _this.PolicyFullDetail = res.ResultData;
        })
      }

      if (data.PolicyGroupId != null && data.PolicyGroupId != undefined) {
        _this.PolicyFullDetail = null;
        _this.ProposalInfo = null;
        _this.apiService.callApiProd("/api/Policy/AvailableGroupPolicyDetail", new CallParameter("POST", { PolicyGroupId: data.PolicyGroupId, FeedID: Number(data.Id) }), true).subscribe(res => {
          _this.ProposalInfo = { 'PolicyInfoPreviews': '' };
          _this.ProposalInfo.PolicyInfoPreviews = res.ResultData['PolicyInfoPreviews'];
          _this.PolicyFullDetail = { 'Policy': '', 'Profile': '' };
          _this.PolicyFullDetail.Policy = res.ResultData['Policy'];
          _this.PolicyFullDetail.Profiles = res.ResultData['Profiles'];
          _this.getLeftPanelClass();
        })
      }

    });


  }

  BuyNow() {
    //ROLLBACK FNO
    if (this.PolicyFullDetail.Policy.ID == 49 || this.PolicyFullDetail.Policy.ID == 50 || this.PolicyFullDetail.Policy.ID == 370 || this.PolicyFullDetail.Policy.ID == 372) {
      window.location.href = "https://www.tsrm.org/";
      return;
    }

    let ChooseProfileDialog: MatDialogRef<ChooseProfilePolicyComponent>;
    let profileID = -1;
    if (this.PolicyFullDetail.Profiles != undefined && this.PolicyFullDetail.Profiles != null && this.PolicyFullDetail.Profiles.length == 1) {

      profileID = this.PolicyFullDetail.Profiles[0].ProfileID;
      this.postCart(profileID);
    } else {
      ChooseProfileDialog = this.dialog.open(ChooseProfilePolicyComponent, {
        panelClass: 'custom-dialog-container',
        width: '500px',
        data: {
          Profiles: this.PolicyFullDetail.Profiles
        }
      });
      ChooseProfileDialog.afterClosed().subscribe(res => {
        if (res.choice) {
          this.postCart(res.profileID);
        }
      });
    }
  }

  postCart(profileID) {
    const policyToCartParams = new PolicyToCart(profileID, this.PolicyFullDetail.Policy.ID, -1);
    this.apiService.callApiProd('/api/Policy/AddPolicyToCart', new CallParameter('POST', policyToCartParams)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.router.navigate(["/acquista-polizza", result.ResultData["UserPolicyID"]]);
      }
    });
  }


  goToPolicyDetail(policyId: number, groupId: number, proposalId: string) {
    
    this.urlBuilderService.navigateToPath(groupId, policyId, proposalId);

  }

  getLeftPanelClass() {
    this.LeftPanelClass = this.ProposalInfo != null && this.ProposalInfo != undefined && this.ProposalInfo.PolicyInfoPreviews.length > 0 ? 'col-lg-7 col-md-12 col-sm-12 feed-left-panel' : 'feed-left-panel';
  }

  closePanel() {
    this.isCollapsed = true;
    this.resetHistory();
    this.feedService.ChangeCollapsible(this.FeedPanel, this);
  }
}

