<ng-container *ngIf="Steps.length > 1; else single">
  <mat-horizontal-stepper [linear]="isLinear" #stepper="matHorizontalStepper" labelPosition="bottom" *ngIf="isHorizontal; else vertical" (selectionChange)="nextMiddleware($event)">
    <ng-template matStepperIcon="edit">
      <mat-icon>done</mat-icon>
    </ng-template>

    <ng-template matStepperIcon="done">
      <mat-icon>done</mat-icon>
    </ng-template>
    <ng-container *ngFor="let step of Steps">
      <mat-step [stepControl]="step.StepControl" [completed]="step.GetCompleted()">

          <form [formGroup]="step.StepControl.controls['a']"></form>
          <ng-template matStepLabel>{{step.Name}}</ng-template>

          <ng-container *ngIf="step.Template">
            <ng-container *ngTemplateOutlet="step.Template"></ng-container>
          </ng-container>

          <ng-container *ngIf="step.ButtonText">
            <button   class="new-button mt-2" matStepperNext [disabled]="!step.GetCompleted() && !step.GetOptional()" title="{{!step.GetCompleted() && !step.GetOptional() ? 'Per proseguire carica i documenti' : 'Prosegui'}}">{{step.ButtonText}}</button>
          </ng-container>
      </mat-step>
    </ng-container>
  </mat-horizontal-stepper>

  <ng-template #vertical>
    <mat-vertical-stepper [linear]="isLinear" #stepper="matVerticalStepper" labelPosition="bottom" (selectionChange)="nextMiddleware($event)">
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
      </ng-template>
      <ng-container *ngFor="let step of Steps">
        <mat-step [stepControl]="step.StepControl" [completed]="step.GetCompleted()">
          <form [formGroup]="step.StepControl.controls['a']"></form>
          <ng-template matStepLabel>{{step.Name}}</ng-template>
          <ng-container *ngTemplateOutlet="step.Template"></ng-container>
          <ng-container *ngIf="step.ButtonText">
            <button   class="new-button mt-2" matStepperNext [disabled]="!step.GetCompleted() && !step.GetOptional()" title="{{!step.GetCompleted() || !step.GetOptional() ? 'Completa lo step per proseguire' : 'Prosegui'}}">{{step.ButtonText}}</button>
          </ng-container>
        </mat-step>
      </ng-container>
    </mat-vertical-stepper>
  </ng-template>
</ng-container>


<ng-template #single>
  <ng-container *ngIf="Steps && Steps[0] && Steps[0].Template">
    <ng-container *ngTemplateOutlet="Steps[0].Template"></ng-container>
  </ng-container>
</ng-template>
