import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faChevronDown, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'ng2-file-upload';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { FileData } from 'src/app/shared/file-upload/file-upload.component';

@Component({
  selector: 'app-canceled-policyForm',
  templateUrl: './canceled-policyForm.component.html',
  styleUrls: ['./canceled-policyForm.component.scss']
})
export class CanceledPolicyFormComponent implements OnInit {

  form: FormGroup;
  formData : any;
  dialog : any;
  dialogSuccess: any;
  precedentCode : any;
  faDoc = faFileDownload;
  faChevron = faChevronDown;
  FileArr : Array<any> = [];
  hisName : string ="";
  policySelected : PolicyInfo;
  UserPoliciesListResult : Array<PolicyInfo> =[];
  UserPoliciesListResultSorted : Array<PolicyInfo> =[];
  inputPlaceHolder: string = 'Carica qui uno o più file';
  public uploaderCanceled: FileUploader = new FileUploader({ itemAlias: 'fileuploadinputcanceled' });

  constructor(public appGeneralService : AppGeneralService, private apiService : ApiService, private fb : FormBuilder,private router : Router) {

    this.getUserPoliciesList();
    this.form = this.fb.group({
      Reason: new FormControl(this.formData?.Reason),
      Code : new FormControl(this.formData?.Code,[Validators.required])
    });

  }

  ngOnInit() {


  }





  dropdown(policy : any){

    policy.IsCollapsed = !policy.IsCollapsed;

  }
  showDialog(){

    if (this.form.valid) {
    this.dialog =  this.appGeneralService.showDialog(null,"Sei sicuro di voler inviare la richiesta di disdetta?<br>Le ricordiamo che la disdetta, una volta che sarà inviata, non potrà più essere modificata.","ATTENZIONE",
    [{ name: 'SI', action: () => { this.putUserInfo() } },{name: 'NO', action: ()=>{this.dialog.close()}}]
    )
    }
  }

  reloadCurrentRoute() {
    this.dialogSuccess.close();
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });



}

  putUserInfo(){


    if (this.form.valid) {


      let CallData : formCanceledInfo = this.form.value as formCanceledInfo;
      CallData.File = this.FileArr;
      this.dialog.close();
      this.apiService.callApiProd('/api/Cancellation/NewCancellation',  new CallParameter('POST', CallData)).subscribe((result) => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        }else{
          this.dialogSuccess =  this.appGeneralService.showDialog(null,"Disdetta avvenuta con successo, troverai tutte le tue richieste di disdetta nella sezione POLIZZE DISDETTE","AVVISO",
          [{name: 'OK', action: ()=>{this.reloadCurrentRoute()}}]
          )
        }
      });





    }
  }

  fileChange(event: any){
    //this.FileArr = [];
    this.inputPlaceHolder = '';
    this.FileArr.forEach(element => {
      this.inputPlaceHolder += element.Name + ', ';
    });

    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        let reader = new FileReader();
        let FileToUpload = new FileData();

        FileToUpload.Name = event.target.files[i].name;
        this.inputPlaceHolder += event.target.files[i].name + ', ';
        reader.onload = (event: any)  => {
          FileToUpload.FileBase64 = event.target.result.toString();
          this.FileArr.push(FileToUpload);
        }

        reader.readAsDataURL(event.target.files[i]);

      }

    }

  }

  changeSelection(policyCode : any){


      this.policySelected = this.UserPoliciesListResultSorted.find(x => x.Code == policyCode);


  }

  DeleteFile(){

    this.FileArr = [];
    this.inputPlaceHolder = '';

  }

   getUserPoliciesList() {


    this.apiService.callApiProd('/api/Cancellation/GetUserPoliciesList', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.UserPoliciesListResult = result['ResultData'] as Array<PolicyInfo>;

        this.UserPoliciesListResult.forEach(element => {
          element.IsCollapsed = false;
        });
        const orderedIDList = [3];
        this.UserPoliciesListResultSorted = [];
        orderedIDList.forEach(element => {
          this.UserPoliciesListResultSorted = this.UserPoliciesListResultSorted.concat(this.UserPoliciesListResult.filter(x => x.State == element));
        })
        };

    });
  }
}

export class formCanceledInfo{

  Code : string;
  Reason? : string;
  File: Array<FileData>;

  constructor(){

    this.File = new Array<FileData>();
  }

}

  export interface InformativeDocument {
      ID: number;
      ShortDescription: string;
      LongDescription?: any;
      Mandatory: boolean;
      HasOwnDownloadableSource: boolean;
      WasAlreadyUploaded: boolean;
      DownloadUrl: string;
      Name: string;
      ValidationError?: any;
      Type: number;
      Code?: any;
  }

  export interface ActivationDocument {
      ID: number;
      ShortDescription: string;
      LongDescription?: any;
      Mandatory: boolean;
      HasOwnDownloadableSource: boolean;
      WasAlreadyUploaded: boolean;
      DownloadUrl: string;
      Name: string;
      ValidationError?: any;
      Type: number;
      Code?: any;
  }

  export class PolicyInfo {
      Code: string;
      ExternalLink?: any;
      PolicyGroupName: string;
      IsRenewable: boolean;
      IsDirectExpired: boolean;
      HasTransactions: boolean;
      IsDeletable: boolean;
      IsCancelable: boolean;
      IsIndirectExpired: boolean;
      Price: number;
      ID: number;
      InformativeDocuments: InformativeDocument[];
      RefusedDocuments: any[];
      ActivationDocuments: ActivationDocument[];
      UploadableDocuments: any[];
      Title: string;
      ValidFrom: string;
      ValidTo: string;
      UserPolicyID: number;
      IsDirectPolicy: boolean;
      ImageUrls: string[];
      Name: string;
      GroupName: string;
      LegalClassification?: any;
      PolicyID: number;
      UserProfiles: any[];
      UserPolicies: any[];
      State: number;
      IsCollapsed: boolean = false;
      UserName : string;
      UserSurname : string;
      LegalInfo : string;
  }



