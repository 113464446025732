<div class="stepper-cntr">
  <mat-horizontal-stepper linear [disableRipple]="true">
    <mat-step [stepControl]="formRegistration" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistration" (ngSubmit)="this.registrationService.setCompleteStep(true, formRegistration)" autocomplete="off">
        <ng-template matStepLabel></ng-template>
        <div class="title-cntr">
          <h2>Inserisci i tuoi dati</h2>
        </div>
        <div class="row">
          <mat-form-field  class="col-sm-4">
            <div class="mat-label-txt">Nome*</div>
            <input type="text" matInput required formControlName="Name" name="user-name" class="mat-form-input"
              autocomplete="none" (keydown)="this.registrationService.generateCF(formRegistration, true);" tabindex="1">
            <mat-error *ngIf="formRegistration.controls['Name'].hasError('required')">
              Inserire <strong>nome</strong>
            </mat-error>
          </mat-form-field >
          <mat-form-field  class="col-sm-4">

            <div class="mat-label-txt">Cognome*</div>
            <input type="text" matInput required formControlName="Surname" name="surname" class="mat-form-input"
              autocomplete="none" (keydown)="this.registrationService.generateCF(formRegistration, true);" tabindex="2">
            <mat-error *ngIf="formRegistration.controls['Surname'].hasError('required')">
              Inserire <strong>cognome</strong>
            </mat-error>
          </mat-form-field >

            <mat-form-field  class="col-sm-4">
              <div class="mat-label-txt">Sesso*</div>
              <mat-select matNativeControl required formControlName="Gender" (selectionChange)="this.registrationService.generateCF(formRegistration, true)"
                class="mat-form-input" tabindex="3">
                <mat-option *ngFor="let gender of appGeneralService.GenderType" [value]="gender.abbrev">
                  {{ gender.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formRegistration.controls['Gender'].hasError('required')">
                Selezionare <strong>sesso</strong>
              </mat-error>
            </mat-form-field >

            <mat-form-field  class="col-sm-4">
              <div class="mat-label-txt">Data di nascita*</div>
              <input matInput type="text" [max]="calendarStartDate" [matDatepicker]="BirthDatePicker" required
              formControlName="BirthDate" (click)="BirthDatePicker.open()"
              name="birth-date" autocomplete="none" (keydown)="this.registrationService.generateCF(formRegistration, true)" (dateChange)="this.registrationService.generateCF(formRegistration, true)" tabindex="4" class="mat-form-input">
            <mat-datepicker-toggle matSuffix [for]="BirthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker [startView]="'multi-year'" #BirthDatePicker [startAt]="calendarStartDate">
            </mat-datepicker>
            <mat-error *ngIf="formRegistration.controls['BirthDate'].hasError('required')">
              Inserire <strong>data di nascita</strong>
            </mat-error>
            </mat-form-field >

            <mat-form-field  class="col-sm-4">

              <div class="mat-label-txt">Città di nascita*</div>
              <input matInput type="text" required formControlName="BirthCity" class="mat-form-input"
              name="birth-city" autocomplete="none" (input)="this.registrationService.generateCF(formRegistration, true)" tabindex="5">
            <mat-error *ngIf="formRegistration.controls['BirthCity'].hasError('required')">
              Inserire <strong>citt&agrave; di nascita</strong>
            </mat-error>
            </mat-form-field >

              <mat-form-field class="col-sm-4">
                <div class="mat-label-txt">Provincia*</div>
                <input matInput type="text" required formControlName="BirthProvince" class="mat-form-input"
                name="birth-province" autocomplete="none" (input)="this.registrationService.generateCF(formRegistration, true)" [matAutocomplete]="auto" maxlength="2"
                (blur)="this.registrationService.CheckExistingProvince(formRegistration)" tabindex="6">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let province of this.registrationService.filteredProvinces | async" [value]="province">
                  {{province}}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="!formRegistration.controls['BirthProvince'].hasError('required') || formRegistration.controls['BirthProvince'].hasError('minLength') || formRegistration.controls['BirthProvince'].hasError('maxLength')">
                Selezionare <strong>provincia</strong> dalla lista proposta
              </mat-error>
              </mat-form-field>

              <mat-form-field class="col-sm-4">
                <div class="mat-label-txt">Stato di nascita*</div>
                <mat-select required tabindex="7" class="mat-form-input"
                  (selectionChange)="this.registrationService.checkState($event.value, formRegistration)" autocomplete="none" name="birth-state">
                  <mat-option *ngFor="let state of this.registrationService.States" [value]="state">
                    {{state}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="formRegistration.controls['BirthState'].hasError('required')">
                  Inserire <strong>stato di nascita</strong>
                </mat-error>
              </mat-form-field>


          <mat-form-field [hidden]="!this.registrationService.isForeignState" class="col-sm-4">
            <div class="mat-label-txt">Stato di nascita*</div>
            <input matInput type="text" class="mat-form-input" required formControlName="BirthState"
              name="birth-state" autocomplete="none" tabindex="7" (input)="this.registrationService.generateCF(formRegistration, true)">

            <mat-error *ngIf="formRegistration.controls['BirthState'].hasError('required')">
              Inserire <strong>stato di nascita</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-8">
            <div class="mat-label-txt">Codice Fiscale*</div>
            <input type="text" matInput #CodiceFiscale required name="fiscal-code" class="mat-form-input"
              formControlName="CF" (paste)="this.registrationService.onCfPaste($event, formRegistration, true)" autocomplete="none">
            <mat-error *ngIf="formRegistration.controls['CF'].hasError('required')">
              Inserire <strong>Codice Fiscale</strong>
            </mat-error>
            <mat-error
              *ngIf="!formRegistration.controls['CF'].hasError('required') && formRegistration.controls['CF'].hasError('pattern')">
              <strong>Codice Fiscale</strong> non valido
            </mat-error>
          </mat-form-field>


          <mat-form-field class="col-sm-4">
            <div class="mat-label-txt">Numero di telefono*</div>
            <input type="tel" matInput required formControlName="Phone" class="mat-form-input"
              name="user-phone" autocomplete="none" tabindex="8">
            <mat-error *ngIf="formRegistration.controls['Phone'].hasError('required')">
              Inserire <strong>numero</strong> </mat-error>
          </mat-form-field>


          <mat-form-field class="col-sm-8">
            <div class="mat-label-txt">Email*</div>
            <input type="email" matInput required formControlName="Email" name="user-mail" class="mat-form-input"
              autocomplete="none" tabindex="9">
            <mat-error
              *ngIf="!formRegistration.controls['Email'].hasError('required') && formRegistration.controls['Email'].hasError('email')">
              <strong>E-mail</strong> non valida
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['Email'].hasError('required')">
              Inserire <strong>e-mail</strong> </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Password*</div>
            <input type="password" matInput required formControlName="Password" #password class="mat-form-input"
              name="user-password" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="10"
              [type]="this.registrationService.hide ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hide = !this.registrationService.hide" class="cursor-pointer">{{this.registrationService.hide ? 'visibility_off' :
              'visibility'}}</mat-icon>

            <mat-error *ngIf="formRegistration.controls['Password'].hasError('required')">
              Inserire <strong>password</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Conferma Password*</div>
            <input matInput required name="user-password-confirm" class="mat-form-input"
              formControlName="PasswordConfirm" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="11"
              [type]="this.registrationService.hideConfirm ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hideConfirm = !this.registrationService.hideConfirm" class="cursor-pointer">{{this.registrationService.hideConfirm ?
              'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error
              *ngIf="formRegistration.controls['PasswordConfirm'].invalid && !formRegistration.controls['PasswordConfirm'].hasError('required') ">
              Le <strong>password</strong> non combaciano
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['PasswordConfirm'].hasError('required')">
              Inserire <strong>conferma password</strong>
            </mat-error>
          </mat-form-field>



          <div class="col-sm-8">
            <mat-password-strength #passwordComponent [min]="6" [max]="25" [enableSpecialCharRule]="false"
              [enableLowerCaseLetterRule]="true" [enableUpperCaseLetterRule]="true" [enableDigitRule]="true"
              [password]="password.value">
            </mat-password-strength>
            <mat-password-strength-info [passwordComponent]="passwordComponent"
              minCharsCriteriaMsg="Inserire almeno 6 caratteri"
              upperCaseCriteriaMsg="Inserire almeno un carattere appartenente alle lettere maiuscole (da A a Z)"
              digitsCriteriaMsg="Inserire almeno un carattere appartenente ai primi 10 numeri di base (da 0 a 9)"
              lowerCaseCriteriaMsg="Inserire almeno un carattere minuscolo">
            </mat-password-strength-info>
          </div>
        <div class="col-sm-4 button-cntr">
          <button class="new-button" type="button" matStepperNext>Avanti</button>
        </div>
      </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="formRegistrationStep2" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistrationStep2" (ngSubmit)="onRegistration()" autocomplete="off" >
        <div class="row">

            <div class="title-cntr">
              <h2>Inserisci Dati Residenza</h2>
            </div>

            <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Indirizzo</div>
              <input matInput type="text" formControlName="Address" autocomplete="new-address" class="mat-form-input"
                tabindex="12">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">N° Civico</div>
              <input matInput type="text" formControlName="BuildingNumber" class="mat-form-input"
                name="Building-Number" autocomplete="none" tabindex="13">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">Città</div>
              <input matInput type="text" formControlName="City" name="city" class="mat-form-input"
                autocomplete="none" tabindex="14">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">Provincia</div>
              <input matInput type="text" formControlName="Province" autocomplete="none" class="mat-form-input"
                name="province" (blur)="this.registrationService.CheckExistingProvince(formRegistrationStep2)" [matAutocomplete]="auto" maxlength="2" tabindex="15">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let province of this.registrationService.filteredProvinces | async" [value]="province">{{province}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

           <div class="col-sm-12">
          <h3>Confermo di aver preso visione del <a
            [href]="this.environmentService.Url + '/api/policy/GetPrivacySpecialDocument'" target="_blank">modulo
            sulla
            Privacy</a> e di:<br></h3>
          <mat-checkbox formControlName="GeneralPrivacyCheck" tabindex="16"
            [ngClass]="formRegistrationStep2.controls['GeneralPrivacyCheck'].hasError('required') ? 'error' : 'no-error'">
            Acconsento al trattamento dei dati per finalità assicurative e per le tecniche di comunicazione a distanza (Obbligatorio)
          </mat-checkbox>
          <br>
          <mat-checkbox formControlName="CheckBox1" tabindex="17">Acconsentire al punto 1 (Facoltativo)
          </mat-checkbox><br>
          <mat-checkbox formControlName="CheckBox2" tabindex="18">Acconsentire al punto 2 (Facoltativo)
          </mat-checkbox><br>
          <mat-checkbox formControlName="CheckBox3" tabindex="19">Acconsentire al punto 3 (Facoltativo)
          </mat-checkbox><br>
        </div>


        <div class="col-sm-12 button-cntr">
          <button class="new-button" type="submit" tabindex="20">Registrati</button>
        </div>
      </div>
    </form>
    </mat-step>
  </mat-horizontal-stepper>

</div>