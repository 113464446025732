<div class="chi-siamo-cntr">
  <div class="chi-siamo-img"></div>
  <div class="about-us-cntr">
    <app-about-us></app-about-us>
  </div>
  <app-nostra-azienda></app-nostra-azienda>
  <app-mission></app-mission>

</div>

  <app-contact-us></app-contact-us>
  <app-prefooter></app-prefooter>
