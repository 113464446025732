export class PolicyToCart {
    profileID: number;
    policyID: number;
    userProfileID: number;
    constructor(
      profileID: number,
      policyID: number,
      userProfileID: number
    ) {
      this.profileID = profileID;
      this.policyID = policyID;
      this.userProfileID = userProfileID;
    }
  }
