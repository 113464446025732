import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as $ from 'jquery';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { isNullOrUndefined } from 'util';
import { CallParameter } from '../../core/classes/system/call-parameter';

export class FileData {
    FileBase64: string;
    Name: string;

    constructor() {
    }
}

export class UploadFile {
    File: FileData;
    UserPolicyID: number;
    FileFamilyTipe: string;
    Type: string;
    PolicyFileID: number;
    UserPolicyFileID: number;
    constructor() {
        this.File = new FileData();
    }
}

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    FileToUpload: UploadFile;
    FileAdditionalToUpload: UploadFile;
    fileUploaded: boolean = false;
    additionalFileUploaded: boolean = false;
    showUploadMessage: boolean = false;
    inputPlaceHolder: string = 'Carica qui il documento';
    inputPlaceHolderAdditional: string = 'Carica qui un documento aggiuntivo';
    public uploader: FileUploader = new FileUploader({ itemAlias: 'fileuploadinput' });
    public uploaderAdd: FileUploader = new FileUploader({ itemAlias: 'fileuploadadditionalinput' });
    IndirectTransfer: any;
    UserPolicyID: any;
    PolicyFileID: any;
    uploading: boolean = false;


    constructor(
        public dialogRef: MatDialogRef<FileUploadComponent>,
        public appGeneralService: AppGeneralService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private apiService: ApiService
    ) {  }

    ngOnInit() {

        this.FileToUpload = new UploadFile();
        this.FileAdditionalToUpload = new UploadFile();
        this.IndirectTransfer = this.data['TransferIndirect'];


        if ((this.data['file'].ValidationError != null && this.data['file'].ValidationError.length > 0) || this.data['file'].WasAlreadyUploaded == true) {
            this.FileToUpload.UserPolicyFileID = this.data['file'].UserPolicyFileID;
            this.FileAdditionalToUpload.UserPolicyFileID = this.data['file'].UserPolicyFileID;
        } else {
            this.FileToUpload.UserPolicyID = this.data['id'];
            this.FileToUpload.PolicyFileID = this.data['file'].ID;

            this.FileAdditionalToUpload.UserPolicyID = this.data['id'];
            this.FileAdditionalToUpload.PolicyFileID = this.data['file'].ID;
        }
        this.FileToUpload.FileFamilyTipe = this.data['file'].FamilyType;
        this.FileToUpload.Type = this.data['file'].FileType;

        this.FileAdditionalToUpload.FileFamilyTipe = this.data['file'].FamilyType;
        this.FileAdditionalToUpload.Type = this.data['file'].FileType;
    }

    close(refresh, redirect): void {
        this.dialogRef.close({ refresh: refresh, redirect: redirect });
    }

    fileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];

            this.FileToUpload.File.Name = file.name;
            this.inputPlaceHolder = file.name;

            reader.readAsDataURL(file);
            reader.onload = () => {
                this.FileToUpload.File.FileBase64 = reader.result.toString();
                this.fileUploaded = true;
                this.showUploadMessage = false;
            }
        }
    }

    fileChangeAdditional(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            this.additionalFileUploaded = true;
            let file = event.target.files[0];

            this.FileAdditionalToUpload.File.Name = file.name;
            this.inputPlaceHolderAdditional = file.name;

            reader.readAsDataURL(file);
            reader.onload = () => {
                this.FileAdditionalToUpload.File.FileBase64 = reader.result.toString();
            }
        }
    }

    CallData;
    CallData2;
    uploadPolicyDocs() {
        if (this.fileUploaded) {
            this.showUploadMessage = false;
            this.uploading = true;
            let CallerName = '';
            if ((this.data['file'].ValidationError != null && this.data['file'].ValidationError.length > 0) || this.data['file'].WasAlreadyUploaded == true) {
                CallerName = '/api/Policy/ReuploadPolicyDocument';
            }
            else {
                CallerName = '/api/Policy/UploadPolicyDocuments';
            }

            this.CallData = this.FileToUpload;
            this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));

            this.apiService.callApiProd(CallerName, new CallParameter('POST', this.CallData)).subscribe((result) => { // CHECK 1
                if (result.InError) {
                    this.uploading = false;
                    this.appGeneralService.showDialog(result.ErrorCode);
                } else { // CHECK 2

                    if (this.additionalFileUploaded) {
                        if (this.data['file'].ValidationError != null && this.data['file'].ValidationError.length > 0) {
                            CallerName = '/api/Policy/ReuploadPolicyDocument';
                        }
                        else {
                            CallerName = '/api/Policy/UploadPolicyDocuments';
                        }
                        this.CallData2 = this.FileAdditionalToUpload; // CHECK 3

                        this.apiService.callApiProd(CallerName, new CallParameter('POST', this.CallData2)).subscribe((result) => { // CHECK 4

                            if (result.InError) {
                                this.appGeneralService.showDialog(result.ErrorCode);
                            } else { // CHECK 5

                                if (!isNullOrUndefined(this.IndirectTransfer) && this.IndirectTransfer) {
                                    this.close(false, true);
                                } else {
                                    this.close(true, false);
                                }
                                this.uploading = false;  // CHECK 6
                            }
                        });

                    } else {
                        if (!isNullOrUndefined(this.IndirectTransfer) && this.IndirectTransfer) {
                            this.close(false, true);
                        } else {
                            this.close(true, false);
                        }
                        this.uploading = false; // CHECK 7


                    }
                }

            });
        } else {
            this.showUploadMessage = true;
        }
    }



}
