<div class="container profile-single-accordion">
    <form [formGroup]="formJobProfile" class="row" autocomplete="off">

        <ng-container formArrayName="JobProfile" *ngFor="let Profile of formJobProfile.get('JobProfile')['controls']; let i = index">
            <ng-container [formGroupName]="i">
                <div class="col-md-12">
                    <div class="JobProfilePnl data row">

                        <div class="col-md-12">
                            <h3>PROFILO {{i + 1}}
                                <div class="only-text-button float-right mobilew" (click)="delete(Profile, formJobProfile, i)" *ngIf="formJobProfile.get('JobProfile')['controls'].length > 1">
                                    <!-- <fa-icon [icon]="faTrash"></fa-icon> -->
                                    Elimina
                                </div>
                            </h3>
                        </div>

                        <div class="col-md-12" style="margin-bottom: 10px;">

                            <div class="col-sm-12 col-md-6 float-left">
                                <mat-checkbox formControlName="Employed">
                                    {{profileCheck.employed.label}}</mat-checkbox><br>
                                <mat-checkbox formControlName="Freelance">
                                    {{profileCheck.freelance.label}}</mat-checkbox>
                            </div>
                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-12 col-sm-12">
                                <input matInput class="mat-form-input" type="text" placeholder="Digita o cerca la professione" required formControlName="JobProfileID" name="job-profile" [matAutocomplete]="auto" (input)="onJobProfileChange($event)" (focus)="onJobProfileChange($event)" (blur)="availableBulletins_Conventions($event)">

                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let profile of filteredJobProfiles | async" [value]="profile.label">
                                        {{profile.label}}
                                    </mat-option>
                                </mat-autocomplete>

                            </mat-form-field>

                        </div>

                        <div class="col-md-12">

                            <mat-form-field floatLabel="always" class="col-sm-12 col-md-6 " *ngIf="Conventions.length > 0">
                                <mat-label for="ConventionID">{{this.storageService.getBrokerManagementLabel(true,true)}}</mat-label>
                                <mat-select floatLabel="never" class="mat-form-input" matNativeControl formControlName="ConventionID">
                                    <mat-option *ngFor="let convention of Conventions" [value]="convention['id']" [disabled]="!convention['available']">
                                        {{ convention['label'] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" type="text" matInput placeholder="PEC" formControlName="PEC" name="pec" autocomplete="none">
                            </mat-form-field>

                        </div>

                        <div class="col-md-12">

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <mat-label for="BulletinID">Albo</mat-label>
                                <mat-select class="mat-form-input" matNativeControl formControlName="BulletinID">
                                    <mat-option *ngFor="let bulletin of Bulletins" [value]="bulletin.id">
                                        {{ bulletin.label }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" type="text" matInput placeholder="Partita IVA" formControlName="Piva" name="piva" autocomplete="none">
                            </mat-form-field>

                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>

        <div class="col-12">
            <div class="buttonArea">
                <button class="commonBtn float-right mb-3" mat-button (click)="saveJobProfile()">SALVA</button><br>
                <div class="only-text-button text-center" mat-button (click)="addJobProfileForm()">Inserisci Nuovo Profilo</div>
            </div>
        </div>

    </form>
</div>
