import { Injectable } from '@angular/core';
import { SiteType } from '../enum/siteType.enum';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class SiteManagementService{

SiteType : SiteType;
BrokerManagement : boolean = false;
SiteManagement : SiteConfigurationValue = SiteConfigurationValueObject.Default;



constructor(private storageService : LocalStorageService) {

  this.SiteType = this.setType(this.storageService.getSiteType());
  this.BrokerManagement = this.storageService.getBrokerManagement();
  this.SiteManagement = SiteConfigurationValueObject.getByType(this.SiteType);


 }



getLabel( BeginsWithCapitalLetter : boolean,IsSingularForm : boolean) : string{


  let label : string;
  let siteConfigurationValue : SiteConfigurationValue = this.SiteManagement;

  if (!this.BrokerManagement && this.SiteManagement.Type != SiteType.SHOPPINGCENTER){

      siteConfigurationValue = SiteConfigurationValueObject.Default;

  }
           label = IsSingularForm == true ? siteConfigurationValue.SingularLabel : siteConfigurationValue.PluralLabel;

            if (BeginsWithCapitalLetter)
            {
                label = label.substring(0,1).toUpperCase() + label.substring(1);
            }

            return label;


}

setType(type : string) : SiteType{

  switch(type){
    case SiteType.SHOPPINGCENTER:{
      return SiteType.SHOPPINGCENTER;
    }
    case SiteType.ONLINEAGENCY:{
      return SiteType.ONLINEAGENCY;
    }
    default:{
      return SiteType.DEFAULT;
    }
  }
}

}

export class SiteConfigurationValueObject{


  public static ShoppingCenter : SiteConfigurationValue = {
    Name : "Centro commerciale",
    Type : SiteType.SHOPPINGCENTER,
    SingularLabel: "agenzia",
    PluralLabel: "agenzie",
    DescriptionCarousel : "Lista agenzie presenti"
  };
  public static Default : SiteConfigurationValue = {
    Name : "MyInsurer",
    Type : SiteType.DEFAULT,
    SingularLabel: "convenzione",
    PluralLabel: "convenzioni",
    DescriptionCarousel : "Lista federazioni e associazioni convenzionate"
  };
  public static OnlineAgency : SiteConfigurationValue = {
    Name : "Agenzia online",
    Type : SiteType.ONLINEAGENCY,
    SingularLabel: "consulente",
    PluralLabel: "consulenti",
    DescriptionCarousel : "Lista federazioni e associazioni convenzionate"
  };

  public static Items : Array<SiteConfigurationValue> = new Array<SiteConfigurationValue>(this.ShoppingCenter,this.Default,this.OnlineAgency);
  public static getByType(type : SiteType) : SiteConfigurationValue {

    return this.Items.find(elem => elem.Type == type);

  };


}



export class SiteConfigurationValue{

    public Name : string;
    public Type : SiteType;
    public SingularLabel : string;
    public PluralLabel : string;
    public DescriptionCarousel : string;

    constructor(name : string,type : SiteType, singularLabel : string, pluralLabel : string, descriptionCarousel : string ){
      this.Name = name;
      this.Type = type;
      this.SingularLabel = singularLabel;
      this.PluralLabel = pluralLabel;
      this.DescriptionCarousel = descriptionCarousel;
    }

    public static create(name : string,type : SiteType, singularLabel : string, pluralLabel : string, descriptionCarousel : string) : SiteConfigurationValue {

      return new SiteConfigurationValue(name,type,singularLabel,pluralLabel, descriptionCarousel);
    }


}
