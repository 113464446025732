import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canceled-area',
  templateUrl: './canceled-area.component.html',
  styleUrls: ['./canceled-area.component.scss']
})
export class CanceledAreaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
