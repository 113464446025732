<ng-container *ngIf="BoughtTogether.length > 0">

    <div class="col-12 BTCntr">

        <div class="singleBT" *ngFor="let SingleBT of BoughtTogether">
            <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ SingleBT.ImageUrls[0].toString() +')'}"></div>

            <div class="position-relative">
                <div class="priceCntr">
                    <div class="cxPart">{{SingleBT.Price | price}}</div>
                    <div class="dxPart"></div>
                </div>
            </div>

            <h3>{{SingleBT.Title}}</h3>
            <button class="new-button" (click)="goToPolicyDetail(SingleBT.GroupName)">Vedi tutti i pacchetti</button>
        </div>

    </div>

</ng-container>
