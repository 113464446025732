import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-added-to-cart',
  templateUrl: './added-to-cart.component.html',
  styleUrls: ['./added-to-cart.component.scss']
})
export class AddedToCartComponent implements OnInit {

  faShoppingCart = faShoppingCart;

  constructor(
    public dialogRef: MatDialogRef<AddedToCartComponent>,
    private router: Router
    ) { }

  ngOnInit() {
  }

  GoToCart(){
    this.router.navigate(['/carrello']);
    this.dialogRef.close();
  }

  GoToNavigation(){
    this.dialogRef.close();
  }

}
