import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-file-delete',
  templateUrl: './file-delete.component.html',
  styleUrls: ['./file-delete.component.scss']
})
export class FileDeleteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FileDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appGeneralService: AppGeneralService,
    private apiService: ApiService
    ) { }

  ngOnInit() {

  }

  delete() {
    let param = new CallParameter("POST");
    let url = ""
    if (this.data['CompiledModuleID']) {
      url = '/api/module/DeleteModule';
      param.CallData = {
        CompiledModuleID: this.data['CompiledModuleID']
      }
    } else {
      url = '/api/Policy/DeletePolicyDocument';
      param.CallData = this.data;
    }



    this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));
    this.apiService.callApiProd(url, param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          this.close(true);
        }
      });
  }

  close(refresh): void {
    this.dialogRef.close(refresh);
  }

}
