import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-successfull-payment-dialog',
  templateUrl: './successfull-payment-dialog.component.html',
  styleUrls: ['./successfull-payment-dialog.component.scss']
})
export class SuccessfullPaymentDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SuccessfullPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
    ) { }

  close(): void {
    this.dialogRef.close();
  }

}
