import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let regexp: RegExp = /\B(?=(\d{3})+(?!\d))/g;
    let euro = "";
    if(Number(value) || value == 0){
      euro = " €"
    }

    return  value.toString().replace(".",",").replace(",00","").replace(regexp, ".") + euro ;
  }

}