import { Component, Input, OnInit } from '@angular/core';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-document-scheda',
  templateUrl: './document-scheda.component.html',
  styleUrls: ['./document-scheda.component.scss']
})
export class DocumentSchedaComponent implements OnInit {

  @Input() Document;

  constructor(public appGeneralService : AppGeneralService) { }

  ngOnInit() {
  }

}
