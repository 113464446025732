import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { isNullOrUndefined } from "util";
import { authType } from "../enum/authType.enum";
import { AppGeneralService } from "./app-general.service";
import { LocalStorageService } from "./localstorage.service";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  IsLogged: boolean = false;
  logged_state: Subject<boolean> = new Subject<boolean>();
  user_name: Subject<string> = new Subject<string>();
  cart_Items: Subject<string> = new Subject<string>();
  fno_check: Subject<boolean> = new Subject<boolean>();
  hisName: string = "";
  cartItems: string = "";
  fno: boolean;

  constructor(
    private appGeneralService: AppGeneralService,
    private router: Router,
    private storageService: LocalStorageService,
  ) {
    this.logged_state.subscribe((value) => {
      this.IsLogged = value;
    });

    this.user_name.subscribe((value) => {
      if(this.hisName != ""){
      this.hisName = value;
      }
    });

    this.cart_Items.subscribe((value) => {
      this.cartItems = value;
    });

    this.fno_check.subscribe((value) => {
      this.fno = value;
    });
  }

  /*
  *   Function to check if the user is logged in, if not opens the login modal without the lite parameter.
  *   @returns {boolean}
  */
  public LoginError = ():boolean => { if(!this.IsLogged){ this.GetLoginErrorModal(); return true; }  return false; };

  public isGuest() {
    return this.getRole() === authType.NO_AUTHENTICATION;
  }

  public isTracedGuest() {
    return this.getRole() === authType.TRACE_SESSION;
  }

  public isUser() {
    return this.getRole() === authType.AUTHENTICATED;
  }

  public isAdmin() {
    return this.getRole() === authType.ADMIN_AUTHENTICATED;
  }

  public isMaintenance() {
    return this.getRole() === authType.MAINTENANCE;
  }

  private getRole(): number {
    if (isNullOrUndefined(this.storageService.getAuthenticationLevel()))
      return 0;
    return Number(this.storageService.getAuthenticationLevel());
  }


  /**
   * Function to get the login error modal.
   * </code>
   * @param [isLiteRegistration=null] - boolean
   * @param [CallBackUrl=null] - the url to which the user will be redirected after login
   * @returns {void}
   */
  GetLoginErrorModal(isLiteRegistration = null, CallBackUrl = null) {
    //Il codice -996 fa riferimento all'errore LOGIN_REQUIRED che normalmente arriverebbe da Backend
    //si imposta manualmente per mostrare la dialog corretta.

    if (isLiteRegistration) {
      let modal = this.appGeneralService.showDialog(-996, null, null, [
        {
          name: "REGISTRATI",
          action: () => {
            this.router.navigate(["/registrazione"], {
              queryParams: {
                LiteRegistration: isLiteRegistration,
                callbackUrl: CallBackUrl,
              },
            });
            modal.close();
          },
        },
        {
          name: "Accedi",
          action: () => {
            this.router.navigate(["/login"], {
              queryParams: {
                LiteRegistration: isLiteRegistration,
                callbackUrl: CallBackUrl,
              },
            });
            modal.close();
          },
        },
      ]);
    } else {
      this.appGeneralService.showDialog(-996);
    }
  }

  check_log_state(): void {
    this.logged_state.next(this.isAdmin() || this.isUser());

    this.user_name.next(this.storageService.getUserData());
    this.cart_Items.next(this.storageService.getCartItems());

    let fnoFlag: boolean = false;

    let storageFnoFlag: any = this.storageService.getFnoFlag();

    if (storageFnoFlag != null && storageFnoFlag != undefined && storageFnoFlag != "undefined") {
      fnoFlag = JSON.parse(storageFnoFlag);
    }

    let queryStringFnoFlag: any = this.storageService.getQueryFnoFlag();

    if (queryStringFnoFlag) {
      fnoFlag = JSON.parse(queryStringFnoFlag) && !this.IsLogged;
    }

    // let fnoFlag: boolean = this.cookieService.check(this.appGeneralService.FnoFlag) && this.cookieService.get(this.appGeneralService.FnoFlag) != 'undefined' ? JSON.parse(this.cookieService.get(this.appGeneralService.FnoFlag)) : false;
    // fnoFlag = this.cookieService.check(this.appGeneralService.QueryFnoFlag) && !this.IsLogged ? true : fnoFlag;
    this.fno_check.next(fnoFlag);
  }
}
