import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { SeoService } from 'src/app/core/services/seo.service';
import { CardType } from 'src/app/core/enum/card-type.enum';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private seoService: SeoService
  ) { }

  ServiceList;
  cardType: CardType = CardType.Service;

  ngOnInit() {
    this.getServices();
    this.seoService.update_image('');
  }

  getServices() {
    this.apiService.callApiProd('/api/Service/ListAvailableServices', new CallParameter('POST')).subscribe((result) => {
      this.ServiceList = result['ResultData']['Results'];
    });
  }

}