import { Component, Input, OnInit } from '@angular/core';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
@Component({
  selector: 'app-single-service-carousel',
  templateUrl: './single-service-carousel.component.html',
  styleUrls: ['./single-service-carousel.component.scss', '../../home.component.scss']
})
export class SingleServiceCarouselComponent implements OnInit {

  constructor(
    private urlBuilderService: UrlBuilderService
    ) { }

  @Input() SingleServiceCarousel;

  ngOnInit() {
  }

  goToDetail(ID: number) {
    this.urlBuilderService.navigateToServicePath(ID);
  }

}
