export class FeedFilterInfo {

    constructor(feedIds: number[], label: string, imageUrl: string){
        this.FeedIds = feedIds;
        this.Label = label;
        this.ImageUrl = 'assets/images/Policies/' + imageUrl;
        this.IsSelected = false;
    }

    FeedIds: number[];
    Label: string;
    ImageUrl: string;
    IsSelected: boolean;

    setIsSelected(selected: boolean) : FeedFilterInfo{
        this.IsSelected = selected;
        return this;
    }
}