import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { faChevronDown, faChevronUp, faFileDownload, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { ContractsConditionService } from 'src/app/auth/contracts-condition/contracts-condition.service';
import { ServiceInfo } from 'src/app/core/classes/ServiceClasses/service-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ProductType } from 'src/app/core/enum/product-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { Step } from '../../../shared/stepper/stepper.component';
import { FileDeleteComponent } from '../buy-service/file-delete/file-delete.component';
import { CallApiProdParameter } from './../../../core/services/api.service';

@Component({
  selector: 'app-buy-service',
  templateUrl: './buy-service.component.html',
  styleUrls: ['./buy-service.component.scss']
})
export class BuyServiceComponent implements OnInit {
  faFileDownload = faFileDownload;
  faDownload = faFileDownload;
  faUpload = faUpload;
  faTimes = faTimes;
  faChevronDownDocuments = faChevronDown;
  UserServiceId: number;
  User;
  public isCollapsed = true;
  Service: ServiceInfo;
  Stepper: Array<Step> = [];
  ProductType = ProductType;
  @ViewChild('InfoDocuments') InfoTemplate: TemplateRef<any>;
  @ViewChild('UploadDocuments') UploadTemplate: TemplateRef<any>;
  @ViewChild('Payment') PaymentTemplate: TemplateRef<any>;
  isHorizontal: boolean = true;
  @ViewChild('inputFile',{static:false}) inputFile;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    public dialog: MatDialog,
    private contactsConditionService : ContractsConditionService
  ) { }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.UserServiceId = Number.parseInt(params.get("id"));
    });

    this.getService();

    this.setHorizontal();
  }

  getService(){
    this.Stepper = new Array<Step>();
    this.apiService.callApiProd("/api/Service/UserServiceDetail",new CallParameter("POST", {"UserServiceID": this.UserServiceId})).subscribe(result => {
      if(result.InError){
        this.appGeneralService.showDialog(result.ErrorCode);
        return;
      }

      this.User = result.ResultData["User"];
      this.Service = result.ResultData["Service"];


        if(this.Service.InformativeUrls.length > 0){
          this.Stepper.push(Step.create(0, "Documenti informativi", "Prossimo Step", this.InfoTemplate, true, this.Service.InformativeUrls.every(z => z.IsRead), () => {
            const dialogAcceptance = this.contactsConditionService.CheckConfirm(this.Service.InformativeUrls.every(z => z.IsRead), true, null, null,this.Service.ID, this.UserServiceId);
             dialogAcceptance.afterClosed().subscribe(result => {
              this.getService();
            });
            return true;
          }));
        }

        if(this.Service.ToBeUploadedFiles.length > 0){
          this.Stepper.push(Step.create(1, "Documenti da caricare", "Ultimo Step", this.UploadTemplate, false, this.Service.ToBeUploadedFiles.every(z => z.UserUploaded == true)));
        }

        this.Stepper.push(Step.create(2, "Pagamento", "", this.PaymentTemplate, false));


    });
  }

  @HostListener('window:resize')
  setHorizontal() {
    this.isHorizontal = window.innerWidth < 768;
  }

    /**
   * Open a dialog box that allows the user to delete a service file from the cart
   * @param UserServiceID - The ID of the User Service that contains the file.
   * @param ServiceFileID - The ID of the file that you want to delete.
   */
  openDeleteModal(UserServiceID, ServiceFileID) {

    let dialogRef = this.dialog.open(FileDeleteComponent, {
      width: '500px',
      data: {
        UserServiceID: UserServiceID,
        ServiceFileID: ServiceFileID,
      },
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getService();
      }
    });

  }

  documentCollapse() {
    this.isCollapsed = !this.isCollapsed
    if (this.isCollapsed) {
      this.faChevronDownDocuments = faChevronDown;
      return;
    }
    this.faChevronDownDocuments = faChevronUp;
  }

  openInput(i:string){
    $('#'+i).click();
  }

  handleDocumentsUpload(Document, event){

    if (!Document.UserUploaded) {
      var CallData = {
        File: event.target.files[0],
        ServiceFileId: parseInt(Document.ID),
        UserServiceId: this.UserServiceId,
        FileName: event.target.files[0].name,
      }


      const reader = new FileReader();
      reader.readAsDataURL(CallData.File);
      reader.onload = () => {
        CallData.File = reader.result.toString();
        var callParameter: CallParameter = new CallParameter("POST", CallData);
        var callApiProdParameter : CallApiProdParameter = CallApiProdParameter.create("/api/Service/UploadServiceFile", callParameter, "POST");

        this.apiService.callApiProduction(callApiProdParameter).subscribe(
          data => {
            if (data.InError) {
              this.appGeneralService.showDialog(data.ErrorCode);
              return;
            }
            this.getService();
          }
        );
      }
    }
  }
}
