import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PipeModule } from 'src/app/core/helpers/Pipe.module';
import { FeedPanelComponent } from './feed-panel.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    IvyCarouselModule,
    PipeModule
  ],
  declarations: [
    FeedPanelComponent
  ],
  exports: [
    FeedPanelComponent
  ]
})
export class FeedPanelModule { }