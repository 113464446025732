import { Component, OnInit } from '@angular/core';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { ApiService } from 'src/app/core/services/api.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormGroupDirective, NgForm, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  if (formGroup.get('Password').value === formGroup.get('PasswordConfirm').value)
    return null;
  else
    return { passwordMismatch: true };
}

@Component({
  selector: 'reset-password-dialog',
  template: `<h4 mat-dialog-title>Il reset della password è avvenuto con successo</h4>
              <div mat-dialog-content>
                <p>Accedi ora con le tue nuove credenziali.</p>
              </div>
              <div mat-dialog-actions style="margin-top: 15px;">
                  <button class="new-button" (click)="close()">Accedi</button>
              </div>`,
  styles: [`button.my_button{
              margin-right: 10px;
            margin-bottom: 10px;
          }`]
})
export class ResetPasswordDialog {

  constructor(public dialogRef: MatDialogRef<ResetPasswordDialog>) {
  }

  close(): void {
    this.dialogRef.close(true);
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formUpdatePassword: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();
  pin: string;
  hide = true;
  hideNew = true;


  constructor(
    public dialog: MatDialog,
    private appGeneralService: AppGeneralService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute
    ) {

    this.activatedRoute.queryParams.subscribe(params => {
      this.pin = params['pin'];
    });

    this.formUpdatePassword = this.formBuilder.group({
      Password: new UntypedFormControl('', Validators.required),
      PasswordConfirm: new UntypedFormControl('', Validators.required)
    }, { validator: passwordMatchValidator });

  }

  ngOnInit() {
  }

  onPasswordInput() {
    if (this.formUpdatePassword.hasError('passwordMismatch'))
      this.formUpdatePassword.get('PasswordConfirm').setErrors([{ 'passwordMismatch': true }]);
    else
      this.formUpdatePassword.get('PasswordConfirm').setErrors(null);
  }

  get formUpdatePasswordControls() { return this.formUpdatePassword.controls }

  onUpdatePassword() {

    if (this.formUpdatePassword.invalid) {
      return;
    }

    this.appGeneralService.loadingPanel.Show();
    let param = new CallParameter("POST", {
      Pin: this.pin,
      NewPassword: this.formUpdatePassword.get('Password').value
    });

    this.apiService.callApiProd("/api/Account/UpdatePasswordByRecoverPin", param).subscribe(
      data => {
        if (data.InError) {
          let MyErrorModal = this.appGeneralService.showDialog(data.ErrorCode, data.Message, "Password non aggiornata!", [{ name: 'OK', action: () => { MyErrorModal.close() } }]);
        } else {
          this.appGeneralService.loadingPanel.Hide();

          let dialogRef = this.dialog.open(ResetPasswordDialog, {
            width: '500px',
            disableClose: true,
            panelClass: 'custom-dialog-container'
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/login']);
            }
          });
        }
      });
  }

}
