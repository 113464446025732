<app-configurator-header></app-configurator-header>
<app-carousel-how-work></app-carousel-how-work>
<div class="configuratore-cntr">

  <ng-container *ngIf="PolicyGroupInfos && PolicyGroupInfos.length > 0; else noPolicyTemplate">

  <div class="title-cntr" >
    <h1 class="title-shadow">CONFIGURA LA POLIZZA</h1>
  </div>

  <div class="carousel-cntr">

    <div class="txt-cntr">

      <h3>Seleziona la polizza da configurare</h3>

    </div>

    <div class="carousel row">
      <ng-container >
        <carousel [cellWidth]="calculateWidth()" [height]="calculateHeight()">

          <ng-container *ngFor="let scheda of PolicyGroupInfos">
            <div class="carousel-cell">
              <app-configurator-scheda-polizza [cardAction]="getCardFunction(scheda.ID)"
                [SingleFeed]="scheda" [isSelected]="scheda.IsSelected" (onSelection)="selectedCardCallback($event)"></app-configurator-scheda-polizza>
            </div>
          </ng-container>
        </carousel>
      </ng-container>

    </div>
  </div>
</ng-container>
  <ng-template #noPolicyTemplate>

    <div class="carousel-cntr">


    <div class="txt-cntr" style="width: 100%; text-align: center;">

      <h3>Ci dispiace attualmente nessuna polizza è configurabile.</h3>

    </div>

    </div>

  </ng-template>

  <ng-container *ngIf="PolicyInfosConfigurator && PolicyInfosConfigurator.length > 0">
    <div class="carousel-cntr">

      <div class="txt-cntr">

        <h3>Seleziona un pacchetto pre-configurato</h3>

      </div>

      <div class="carousel row">

        <carousel [cellWidth]="300" [height]="300">

          <ng-container *ngFor="let PolicyInfo of PolicyInfosConfigurator">
            <div class="carousel-cell">
              <app-configurator-scheda-pacchetto [cardAction]="getPolicyConfiguration(PolicyInfo.ID)"
                [SingleFeed]="PolicyInfo"></app-configurator-scheda-pacchetto>
            </div>
          </ng-container>
        </carousel>

      </div>
    </div>
  </ng-container>


  <div  class="configurator row mt-3" [hidden]="!showConfguration">
    <ng-container *ngIf="PolicyConfiguration != null && PolicyConfiguration != undefined">

        <h1 [innerHTML]="PolicyConfiguration.TileTitle" style="text-align: center; width: 100%;"></h1>

    </ng-container>

    <div id="destination"></div>
    <div class="section-cntr">
      <h2 class="text-center">Personalizza in base alle tue esigenze</h2>
        <div *ngIf="PolicyConfiguration != null && PolicyConfiguration != undefined">
            <div class="section" *ngFor="let section of PolicyConfiguration.sections">
                <h2>{{section.SectionName}}</h2>
                <div class="d-flex flex-wrap mb-3 option" *ngFor="let option of section.Options">

                  <div class="info-icon" (click)="openInfoDialog(option.Description)">
                    <a title="Info">
                        <img src="../../../assets/images/icons/i_info.svg">
                    </a>
                </div>
                        <div class="d-flex flex-wrap w-100">
                            <h3 class="mb-0 option-title" style="text-align: center; font-weight: unset!important;" (click)="toggleCollapseOption(option)">
                                {{option.Title}} {{option.SubTitle}}:
                            </h3>

                        </div>
                        <ng-container *ngIf="!option.Collapsed">
                            <div class="option-button" *ngFor="let subValue of option.SubValueDatas">

                                <button *ngIf="subValue.Type.SubValueTypeId == 0"
                                    [class]='subValue.Disabled == true ? "Disabled" : "Enabled"'
                                    (click)="setNewPrice(section, option.OptionId, subValue.ID)">
                                    {{subValue.Maximal | price}}
                                </button>

                                <button *ngIf="subValue.Type.SubValueTypeId == 1"
                                    [class]='subValue.Disabled == true ? "Disabled" : "Enabled"'
                                    (click)="setNewPrice(section, option.OptionId, subValue.ID)">
                                    Includi
                                </button>
                                <div style="overflow: visible; margin-top: 10px;margin-left: 5px;">Paghi {{subValue.Price | price}}</div>
                            </div>
                        </ng-container>
                        <div class="collapser-arrow" (click)="toggleCollapseOption(option)">
                            <fa-icon [icon]="option.Chevron"></fa-icon>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="mobile-cart-button" (click)="toggleCart()" [hidden]="!cartListHidden">
        <div class="cart-notification" *ngIf="cartItems >= 1">{{cartItems}}</div>
        <fa-icon [icon]="faClipboardList" alt="Configurator Cart"></fa-icon>
    </div> -->


        <div class="cart">
            <!-- <div class="close-cart" [hidden]="cartListCloseHidden" (click)="toggleCart()"><fa-icon [icon]="faTimes"></fa-icon></div> -->
            <h3 class="col-lg-12 col-md-12 col-sm-12 mb-0"
                *ngIf="PolicyConfiguration != null && PolicyConfiguration != undefined"
                [innerHTML]="'La tua polizza '+PolicyConfiguration.TileTitle"></h3>

            <div class="choosen-configurations">
                <ng-container *ngIf="ChoosenConfigurationSections != null && ChoosenConfigurationSections != undefined">
                    <div *ngFor="let conf of ChoosenConfigurationSections">
                        <div class="choosen-conf-title">{{conf.SectionName}}:</div> <br>
                        <div class="mb-3 configuration-container"
                            *ngFor="let ChoosenConfigurationOption of conf.UserPolicyValueDatas">
                            <div class="configuration">
                                <div class="mb-0">{{ChoosenConfigurationOption.OptionTitle}}</div>
                                <div class="maximal">{{ChoosenConfigurationOption.OptionSubtitle}} <span class="price float-right"><span style="font-weight: normal!important;">Prezzo:</span><br> {{ChoosenConfigurationOption.SubValue.Price | price}}</span></div><div><span style="font-weight: bold;">{{ChoosenConfigurationOption.SubValue.Maximal
                                  | price}}</span></div>



                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="total mr-4">
                <span style="font-weight: normal!important; text-transform: none!important;">Totale: </span> <span>{{Total | price}}</span>
            </div>
            <div class="button-cntr">
                <button class="new-button "  (click)="buyCart()">Aggiungi al carrello</button>

                <button class="new-button primary"  (click)="buyCart(true)">Acquista subito</button>
            </div>
        </div>
    </div>


</div>
<app-prefooter></app-prefooter>
<!-- <h1>Configuratore</h1>
<h2>Seleziona la polizza da configurare</h2>
<div class="FeedCntr">
    <ng-container *ngIf="PolicyGroupInfos && PolicyGroupInfos.length > 0">
        <carousel [cellWidth]="170" [height]="315" [arrows]="calculateArrows()">
            <div class="carousel-cell" *ngFor="let PolicyGroup of PolicyGroupInfos">
                <app-general-card [showPrice]="false" [buttonText]="'Configura'" [SingleFeed]="PolicyGroup" [cardAction]="getCardFunction(PolicyGroup.ID)" [cardType]="cardType" [cardWidth]="170"></app-general-card>
            </div>
        </carousel>
    </ng-container>
</div>

<div class="FeedCntr">
    <ng-container *ngIf="PolicyInfosConfigurator && PolicyInfosConfigurator.length > 0">
        <h2>Seleziona un pacchetto pre-configurato</h2>
        <carousel [cellWidth]="170" [height]="315" [arrows]="calculateArrows()">
            <div class="carousel-cell" *ngFor="let PolicyInfo of PolicyInfosConfigurator">
                <app-general-card [showPrice]="false" [buttonText]="'Seleziona'" [SingleFeed]="PolicyInfo" [cardAction]="getPolicyConfiguration(PolicyInfo.ID)" [cardType]="cardType" [cardWidth]="170"></app-general-card>

              </div>
        </carousel>
    </ng-container>
</div>

<div [hidden]="!showConfguration" class="row mt-3">

    <h2 class="col-lg-12 col-md-12 col-sm-12">Configura Garanzie e Accessori della polizza</h2>
    <div class="col-lg-6 col-md-6 col-sm-12">
        <div *ngIf="PolicyConfiguration != null && PolicyConfiguration != undefined">
            <div class="section" *ngFor="let section of PolicyConfiguration.sections">
                <h2>{{section.SectionName}}</h2>
                <div class="d-flex flex-wrap mb-3 option" *ngFor="let option of section.Options">
                        <div class="d-flex flex-wrap w-100">
                            <h3 class="mb-0 option-title" (click)="toggleCollapseOption(option)">
                                {{option.Title}}
                                <div>{{option.SubTitle}}:</div>
                            </h3>
                            <div class="info-icon" (click)="openInfoDialog(option.Description)">
                                <a title="Info">
                                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                                </a>
                            </div>
                        </div>
                        <ng-container *ngIf="!option.Collapsed">
                            <div style="width: 47%; margin-left: 5px;" *ngFor="let subValue of option.SubValueDatas">

                                <button *ngIf="subValue.Type.SubValueTypeId == 0"
                                    [class]='subValue.Disabled == true ? "Disabled" : "Enabled"'
                                    (click)="setNewPrice(section, option.OptionId, subValue.ID)">
                                    {{subValue.Maximal | price}}
                                </button>

                                <button *ngIf="subValue.Type.SubValueTypeId == 1"
                                    [class]='subValue.Disabled == true ? "Disabled" : "Enabled"'
                                    (click)="setNewPrice(section, option.OptionId, subValue.ID)">
                                    Includi
                                </button>
                                <div style="overflow: visible;">Paghi {{subValue.Price | price}}</div>
                            </div>
                        </ng-container>
                        <div class="collapser-arrow" (click)="toggleCollapseOption(option)">
                            <fa-icon [icon]="option.Chevron"></fa-icon>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mobile-cart-button" (click)="toggleCart()" [hidden]="!cartListHidden">
        <div class="cart-notification" *ngIf="cartItems >= 1">{{cartItems}}</div>
        <fa-icon [icon]="faClipboardList" alt="Configurator Cart"></fa-icon>
    </div>

    <div class="offset-lg-2 col-lg-4 col-md-6 col-sm-12">
        <div class="cart" [hidden]="cartListHidden">
            <div class="close-cart" [hidden]="cartListCloseHidden" (click)="toggleCart()"><fa-icon [icon]="faTimes"></fa-icon></div>
            <h2 class="col-lg-12 col-md-12 col-sm-12 mb-0"
                *ngIf="PolicyConfiguration != null && PolicyConfiguration != undefined"
                [innerHTML]="'CONFIGURAZIONE POLIZZA '+PolicyConfiguration.TileTitle"></h2>
                <h3 class="cart-title">Di seguito vengono riportate le garanzie selezionate</h3>

            <div class="choosen-configurations">
                <ng-container *ngIf="ChoosenConfigurationSections != null && ChoosenConfigurationSections != undefined">
                    <div *ngFor="let conf of ChoosenConfigurationSections">
                        <div class="choosen-conf-title">{{conf.SectionName}}:</div>
                        <div class="mb-3 configuration-container"
                            *ngFor="let ChoosenConfigurationOption of conf.UserPolicyValueDatas">
                            <div class="configuration">
                                <h3 class="mb-0">{{ChoosenConfigurationOption.OptionTitle}}</h3>
                                <div class="maximal">{{ChoosenConfigurationOption.OptionSubtitle}}</div><div><span>{{ChoosenConfigurationOption.SubValue.Maximal
                                  | price}}</span><span class="price float-right">Paghi {{ChoosenConfigurationOption.SubValue.Price | price}}</span></div>



                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="total mr-4">
                Totale: <span>{{Total | price}}</span>
            </div>
            <div class="btnContainer">
                <button style="width: 80%;" class="new-buttonConfig" (click)="buyCart()">Aggiungi al carrello</button>

                <button style="width: 80%;" class="new-buttonConfig" (click)="buyCart(true)">Acquista subito</button>
            </div>
        </div>
    </div>
</div> -->
