import { Component, Input, OnInit } from '@angular/core';
import { HeaderBackgroundFilter } from '../../../home-policies.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-background-scheda',
  templateUrl: './header-background-scheda.component.html',
  styleUrls: ['./header-background-scheda.component.scss']
})
export class HeaderBackgroundSchedaComponent implements OnInit {

  @Input() Scheda : HeaderBackgroundFilter

  constructor(private route : Router) { }

  ngOnInit() {
  }


  navigateTo(){

    let url = this.Scheda.title.replace(/ /g, "-");
    this.route.navigate([this.Scheda.url + url +'/' + this.Scheda.indexFeed]);

  }

}
