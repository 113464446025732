import { AppGeneralService } from '../../core/services/app-general.service';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as $ from 'jquery';

export interface CookieElement {
  name: string;
  supplier: string;
  purpose: string;
  expiry: string;
  type: string;
}

const NECESSARY_COOKIES: CookieElement[] = [
  {
    name: 'CookieConsent',
    supplier: 'myinsurer.it',
    purpose: 'Memorizza lo stato del consenso ai cookie dell\'utente per il dominio corrente',
    expiry: '1 anno',
    type: 'HTTP Cookie'
  },
  {
    "name": "SIDCC",
    "supplier": "Google",
    "purpose": "to provide the identification of trusted web traffic.",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "__Secure-3PSID",
    "supplier": "Google",
    "purpose": "consentono di contare le visite e le fonti di traffico in modo da poter misurare e migliorare le prestazioni del Sito. Aiutano a sapere quali sono le pagine più e meno popolari e vedere come i visitatori si muovono all'interno del Sito. Con questi cookie non vengono raccolte informazioni sull'identità dell'utente, né alcun dato personale. Le informazioni sono trattate in forma aggregata e anonima.",
    "expiry": "2 anni",
    "type": "Informativa"
  },
  {
    "name": "__Secure-1PAPISID",
    "supplier": "Google",
    "purpose": "Utilizzato per finalità di targeting per costruire un profilo degli interessi dei visitatori del sito web al fine di mostrare pubblicità Google pertinente e personalizzata. 2 anni",
    "expiry": "Funzionale",
    "type": ""
  },
  {
    "name": "SSID",
    "supplier": "Google (google.com)",
    "purpose": "Cookie utilizzati per raccogliere informazioni sull’utilizzo del sito da parte dei visitatori ogni volta che vengono visitate pagine web contenenti servizi di Google",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "SAPISID",
    "supplier": "Google",
    "purpose": "Utilizzato per raccogliere informazioni sull’utilizzo del sito da parte dei visitatori ogni volta che vengono visitate pagine web contenenti servizi di Google",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "__Secure-3PSIDCC",
    "supplier": "Google",
    "purpose": "consentono di contare le visite e le fonti di traffico in modo da poter misurare e migliorare le prestazioni del Sito. Aiutano a sapere quali sono le pagine più e meno popolari e vedere come i visitatori si muovono all'interno del Sito. Con questi cookie non vengono raccolte informazioni sull'identità dell'utente, né alcun dato personale. Le informazioni sono trattate in forma aggregata e anonima.",
    "expiry": "2 anni",
    "type": "Informativa"
  },
  {
    "name": "__Secure-3PAPISID",
    "supplier": "Google",
    "purpose": "Utilizzato per finalità di targeting per costruire un profilo degli interessi dei visitatori del sito web al fine di mostrare pubblicità Google pertinente e personalizzata. 2 anni",
    "expiry": "Funzionale",
    "type": ""
  },
  {
    "name": "__Secure-1PSID",
    "supplier": "Google",
    "purpose": "consentono di contare le visite e le fonti di traffico in modo da poter misurare e migliorare le prestazioni del Sito. Aiutano a sapere quali sono le pagine più e meno popolari e vedere come i visitatori si muovono all'interno del Sito. Con questi cookie non vengono raccolte informazioni sull'identità dell'utente, né alcun dato personale. Le informazioni sono trattate in forma aggregata e anonima.",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "SID HSID",
    "supplier": "Google",
    "purpose": "Cookie usati per autenticare gli utenti, contribuendo ad assicurare che soltanto l'effettivo proprietario di un account possa accedervi. I cookie chiamati \"SID\" e \"HSID\" contengono record con firma digitale e criptati relativi all'ID dell'Account Google di un utente e alla sua data di accesso più recente. La combinazione di questi cookie ci permette di bloccare molti tipi di attacchi, ad esempio i tentativi di carpire i contenuti dei moduli inviati nei servizi Google",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "APISID",
    "supplier": "Google",
    "purpose": "Memorizza le preferenze e le informazioni dell’utente ogni volta che visita pagine web contenenti servizi di Google",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "_gcl_au",
    "supplier": "Google",
    "purpose": "Controllo conversioni",
    "expiry": "persistente",
    "type": "Funzionale"
  },
  {
    "name": "OGPC-OGP",
    "supplier": "Google",
    "purpose": "Memorizza le preferenze e le informazioni dell’utente ogni volta che visita pagine web contenenti servizi di Google",
    "expiry": "24 ore",
    "type": "Funzionale"
  },
  {
    "name": "NID",
    "supplier": "Google",
    "purpose": "Conserva le preferenze dell'utente per il retageting",
    "expiry": "6 mesi",
    "type": "Funzionale"
  },
  {
    "name": "ajs_user_id",
    "supplier": "Hotjar",
    "purpose": "Utilizzato per dati analitici",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "ajs_anonymous_id",
    "supplier": "Hotjar",
    "purpose": "ID per utenti anonimi",
    "expiry": "1 anno",
    "type": "Funzionale"
  },
  {
    "name": "_hjDonePolls",
    "supplier": "Hotjar",
    "purpose": "Set when a user completes an on-site Survey",
    "expiry": "1 anno",
    "type": "Funzionale"
  }
];

const STATISTICS_COOKIES: CookieElement[] = [
  {
    name: '_ga',
    supplier: 'myinsurer.it',
    purpose: 'Registra un ID univoco utilizzato per generare dati statistici su come il visitatore utilizza il sito internet.	',
    expiry: '2 anni',
    type: 'HTTP Cookie'
  },
  {
    name: '_gat	',
    supplier: 'myinsurer.it',
    purpose: 'Utilizzato da Google Analytics per limitare la frequenza delle richieste',
    expiry: 'Session',
    type: 'HTTP Cookie'
  },
  {
    name: '_gid',
    supplier: 'myinsurer.it',
    purpose: 'Registra un ID univoco utilizzato per generare dati statistici su come il visitatore utilizza il sito internet.',
    expiry: 'Session',
    type: 'HTTP Cookie'
  }
];

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.css']
})
export class CookieComponent implements OnInit {

  displayedColumns: string[] = ['name', 'supplier', 'purpose', 'expiry', 'type'];
  necessary_cookies_source = NECESSARY_COOKIES;
  statistics_cookies_source = STATISTICS_COOKIES;
  
  constructor(
    private cookieService: CookieService,
    public appGeneralService: AppGeneralService
  ) {
  }

  ngOnInit() {
  }

  get Consent(){
    return this.cookieService.get('CookieConsent') == 'true';
  }

  get StatisticConsent(){
    return this.cookieService.get('StatisticConsent') == 'true';
  }

  //create variable date with today + 1 year
  get CookieExpiry(){
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }

  SetCosent(obj) {
    if (obj._checked) {
      $('.CookieMessageCntr').removeClass('slide-in-bottom').addClass('slide-out-right');
      setTimeout(() => {
        this.cookieService.set('CookieConsent', 'true', this.CookieExpiry, '/', null, null, 'Lax');
      }, 500);
    } else {
      setTimeout(() => {
        this.cookieService.set('CookieConsent', 'false', null, '/', null, null, 'Lax');
      }, 500);
    }
  }

  SetStatisticCosent(obj) {
    if (obj._checked) {
      setTimeout(() => {
        this.cookieService.set('StatisticConsent', 'true', this.CookieExpiry, '/', null, null, 'Lax');
      }, 500);
    } else {
      setTimeout(() => {
        this.cookieService.set('StatisticConsent', 'false', null, '/', null, null, 'Lax');
      }, 500);
    }
  }
}
