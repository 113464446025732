<!-- <ng-container *ngIf="SingleConvention != undefined && SingleConvention != null"> -->
  <div class="card-cntr">
    <div
      class="card-image"
      [ngStyle]="{
        'background-image': 'url(' + SingleConvention.Image + ')'
      }"
    ></div>

    <h4>{{ SingleConvention.Label }}</h4>

    <p>{{ SingleConvention.Description }}</p>

    <div class="link" href="#" (click)="goToDetail(SingleConvention)">
      scopri di più
    </div>

    <div class="linkCntr">
      <div class="cxPart">
        <img
          src="../../../assets/images/Home/Generic/freccia.svg"
          (click)="goToDetail(SingleConvention)"
        />
      </div>
    </div>
  </div>
<!-- </ng-container> -->