<div class="container">

    <div class="row">

        <div class="col-sm-12 col-md-6 float-left">
            <form [formGroup]="formEditMail" class="row" autocomplete="off">

                <div class="col-md-12">
                    <p>MODIFICA LA TUA MAIL</p>
                </div>

                <mat-form-field class="col-12">
                    <input type="email" class="mat-form-input" matInput placeholder="Inserisci nuova mail" required formControlName="NewMail"
                        name="new-mail">
                    <mat-error
                        *ngIf="!formEditMailControls['NewMail'].hasError('required') && formEditMailControls['NewMail'].hasError('email')">
                        <strong>E-mail</strong> non valida
                    </mat-error>
                    <mat-error *ngIf="formEditMailControls['NewMail'].hasError('required')">
                        Inserire <strong>nuova Mail</strong></mat-error>
                </mat-form-field>

                <mat-form-field class="col-12">
                    <input type="password" class="mat-form-input" matInput placeholder="Inserisci Password" required formControlName="Password"
                        name="password"  [type]="hide ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="formEditMailControls['Password'].hasError('required')">
                        Inserire <strong>Password</strong>
                    </mat-error>
                </mat-form-field>

                <div class="col-12">
                    <button type="submit" class="new-button new-button-blue float-right" (click)="saveEditedEmail()">SALVA</button>
                </div>

            </form>
        </div>

        <div class="col-sm-12 col-md-6 float-left passwordBox">
            <form [formGroup]="formEditPassword" class="row" autocomplete="off">

                <div class="col-md-12">
                    <p>MODIFICA LA TUA PASSWORD</p>
                </div>

                <mat-form-field class="col-12">
                    <input class="mat-form-input" type="password" matInput placeholder="Inserisci vecchia password" required
                        formControlName="OldPassword" name="old-password"  [type]="hideOld ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hideOld = !hideOld" class="cursor-pointer">{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="formEditPasswordControls['OldPassword'].hasError('required')">
                        Inserire <strong>vecchia Password</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="col-12">
                    <input class="mat-form-input" type="password" matInput placeholder="Inserisci nuova password" required
                        formControlName="NewPassword" name="new-password" #password autocomplete="new-password"
                        (input)="onPasswordInput()"  [type]="hideNew ? 'password' : 'text'">
                        <mat-icon matSuffix (click)="hideNew = !hideNew" class="cursor-pointer">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint align="end" aria-live="polite">
                        {{password.value.length}} / 25
                    </mat-hint>
                    <mat-error *ngIf="formEditPasswordControls['NewPassword'].hasError('required')">
                        Inserire <strong>nuova password</strong>
                    </mat-error>
                </mat-form-field>

                <div class="col-12">
                    <mat-password-strength #passwordComponent [min]="6" [max]="25" [enableSpecialCharRule]="false"
                        [enableLowerCaseLetterRule]="true" [enableUpperCaseLetterRule]="true" [enableDigitRule]="true"
                        [password]="password.value">
                    </mat-password-strength>
                    <mat-password-strength-info [passwordComponent]="passwordComponent"
                        minCharsCriteriaMsg="Inserire almeno 6 caratteri"
                        upperCaseCriteriaMsg="Inserire almeno un carattere appartenente alle lettere maiuscole (da A a Z)"
                        digitsCriteriaMsg="Inserire almeno un carattere appartenente ai primi 10 numeri di base (da 0 a 9)"
                        lowerCaseCriteriaMsg="Inserire almeno un carattere minuscolo">

                    </mat-password-strength-info>
                </div>

                <mat-form-field class="col-12 mt-2 mb-2">
                    <input type="password" class="mat-form-input" matInput placeholder="Conferma Password" required
                        name="user-password-confirm" formControlName="PasswordConfirm"
                        (input)="onPasswordInput()">
                    <mat-error
                        *ngIf="formEditPasswordControls['PasswordConfirm'].invalid && !formEditPasswordControls['PasswordConfirm'].hasError('required') ">
                        Le <strong>password</strong> non combaciano
                    </mat-error>
                    <mat-error *ngIf="formEditPasswordControls['PasswordConfirm'].hasError('required')">
                        Inserire <strong>conferma password</strong>
                    </mat-error>
                </mat-form-field>

                <div class="col-12">
                    <button type="submit" class="new-button new-button-blue float-right" (click)="saveEditedPassword()">SALVA</button>
                </div>
            </form>
        </div>
    </div>

</div>
