export enum SubUserProfileType {
    Family = 0,
    Business = 1,
}

export enum ProfileTypes {
    user = 0,
    family = 1,
    business = 2
}

