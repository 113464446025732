import { Location } from '@angular/common';
import { Component, ContentChild, Injectable, OnInit, TemplateRef } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { RegistrationStep } from 'src/app/core/enum/registration-step.enum';
import { SecurityService } from 'src/app/core/services/security.service';
import { ApiService } from './../../../app/core/services/api.service';
import { AppGeneralService } from './../../../app/core/services/app-general.service';
import { PasswordRecoveryDialogComponent } from './password-recovery-dialog/password-recovery-dialog.component';

import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from "angularx-social-login";
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { RegistrationStepService } from 'src/app/core/services/registration.service';
import { FeedService } from 'src/app/shared/feed-carousel/feed.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ContentChild('templatelogin') public template: TemplateRef<any>;

  faUser = faUser;
  faFace = faFacebook;
  hide = true;

  step: Number | any = 0;
  LiteRegistration: boolean = false;
  CallBackUrl = ['/login'];
  ExternalAuthParams = {};
  loginForm: UntypedFormGroup;
  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email
  ]);

  passwordFormControl = new UntypedFormControl('', Validators.required);

  matcher = new MyErrorStateMatcher();

  pin: string;

  spidProd: boolean;

  constructor(
    public dialog: MatDialog,
    public environmentService: EnvironmentService,
    private formBuilder: UntypedFormBuilder,
    private loginController: LoginController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private securityService: SecurityService,
    private location: Location,
    private authService: SocialAuthService,
    private feedService: FeedService,
    private appGeneralService: AppGeneralService,
  ) {
    this.loginForm = this.formBuilder.group({
      emailFormControl: this.emailFormControl,
      passwordFormControl: this.passwordFormControl
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(PasswordRecoveryDialogComponent, { panelClass: 'custom-dialog-container', width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
        if(result.res == false){
          this.appGeneralService.showDialog(result.error);
          return;
        }

        let esito = this.appGeneralService.showDialog(null,"Email inviata all'indirizzo inserito o alla mail associata al Codice fiscale controlla il tuo provider di posta.",
        "ESITO",[{ name: 'OK', action: () => { esito.close() } }]);

    });
  }

  ngOnInit() {
    if (this.securityService.IsLogged) {
      this.router.navigate(['/']);
      return;
    }


    this.activatedRoute.queryParams.subscribe(param => {
      if (param["LiteRegistration"] != null || param["LiteRegistration"] != undefined) {
        this.LiteRegistration = JSON.parse(param["LiteRegistration"]);
        this.CallBackUrl = param["callbackUrl"];
      }

      if (param["SpidProdTest"] != null || param["SpidProdTest"] != undefined) {
        this.spidProd = JSON.parse(param["SpidProdTest"]);
      }

    });

    if (this.activatedRoute.snapshot.routeConfig.path == 'login') {
      this.location.replaceState('/login');
      this.step = 0;
    } else {
      this.location.replaceState('/registrazione');
      this.step = 1;
    }
  }

  openRegistration() {
    this.router.navigate(['registrazione'],
      { queryParams: { LiteRegistration: this.LiteRegistration, callbackUrl: this.CallBackUrl } });
  }

  openLogin() {
    this.router.navigate(['login'], { queryParams: { LiteRegistration: this.LiteRegistration, callbackUrl: this.CallBackUrl } });
  }

  signInWithGoogle(): void {
    let result = this.socialSignIn(GoogleLoginProvider.PROVIDER_ID);

    result.then(value => {
      if (value != null)
        this.socialLogin(value, "ExternalLoginGoogle");
    });
  }

  signInWithFacebook(): void {
    let result = this.socialSignIn(FacebookLoginProvider.PROVIDER_ID);

    result.then(value => {
      if (value != null)
        this.socialLogin(value, "ExternalLoginFacebook");
    });
  }

  private socialSignIn(LoginProvider) {
    return this.authService.signIn(LoginProvider);
  }

  socialLogin(authResult: SocialUser, changeAction: string) {
    this.loginController.login(authResult.email, "", false, this.loginForm, this.LiteRegistration, this.CallBackUrl, true, authResult, changeAction);
  }

  login() {
    if (this.loginForm.valid) {

      this.feedService.DestroyFeedPanelOpened();
      this.loginController.login(this.loginForm.get('emailFormControl').value, this.loginForm.get('passwordFormControl').value, false, this.loginForm, this.LiteRegistration, this.CallBackUrl)

    }
  }

}

@Injectable()
export class LoginController {

  constructor(
    public appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private router: Router,
    private storageService: LocalStorageService,
    private registrationService: RegistrationStepService,
    private securityService: SecurityService
  ) { }

  RegistrationStep: RegistrationStep;

  login(Mail: string, Password: string, RememberMe: boolean = false, Form: UntypedFormGroup, LiteRegistration: boolean, CallBackUrl, IsSocialLogin: boolean = false, SocialLoginResult: SocialUser = null, changeAction: string = undefined) {

    const loginParams = new Login(Mail, Password, RememberMe, IsSocialLogin, SocialLoginResult);
    var action = "/api/Authentication/" + (changeAction ? changeAction : "Login");

    this.apiService.callApiProd(action, new CallParameter('POST', loginParams)).subscribe((result) => {

      this.storageService.setAuthenticationLevel(result.AuthenticationType);
      this.storageService.setSessionID(result.SessionID);
      this.storageService.setRegistrationIncomplete(result.RegistrationIncomplete);

      if (result.InError) {
        if (result.ErrorCode == 402 && IsSocialLogin) {
          let socialDialog = this.appGeneralService.showDialog(0, "Il tuo profilo social non è stato trovato, completa la registrazione con il tuo account social", "Accesso fallito!");
          if (socialDialog != null) {
            socialDialog.afterClosed().subscribe(evt => {
              Form.controls["emailFormControl"].setValue("");
              Form.controls["passwordFormControl"].setValue("");
              this.router.navigate(['registrazione'],
                { queryParams: { LiteRegistration: LiteRegistration, callbackUrl: CallBackUrl, socialLoginMail: SocialLoginResult.email, socialLoginName: SocialLoginResult.firstName, socialLoginSurname: SocialLoginResult.lastName } });
            })
          }
          return;
        }

        let dialog = this.appGeneralService.showDialog(result.ErrorCode);

        if (dialog != null) {

          dialog.afterClosed().subscribe(evt => {
            Form.controls["emailFormControl"].setValue("");
            Form.controls["passwordFormControl"].setValue("");
          })

        }
        return;
      }

      this.RegistrationStep = result.ResultData["RegistrationStep"];

      this.storageService.setRegistrationStep(this.RegistrationStep.toString());

      if (!this.registrationService.checkRegistrationCompleted(this.RegistrationStep)) {
        return;
      }

      this.storageService.setUserData(result.ResultData["Name"]);

      this.securityService.check_log_state();

      if(this.storageService.getRegistrationIncomplete()){

        this.appGeneralService.showDialog(null,"Ricordati di compilare i dati mancanti nella sezione profilo!","ATTENZIONE");
        this.storageService.setRegistrationIncomplete(false);

      }

      let Id = this.storageService.getLoginConvention();

      if (Id) {
        var action = "/api/Account/UpdateUserProfileData";
        this.apiService.callApiProd(action, new CallParameter("POST", {
          UserProfiles: [{
            ConventionID: Number(Id),
            JobProfileID: null,
            BulletinID: null,
            GraduationDate: null,
            Employed: false,
            Freelance: false,
            PEC: "",
            PIVA: "",
            ReadOnly: false
          }]
        })).subscribe();
      }

      if (LiteRegistration) {
        this.router.navigate(CallBackUrl);
        return;
      }

      if(result.ResultData["profileInfo"].find(profile => profile.IsAllPolicy == true)){

              let path = this.storageService.getOrSetDefaultLastPageSeen('/');

              this.router.navigateByUrl(path);
              return;
      }

      this.router.navigate(["/"]);
    });
  }
}

export class Login {

  Mail: string;
  Password: string;
  RememberMe: boolean;
  IsSocialLogin: boolean;
  SocialLoginResult: SocialUser

  constructor(
    Mail: string,
    Password: string,
    RememberMe: boolean = false,
    IsSocialLogin = false,
    SocialLoginResult = null) {

    this.Mail = Mail;
    this.Password = Password;
    this.RememberMe = RememberMe;
    this.IsSocialLogin = IsSocialLogin;
    this.SocialLoginResult = SocialLoginResult;
  }

}
