import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageSliderComponent implements OnInit {

  @Input() Background;

  constructor() { }

  ngOnInit() {


  }

  link(path){
    window.location.href=path;
  }
}
