import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-group-proposal-carousel',
  templateUrl: './policy-group-proposal-carousel.component.html',
  styleUrls: ['./policy-group-proposal-carousel.component.scss']
})
export class PolicyGroupProposalCarouselComponent implements OnInit {

  @Input() ProposalInfo;
  @Input() Title;


  constructor() { }

  ngOnInit() {
  }

  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 600) {
      return 600;
    }
      return (windowWidth / 100)* 70;

  }


  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 600) {
      return 260;
    }
      return (window.innerHeight / 100) * 45 ;

  }

}
