import { Component, OnInit } from '@angular/core';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { OptionInfoDialogComponent } from 'src/app/pages/policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';

@Component({
  selector: 'app-store-home',
  templateUrl: './store-home.component.html',
  styleUrls: ['./store-home.component.scss']
})
export class StoreHomeComponent implements OnInit {

  constructor(private appGeneralService : AppGeneralService) { }

  ngOnInit() {
  }

  openInfoDialog() {
    this.appGeneralService.showDialog(null,"","Presto disponibile sugli store!");
  }

  goToGoogle(){

    window.location.href = "https://play.google.com/store/apps/details?id=com.myinsurer.app&hl=it"


  }

  goToApple(){

    window.location.href = "https://apps.apple.com/it/app/myinsurer/id6503043358"


  }

}
