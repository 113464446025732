
<div class="container ContractAccept">
    <div *ngIf="!disableClose" class="clearfix w-100" style="justify-content: flex-end;">
        <fa-icon [icon]="faTimes" style="cursor: pointer;" (click)="close()"></fa-icon>
    </div>
    <ng-container *ngIf="(infoDocument._Documents != null && infoDocument._Documents.length > 0) || (infoDocument._Modules != null && infoDocument._Modules.length > 0)">
        <h3>In osservanza ai regolamenti IVASS ti invitiamo a scaricare la documentazione sottostante prima di procedere</h3>
        <p [innerHTML]="infoDocument._Text"></p>
        <div class="checkboxArea contract-document">
            <ul style="list-style-type: none;">
                <div *ngFor="let document of infoDocument._Documents">
                        <li style="width: 100%;">
                            <a [href]="document.DownloadUrl" target="_blank" title="document.Name">
                                {{document.OriginalFileName ?? document.Name}}
                            </a>
                        </li>
                </div>
            </ul>
            <ul style="list-style-type: none;">
                <div *ngFor="let Module of infoDocument._Modules">
                    <ng-container *ngIf="!Module.WasAlreadyUploaded">
                        <li style="width: 100%;">
                            <a (click)="openModuleModal(Module.Url)" target="_blank" title="Module.Name">
                                {{Module.OriginalFileName}}
                            </a>
                        </li>
                    </ng-container>
                </div>
            </ul>
            <p>
                <small>Clicca sul singolo documento per visualizzarne il contenuto.</small>
            </p>
        </div>

        <button *ngIf="!(isUser)" class="new-button" (click)="close()" routerLink="/login">Accedi</button>
        <button class="new-button ml-2" (click)="onAccept()">
            <span *ngIf="!isToCompleteRegistration; else registration">SCARICA</span>
            <ng-template #registration>
                <span>ACCETTA E CONTINUA</span>
            </ng-template>
        </button>
    </ng-container>
</div>