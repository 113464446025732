import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-how-work',
  templateUrl: './carousel-how-work.component.html',
  styleUrls: ['./carousel-how-work.component.scss']
})
export class CarouselHowWorkComponent implements OnInit {

  schede : Array<SchedaHowWork> =[

    {
      title : "Seleziona la polizza da configurare",
      img : "../../../../assets/images/Configuratore/ICONA_1.svg"
    },
    {
      title : "Personalizza in base alle tue esigenze",
      img : "../../../../assets/images/Configuratore/ICONA_2.svg"
    },
    {
      title : "Il gioco è fatto. Procedi all'acquisto",
      img : "../../../../assets/images/Configuratore/ICONA_3.svg"
    }

  ];

  constructor() { }

  ngOnInit() {

  }

  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1400) {
      return 350;
    } else if (windowWidth > 600 && windowWidth <= 1400) {
      return 250;
    } else {
      return 150;
    }
  }


  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1400) {
      return 400;
    } else if (windowWidth > 600 && windowWidth <= 1400) {
      return 340;
    } else {
      return 230;
    }
  }

}

export class SchedaHowWork{

   title : string
   img : string


}
