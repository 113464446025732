import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  PolicyGroupInfo,
  ProposalInfos,
} from "src/app/core/classes/policy-detail";
import { CallParameter } from "src/app/core/classes/system/call-parameter";
import { ApiService } from "src/app/core/services/api.service";
import { AppGeneralService } from "src/app/core/services/app-general.service";
import * as $ from "jquery";
import { SeoService } from "src/app/core/services/seo.service";
import { JsonLoaderService } from "src/app/core/services/json-loader.service";
import { UrlBuilderService } from "src/app/core/services/url-builder.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-policy-group",
  templateUrl: "./policy-group.component.html",
  styleUrls: ["./policy-group.component.scss"],
})
export class PolicyGroupComponent implements OnInit {
  PolicyFullDetail = null;
  PolicyGroupInfo: PolicyGroupInfo = null;
  ProposalInfos: ProposalInfos = null;

  tabTitle: string = '';

  policyGroupID: number;
  proposalIdCode: string = '';
  proposalCodeChanged: string = '';

  JobProfileID;

  firstInit: boolean = false;

  scroll : boolean = false;

  PolicyGroupList: any;

  SecurityUrl : SafeResourceUrl;

  constructor(
    private appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    private jsonLoaderService: JsonLoaderService,
    private urlBuilderService: UrlBuilderService,
    private _sanitizer: DomSanitizer
  ) {

    this.activatedRoute.queryParams.subscribe((params) => {

      if (params["GroupID"]) {
        this.policyGroupID = +params["GroupID"];
      }

      if (params["ProposalID"]) {
        this.proposalCodeChanged = params["ProposalID"];
      }
      if (params["Scroll"]) {
        this.scroll = params["Scroll"];
      }

      this.getPolicyDetail(this.policyGroupID);

      this.getSEOData();
    });
  }


  ngOnInit() {

    this.proposalIdCode = this.proposalCodeChanged;

   }

  getSEOData() {
    // carico il json con i dati per la SEO e imposto Title e Description
    this.jsonLoaderService
      .getJsonData("../../../../assets/group-policies-seo.json")
      .subscribe((r) => {
        this.PolicyGroupList = r;

        let currentPolicyGroup = this.PolicyGroupList.find(
          (elm) => elm.ID === this.policyGroupID
        );

        if (currentPolicyGroup) {
          this.seoService.update_title(currentPolicyGroup.title);
          this.seoService.update_description(currentPolicyGroup.description);
        }
      });
  }

  getPolicyDetail(ID: number) {

    this.JobProfileID = 0;
    const PolicyParams = new PolicyGroup(ID);

    this.apiService
      .callApiProd(
        "/api/Policy/ListAvailablePolicies",
        new CallParameter("POST", PolicyParams)
      )
      .subscribe((result) => {

        if (result.InError) {

          this.appGeneralService.showDialog(result.ErrorCode);

        } else {

          this.PolicyFullDetail = result["ResultData"];
          this.setLink();

          if (this.PolicyFullDetail != undefined && this.PolicyFullDetail != null && this.proposalCodeChanged == '') {
            this.SingleProposal = this.PolicyFullDetail.ProposalInfos[0];
            this.tabTitle = this.PolicyFullDetail.ProposalInfos[0].Label;
            this.proposalIdCode = this.PolicyFullDetail.ProposalInfos[0].PolicyGroupID + '_' + this.PolicyFullDetail.ProposalInfos[0].ProposalId;
            this.seoService.update_image(this.PolicyFullDetail.PolicyGroupInfo.ImageUrls[0]);

          }
          else {

            let propSplitted = this.proposalCodeChanged.split('_')[1];

            this.SingleProposal = this.PolicyFullDetail.ProposalInfos.find(elm => elm.ProposalId === +propSplitted);

            this.proposalTabActivator(this.proposalCodeChanged);

            this.seoService.update_image(this.PolicyFullDetail.PolicyGroupInfo.ImageUrls[0]);

          }

          setTimeout(() => {
            const element = document.getElementById("destination");
            if (element && this.scroll) element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }, 1000);
        }

      });

  }

  proposalTabActivator(code) {

    setTimeout(() => {
      $(".selected").removeClass("selected");
      $("#" + code).addClass("selected");
    }, 0);

    this.proposalIdCode = code;
  }

  SingleProposal: ProposalInfos = null;
  ShowProposalBoxes(proposalCodeSelected) {


    // this.SingleProposal = this.PolicyFullDetail.ProposalInfos[index];

    // this.tabTitle = this.PolicyFullDetail.ProposalInfos[index].Label;
    // $(".selected").removeClass("selected");
    // $("#ProposalBox_" + index).addClass("selected");

    this.urlBuilderService.navigateToPath(this.policyGroupID, 0, this.proposalIdCode);

  }


  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 660;
    }
      return windowWidth ;

  }


  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 355;
    }
      return (window.innerHeight / 100) * 30 ;

  }
  setLink() {


    this.SecurityUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.PolicyFullDetail.PolicyGroupInfo.VideoLink);
  }

  navigateToYoutube(){

    window.open("https://www.youtube.com", '_blank');

  }

}

export class PolicyGroup {
  policyGroupID: number;

  constructor(policyGroupID: number) {
    this.policyGroupID = policyGroupID;
  }
}
