import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedCarousel } from 'src/app/core/classes/Feed/feed-carousel';
import { FeedInfo } from 'src/app/core/classes/Feed/feed-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { CardType } from 'src/app/core/enum/card-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { FeedTypeArray } from 'src/app/pages/home/home.component';

@Component({
  selector: 'app-feed-carousel',
  templateUrl: './feed-carousel.component.html',
  styleUrls: ['./feed-carousel.component.scss']
})

export class FeedCarouselComponent implements OnInit {

  @Input() FeedCarousel: FeedCarousel;
  FeedTypes = [];
  Feeds: FeedInfo[] = [];
  FeedCarousels: FeedCarousel[] = [];

  cardType: CardType = CardType.Home;

  constructor(
    private appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private route :Router ) { }

  ngOnInit() {

    if(this.FeedCarousel == null){

      this.getFeedHome();

    }

  }

  getFeedHome() {
    this.FeedTypes = [0];
    const FeedTypesSent = new FeedTypeArray(this.FeedTypes);


    this.apiService.callApiProd('/api/Feed/GetPolicyFeed', new CallParameter('POST', FeedTypesSent)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.Feeds = result['ResultData']['Feed'] as FeedInfo[];
        this.Feeds.sort((a, b) => { return a.Id - b.Id });
        this.Feeds.forEach(feed => {
          this.FeedCarousels.push(new FeedCarousel(feed));
        });
      }
    });
  }

  navigateTo(){

    this.route.navigate(['/assicurazioni']);

  }

}
