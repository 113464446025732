import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }
  readonly originDomain = environment.originDomain;
  readonly Url = environment.api.URL;
  readonly UrlF = environment.api.URLF;
  readonly Prod = environment.production;
  readonly Configuratore = environment.api.Configuratore;
  readonly SPIDProd = environment.api.SPIDProd;
  readonly SPIDDev = environment.api.SPIDDev;
  readonly paypalKey = environment.cliendIDSandBox;
  readonly fakePrice = environment.fakePrice;
}
