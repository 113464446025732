import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';

@Component({
  selector: 'app-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss']
})
export class DialogHandlerComponent implements OnInit {

  modalBuilder;
  constructor(
    public dialogRef: MatDialogRef<DialogHandlerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: LocalStorageService,
    private router: Router
    ) {
  }

  ngOnInit() {
    this.modalBuilder = this.getMessage(this.data['messageCode'])

  }

  close(): void {
    this.dialogRef.close();
  }

  logout(login : boolean = true) {
    sessionStorage.clear();
    localStorage.clear();
    if(login){
    this.router.navigate(['/login']);
    }
  }

  cons(){}

  getMessage(code) {

    var MyErrorModal = this.dialogRef;
    switch (code) {

      case -5000:
        return {
          title: 'Redirect',
          message: 'Stai per essere reindirizzato alla pagina di pagamento...',
          buttons: []
        };
      case -1000:
        return {
          title: 'Chiamata rifiutata',
          message: 'La chiamata al servizio &egrave; stata rifiutata. Si prega di riprovare.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case -998:
      case -997:
        return {
          title: 'Sessione scaduta',
          message: '&Egrave; necessario effettuare la login per continuare a navigare.',
          buttons: [{ name: 'Accedi', action: () => { this.logout(); MyErrorModal.close() } },
                    { name: 'Chiudi', action: () => { this.logout(false); MyErrorModal.close() } }]

        };
      case -996:
        return {
          title: 'ACCEDI O REGISTRATI',
          message: 'Per proseguire è necessario accedere o registrarsi.',
          buttons: this.data['buttons'] != null ? this.data['buttons'] : [
            { name: 'REGISTRATI', action: () => { this.router.navigate(['/registrazione']); MyErrorModal.close(); } },
            {name:"Accedi", action: () => { this.router.navigate(['/login']); MyErrorModal.close(); } }
          ]
        };
      case 399:
        return {
          title: 'E-mail, numero di telefono o codice fiscale non disponibile',
          message: '&Egrave; necessario inserire un\'altra e-mail, un altro numero di telefono e/o un altro codice fiscale per proseguire.<br><br>Se eri già registrato con il vecchio portale e non ricordi la password, prova a fare recupero password',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }, { name: 'Accedi', action: () => { this.router.navigate(['/login']); MyErrorModal.close(); } }]
        };
      case 400:
        return {
          title: 'Si &egrave; verificato un errore',
          message: "Se l'errore persiste contattare l'assistenza.",
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 401:
        return {
          title: 'Errore durante il salvataggio',
          message: 'Si &egrave; verificato un errore durante la procedura di salvataggio. Si prega di riprovare.</br>Se l\'errore persiste si prega di contattare l\'assistenza.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 402:
        return {
          title: 'Accesso fallito',
          message: 'Non &egrave; stato possibile accedere. Verifica le credenziali e riprova.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 403:
        return {
          title: 'Mail non inviata',
          message: 'Non &egrave; stato possibile inviare la mail. Si prega di riprovare.</br>Se l\'errore persiste si prega di contattare l\'assistenza.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 404:
        return {
          title: 'Pagina non trovata',
          message: 'Non &egrave; stato possibile raggiungere la pagina che cercavi.',
          buttons: [{ name: 'OK', action: () => { this.router.navigate(['/']); MyErrorModal.close(); } }]
        };

      case 405:
        return {
          title: 'Il codice inserito non è valido',
          message: 'Non è possibile completare la registrazione, verifica il codice che ti abbiamo mandato per email e riprova.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 406:
        return {
          title: 'Profilo lavorativo non trovato',
          message: 'Il profilo lavorativo che hai provato ad inserire non &egrave; stato trovato. Seleziona un profilo corretto.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 407:
        return {
          title: 'Step non disponibile',
          message: 'Devi confermare la tua mail prima di proseguire con la registrazione.',
          buttons: [{ name: 'OK', action: () => { this.router.navigate(['/']); MyErrorModal.close(); } }]
        };

      case 408:
        return {
          title: 'Configurazione non valida',
          message: 'La configurazione del profilo lavorativo associata ai relativi albi e ' + this.storageService.getBrokerManagementLabel(false,false) +' non è valida.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 409:
        return {
          title: 'PIN non valido',
          message: 'Si è verificato un errore durante l\'attivazione del servizio.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 410:
        return {
          title: 'Servizio non trovato',
          message: 'Non &egrave; stato possibile trovare il servizio che cercavi.',
          buttons: [{ name: 'OK', action: () => { this.router.navigate(['/']); MyErrorModal.close(); } }]
        };

      case 411:
        return {
          title: 'Errore attivazione polizza',
          message: 'Non &egrave; stato possibile attivare la polizza. Questo può essere dovuto all\'assenza di un profilo lavorativo. Se il problema persiste contattare l\'assistenza',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }, { name: 'VAI AL TUO PROFILO', action: () => { this.router.navigate(['/profilo'], { queryParams: { manage: true } }); MyErrorModal.close();}}]
        }
      case 416:
        return {
          title: 'Polizza non trovata',
          message: 'Non &egrave; stato possibile trovare la polizza che cercavi.',
          buttons: [{ name: 'OK', action: () => { this.router.navigate(['/']); MyErrorModal.close(); } }]
        };

      case 417:
        return {
          title: 'Errore durante il recupero delle informazioni',
          message: 'Non &egrave; stato possibile recuperare le informazioni dell\'utente.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 418:
        return {
          title: 'Utente non trovato',
          message: 'Non &egrave; stato possibile trovare l\'utenza inserita.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };

      case 419:
        return {
          title: 'Errore durante il recupero della password',
          message: 'Si &egrave; verificato un errore durante il recupero della password. Si prega di riprovare.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 422:
      case 424:
        return {
          title: 'Impossibile salvare il file',
          message: 'Si &egrave; verificato un errore durante il salvataggio del password.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 423:
      case 425:
        return {
          title: 'Profilo non trovato',
          message: 'Non &egrave; stato possibile trovare il profilo associato all\'utenza.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 427:
        return {
          title: 'Profilo non abilitato',
          message: 'Profilo non abilitato alla sottoscrizione per questa polizza.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 428:
        return {
          title: 'Pagamento non riuscito',
          message: 'Il pagamento non &egrave; andato a buon fine. Si prega di riprovare.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 430:
        return {
          title: 'Profilo non trovato',
          message: 'Il profilo selezionato non è disponibile.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 431:
        return {
          title: 'Impossibile procedere',
          message: 'Verifica di aver inserito correttamente la tua vecchia password.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 436:
        return {
          title: 'Password errata',
          message: 'La password inserita non è corretta',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 438:
        return {
          title: 'Impossibile procedere',
          message: 'Prima di dichiarare la polizza come pagata devi caricare tutti i documenti.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 440:
        return {
          title: 'Impossibile aggiungere la polizza al carrello',
          message: 'Sembra che tu abbia gi&agrave; acquistato una polizza di questo tipo, le polizze sono rinnovabili a 60 giorni dalla scadenza.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 441:
        return {
          title: 'Impossibile aggiungere la polizza al carrello',
          message: 'Hai gi&agrave; inserito nel carrello una polizza di questo tipo, se vuoi cambiare polizza rimuovi prima quella nel carrello.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }, { name: 'VAI AL CARRELLO', action: () => { this.router.navigate(['/carrello']); MyErrorModal.close(); } }]
        };
      case 442: 
        return {
          title: 'Impossibile procedere',
          message: `Non è stato trovato il file di conferma della polizza. Contattare l'assistenza.`,
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 455:
        return {
          title: 'Impossibile creare nuovo profilo lavorativo',
          message: 'Sembra che il profilo che stai provando ad inserire sia gi&agrave; presente.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 456:
        return {
          title: '',
          message: 'Gentile cliente, l\'importazione della sua utenza non è ancora stata completata, la preghiamo di tornare sul portale della sua convenzione, cliccare sul tasto per scaricare la quietanza e successivamente completare la registrazione.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 500:
        return {
          title: 'Impossibile caricare il modulo di adesione',
          message: 'Sembra che tu non abbia inserito la data di laurea nel tuo profilo. Aggiornalo e riprova.',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }, { name: 'VAI AL TUO PROFILO', action: () => { this.router.navigate(['/profilo'], { queryParams: { manage: true } }); MyErrorModal.close(); } }]
        };
      case 501:
        return {
          title: 'Non possiedi un profilo adatto alla polizza',
          message: "Sembra che tu non abbia un profilo per l'acquisto di questa polizza. Attivane uno adatto e riprova.",
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }, { name: 'VAI AL TUO PROFILO', action: () => { this.router.navigate(['/profilo'], { queryParams: { manage: true } }); MyErrorModal.close(); } }]
        };
      case 502:
        return {
          title: 'Si è verificato un errore',
          message: "Se l'errore persiste contattare l'assistenza.",
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 503:
        return {
          title: 'Sembra che si sia verificato un errore',
          message: "Se l'errore persiste contattare l'assistenza.",
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 506:
        return {
          title: 'Si è verificato un imprevisto',
          message: this.data['resultData'] != null && this.data['resultData']['message'] ? this.data['resultData']['message'] : 'Non è stato possibile definire l\'entità dell\'errore',
          buttons: [{ name: 'VAI AL TUO PROFILO', action: () => { this.router.navigate(['/profilo']); MyErrorModal.close(); } }]
        };
      case 509:
        return {
          title: 'Attenzione',
          message: 'Prima di proseguire con il pagamento della polizza è necessario compilare tutti i moduli',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 510:
        return {
          title: 'Attenzione',
          message: 'Il codice fiscale inserito non è disponibile',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 512:
        return {
          title: 'Attenzione',
          message: 'Non risulti iscritto a Federazione Nazionale degli Ordini come Neolaureato',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
      case 517:
        return {
          title: 'Attenzione',
          message: 'La polizza verrà gestita dal sito https://www.tsrm.org/ cliccando il pulsante verrai reindirizzato',
          buttons: [{ name: 'Redirect', action: () => { window.location.href = "https://www.tsrm.org/" } }]
        };
      case 518:
        return {
          title: 'Attenzione',
          message: 'Ti abbiamo inviato una mail per completare la registrazione!',
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close(); } }]
        };
      case 519:
        return {
          title: 'Errore',
          message: "Errore critico contattare l'assistenza al numero: 010565582 e comunicare l'errore 'Generazione Identity'",
          buttons: [{ name: 'OK', action: () => { MyErrorModal.close(); } }]
        };
      default:
        return {
          title: this.data['title'] != null ? this.data['title'] : 'Si è verificato un imprevisto',
          message: this.data['message'] != null ? this.data['message'] : 'Non è stato possibile definire l\'entità dell\'errore',
          buttons: this.data['buttons'] != null ? this.data['buttons'] : [{ name: 'OK', action: () => { MyErrorModal.close() } }]
        };
    }
  }
}
