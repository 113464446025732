<div class="CookiesPolicyCntr">
    <h1 class="text-center">Informativa Cookies</h1>
    <p>
        Questo sito web utilizza i cookie. Utilizziamo i cookie per personalizzare contenuti ed annunci, per fornire funzionalità dei social media e per analizzare il nostro traffico. Condividiamo inoltre informazioni sul modo in cui utilizza il nostro sito con
        i nostri partner che si occupano di analisi dei dati web, pubblicità e social media, i quali potrebbero combinarle con altre informazioni che ha fornito loro o che hanno raccolto dal suo utilizzo dei loro servizi. Acconsenta ai nostri cookie se
        continua ad utilizzare il nostro sito web.
    </p>
    <p>
        I cookie sono piccoli file di testo che possono essere utilizzati dai siti web per rendere più efficiente l’esperienza per l’utente.
    </p>
    <p>
        La legge afferma che possiamo memorizzare i cookie sul suo dispositivo se sono strettamente necessari per il funzionamento di questo sito. Per tutti gli altri tipi di cookie abbiamo bisogno del suo permesso.
    </p>
    <p>
        Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono collocati da servizi di terzi che compaiono sulle nostre pagine.
    </p>
    <p>
        In qualsiasi momento è possibile modificare o revocare il proprio consenso dalla Dichiarazione dei cookie sul nostro sito Web.
    </p>
    <p>
        Scopra di più su chi siamo, come può contattarci e come trattiamo i dati personali nella nostra Informativa sulla privacy.
    </p>
    <p>
        Il suo consenso si applica ai seguenti siti web: www.myinsurer.it
    </p>

    <h5>Necessario <small>(1)</small></h5>
    <p>
        I cookie necessari aiutano a contribuire a rendere fruibile un sito web abilitando le funzioni di base come la navigazione della pagina e l’accesso alle aree protette del sito. Il sito web non può funzionare correttamente senza questi cookie.
    </p>
    <p>
        <mat-slide-toggle [checked]="Consent" (change)="SetCosent(ConsentToggle)" #ConsentToggle>Necessari</mat-slide-toggle>
    </p>
    <div class="tableContainer">
        <table mat-table [dataSource]="necessary_cookies_source" class="full-width mat-elevation-z8">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef> Fornitore </th>
                <td mat-cell *matCellDef="let element"> {{element.supplier}} </td>
            </ng-container>

            <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef> Scopo </th>
                <td mat-cell *matCellDef="let element"> {{element.purpose}} </td>
            </ng-container>

            <ng-container matColumnDef="expiry">
                <th mat-header-cell *matHeaderCellDef> Scadenza </th>
                <td mat-cell *matCellDef="let element"> {{element.expiry}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <h5>Statistiche <small>(3)</small></h5>
    <p>
        I cookie statistici aiutano i proprietari del sito web a capire come i visitatori interagiscono con i siti raccogliendo e trasmettendo informazioni in forma anonima.
    </p>
    <p>
        <mat-slide-toggle [checked]="StatisticConsent" (change)="SetStatisticCosent(StatisticConsentToggle)" #StatisticConsentToggle>Statistici</mat-slide-toggle>
    </p>
    <div class="tableContainer">
        <table mat-table [dataSource]="statistics_cookies_source" class="full-width mat-elevation-z8">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="supplier">
                <th mat-header-cell *matHeaderCellDef> Fornitore </th>
                <td mat-cell *matCellDef="let element"> {{element.supplier}} </td>
            </ng-container>

            <ng-container matColumnDef="purpose">
                <th mat-header-cell *matHeaderCellDef> Scopo </th>
                <td mat-cell *matCellDef="let element"> {{element.purpose}} </td>
            </ng-container>

            <ng-container matColumnDef="expiry">
                <th mat-header-cell *matHeaderCellDef> Scadenza </th>
                <td mat-cell *matCellDef="let element"> {{element.expiry}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Tipo </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="mt-5">
        <button class="new-button" (click)="appGeneralService.routerBack()">INDIETRO</button>
    </div>
</div>
