<div class="stepper-cntr">
  <mat-horizontal-stepper linear [disableRipple]="true">
    <mat-step [stepControl]="formRegistration" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistration" (ngSubmit)="this.registrationService.setCompleteStep(true, formRegistration)" autocomplete="off">
        <ng-template matStepLabel></ng-template>
        <div class="title-cntr">
          <h2>Inserisci i tuoi dati</h2>
        </div>
        <div class="row">
          <mat-form-field  class="col-sm-4">
            <div class="mat-label-txt">Nome Rappresentante*</div>
            <input type="text" matInput required formControlName="Name" name="user-name" class="mat-form-input"
              autocomplete="none" tabindex="1">
            <mat-error *ngIf="formRegistration.controls['Name'].hasError('required')">
              Inserire <strong>nome rappresentante</strong>
            </mat-error>
          </mat-form-field >

          <mat-form-field  class="col-sm-4">
            <div class="mat-label-txt">Cognome Rappresentante*</div>
            <input type="text" matInput required formControlName="Surname" name="user-name" class="mat-form-input"
              autocomplete="none" tabindex="1">
            <mat-error *ngIf="formRegistration.controls['Surname'].hasError('required')">
              Inserire <strong>cognome rappresentante</strong>
            </mat-error>
          </mat-form-field >

          <mat-form-field class="col-sm-4">
            <div class="mat-label-txt">Codice Fiscale*</div>
            <input type="text" matInput #CodiceFiscale required name="fiscal-code" class="mat-form-input"
              formControlName="CF" (paste)="this.registrationService.onCfPaste($event, formRegistration, true)" autocomplete="none">
            <mat-error *ngIf="formRegistration.controls['CF'].hasError('required')">
              Inserire <strong>Codice Fiscale</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field  class="col-sm-8">
            <div class="mat-label-txt">Ragione Sociale*</div>
            <input type="text" matInput required formControlName="CompanyName" name="user-name" class="mat-form-input"
              autocomplete="none" tabindex="1">
            <mat-error *ngIf="formRegistration.controls['CompanyName'].hasError('required')">
              Inserire <strong>ragione sociale</strong>
            </mat-error>
          </mat-form-field >

          <mat-form-field  class="col-sm-4">
            <div class="mat-label-txt">Tipo di società*</div>
            <mat-select matNativeControl required formControlName="CompanyType" class="mat-form-input" tabindex="2">
              <mat-option *ngFor="let companyType of companyTypes.TypeArray" [value]="companyType.Id">
                {{ companyType.Name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formRegistration.controls['CompanyType'].hasError('required')">
              Selezionare <strong>tipo societario</strong>
            </mat-error>
          </mat-form-field >
          
          <mat-form-field  class="col-sm-6">
            <div class="mat-label-txt">Rea*</div>
            <input type="text" matInput required formControlName="Rea" name="user-name" class="mat-form-input"
              autocomplete="none" tabindex="3">
            <mat-error *ngIf="formRegistration.controls['Rea'].hasError('required')">
              Inserire <strong>rea</strong>
            </mat-error>
          </mat-form-field >
          
          <mat-form-field  class="col-sm-6">
            <div class="mat-label-txt">Partita Iva*</div>
            <input type="text" matInput required formControlName="Piva" name="user-name" class="mat-form-input"
              autocomplete="none" tabindex="3">
            <mat-error *ngIf="formRegistration.controls['Piva'].hasError('required')">
              Inserire <strong>partita iva</strong>
            </mat-error>
          </mat-form-field >

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Indirizzo</div>
              <input matInput type="text" formControlName="Address" autocomplete="new-address" class="mat-form-input"
                tabindex="4">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">N° Civico</div>
              <input matInput type="text" formControlName="BuildingNumber" class="mat-form-input"
                name="Building-Number" autocomplete="none" tabindex="5">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">Città</div>
              <input matInput type="text" formControlName="City" name="city" class="mat-form-input"
                autocomplete="none" tabindex="6">
            </mat-form-field>

            <mat-form-field class="col-sm-6">
              <div class="mat-label-txt">Provincia</div>
              <input matInput type="text" formControlName="Province" autocomplete="none" class="mat-form-input"
                name="province" (blur)="this.registrationService.CheckExistingProvince(formRegistrationStep2)" [matAutocomplete]="auto" maxlength="2" tabindex="7">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let province of this.registrationService.filteredProvinces | async" [value]="province">{{province}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>


          <mat-form-field class="col-sm-4">
            <div class="mat-label-txt">Numero di telefono*</div>
            <input type="tel" matInput required formControlName="Phone" class="mat-form-input"
              name="user-phone" autocomplete="none" tabindex="8">
            <mat-error *ngIf="formRegistration.controls['Phone'].hasError('required')">
              Inserire <strong>numero</strong> </mat-error>
          </mat-form-field>


          <mat-form-field class="col-sm-8">
            <div class="mat-label-txt">Email*</div>
            <input type="email" matInput required formControlName="Email" name="user-mail" class="mat-form-input"
              autocomplete="none" tabindex="9">
            <mat-error
              *ngIf="!formRegistration.controls['Email'].hasError('required') && formRegistration.controls['Email'].hasError('email')">
              <strong>E-mail</strong> non valida
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['Email'].hasError('required')">
              Inserire <strong>e-mail</strong> </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Password*</div>
            <input type="password" matInput required formControlName="Password" #password class="mat-form-input"
              name="user-password" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="10"
              [type]="this.registrationService.hide ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hide = !this.registrationService.hide" class="cursor-pointer">{{this.registrationService.hide ? 'visibility_off' :
              'visibility'}}</mat-icon>

            <mat-error *ngIf="formRegistration.controls['Password'].hasError('required')">
              Inserire <strong>password</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Conferma Password*</div>
            <input matInput required name="user-password-confirm" class="mat-form-input"
              formControlName="PasswordConfirm" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="11"
              [type]="this.registrationService.hideConfirm ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hideConfirm = !this.registrationService.hideConfirm" class="cursor-pointer">{{this.registrationService.hideConfirm ?
              'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error
              *ngIf="formRegistration.controls['PasswordConfirm'].invalid && !formRegistration.controls['PasswordConfirm'].hasError('required') ">
              Le <strong>password</strong> non combaciano
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['PasswordConfirm'].hasError('required')">
              Inserire <strong>conferma password</strong>
            </mat-error>
          </mat-form-field>



        <div class="col-sm-8">
          <mat-password-strength #passwordComponent [min]="6" [max]="25" [enableSpecialCharRule]="false"
            [enableLowerCaseLetterRule]="true" [enableUpperCaseLetterRule]="true" [enableDigitRule]="true"
            [password]="password.value">
          </mat-password-strength>
          <mat-password-strength-info [passwordComponent]="passwordComponent"
            minCharsCriteriaMsg="Inserire almeno 6 caratteri"
            upperCaseCriteriaMsg="Inserire almeno un carattere appartenente alle lettere maiuscole (da A a Z)"
            digitsCriteriaMsg="Inserire almeno un carattere appartenente ai primi 10 numeri di base (da 0 a 9)"
            lowerCaseCriteriaMsg="Inserire almeno un carattere minuscolo">
          </mat-password-strength-info>
        </div>
        <div class="col-sm-4 button-cntr">
          <button class="new-button" type="button" matStepperNext>Avanti</button>
        </div>
      </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="formRegistrationStep2" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistrationStep2" (ngSubmit)="onRegistration()" autocomplete="off" >
        <div class="row">
          <div class="col-sm-12">
            <h3>Confermo di aver preso visione del <a
              [href]="this.environmentService.Url + '/api/policy/GetPrivacySpecialDocument'" target="_blank">modulo
              sulla
              Privacy</a> e di:<br></h3>
            <mat-checkbox formControlName="GeneralPrivacyCheck" tabindex="16"
              [ngClass]="formRegistrationStep2.controls['GeneralPrivacyCheck'].hasError('required') ? 'error' : 'no-error'">
              Acconsento al trattamento dei dati per finalità assicurative e per le tecniche di comunicazione a distanza (Obbligatorio)
            </mat-checkbox>
            <br>
            <mat-checkbox formControlName="CheckBox1" tabindex="17">Acconsentire al punto 1 (Facoltativo)
            </mat-checkbox><br>
            <mat-checkbox formControlName="CheckBox2" tabindex="18">Acconsentire al punto 2 (Facoltativo)
            </mat-checkbox><br>
            <mat-checkbox formControlName="CheckBox3" tabindex="19">Acconsentire al punto 3 (Facoltativo)
            </mat-checkbox><br>
          </div>


          <div class="col-sm-12 button-cntr">
            <button class="new-button" type="submit" tabindex="20">Registrati</button>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-horizontal-stepper>

</div>