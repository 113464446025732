import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { SiteType } from 'src/app/core/enum/siteType.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { SiteManagementService } from 'src/app/core/services/site-management.service';
import { isNullOrUndefined } from 'util';
import { ContractsConditionService } from '../../contracts-condition/contracts-condition.service';
import { MyErrorStateMatcher } from '../../login/login.component';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  formRegistrationComplete: UntypedFormGroup;
  matcher = new MyErrorStateMatcher();

  fno: boolean = false;

  today: Date = new Date();
  calendarStartDate: Date = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
  graduationFilter = (date: Date) => date <= this.calendarStartDate;

  VATLength: boolean = false;

  availableProfiles: Array<Object> = [];
  Bulletins: Array<{ label: string, id: number, available: boolean }> = [];
  Conventions: Array<{ label: string, id: number, available: boolean }> = [];
  JobProfiles: Array<{ label: string, id: number, conventionId: number }> = [];
  filteredJobProfilesAddictional = new Observable<any>();
  filteredJobProfiles = new Observable<any>();
  AddictionalConventions;
  AddictionalJobProfiles;
  Profiles = [
    { name: 'Profilo', id: 0 },
    { name: 'Profilo 1', id: 1 },
    { name: 'Profilo 2', id: 2 }
  ];
  availableBulletins: any;

  informativePrivacyCheck = {
    CheckBox1: {
      label: 'Acconsentire al punto 1 *',
      checked: false,
      show: true
    },
    CheckBox2: {
      label: 'Acconsentire al punto 2 *',
      checked: false,
      show: true
    },
    CheckBox3: {
      label: 'Acconsentire al punto 3 *',
      checked: false,
      show: true
    }
  };

  ConventionID;


  IsShoppingCenter : boolean = false;

  constructor(
    private appGeneralService: AppGeneralService,
    public storageService: LocalStorageService,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private contactsConditionService: ContractsConditionService,
    private apiService: ApiService,
    private router: Router,
    private securityService: SecurityService,
    private siteManagementService : SiteManagementService,
  ) {

    this.ConventionID = this.storageService.getRegistrationConvention();


    if (!this.ConventionID) {
      this.ConventionID = '';
    }

    this.formRegistrationComplete = this.formBuilder.group({
      Pec: new UntypedFormControl('', Validators.email),
      GraduationDate: new UntypedFormControl(this.calendarStartDate, Validators.required),
      Freelance: new UntypedFormControl(false),
      Employed: new UntypedFormControl(false),
      PIva: new UntypedFormControl('', [Validators.minLength(11), Validators.maxLength(11)]),
      BulletinID: new UntypedFormControl(''),
      ConventionID: new UntypedFormControl(this.ConventionID),
      JobProfileID: new UntypedFormControl('', Validators.required),

      PecAdditional: new UntypedFormControl(''),
      GraduationDateAdditional: new UntypedFormControl(this.calendarStartDate),
      FreelanceAdditional: new UntypedFormControl(false),
      EmployedAdditional: new UntypedFormControl(false),
      PIvaAdditional: new UntypedFormControl(''),
      BulletinIDAdditional: new UntypedFormControl(''),
      ConventionIDAdditional: new UntypedFormControl(''),
      JobProfileIDAddictional: new UntypedFormControl('')
    });

    this.securityService.fno_check.subscribe((value) => {
      this.fno = value;
      if (this.fno) {
        this.formRegistrationComplete.get('ConventionID').setValidators(Validators.required);
        this.formRegistrationComplete.get('ConventionID').updateValueAndValidity();
      }
    });

  }

  onJobProfileChange(event){
    this.filteredJobProfiles = this.formRegistrationComplete.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(event.target.value))
    );
  }

  onJobProfileChangeAddictional(event){
    this.filteredJobProfilesAddictional = this.formRegistrationComplete.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAddictional(event.target.value))
    );
  }

  filter(val: string): any {
    return this.JobProfiles.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  filterAddictional(val: string): any {
    return this.AddictionalJobProfiles.filter(option =>
      option.label.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  /**
   * * Reset the availability of all bulletins and conventions.
   * * Find all the profiles that are relative to the selected profile.
   * * Find all the conventions that are relative to the selected profile.
   * * Set the availability of all the conventions that are relative to the selected profile
   * @param profile - The selected profile
   */
  availableBulletins_Conventions(profile) {
    this.resetBulletinsAndConventionsAvailability();
    const relativeProfiles = this.availableProfiles.filter(elm => elm['JobProfileName'] === profile.target.value);
    relativeProfiles.forEach(rProfile => {
      this.Conventions.filter(convention => convention.id === rProfile['ConventionID']).forEach(c => c.available = true);
    });

    if(this.Conventions.some(convention => convention.available) == false) {
      this.formRegistrationComplete.get('ConventionID').setValue(null);
    }

  }


  /**
   * * Reset the bulletins and conventions availability.
   * * Get the relative profiles based on the selected job profile.
   * * Set the bulletins and conventions availability based on the relative profiles.
   * * Reset the convention if the job profile is changed
   * @param profile - The selected profile.
   */
  availableBulletins_ConventionsAddictional(profile) {
    this.resetBulletinsAndConventionsAvailability();
    const relativeProfiles = this.availableProfiles.filter(elm => elm['JobProfileName'] === profile.target.value);
    relativeProfiles.forEach(rProfile => {
      this.AddictionalConventions.filter(convention => convention.id === rProfile['ConventionID']).forEach(c => c.available = true);
    });
    if(this.AddictionalConventions.some(convention => convention.available) == false) {
      this.formRegistrationComplete.get('ConventionIDAdditional').setValue(null);
    }
  }

  /**
   * Reset the availability of all bulletins and conventions
   */
  resetBulletinsAndConventionsAvailability() {
    this.Bulletins.forEach(bulletin => bulletin.available = false);
    this.Conventions.forEach(convention => convention.available = false);
    this.AddictionalConventions.forEach(convention => convention.available = false);
  }

  get formRegistrationCompleteControls() { return this.formRegistrationComplete.controls }

  ngOnInit() {
    this.IsShoppingCenter = this.siteManagementService.SiteManagement.Type === SiteType.SHOPPINGCENTER;

    if(this.IsShoppingCenter){

      this.onRegistrationComplete();
      return;

    }
    this.formRegistrationComplete
    this.getProfileConfigurations();
    this.formRegistrationComplete
  }

  /**
   * Get the list of available profiles and bulletins
   */
  getProfileConfigurations() {
    this.appGeneralService.loadingPanel.Show();
    let param = new CallParameter("POST");
    param.CallData = {
    };

    this.apiService.callApiProd("/api/Profile/ListAvailable", param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          if (this.fno && data.ResultData['Profiles'].length > 0) {
            this.availableProfiles = data.ResultData['Profiles'].filter(profile => profile.FnoSpecialType == true);
          } else {
            this.availableProfiles = data.ResultData['Profiles']
          }

          this.availableBulletins = data.ResultData['Bulletins'];
          this.Bulletins = this.availableBulletins.map(
            bulletin => { if (bulletin['Name'] && bulletin['ID']) return { label: bulletin['Name'], id: bulletin['ID'] } }).filter(
              (elm, index, self) => index === self.findIndex(
                (e) => (e && elm && e['id'] === elm['id'] && e['label'] === elm['label'])));

          this.Conventions = this.availableProfiles.map(
            convention => { if (convention['ConventionName'] && convention['ConventionID']) return { label: convention['ConventionName'], id: convention['ConventionID'], available: false } }
          ).filter(
            (elm, index, self) => index === self.findIndex(
              (e) => (e && elm && e['id'] === elm['id'] && e['label'] === elm['label'])
            )
          );

          this.AddictionalConventions = this.Conventions;

          if (this.ConventionID) {
            this.Conventions = this.Conventions.filter(z => z.id == this.ConventionID);
          }


          this.JobProfiles = this.availableProfiles.map(profile => {
            if (profile['JobProfileName'] && profile['JobProfileID']) {
              return { label: profile['JobProfileName'], id: profile['JobProfileID'], conventionId: profile['ConventionID'] }
            }
          }).filter(
            (elm, index, self) => index === self.findIndex(
              (e) => (e && elm && e['id'] === elm['id'] && e['label'] === elm['label'])
            )
          );

          this.AddictionalJobProfiles = this.JobProfiles;

          this.AddictionalJobProfiles = this.AddictionalJobProfiles.filter(z => this.AddictionalConventions.find(y => y.id == z.conventionId).id == z.conventionId);

          this.GetUserFieldsStep3();

          this.appGeneralService.loadingPanel.Hide();
        }
      });
  }

  /**
   * Get the user's data from the database and set the form values
   */
  GetUserFieldsStep3() {
    this.appGeneralService.loadingPanel.Show();
    let param = new CallParameter("POST");
    param.CallData = {
    };

    this.apiService.callApiProd("/api/Registration/GetStep3", param).subscribe(
      data => {

        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          this.appGeneralService.loadingPanel.Hide();

          if (!isNullOrUndefined(data.ResultData['UserProfiles']) && data.ResultData['UserProfiles'].length > 0) {

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['JobProfileID'])) {
              this.formRegistrationComplete.get('JobProfileID').setValue(data.ResultData['UserProfiles'][0]['JobProfileID']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['GraduationDate'])) {
              this.formRegistrationComplete.get('GraduationDate').setValue(data.ResultData['UserProfiles'][0]['GraduationDate']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['PEC'])) {
              this.formRegistrationComplete.get('Pec').setValue(data.ResultData['UserProfiles'][0]['PEC']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['PIVA'])) {
              this.formRegistrationComplete.get('PIva').setValue(data.ResultData['UserProfiles'][0]['PIVA']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['Employed'])) {
              this.formRegistrationComplete.get('Employed').setValue(data.ResultData['UserProfiles'][0]['Employed']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['Freelance'])) {
              this.formRegistrationComplete.get('Freelance').setValue(data.ResultData['UserProfiles'][0]['Freelance']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['BulletinID'])) {
              this.formRegistrationComplete.get('BulletinID').setValue(data.ResultData['UserProfiles'][0]['BulletinID']);
            }

            if (!isNullOrUndefined(data.ResultData['UserProfiles'][0]['ConventionID'])) {
              this.formRegistrationComplete.get('ConventionID').setValue(data.ResultData['UserProfiles'][0]['ConventionID']);
            }

          }

          // this.informativePrivacyCheck.CheckBox1.show = !data.ResultData['CheckBox1'];
          this.informativePrivacyCheck.CheckBox1.checked = data.ResultData['CheckBox1'];
          // this.informativePrivacyCheck.CheckBox2.show = !data.ResultData['CheckBox2'];
          this.informativePrivacyCheck.CheckBox2.checked = data.ResultData['CheckBox2'];
          // this.informativePrivacyCheck.CheckBox3.show = !data.ResultData['CheckBox3'];
          this.informativePrivacyCheck.CheckBox3.checked = data.ResultData['CheckBox3'];

        }
      });
  }

  /**
   * Selects or deselects a checkbox based on the value of the checkbox
   * @param key - The name of the checkbox.
   * @param value - The value of the checkbox.
   */
  selectCheckBox(key, value) {
    this.informativePrivacyCheck[key].checked = value;
  }

  /**
   * Reset the form's BulletinID field to an empty string
   */
  onJobChangeResetBulletin() {
    this.formRegistrationComplete.get('BulletinID').setValue('');
    this.onBulletinResetConvention();
  }

  /**
   * *Reset the ConventionID field to an empty string.*
   */
  onBulletinResetConvention() {
    this.formRegistrationComplete.get('ConventionID').setValue('');
  }

  get SecondFormVisible() {
    return $('.additionalProfile').is(':visible');
  }

  /**
   * * If the checkbox is checked, then the additional profile fields are enabled.
   * * If the checkbox is unchecked, then the additional profile fields are disabled
   * @param evt - The event object.
   */
  ShowAdditionalProfile(evt) {
    if (evt.checked) {
      this.formRegistrationComplete.get("PecAdditional").setValidators(Validators.email);
      this.formRegistrationComplete.get("PecAdditional").updateValueAndValidity();

      this.formRegistrationComplete.get("GraduationDateAdditional").setValidators(Validators.required);
      this.formRegistrationComplete.get("GraduationDateAdditional").updateValueAndValidity();

      this.formRegistrationComplete.get("PIvaAdditional").setValidators([Validators.minLength(11), Validators.maxLength(11)]);
      this.formRegistrationComplete.get("PIvaAdditional").updateValueAndValidity();

      this.formRegistrationComplete.get("JobProfileIDAddictional").setValidators(Validators.required);
      this.formRegistrationComplete.get("JobProfileIDAddictional").updateValueAndValidity();

      if (!$('.additionalProfile').is(':visible')) {
        $('.additionalProfile').slideDown();
      }
    } else {
      this.formRegistrationComplete.get("PecAdditional").clearValidators();
      this.formRegistrationComplete.get("PecAdditional").updateValueAndValidity();
      this.formRegistrationComplete.get("GraduationDateAdditional").clearValidators();
      this.formRegistrationComplete.get("GraduationDateAdditional").updateValueAndValidity();
      this.formRegistrationComplete.get("PIvaAdditional").clearValidators();
      this.formRegistrationComplete.get("PIvaAdditional").updateValueAndValidity();
      this.formRegistrationComplete.get("JobProfileIDAddictional").clearValidators();
      this.formRegistrationComplete.get("JobProfileIDAddictional").updateValueAndValidity();

      if ($('.additionalProfile').is(':visible')) {
        $('.additionalProfile').slideUp();
      }
    }
  }

  /**
   * * If the checkbox is checked, hide the profile fields.
   * * If the checkbox is unchecked, show the profile fields
   * @param evt - The event object.
   */
  HideProfile(evt) {
    if (evt.checked) {
      this.formRegistrationComplete.get("JobProfileID").clearValidators()
      this.formRegistrationComplete.get("JobProfileID").updateValueAndValidity();

      this.formRegistrationComplete.get("BulletinID").clearValidators();
      this.formRegistrationComplete.get("BulletinID").updateValueAndValidity();

      this.formRegistrationComplete.get("ConventionID").clearValidators();
      this.formRegistrationComplete.get("ConventionID").updateValueAndValidity();

      this.formRegistrationComplete.get("PIva").clearValidators();
      this.formRegistrationComplete.get("PIva").updateValueAndValidity();

      this.formRegistrationComplete.get("Pec").clearValidators();
      this.formRegistrationComplete.get("Pec").updateValueAndValidity();

      if ($('.profile').is(':visible')) {
        $('.profile').slideUp();
      }
    } else {
      this.formRegistrationComplete.get("JobProfileID").setValidators(Validators.required);
      this.formRegistrationComplete.get("JobProfileID").updateValueAndValidity();

      this.formRegistrationComplete.get("ConventionID").setValidators([Validators.minLength(11), Validators.maxLength(11)]);
      this.formRegistrationComplete.get("ConventionID").updateValueAndValidity();

      this.formRegistrationComplete.get("PIva").setValidators([Validators.minLength(11), Validators.maxLength(11)]);
      this.formRegistrationComplete.get("PIva").updateValueAndValidity();

      this.formRegistrationComplete.get("Pec").setValidators(Validators.email);
      this.formRegistrationComplete.get("Pec").updateValueAndValidity();

      if (!$('.profile').is(':visible')) {
        $('.profile').slideDown();
      }
    }
  }



  /**
   * * If the form is invalid, return.
   * * Get the JobProfiles from the form.
   * * Get the checkbox values from the form.
   * * Call the API to save the data.
   * * If the call is successful, redirect to the previous page
   * @returns The data is being returned in the form of a JSON object.
   */
  onRegistrationComplete() {
    let JobProfiles = [];
    if (!$('.additionalProfile').is(':visible')) {

      this.formRegistrationComplete.get("JobProfileIDAddictional").clearValidators();
      this.formRegistrationComplete.get("JobProfileIDAddictional").updateValueAndValidity();

    }

    if (this.formRegistrationComplete.invalid && !this.IsShoppingCenter) {
      return;
    }

    if(!this.IsShoppingCenter)
    {
      if ($('.profile').is(':visible')) {
        JobProfiles = [{
          JobProfileID: this.JobProfiles.find(z => z.label == this.formRegistrationComplete.get('JobProfileID').value)?.id ?? 0,
          JobProfileName: this.JobProfiles.find(z => z.label == this.formRegistrationComplete.get('JobProfileID').value)?.label ?? this.formRegistrationComplete.get('JobProfileID').value,
          GraduationDate: this.appGeneralService.convertDate(this.formRegistrationComplete.get('GraduationDate').value),
          PEC: this.formRegistrationComplete.get('Pec').value,
          PIVA: this.formRegistrationComplete.get('PIva').value,
          Employed: this.formRegistrationComplete.get('Employed').value,
          Freelance: this.formRegistrationComplete.get('Freelance').value,
          BulletinID: this.formRegistrationComplete.get('BulletinID').value !== '' ? this.formRegistrationComplete.get('BulletinID').value : null,
          ConventionID: this.formRegistrationComplete.get('ConventionID').value !== '' ? this.formRegistrationComplete.get('ConventionID').value : null
        }];
      }

      if ($('.additionalProfile').is(':visible')) {
      JobProfiles.push({
        JobProfileID: this.AddictionalJobProfiles.find(z => z.label == this.formRegistrationComplete.get('JobProfileIDAddictional').value)?.id ?? 0,
        JobProfileName: this.AddictionalJobProfiles.find(z => z.label == this.formRegistrationComplete.get('JobProfileIDAddictional').value)?.label ?? this.formRegistrationComplete.get('JobProfileIDAddictional').value,
        GraduationDate: this.appGeneralService.convertDate(this.formRegistrationComplete.get('GraduationDateAdditional').value),
        PEC: this.formRegistrationComplete.get('PecAdditional').value,
        PIVA: this.formRegistrationComplete.get('PIvaAdditional').value,
        Employed: this.formRegistrationComplete.get('EmployedAdditional').value,
        Freelance: this.formRegistrationComplete.get('FreelanceAdditional').value,
        BulletinID: this.formRegistrationComplete.get('BulletinIDAdditional').value !== '' ? this.formRegistrationComplete.get('BulletinIDAdditional').value : null,
        ConventionID: this.formRegistrationComplete.get('ConventionIDAdditional').value
      });
    }


    this.appGeneralService.loadingPanel.Show();

  }
  let param = new CallParameter("POST");

  param.CallData = {
    UserProfiles: JobProfiles
  };

  this.apiService.callApiProd("/api/Registration/PutStep3", param).subscribe(
    data => {
      if (data.InError) {
        this.appGeneralService.showDialog(data.ErrorCode);
      } else {
        this.appGeneralService.loadingPanel.Hide();

        if (this.securityService.isUser()) {

          let path = this.storageService.getOrSetDefaultLastPageSeen('/');

          this.router.navigate([path]);

        }
        this.contactsConditionService.CheckConfirm(false, true, null, null);
      }
    });
};

}
