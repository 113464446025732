<ng-container *ngIf="FeedCarousel != null && FeedCarousel.Items.length > 0 else defaultFeed">

  <h1 style="padding-left: 20px;" title="{{FeedCarousel.Label}}" class="duplicate"></h1>

  <app-general-carousel [ItemList]="FeedCarousel.Items" [FeedId]="FeedCarousel.Id" [CardType]="cardType">
  </app-general-carousel>

  <div class="span-cntr">
    <span class="feed-link" (click)="navigateTo()">tutte le assicurazioni<img style="margin-left: 10px;"
        src="../../../assets/images/Home/Generic/freccia_blu.svg"></span>

  </div>
</ng-container>
<ng-template #defaultFeed>

  <div class="carousel-feed-cntr">

    <ng-container *ngFor="let Feed of FeedCarousels">

      <h1>Scopri anche</h1>

      <app-general-carousel [ItemList]="Feed.Items" [FeedId]="Feed.Id" [CardType]="cardType">
      </app-general-carousel>

      <div class="span-cntr">
        <span class="feed-link" (click)="navigateTo()">tutte le assicurazioni<img style="margin-left: 10px;"
            src="../../../assets/images/Home/Generic/freccia_blu.svg"></span>

      </div>

    </ng-container>
  </div>

</ng-template>
