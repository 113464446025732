<div class="payment-cntr">
<div class="img-cntr" style="width: 100%;"></div>
<div class="mailConfirmedAreaCntr">
    <div class="message text-center">
        <h1 *ngIf="!Success" style="color:#9cc666"class="mt-2">POLIZZA ACQUISTATA <BR> CON SUCCESSO</h1>
        <h1 *ngIf="Success" style="color:rgb(152, 48, 48)"class="mt-2">ERRORE NEL PAGAMENTO</h1>
        <h3>{{NexiMessage}}</h3>
        <button class="new-button" (click)="navigateTo()">I MIEI ACQUISTI</button>
        <app-feedback-area *ngIf="!Success" [userPolicyID]="userPolicyID" ></app-feedback-area>

    </div>
<img alt="sfondo" class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>


</div>
