import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import * as $ from 'jquery';
import { ApiService } from 'src/app/core/services/api.service';


@Component({
  selector: 'data-recover-dialog',
  template: `<h3 mat-dialog-title>
                  Questa polizza non è idonea alle tue esigenze per i seguenti motivi:
            </h3>
            <form mat-dialog-content [formGroup]="formError">
              <div class="Messages">
                <ul>
                <li *ngFor="let message of MessagesError" [innerHTML]="message"></li>
                </ul>
              </div>
              <!-- <mat-form-field class="full-width">
                <label>Se vuoi stipulare comunque questa polizza spiegaci di seguito il motivo:</label>
                  <textarea matInput formControlName="Motivo" rows="5"></textarea>
                  <mat-error *ngIf="formSurveyModule['Motivo'].hasError('required')">
                            Inserire <strong>motivazione</strong> </mat-error>
              </mat-form-field> -->
            </form>
            <div class="clearfix" style="padding: 10px">
            <button class="new-button" (click)="this.dialogRef.close(true);">HO CAPITO</button>
            </div>
              `,
  styles: [`
  `]
})
export class SurveyModalError {

  MessagesError = [];
  formError: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SurveyModalError>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public appGeneralService: AppGeneralService,
    private apiService: ApiService
  ) {
    this.MessagesError = data['Messages'];
    this.formError = this.formBuilder.group({
      Motivo: new UntypedFormControl('', Validators.required)
    });
  }

  get formSurveyModule() { return this.formError.controls }

  stipulateAnyways() {

    if (this.formError.invalid) {
      return;
    }

    const CallData = {
      CompiledModuleID: this.data['CompiledModuleID'],
      Text: this.formError.get('Motivo').value,
      UserPolicyID: this.data['UserPolicyID']
    };

    this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));

    this.apiService.callApiProd('/api/Module/IgnoreModuleResult', new CallParameter('POST', CallData)).subscribe((result) => {

      result => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode, result.Message);
        }
        this.dialogRef.close(true);
      }
    });
  }

  delete() {
    const CallData = {
      CompiledModuleID: this.data['CompiledModuleID']
    }

    this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));
    this.apiService.callApiProd('/api/Module/DeleteModule', new CallParameter('POST', CallData)).subscribe((result) => {

      result => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        }
        this.dialogRef.close();
      }
    });
  }

}

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})

export class ModuleComponent implements OnInit {
  moduleContent = "";
  UserPolicyID: number;

  constructor(
    public dialogRef: MatDialogRef<ModuleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
  ) { }


  ngOnInit() {
    this.dialog.afterOpened.subscribe(z => {
      z.updateSize("85vw", "85vh")
    })
    this.moduleContent = this.data['contenuto'];
    this.UserPolicyID = this.data['UserPolicyID'];
  }

  ngAfterViewInit() {

  }

  saveModule() {

    let moduleHasRequiredFields = false;
    $('div[data-val-required] input').each(function () {
      switch ($(this).attr('type')) {
        case 'checkbox':
        case 'radio':
          var type = $(this).attr('type');
          var name = $(this).attr('name');
          $(this).change(function () { if ($(this).closest('div[data-val-required]').find('.errorMessage').length > 0) { $(this).closest('div[data-val-required]').find('.errorMessage').empty() } });
          if ($('input[type="' + type + '"][name="' + name + '"]:checked').length < 1 && $(this).closest('div[data-val-required]').find('.errorMessage').length < 1) {
            moduleHasRequiredFields = true;
            $(this).closest('div[data-val-required]').append('<div class="col-12 errorMessage" style="color: red;">' + $(this).closest('div[data-val-required]').attr('data-val-required') + '</div>');
            return;
          }
          break;
        case 'text':
          if ($(this).val() == null || $(this).val() == "") {
            moduleHasRequiredFields = true;
            $(this).keyup(function () { if ($(this).closest('div[data-val-required]').find('.errorMessage').length > 0) { $(this).closest('div[data-val-required]').find('.errorMessage').empty() } });
            $(this).closest('div[data-val-required]').append('<div class="col-12 errorMessage" style="color: red;">' + $(this).closest('div[data-val-required]').attr('data-val-required') + '</div>');
          }
          break;
        default:
          return;
      }

    });

    if (moduleHasRequiredFields) {

      setTimeout(function () {

        var $GoTo = $('.errorMessage:visible:first');
        var $DialogCntr = $(".mat-dialog-container");

        $DialogCntr.scrollTop(0);
        var offset = $DialogCntr.offset().top;

        $DialogCntr.animate({
          scrollTop: $GoTo.position().top - offset - 100
        }, 500);

      }, 0);

      return;
    }

    let SurveyModuleParameters: any = {};

    $('input[type="text"]').each(function () {
      const inputTextName = $(this).attr('name');
      const inputTextValue = $(this).val();
      SurveyModuleParameters[inputTextName] = inputTextValue;
    });

    $('input[type="radio"]:checked').each(function () {
      const inputRadioName = $(this).attr('name');
      const inputVal: any = $('input[type="radio"][name="' + inputRadioName + '"]:checked').val()
      // const inputRadioValue: any = $(this).val();
      SurveyModuleParameters[inputRadioName] = parseInt(inputVal);
    });

    $('input[type="checkbox"]:checked').each(function () {
      const inputCheckboxName = $(this).attr('name');
      const inputCheckboxValue: any = $(this).val();

      if (isNullOrUndefined(SurveyModuleParameters[inputCheckboxName])) {
        SurveyModuleParameters[inputCheckboxName] = [parseInt(inputCheckboxValue)]
      } else {
        SurveyModuleParameters[inputCheckboxName].push(parseInt(inputCheckboxValue));
      }
    });

    let ModuleID: any = $('#ModuleID').val();

    const CallData = {
      ModuleID: parseInt(ModuleID),
      Answers: SurveyModuleParameters,
      UserPolicyID: this.UserPolicyID
    };

    this.appGeneralService.loadingPanel.ShowIn($('.mat-dialog-container'));
    this.apiService.callApiProd('/api/Module/PostModule', new CallParameter('POST', CallData)).subscribe(

      result => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode, result.Message);
        } else {
          if (result['ResultData'] != null && result['ResultData']['Success'] == false) {
            let dialogRefSurvey = this.dialog.open(SurveyModalError, {
              data: {
                Messages: result['ResultData']['ErrorList'],
                CompiledModuleID: result['ResultData']['CompiledModuleID'],
                UserPolicyID: this.UserPolicyID
              },
              disableClose: true,
              panelClass: 'custom-dialog-container',
              width:'300px'
            });

            dialogRefSurvey.afterClosed().subscribe(result => {
              this.dialogRef.close();
            });
          } else {
            this.dialogRef.close();
            window.location.reload();
          }
        }

    });
  }
}
