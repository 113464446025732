import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { environment } from 'src/environments/environment';

declare function conversion(transaction_id): any;
@Component({
  selector: 'app-payment-confirmed',
  templateUrl: './payment-confirmed.component.html',
  styleUrls: ['./payment-confirmed.component.scss']
})
export class PaymentConfirmedComponent implements OnInit {

  NexiMessage;
  Type;
  userPolicyID;
  Success;
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private route : Router
  ) {


    this.Type = this.activatedRoute.snapshot.paramMap.get("id");
    this.userPolicyID = this.activatedRoute.snapshot.paramMap.get("userPolicyId");

    this.activatedRoute.queryParams.subscribe(param => {
      let url = "";
      this.Success = param["esito"].toLowerCase() == "ko";

      switch (this.Type) {

        case "policy":
          url = "/api/Policy/NexiPolicyReturn"
          break;

        case "service":
          url = "/api/Service/NexiServiceReturn";
          break;

        case "paypal":
          this.NexiMessage = "Acquisto completato, grazie!";
          return;

          case "SatiSpay":
            this.NexiMessage = "Acquisto completato, grazie!";
            return;

          case "indirect":
            this.NexiMessage = "Acquisto completato, grazie!";
            return;

        default:
          this.NexiMessage = "Tipologia prodotto non riconosciuta";
          return;
      }

      let callParameter = new CallParameter("POST")

      callParameter.CallData = {
        Result: param["esito"],
        ResultCode: param["codiceEsito"],
        PaymentCode: param["num_contratto"],
        TransactionCode: param["codTrans"],
      };

      this.apiService.callApiProd(url, callParameter).subscribe(res => {

        if (res.InError) {

          this.appGeneralService.showDialog(res.ErrorCode);
        }

        this.NexiMessage = res.ResultData["NexiMessage"];

        if (this.NexiMessage == "") {

          this.NexiMessage = "Transazione completata con successo";
        }

      });

      if (environment.production) {
        conversion(param["codTrans"]);
      }

    });

  }

  ngOnInit() {
  }

  navigateTo(){

    this.route.navigate(['prodotti-acquistati']);

  }
}
