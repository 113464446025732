import { Component, OnInit } from '@angular/core';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { UserTypeVO } from 'src/app/core/classes/User/UserType';
import { SiteType } from 'src/app/core/enum/siteType.enum';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SiteManagementService } from 'src/app/core/services/site-management.service';

@Component({
  selector: 'app-personal-profile-accordion',
  templateUrl: './personal-profile-accordion.component.html',
  styleUrls: ['./personal-profile-accordion.component.scss']
})
export class PersonalProfileAccordionComponent implements OnInit {

  isCollapsed1: boolean = true;
  isCollapsed2: boolean = true;
  isCollapsed3: boolean = true;
  isCollapsed4: boolean = true;
  profileType : UserTypeVO;
  profileTypesEnum = UserTypeVO;

  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;

  isShoppingCenter : boolean = false;

  constructor(private siteManagementService : SiteManagementService,private LocalStorageService: LocalStorageService) {

    this.isShoppingCenter = this.siteManagementService.SiteManagement.Type === SiteType.SHOPPINGCENTER;

      this.profileType = this.LocalStorageService.getUserType().Code;

  }

  ngOnInit() {
  }

}
