<div class="container">
    <div class="row menuCntr">
        <div class="col-12 col-lg col-md col-sm" style="padding: 0;">

            <a class="col-lg-1 col-md-6 col-sm-6" [routerLink]="['/']" style="padding: 0;" title="Torna alla Home">
                <img id="logo" src='./assets/images/logo-myinsurer.png' max-width="100px" alt="MyInsurer Logo" />
            </a>

            <ul class="menu-list">
                <li  [routerLink]="['/per-te-casa-salute/Casa-&-Famiglia/'+0]" title="Per te, casa & salute">Per te, casa & salute</li>
                <li  [routerLink]="['/per-il-tuo-lavoro/Studio-&-attività-professionali/'+0]" title="Per il tuo lavoro">Per il tuo lavoro</li>
                <li [routerLink]="['/convenzioni']" title="Per i convenzionati">Per i convenzionati</li>
                <!-- <li  [routerLink]="['']" title="My Club">My Club</li> -->
                <li [routerLink]="['/assicurazioni']" title="Assicurazioni">Assicurazioni</li>
                <li [routerLink]="['/chi-siamo']">Chi siamo</li>
                <li [routerLink]="['/configuratore']" title="Configura la tua polizza">Configura la tua polizza</li>
                <li [routerLink]="['/login']" *ngIf="!logged">Accedi</li>
                <li class="hasSubMenu mobile-item hide-mobile" *ngIf="logged">Area personale<fa-icon style="font-size:12px; position:sticky!important;padding: revert-layer!important; margin-top: 5px!important;margin-left: 10px;" [icon]="faChevronDownDocuments" id="DocPanelIcon"></fa-icon>

                  <ul class="menu-list subMenu">
                    <span class="divider"></span>
                    <ng-container *ngIf="logged">
                        <li style="padding: 10px;" [routerLink]="'/prodotti-acquistati'">Le mie polizze</li>
                        <li style="padding: 10px;" [routerLink]="['/area-sinistro']">Area sinistro</li>
                        <li style="padding: 10px;" [routerLink]="['/area-disdetta']">Area disdetta</li>
                        <li style="padding: 10px;" (click)="toggle()" [routerLink]="['/profilo', storageService.ConstantString.userString]" title="Il tuo profilo">Account</li>
                        <li style="padding: 10px;" (click)="logout()">Logout</li>
                    </ng-container>
                </ul>

                </li>

            </ul>

            <div class="searchContainer" [hidden]="searchHide">
                <app-search (searchCompleted)="searchHide = $event"></app-search>
            </div>

            <ul class="menu-list-user col-sm-7 col-md-4 float-right">

                <li class="menu-list mobile-item hide-mobile" (click)="ToggleSearch()">
                    <img class="menuIcon" src="../../../assets/images/Home/Header/CERCA.svg" alt="ricerca">
                </li>

                <!-- Menu Hamburger -->

                <li (click)="toggle()" class="menu-hamburger zippy mobile-item">
                    <div>
                        <fa-icon [icon]="faBars" [hidden]="visible"></fa-icon>
                    </div>
                </li>
                <li class="menu-list mobile-item" [routerLink]="['/carrello']">
                  <div class="cart-items" *ngIf="cartItems != 0">{{cartItems}}</div>
                  <img class="menuIcon" src="../../../assets/images/Home/Header/CARRELLO.svg" alt="carrello">
              </li>

                <!-- <li class="hasSubMenu menu-list mobile-item hide-mobile" *ngIf="logged">

                  <div class="profilo-logged" style="text-transform: uppercase;">{{initialName}}</div>


                    <ul class="menu-list subMenu">
                        <span class="divider"></span>
                        <ng-container *ngIf="logged">
                            <li style="padding: 10px;" [routerLink]="'/prodotti-acquistati'">Le mie polizze</li>
                            <li style="padding: 10px;" [routerLink]="['/area-sinistro']">Area sinistro</li>
                            <li style="padding: 10px;" [routerLink]="['/area-disdetta']">Area disdetta</li>
                            <li style="padding: 10px;" (click)="toggle()" [routerLink]="['/profilo', storageService.ConstantString.userString]" title="Il tuo profilo">Account</li>
                            <li style="padding: 10px;" (click)="logout()">Logout</li>
                        </ng-container>
                    </ul>
                </li>  icona con lettera profilo-->
                <!-- <ng-container *ngIf="!logged">
                    <li class="hasSubMenu menu-list mobile-item hide-mobile" [routerLink]="['/login']">
                        <img class="menuIconUser" src="../../../assets/images/account/Icona_account.svg" alt="utente">
                    </li>
                </ng-container> -->

                <div class="dropdown" [hidden]="!visible">
                    <div (click)="toggle()">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </div>
                    <nav>
                        <ul>
                          <li><div (click)="toggle()" class="logo" [routerLink]="['/home']"><img src="../../../assets/images/Menu-mobile/icona logo.svg"></div></li>
                            <ng-container *ngIf="!logged">
                                <li class="p-10" style="font-weight: bold;">Benvenuto su MyInsurer</li>
                               <div class="app-search-cntr p-10"><app-search (searchCompleted)="toggle()"></app-search></div>
                                <li class="p-10" (click)="toggle()" [routerLink]="['/login']"><img src="../../../assets/images/Menu-mobile/AREA PERSONALE.svg">  Accedi</li>
                            </ng-container>
                            <ng-container *ngIf="logged">
                              <li class="p-10" style="font-weight: bold;">Ciao {{hisName}}</li>
                              <div class="app-search-cntr p-10"><app-search></app-search></div>
                              <li class="p-10" (click)="toggleAccordion()"><img src="../../../assets/images/Menu-mobile/AREA PERSONALE.svg">  Area personale <fa-icon style="font-size:15px; position:sticky!important;padding: revert-layer!important; margin-top: 5px!important;margin-left: 10px;" [icon]="faChevronDownDocuments" id="DocPanelIcon"></fa-icon>
                              </li>
                              <li (click)="toggle()" class="closeAccordion p-10" [ngClass]="{'openAccordion': isAccordionOpen}" [routerLink]="['/prodotti-acquistati']" style="margin-left: 80px;">  Le mie polizze</li>
                              <li (click)="toggle()" class="closeAccordion p-10" [ngClass]="{'openAccordion': isAccordionOpen}" [routerLink]="['/area-sinistro']" style="margin-left: 80px;">  Area sinistro</li>
                              <li (click)="toggle()" class="closeAccordion p-10" [ngClass]="{'openAccordion': isAccordionOpen}" [routerLink]="['/area-disdetta']" style="margin-left: 80px;">  Area disdetta</li>
                              <li (click)="toggle()" class="closeAccordion p-10" [ngClass]="{'openAccordion': isAccordionOpen}" [routerLink]="['/profilo']" style="margin-left: 80px;">  Account</li>
                          </ng-container>
                          <li class="p-10" (click)="toggle()" [routerLink]="['/carrello']" title="Carrello"><div class="cart-items" *ngIf="cartItems != 0">{{cartItems}}</div><img src="../../../assets/images/Menu-mobile/carrello_bianco.svg">  Carrello</li>
                            <li class="p-10" (click)="toggle()" [routerLink]="['/per-te-casa-salute/Casa-&-Famiglia/'+0]" title="Per te, casa & salute"> <img src="../../../assets/images/Menu-mobile/CASA_SALUTE.svg">  Per te, casa & salute</li>
                            <li class="p-10" (click)="toggle()" [routerLink]="['/per-il-tuo-lavoro/Studio-&-attività-professionali/'+0]" title="Per il tuo lavoro"> <img src="../../../assets/images/Menu-mobile/PER IL TUO LAVORO.svg">  Per il tuo lavoro</li>
                            <li class="p-10" (click)="toggle()" [routerLink]="['/convenzioni']" title="Per i convenzionati"><img src="../../../assets/images/Menu-mobile/PERICONVENZIONATI.svg">  Per i convenzionati</li>
                            <!-- <li  [routerLink]="['']" title="My Club"><img src="../../../assets/images/Menu-mobile/MYPASS.svg">  My Club</li> -->
                            <li class="p-10"(click)="toggle()" [routerLink]="['/assicurazioni']" title="Assicurazioni"><img src="../../../assets/images/Menu-mobile/ASSICURAZIONI.svg">  Assicurazioni</li>
                            <li class="p-10" (click)="toggle()" [routerLink]="['/chi-siamo']"><img src="../../../assets/images/Menu-mobile/CHI SIAMO.svg">  Chi siamo</li>
                            <li class="p-10" (click)="toggle()" [routerLink]="['/configuratore']" title="Configura la tua polizza"><img src="../../../assets/images/Menu-mobile/CREA_LATUA_POLIZZA.svg">  Configura la tua polizza</li>

                            <li  class="separator"><strong></strong>
                            </li>
                            <nav *ngIf="logged" style="width: 96vw; max-width: 104%;" [hidden]="!visibleProfile">
                              <li class="p-10" (click)="logout()"><img src="../../../assets/images/Menu-mobile/LOGOUT.svg">  Logout</li>
                          </nav>
                        </ul>
                    </nav>

                </div>

                <!-- <li class="menu-list mobile-item hide-desk" (click)="Search()">
                    <img class="menuIcon" src="../../../assets/images/Home/Header/cerca.svg" alt="ricerca">
                </li> -->
            </ul>

            <div class="img-cntr">
                <img class="italianaAssicurazioni" [src]="this.logoUrl" alt="Italiana Asssicurazioni Logo" />
            </div>

        </div>
    </div>
</div>
