export class BoxDTO{

  public Url : string;
  public File : string;
  public Text : string;
  public Title : string;
  public FileName : string;

  public constructor(url : string, file : string, text : string, title : string, fileName : string ){

    this.Url = url;
    this.File = file;
    this.FileName = fileName;
    this.Title = title;
    this.Text = text;


  }

}
