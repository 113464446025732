import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {

  constructor(private router : Router) { }

  ngOnInit() {
  }

  navigate(){

    window.open("https://www.buzziinsurancegroup.com/chi-siamo/", "_blank")

  }

}
