<div class="file-upload-dialog">
    <h3 mat-dialog-title class="dialogTitle">{{data['file'].Name}}</h3>
    <div mat-dialog-content>
        <div class="fileUploadCntr">
            <div *ngIf="data['file'].HasOwnDownloadableSource" class="my_text" style="margin-top: 25px;margin-left: 5px;margin-bottom: 15px;">
                <button *ngIf="data['file'].WasAlreadyUploaded == false"
                class="new-button" (click)="appGeneralService.GoToExternalURL(data['file'].DownloadUrl)" title="Clicca qui per scaricare il modello.">
                Clicca qui per scaricare {{data['file'].Name}}
                </button>

                <button *ngIf="data['file'].WasAlreadyUploaded"
                class="new-button" (click)="appGeneralService.GoToExternalURL(data['file'].DownloadUrl)" title="Clicca qui per scaricare il file da te precedentemente caricato.">
                File precedentemente caricato
                </button>
            </div>
            <p *ngIf="data['file'].ValidationError != null && data['file'].ValidationError.length > 0"><strong>Questo documento
                &egrave;
                stato visionato dai nostri operatori ed è risultato non valido per il seguente motivo:</strong><br />
                  <i>{{data['file'].ValidationError}}</i> </p>
              <p *ngIf="data['file'].Type != 7">{{data['file'].ShortDescription}}</p>
              <p *ngIf="data['file'].Type == 7 && !data['isAifi']">Effettua un bonifico a questo IBAN: {{this.data['Iban']}} <br /> con la causale
                  <i>“Pagamento polizza: {{data['Code']}}".</i></p>
              <p *ngIf="data['file'].Type == 7 && data['isAifi']">
                  Se non hai gi&agrave; saldato il pagamento effettua un bonifico a questo IBAN: IT83M0301503200000003539170
                  <br>Intestato a AIFI – ASSOCIAZIONE ITALIANA FISIOTERAPISTI con la causale “Polizza105/2013/076077652 – Nome e Cognome"
              </p>
              <p *ngIf="data['file'].Type != 7">{{data['file'].LongDescription}}</p>
              <p *ngIf="data['file'].Type == 7">Carica qui la ricevuta:</p>
            <div class="input-group">
                <div class="custom-file">
                    <input id="file" class="custom-file-input" type="file" name="fileuploadinput" ng2FileSelect [uploader]="uploader" accept="image/*,.pdf" (change)="fileChange($event)" lang="it" />
                    <label class="custom-file-label" for="file">{{inputPlaceHolder}}</label>
                </div>
            </div>
            <div class="my_text uploadFileError" *ngIf="!fileUploaded && showUploadMessage">
                Caricare un <strong>documento</strong>
            </div>
            <div class="my_text" *ngIf="data['file'].Mandatory" style="margin-top:5px;margin-left: 5px;">Questo documento è obbligatorio.
            </div>
        </div>
        <div class="fileUploadCntrAgg" *ngIf="!(data['file'].ValidationError != null && data['file'].ValidationError.length > 0)">
            <div class="my_text">
                Carica qui un eventuale documento aggiuntivo (es. Pagina 2):
            </div>
            <div class="input-group">
                <div class="custom-file">
                    <input id="file-2" class="custom-file-input" type="file" name="fileuploadinputadditional" ng2FileSelect [uploader]="uploader" accept="image/*,.pdf" (change)="fileChangeAdditional($event)" lang="it" />
                    <label class="custom-file-label" for="file">{{inputPlaceHolderAdditional}}</label>
                </div>
            </div>
        </div>
    </div>
    <div class="footer" mat-dialog-actions>
        <button class="new-button"(click)="close(false, false)">ANNULLA</button>
        <button class="new-button"(click)="uploadPolicyDocs()" [disabled]="uploading">
            <span *ngIf="!data['file'].WasAlreadyUploaded">CARICA</span>
            <span *ngIf="data['file'].WasAlreadyUploaded">AGGIORNA</span>
      </button>
    </div>
</div>
