<h1>DETTAGLIO SERVIZIO</h1>

<ng-container *ngIf="ServiceData">
    <div class="ServiceCntr">
        <div class="topCntr row">
            <div class="col-lg-4 col-md-12 col-sm-12">
                <div class="sx-cntr">
                    <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ ServiceData.ImageUrls[0] +')'}">
                        <div class="priceCntr">
                            <div class="cxPart"><div class="discount">{{ServiceData.FakePrice | price}}</div>{{ServiceData.Price | price}}</div>
                            <div class="dxPart"></div>
                        </div>
                    </div>
                </div>
                <p class="lg-extended-descr" [innerHTML]="ServiceData.ExtendedDescription"></p>
            </div>
            <div class="textCntr col-lg-8 col-md-12 col-sm-12">
                <h2 style="font-size: 30px; font-weight: bold;">{{ServiceData.Name}}</h2>
                <p [innerHTML]="ServiceData.Description"></p><br>
                <p class="sm-extended-descr" [innerHTML]="ServiceData.ExtendedDescription"></p>
                <div class="btn-container">
                    <button class="new-button" (click)="addToCart()">Aggiungi al carrello</button>
                </div>
                <div class="btn-container">
                    <button class="new-button" (click)="buyNow()">Acquista subito</button>
                </div>
            </div>

        </div>

    </div>
</ng-container>
