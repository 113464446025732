import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { SubUserProfile } from 'src/app/core/classes/SubUserClasses/sub-user';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

import { CompanyType } from 'src/app/core/classes/SubUserClasses/company-type';

@Component({
  selector: 'app-my-headquarter',
  templateUrl: './my-headquarter.component.html',
  styleUrls: ['./my-headquarter.component.scss']
})
export class MyHeadquarterComponent implements OnInit {

  CallData;
  CompanyType: CompanyType = new CompanyType();
  VisibilityButton : Array<boolean> = [];
  public formMyBusiness: UntypedFormArray = this.formBuilder.array([]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService
    ) {}

  ngOnInit() {}

  //get formMyBusinessControls() { return this.formMyBusiness.controls }

  setBusiness(business: SubUserProfile[]){
    this.VisibilityButton = [];
    if(business != null && business != undefined && business.length > 0){
      business.forEach(result => {
        this.formMyBusiness.push(result.getFormGroup());
        this.VisibilityButton.push(true);
      })
    }else{
      this.formMyBusiness.push(new SubUserProfile().getFormGroup());
    }
  }

  addNewBusiness(){
    this.formMyBusiness.push(new SubUserProfile().getFormGroup());
  }

  saveData(i) {

    const subUserProfileData = new SubUserProfile(this.formMyBusiness.controls[i].value );

    subUserProfileData.Type = 2;
    this.CallData = {
      subUserProfileData
    };
    // Cf: this.formMyBusiness.get('CF').value,
    // AddressNumber: this.formMyBusiness.get('StreetNumber').value,

    this.apiService.callApiProd('/api/SubUserProfile/Save', new CallParameter('POST', this.CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
        return;
      }

      this.appGeneralService.showDialog( null, "Salvataggio completato", "Esito");
      this.VisibilityButton.push(true);

    });
  }

  deleteData(i){
    let confirmDialog = this.appGeneralService.showDialog(null,"Sei sicuro di voler rimuovere la tua azienda?", "Attenzione", [{ name: 'OK', action: () => { this.deleteBusiness(i); confirmDialog.close(); } }, { name: 'Annulla', action: () => {  confirmDialog.close() } }]);
  }

  deleteBusiness(i){
    const subUserProfileData = this.formMyBusiness.controls[i].value as SubUserProfile;
    this.apiService.callApiProd("/api/SubUserProfile/Delete", new CallParameter("POST", {SubUserProfileID: subUserProfileData.Id})).subscribe(ret => {
      if(ret.InError){
        this.appGeneralService.showDialog(ret.ErrorCode);
      }
      this.formMyBusiness.controls.splice(i, 1);
    });
  }
}
