import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContractsConditionComponent } from './contracts-condition.component';

@Injectable({
    providedIn: 'root'
})
export class ContractsConditionService {

    showBanner: boolean = false;
    policyGroupId: number = null;
    policyID: number = null;
    NeedsToAcceptSpecialPrivacyPolicy: boolean = false;

    constructor(
        public dialog: MatDialog
    ) {
    }

  CheckConfirm(bypass: boolean = false, disableClose: boolean = false, UserPolicyId: any, policyGroupId: any, ServiceID? : any,UserServiceID? : any) {

    if((ServiceID && bypass)){

      return;

    }

    this.policyID = UserPolicyId;
    return this.dialog.open(ContractsConditionComponent, {
        maxHeight: '99vh',
        data: {
            PolicyGroupId: policyGroupId,
            UserPolicyID: UserPolicyId,
            BypassAccepted: bypass,
            disableClose: disableClose,
            ServiceID : ServiceID,
            UserServiceID : UserServiceID
        },
        disableClose: disableClose,
        panelClass: 'ContractsConditionDialog'
    });
  }
};
