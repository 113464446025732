export enum ConventionSpecialTypeEnum {
    NONE = 0,
    TSRM = 1,
    BUSINESS = 2
}

export interface UserType {
    ConventionSpecialTypes: ConventionSpecialTypeEnum[];
}

export interface IUserTypeValue {
    Code: UserTypeVO;
    Description: string;
    Configuration: UserType;
}

export enum UserTypeVO {
    Default,
    Business
}