import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
  selector: 'app-indirect-payment-redirect-dialog',
  templateUrl: './indirect-payment-redirect-dialog.component.html',
  styleUrls: ['./indirect-payment-redirect-dialog.component.scss']
})
export class IndirectPaymentRedirectDialogComponent {

  constructor(public dialogRef: MatDialogRef<IndirectPaymentRedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

/**
 * The function closes the dialog and redirects the user to the URL specified in the RedirectTo
 * property of the data object
 */
  close(): void {
    window.location.href = this.data['RedirectTo'];
    this.dialogRef.close();
  }


}
