import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddedToCartComponent } from 'src/app/shared/dialogs/added-to-cart/added-to-cart.component';
import { MatDialog } from '@angular/material/dialog';
import { SecurityService } from 'src/app/core/services/security.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { JsonLoaderService } from 'src/app/core/services/json-loader.service';
@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.scss']
})
export class ServiceDetailComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private activatedRoute: ActivatedRoute,
    private securityService: SecurityService,
    private seoService: SeoService,
    private jsonLoaderService: JsonLoaderService
    ) {

      this.activatedRoute.queryParams.subscribe((params) => {

        if (params["ServiceID"]) {
          this.ServiceID = +params["ServiceID"];
        }

      });

    // carico il json con i dati per la SEO e imposto Title e Description
    this.jsonLoaderService.getJsonData('../../assets/services-seo.json')
      .subscribe(r => {
        let ServicesList: any = r;

        let currentService = ServicesList.find(elm => elm.ID === this.ServiceID);

        this.seoService.update_title(currentService.title);
        this.seoService.update_description(currentService.description);

      });

     }

  ServiceFirst;
  ServiceIDUrl;
  ServiceID;
  ServiceData;

  ngOnInit() {
    this.getServiceDetail(this.ServiceID);
  }

  getServiceDetail(id){
    const serviceParams = new Service(id);
    this.apiService.callApiProd('/api/Service/ServiceDetail', new CallParameter('POST', serviceParams)).subscribe((result) => {
      this.ServiceData = result['ResultData']['ServiceDetail'];
      if(this.ServiceData.Images && this.ServiceData.Images.length > 0){
        this.seoService.update_image(this.ServiceData.Images[0]);
      }
    });
  }

  addToCart() {
    const serviceCartParams = new Service(this.ServiceID);
    this.apiService.callApiProd('/api/Service/AddServiceToCart', new CallParameter('POST', serviceCartParams)).subscribe((result) => {
      if(result.InError){
        this.appGeneralService.showDialog(result.ErrorCode);
      }else{
        const dialogRef = this.dialog.open(AddedToCartComponent,{ panelClass: 'custom-dialog-container', width:'500px'});
      }
    });
  }

  buyNow() {
    const serviceCartParams = new Service(this.ServiceID);
    this.apiService.callApiProd('/api/Service/AddServiceToCart', new CallParameter('POST', serviceCartParams)).subscribe((result) => {
      if(result.InError){
        this.appGeneralService.showDialog(result.ErrorCode);
      }else{
        let serviceID = result.ResultData["UserServiceID"];
        this.router.navigate(["/acquista-servizio",  serviceID]);
        if(!this.securityService.IsLogged){
          this.securityService.GetLoginErrorModal(this.ServiceData.LightRegistration, ["/acquista-servizio",  serviceID]);
          return;
        }
      }
    });
  }

}

export class Service {
  serviceID: number;
  constructor(
    serviceID: number
    ) {
    this.serviceID = serviceID;
  }
}
