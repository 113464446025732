import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-contact',
  templateUrl: './main-contact.component.html',
  styleUrls: ['./main-contact.component.scss']
})
export class MainContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  chiama(): void {
    window.location.href = "tel:+39010565582";
  }
  email(email : string): void {
    window.location.href = "mailto:" + email;
  }

}
