export const environment = {
  production: true,
  originDomain: 'https://test.myinsurer.it',
  api: {
    URL: 'https://test.api.myinsurer.it',
    URLF: './assets/fake-call/',
    SecretKey: 'lsufygoiysdfbgl',
    CallerName: 'MyInsurerTest',
    WorkingSessionID: '',
    TestCallFakeData: true,
    Configuratore: true,
    SPIDProd: false,
    SPIDDev: true
  },
  cliendIDSandBox :"AV0ZxgVGbmaBnfwTt_TsxZaq-w4q9DxgrzNm6ogIwb8ikwS8ie76umjxIJyYK1h8c9fLQiE6yvXwvZdg",
  GoogleID: '186927239206-u3v3be9m3dammfdv7vkqc2blq7i4k5tb.apps.googleusercontent.com',
  FacebookID: '1222963784853969',
  fakePrice: true,
  SatiSpay:"https://staging.online.satispay.com/web-button.js"


};
