

      <!-- <div class="insurance" (click)="dropdown(policy)" [attr.aria-expanded]="policy.IsCollapsed" aria-controls="collapseExample" [ngStyle]="{'background-color': appGeneralService.getStatusColor(policy.State).color, 'border-color': appGeneralService.getStatusColor(policy.State).color}">

        <div class="container">
          <div class="w-100">
        <div class="policy-reference col-3">
              Polizza di riferimento: <br><strong>{{policy.Code}}</strong>
          </div>
          <div class="state-policy col-3">
              Titolo:<br><strong>{{policy.Name}}</strong>
          </div>
          <div class="date-compiling col-3">
              Data scadenza:<br><strong>{{policy.ValidTo}}</strong>
          </div>
        </div>
        <div class="w-100">

          <div class="icon col-12">
              <fa-icon [icon]="faChevron"></fa-icon>
          </div>
        </div>
      </div>

      </div> -->

      <div class="canceled-form-cntr">

      <div id="collapseExample">
          <div class="card">

            <form [formGroup]="form" (submit)="showDialog()">

              <div class="mat-label-txt">Seleziona la polizza da disdire</div>
              <p>
                <mat-form-field class="col-lg-4 col-md-6 col-sm-12 mt-2">
                  <mat-select formControlName="Code" class="mat-form-input" (valueChange)="changeSelection($event)">
                    <mat-option *ngFor="let policy of UserPoliciesListResultSorted" [value]="policy.Code">
                      {{policy.Code}}: {{policy.GroupName}} {{policy.Name}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.get('Code')?.hasError('required')">{{"Campo obbligatorio"}}</mat-error>
                </mat-form-field>

              </p>



                <p *ngIf="policySelected">

                  Data di scadenza : {{policySelected?.ValidTo| date:'dd/MM/yyyy'}} <br>
                  Gruppo polizza : {{policySelected?.GroupName}} <br>
                  <span [innerHTML]="policySelected?.LegalInfo"></span>

                </p>




              <div class="docsCntr">
                  <h2>DISDICI</h2>

                  <div class="row canceled-info">

                    Le ricordiamo che la disdetta deve essere inviata almeno 30 giorni prima della scadenza del contratto, come da termini di polizza. Qualora l'invio non fosse inoltrato entro i termini prestabiliti la disdetta non sarà valida.
                   <div class="row canceled-info mt-2" style="flex-direction: column;">

                    <div class="form-text" > Il/La sottoscritto/a  <span *ngIf="policySelected">{{policySelected?.UserName}} {{policySelected?.UserSurname}}</span> desidera disdire il contratto per: </div>
                    <p class="col-lg-4 col-md-6 col-sm-12 mt-4" style="padding: 0;">
                      <mat-form-field style="width: 100%;" class="mat-form-input" >
                        <mat-label > Descrivi il motivo della disdetta (facoltativo)</mat-label>
                        <textarea matInput placeholder="Descrivi il motivo.." formControlName="Reason"></textarea>
                      </mat-form-field>
                    </p>
                  </div>
                </div>
                <span class="mt-4">Carica documenti aggiuntivi (facoltativo)</span>
                  <div class="row input-cntr">
                  <div class="col-lg-6 col-sm-12 custom-file">
                    <input id="file" class="custom-file-input" type="file" multiple name="fileuploadinputcanceled" accept="image/*,.pdf" (change)="fileChange($event)" lang="it">
                    <label class="custom-file-label"  for="file">{{inputPlaceHolder}}</label>
                  </div>
                  <button class="new-button delete-file-button"(click)="DeleteFile()">Cancella File</button>
                  </div>

                      <div class="row canceled-info" style="justify-content: right;">
                        <button  class="new-button">
                          DISDICI</button>
                      </div>

                      <div class="row canceled-info mt-2" style="font-weight: bold;">
                        Qualora dovesse riscontrare problemi nella compilazione, avesse bisogno di informazioni, la invitiamo a contattarci al numero 010 565582, in chat o a scriverci all'indirizzo info@buzziinsurancegroup.com.
                        </div>
                  </div>

            </form>

          </div>

      </div>




      </div>
