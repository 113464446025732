import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ConventionCarousel, SingleConvention } from 'src/app/core/classes/convention-carousel';
import { SiteType } from 'src/app/core/enum/siteType.enum';
import { SecurityService } from 'src/app/core/services/security.service';
import { SiteManagementService } from 'src/app/core/services/site-management.service';
import { Convention } from './convention-detail/convention-detail.component';

@Component({
  selector: 'app-convention-carousel',
  templateUrl: './convention-carousel.component.html',
  styleUrls: ['./convention-carousel.component.scss']
})
export class ConventionCarouselComponent implements OnInit {

  isShoppingCenter : boolean;

  constructor(
    private securityService: SecurityService,
    private siteManagementService : SiteManagementService
  ) { }

  @Input() ConventionCarousel: ConventionCarousel;

  @Input() ConventionList: ConventionCarousel;
  
  TitleCarousel : string = this.siteManagementService.SiteManagement.DescriptionCarousel;

  ngOnInit() {

    // this.isShoppingCenter = this.siteManagementService.SiteManagement.Type === SiteType.SHOPPINGCENTER;

  }

  // ngOnChanges() {
  //   if (this.securityService.IsLogged && !this.isShoppingCenter) {
  //     if (this.ConventionCarousel) {

  //       this.ConventionCarousel.conventionInfos.push(<SingleConvention>(
  //         {
  //           ID: -1,
  //           Description: "Aggiungi",
  //           Image: "assets/images/conventions-plus.png",
  //           Label: "Aggiungi",
  //           IsPlus: true,
  //         }))
  //     }
  //   }
  // }
}
