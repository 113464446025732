import { Component, Input, OnInit } from '@angular/core';
import { ICardAction } from 'src/app/shared/general-card/general-card.component';

@Component({
  selector: 'app-configurator-scheda-pacchetto',
  templateUrl: './configurator-scheda-pacchetto.component.html',
  styleUrls: ['./configurator-scheda-pacchetto.component.scss']
})
export class ConfiguratorSchedaPacchettoComponent implements OnInit {


  @Input() SingleFeed;
  @Input() cardAction : ICardAction;

  constructor() { }

  ngOnInit() {
  }

}
