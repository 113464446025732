import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-profile-policy-upgrade-dialog',
  templateUrl: './select-profile-policy-upgrade-dialog.component.html',
  styleUrls: ['./select-profile-policy-upgrade-dialog.component.scss']
})
export class SelectProfilePolicyUpgradeDialogComponent implements OnInit {

  formProfile: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<SelectProfilePolicyUpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder
    ) {
    this.formProfile = this.formBuilder.group({
      Profile: new UntypedFormControl('', Validators.required)
    });
   }

  ngOnInit() {
  }
  
  get formProfileControls() { return this.formProfile.controls }

  close() {
    this.dialogRef.close();
  }

  renew_policy() {
    if (this.formProfile.valid) {
      this.dialogRef.close(this.formProfile.get('Profile').value);
    }
  }

}
