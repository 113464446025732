import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';

import { BoughtTogether } from 'src/app/core/classes/BoughtTogether/bought-together';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  PackagesResult;
  PolicyResult;
  CartItems;
  ListCartItems : any = {};
  BoughtTogether: BoughtTogether[] = [];

  constructor(
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private securityService: SecurityService
  ) { }

  ngOnInit() {
    this.getListCartPolicies();
    this.getListCartServices();
    this.securityService.cart_Items.subscribe(items => this.CartItems=items);
  }

  getListCartPolicies() {
    this.apiService.callApiProd('/api/Policy/ListCartPolicies', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.BoughtTogether = result['ResultData']['BoughtTogether'];
        this.ListCartItems.Policies = result['ResultData']['CartPolicies'];
      }

    });
  }

  getListCartServices() {
    this.apiService.callApiProd('/api/Service/ListUserServices', new CallParameter('POST', { fromCart: true })).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.ListCartItems.Servicies = result['ResultData']['services'];
      }

    });
  }

  onPolicyChange() {
    this.getListCartPolicies();
  }

  onServiceChange() {
    this.getListCartServices();
  }
}
