

//CLASSE CAROUSEL DI PACCHETTI IN EVIDENZA

import { FeedInfo } from "./feed-info";

export class FeedCarousel {
    Label: string;
    Description: string;
    Id: string;
    Items: any;
    displayArrows: boolean;

    constructor(obj: FeedInfo) {
        this.Label = obj.Label;
        this.Description = obj.Description;
        this.Id = obj.Id.toString();
        this.Items = [];
        if (obj.CardInfos != null && obj.CardInfos != undefined && obj.CardInfos.length > 0) {
            obj.CardInfos.sort((first, second) =>  first.Order - second.Order ).forEach(element => {
                this.Items.push(element.Detail);
            });
        }

        this.displayArrows = true;
    }
}

//CLASSE PACCHETTI IN EVIDENZA
export class PolicyFeedInfo {

    ProposalId: number;
    PolicyInfoPreview: SingleFeed;
}


export class SingleFeed {
    ID: number;
    ImageUrls: object;
    TileTitle: string;
    Title: string;
    Price: number;
    FakePrice: number;
    ExtendedDescription: string;
}

export class FeedPanel {
    Id: number;
    PolicyId: number;
    PolicyGroupId: number;
    ProposalId: number;
    constructor(id: number, policyId: number, proposalId: number, PolicyGroupId: number) {
        this.Id = id;
        this.PolicyId = policyId;
        this.ProposalId = proposalId;
        this.PolicyGroupId = PolicyGroupId;
    }
}
