<div class="container insurance-complaint-cntr">

    <form autocomplete="off" [formGroup]="ReportGenericParamForm">
        <div class="col-12" style="padding-top:2vh;">
            <h3>Denuncia sinistro</h3>
        </div>
        <div class="row">
            <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                <div class="mat-label-txt">*Polizza di Riferimento</div>
                <mat-select matNativeControl required formControlName="PolicyReference" class="mat-form-input" (selectionChange)="setUserNameOnSelect($event)">
                    <mat-option *ngFor="let policy of Policies" [value]="policy.ID">
                        {{ policy.Title }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="ReportGenericParamFormControls['PolicyReference'].hasError('required')">
                    Selezionare <strong>polizza di riferimento</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
              <div class="mat-label-txt">*Data dell'evento</div>
                <input matInput type="text" class="mat-form-input" readonly [matDatepicker]="ReportPicker" [matDatepickerFilter]="reportDate" name="event-date" autocomplete="new-event-date" required formControlName="ReportDate">
                <mat-datepicker-toggle matSuffix [for]="ReportPicker"></mat-datepicker-toggle>
                <mat-datepicker #ReportPicker></mat-datepicker>
                <mat-error *ngIf="ReportGenericParamFormControls['ReportDate'].hasError('required')">
                    Inserire <strong>data dell'evento</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
              <div class="mat-label-txt">*Luogo dell'evento</div>
                <input  class="mat-form-input" type="text" matInput name="place" autocomplete="new-place" required formControlName="ReportPlace">
                <mat-error *ngIf="ReportGenericParamFormControls['ReportPlace'].hasError('required')">
                    Inserire <strong>luogo dell'evento</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="complaintForm" [formGroup]="DamagedPeople">
        <form autocomplete="off" [formGroup]="Damaged" *ngFor="let Damaged of DamagedControls.controls; let i = index">
            <h3>Daneggiati: {{i + 1}}</h3>
            <div class="clearfix">

                <div class="row">

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                          <div class="mat-label-txt">*Nome</div>
                            <input type="text" matInput class="mat-form-input" name="user-name" autocomplete="new-user-name" required formControlName="Name">
                            <mat-error *ngIf="Damaged['controls'].Name.errors">
                                Inserire <strong>nome</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                          <div class="mat-label-txt">*Cognome</div>
                            <input type="text" matInput class="mat-form-input" name="user-surname" autocomplete="new-user-surname" required formControlName="Surname">
                            <mat-error *ngIf="Damaged['controls'].Surname.errors">
                                Inserire <strong>cognome</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                          <div class="mat-label-txt">Codice Fiscale</div>
                            <input type="text" matInput class="mat-form-input" name="fiscal-code" formControlName="CF" autocomplete="new-fiscal-code">
                            <mat-error *ngIf="Damaged['controls'].CF.errors">
                                Inserire <strong>codice fiscale</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                          <div class="mat-label-txt">*Indirizzo di residenza</div>
                            <input type="text" class="mat-form-input" matInput required name="address" formControlName="Address" autocomplete="new-address">
                            <mat-error *ngIf="Damaged['controls'].Address.errors">
                                Inserire <strong>indirizzo di residenza</strong>
                            </mat-error>

                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                          <div class="mat-label-txt">*Città di residenza</div>
                            <input type="text" matInput class="mat-form-input" required name="city" formControlName="City" autocomplete="new-city">
                            <mat-error *ngIf="Damaged['controls'].City.errors">
                                Inserire <strong>città di residenza</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-lg-4 col-md-6 col-sm-12" [ngStyle]="{'width': DamagedControls.controls.length > 1 ? '75%' : '100%'}">
                          <div class="mat-label-txt">*CAP di residenza</div>
                            <input type="text" matInput class="mat-form-input" required name="cap" formControlName="CAP" autocomplete="new-cap">
                            <mat-error *ngIf="Damaged['controls'].CAP.errors">
                                Inserire <strong>CAP</strong>
                            </mat-error>
                        </mat-form-field>

                        <div class="float-right">
                            <button class="float-right new-button" (click)="DamagedControls.controls.splice(i, 1)" *ngIf="DamagedControls.controls.length > 1">Rimuovi danneggiato</button>
                        </div>
                </div>
            </div>
        </form>

        <div class="clearfix">
            <button class="float-right new-button" style="margin-top: 5px;" (click)="addDamagedPerson()">Aggiungi
        danneggiato</button>
        </div>
    </div>

    <div class="complaintForm" [formGroup]="Witnesses">
        <form autocomplete="off" [formGroup]="WitnessForm" *ngFor="let WitnessForm of WitnessControls.controls; let i = index">
            <h3 >Testimoni: {{i + 1}} </h3>
            <div class="clearfix">

                <div class="row">

                    <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                      <div class="mat-label-txt">Nome</div>
                        <input type="text" matInput class="mat-form-input" formControlName="Name" name="witness_name_{{i}}" autocomplete="new_witness_name_{{i}}">
                        <mat-error *ngIf="WitnessForm['controls'].Name.errors">
                            Inserire <strong>nome</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-lg-4 col-md-6 col-sm-12">
                      <div class="mat-label-txt">Cognome</div>
                        <input type="text" matInput class="mat-form-input" formControlName="Surname" name="witness_surname_{{i}}" autocomplete="new_witness_surname_{{i}}">
                        <mat-error *ngIf="WitnessForm['controls'].Surname.errors">
                            Inserire <strong>cognome</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="col-lg-4 col-md-6 col-sm-12" [ngStyle]="{'width': WitnessControls.controls.length > 1 ? '75%' : '100%'}">
                      <div class="mat-label-txt">Codice Fiscale</div>
                        <input type="text" matInput class="mat-form-input" formControlName="CF" name="witness_cf_{{i}}" autocomplete="new_witness_cf_{{i}}">
                        <mat-error *ngIf="WitnessForm['controls'].CF.errors">
                            Inserire <strong>codice fiscale</strong>
                        </mat-error>
                    </mat-form-field>

                    <div class="float-right">
                        <button class="float-right new-button" (click)="WitnessControls.controls.splice(i, 1)" *ngIf="WitnessControls.controls.length > 1">Rimuovi testimone</button>
                    </div>
                </div>
            </div>
        </form>

        <div class="clearfix">
            <button class="float-right new-button" style="margin-top: 5px;" (click)="addWitness()">Aggiungi
        testimone</button>
        </div>
    </div>


    <form class="complaintForm" [formGroup]="DescriptionForm">

        <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="col-12">
                  <div class="mat-label-txt">*Inserisci descrizione sinistro</div>
                    <textarea matInput class="mat-form-input" formControlName="Description" rows="5"></textarea>
                    <mat-error *ngIf="DescriptionFormControls['Description'].hasError('required')">
                        Inserire <strong>descrizione del sinistro</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12">
                <mat-form-field class="col-12">
                  <div class="mat-label-txt">*Luogo di compilazione</div>
                    <input type="text" matInput class="mat-form-input" required formControlName="CompilationPlace" name="write-place" autocomplete="new-write-place">
                    <mat-error *ngIf="DescriptionFormControls['CompilationPlace'].hasError('required')">
                        Inserire <strong>luogo di compilazione</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 custom-file">
              <input matInput class="hidden">
              <input id="file" class="custom-file-input" type="file" multiple name="fileuploadinput" accept="image/*,.pdf" (change)="fileChange($event)" lang="it" />
              <label class="custom-file-label" for="file">{{inputPlaceHolder}}</label>
          </div>

        </div>

    </form>

    <div class ="button-cntr">
        <button class="new-button " style="margin-bottom: 15px; margin-top: 5px;" (click)="submitIncident() ">
      CONFERMA</button>
    </div>
    <p class="mt-2" style="color: red;">*Campo obbligatorio</p>


</div>
