
<div class="prefooter-cntr">

    <!-- <div class="icon-cntr">

        <img class="icon-social" src="../../../../../assets/images/Home/Prefooter/FACEBOOK.svg">
        <img class="icon-social" src="../../../../../assets/images/Home/Prefooter/YOUTUBE.svg">
        <img class="icon-social" src="../../../../../assets/images/Home/Prefooter/LINKEDIN.svg">
        <img class="icon-social" src="../../../../../assets/images/Home/Prefooter/INSTAGRAM.svg">

    </div> -->

    <img class="single-logo" src="../../../../../assets/images/Home/Prefooter/insurance_group.svg" alt="">

    <div class="loghi-cntr">

        <img class="loghi" src="../../../../../assets/images/Home/Prefooter/insurtechservice.svg">
        <img class="loghi" style="max-width: 200px;" src="../../../../../assets/images/Home/Prefooter/myinsurer.svg">
        <img class="loghi" src="../../../../../assets/images/Home/Prefooter/assicurazioni.svg">

    </div>

</div>
