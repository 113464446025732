<h1 id="myinsurer">MyInsurer il marketplace per acquistare dove vuoi e quando vuoi Polizze e Servizi</h1>

<div class="col-12 col-sm col-md col-lg" style="padding: 0px;">

    <div class="SliderCntr">

        <ng-container *ngIf="BackgroundListDesktop.length > 0">
            <carousel class="HeaderCarousel" [overflowCellsLimit]="1" [cellWidth]="'100%'" [height]="calculateHeight()"
                [arrows]="BackgroundListDesktop.length > 1" [autoplay]="BackgroundListDesktop.length > 1"
                [autoplayInterval]="10000" [arrows]="BackgroundListDesktop.length > 1" [margin]="0"
                [loop]="BackgroundListDesktop.length > 1" [transitionTimingFunction]="'ease-in-out'"
                [transitionDuration]="2000" [counter]="false" [pauseOnHover]="true">
                <div class="carousel-cell" style="width: 100%;" *ngFor="let Bg of BackgroundListDesktop">
                    <app-image-slider [Background]="Bg"></app-image-slider>
                </div>
            </carousel>
        </ng-container>

    </div>

    <div class="SliderMobileCntr">

        <ng-container *ngIf="BackgroundListMobile.length > 0">
            <carousel class="HeaderCarousel" [cellWidth]="'100%'" [margin]="0" [overflowCellsLimit]="1" [height]="calculateHeight()"
                [arrows]="BackgroundListMobile.length > 1" [autoplay]="BackgroundListMobile.length > 1"
                [autoplayInterval]="10000" [loop]="BackgroundListMobile.length > 1"
                [transitionTimingFunction]="'ease-in-out'" [transitionDuration]="2000">
                <div class="carousel-cell" *ngFor="let Bg of BackgroundListMobile">
                    <app-image-slider [Background]="Bg"></app-image-slider>
                </div>
            </carousel>
        </ng-container>

    </div>

    <ng-container *ngIf="!fno">
        <app-home-slider-boxes></app-home-slider-boxes>
    </ng-container>

</div>

<div class="col-sm-12 col-md-12 pl-0 pr-0 margin-home">

    <div *ngFor="let SingleFeed of FeedCarousels">
        <app-feed-carousel [FeedCarousel]="SingleFeed"></app-feed-carousel>
    </div>

</div>
<div>
  <app-create-policy></app-create-policy>
</div>
<ng-container *ngIf="!fno">

    <!-- <div class="row">
        <div class="col-12 col-sm col-md col-lg">
            <ng-container *ngIf="ServiceListResult && ServiceListResult.Results.length > 0">
                <div style="float: left; margin-top: 5em; width: 100%;">
                    <app-service-carousel [ServiceCarousel]="ServiceListResult"></app-service-carousel>
                </div>
            </ng-container>
        </div>
    </div> -->

    <div class="row">
        <div class="col-12 col-sm col-md col-lg">
            <div style="float: left; width: 100%;">
                <app-readmore-carousel></app-readmore-carousel>
            </div>
        </div>
    </div>

</ng-container>

<div>
  <app-store-home></app-store-home>
</div>
<div>
    <app-five-quality></app-five-quality>
</div>
<div>
    <app-treedom [isPage]="false"></app-treedom>
</div>
<!-- <div>
<app-feedback-story></app-feedback-story>

</div> -->
<app-newsletter-mailchimp></app-newsletter-mailchimp>
<div>
  <app-contact-us> </app-contact-us>
</div>

<div>
    <app-prefooter></app-prefooter>
</div>
