import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolicyGroupInfo } from 'src/app/core/classes/policy-detail';
import { shoppingCenterAgencyDTO } from 'src/app/core/classes/ShoppingCenter/ShoppingCenterAgencyDTO';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { CardType } from 'src/app/core/enum/card-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { Convention } from '../../conventions/convention-carousel/convention-detail/convention-detail.component';
import { PolicyGroup } from '../../policies/policy-group/policy-group.component';
import { Policy } from '../../policies/policy-group/policy-price-boxes/single-package-detail-page/single-package-detail-page.component';

@Component({
  selector: 'app-shopping-center-agency-detail',
  templateUrl: './shopping-center-agency-detail.component.html',
  styleUrls: ['./shopping-center-agency-detail.component.scss']
})
export class ShoppingCenterAgencyDetailComponent implements OnInit {

  id : number;
  agency : Array<PolicyGroupInfo>;
  convention;
  cardType = CardType.Policy;

  constructor(private activatedRoute : ActivatedRoute,
    private apiService : ApiService,
    private appGeneralService : AppGeneralService) {

    this.activatedRoute.queryParams.subscribe((params) => {

      this.id = params["id"];
      this.getAgencyById(this.id);
      this.getConvention(this.id);

    });

   }

  ngOnInit() {

  }

  getAgencyById(id : number){

    const conventionParams = new Convention(id);
    this.apiService.callApiProd('/api/Policy/ListAvailableGroups', new CallParameter('POST', { ConventionId : id , Search : "" })).subscribe((result) => {


      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.agency = result['ResultData']['Results'];


      }

    });
  }

  getConvention(id: number) {
    const conventionParams = new Convention(id);
    this.apiService.callApiProd('/api/Profile/GetConvention', new CallParameter('POST', conventionParams)).subscribe((result) => {


      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.convention = result['ResultData']['Convention'];

      }

    });
  }

}
