import { Component, Input, OnInit } from '@angular/core';
import { FeedCarousel } from 'src/app/core/classes/Feed/feed-carousel';
import { CardType } from 'src/app/core/enum/card-type.enum';

@Component({
  selector: 'app-home-policies-feed',
  templateUrl: './home-policies-feed.component.html',
  styleUrls: ['./home-policies-feed.component.scss']
})
export class HomePoliciesFeedComponent implements OnInit {

  @Input() label;
  @Input() Feeds: FeedCarousel;
  cardType: CardType = CardType.Home;


  constructor() { }

  ngOnInit() {

    setTimeout(() => {
      this.Feeds

    }, 1000);

  }

}
