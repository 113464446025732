import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../services/localstorage.service';

export class CallParameter {

    method: string;
    CallData: object;
    CallerName: String;
    SecretKey: string;
    WorkingSessionID: string;
    TestCallFakeData: boolean;

    constructor(method, CallData?) {

        let storageService = new LocalStorageService();
        this.method = method;
        this.CallData = CallData;
        this.CallerName = environment.api.CallerName;
        this.SecretKey = environment.api.SecretKey;
        this.WorkingSessionID = storageService.getSessionID();
        this.TestCallFakeData = environment.api.TestCallFakeData;
    }
}

export class Utils {

    /**
     * It takes a JSON object and converts it to a FormData object
     * @param {any} objToSend - The object that you want to send to the server.
     * @returns A FormData object
     */
    public static jsonToFormData(objToSend: any): FormData {
        let formData = new Utils().setFormData(objToSend);
        return formData;
    }
    

    private setFormData(objToSend: any): FormData {

        var formData: FormData = new FormData();
        
        this.f(objToSend, undefined, formData);

        return formData;
    }


    /**
     * It takes an object, and recursively converts it into a formData object
     * @param {any} objToSend - The object you want to send to the server.
     * @param {string | undefined} keyCallback - This is the key of the object that is being sent.
     * @param {FormData} formData - FormData - The form data object that will be sent to the server.
     */
    private f(objToSend: any, keyCallback:string | undefined, formData: FormData) {
        if(typeof objToSend == 'object')
        {
            var filter = Object.keys(objToSend).filter((key, index) => 
            {
                var recursingCallbackKey = (keyCallback) ? keyCallback + '.' + key : key;
                this.f(objToSend[key], recursingCallbackKey, formData);
                if(index >= Object.keys(objToSend).length - 1)
                {
                    return true;
                }
            });
            if(filter.length > 0)
            {
                return;
            }
        }

        if(keyCallback){
            formData.append(keyCallback, (objToSend[keyCallback] == undefined ) ? objToSend : objToSend[keyCallback]);
            console.info(formData.get(keyCallback) + ' - ' + keyCallback);
        }

    }
}