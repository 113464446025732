import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { SiteType } from 'src/app/core/enum/siteType.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { SiteManagementService } from 'src/app/core/services/site-management.service';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';

@Component({
  selector: 'app-conventions',
  templateUrl: './conventions.component.html',
  styleUrls: ['./conventions.component.scss']
})
export class ConventionsComponent implements OnInit {

  constructor(
    public storageService: LocalStorageService,
    private router: Router,
    private securityService: SecurityService,
    private urlBuilderService: UrlBuilderService,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private seoService: SeoService,
    private environmentService: EnvironmentService,
    public siteManagementService : SiteManagementService
  ) {
    this.isShoppingCenter = siteManagementService.SiteManagement.Type === SiteType.SHOPPINGCENTER;
   }

  ConventionListResult: any;
  isShoppingCenter : boolean = false;

  ngOnInit() {
    this.getConventions();

    this.seoService.update_image(this.environmentService.originDomain + '/assets/images/convenzioni_def.jpg');
  }

  getConventions() {
      this.apiService.callApiProd('/api/Profile/ListAvailableConventions', new CallParameter('POST')).subscribe((result) => {

        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        } else {
          this.ConventionListResult = result['ResultData'];
        }

    });
  }

  goToDetail(convention: any) {

    if (this.securityService.IsLogged) {
      this.router.navigate(['/assicurazioni'])
    } else {
      this.urlBuilderService.navigateToConventionPath(convention.Id);
    }

  }

}
