<div class="header-background-scheda-cntr" [ngClass]="{'clicked': Scheda.Clicked}" (click)="navigateTo()">

  <h3 style="font-size: 15px!important; margin: 0;">SCOPRI</h3>

  <div class="img-cntr">

    <img src="{{Scheda.imgUrl}}">

  </div>


  <div class="txt-cntr">

    <h3>{{Scheda.title}}</h3>

  </div>

</div>
