<!-- <div class="CartProductCntr row">

    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="dataCntr">
            <h2 *ngIf="!IsPolicy">{{CartItem.Name}}</h2>
            <h2 *ngIf="IsPolicy" [innerHtml]='CartItem.TileTitle + " - " + CartItem.Title' class="d-flex"></h2>
        </div>
    </div>

    <div class="cartImageCntr">
        <div *ngIf="CartItem.Images" class="imageCntr" [ngStyle]="{'background-image': 'url('+ CartItem.Images[0] +')'}"></div>
        <div *ngIf="CartItem.ImageUrls" class="imageCntr" [ngStyle]="{'background-image': 'url('+ CartItem.ImageUrls[0] +')'}"></div>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-4 mb-2">

        <ng-container *ngIf="IsPolicy">
            <div>PACCHETTO: {{CartItem.Title}}</div><br>
            <div>CODICE POLIZZA: {{CartItem.Code}}</div><br>
            <ng-container [formGroup]="form">

                <ng-container *ngIf="CartItem.SubUserAllowed && CartItem.SubUsers.length > 0">

                    <mat-form-field>

                        <mat-label>Assicurato:</mat-label>
                        <mat-select formControlName="insured" (selectionChange)="save($event)">

                            <mat-option [value]="-1">{{UserName}}</mat-option>

                            <ng-container *ngFor="let subUser of CartItem.SubUsers">

                                <mat-option [value]="subUser.Id">
                                    {{subUser.Name}} {{subUser.Surname}} {{subUser.BusinessName}}</mat-option>

                            </ng-container>

                        </mat-select>
                    </mat-form-field>

                </ng-container>

            </ng-container>
        </ng-container>

        <app-long-text-container *ngIf="!IsPolicy" [toHideText]="CartItem.ExtendedDescription" [hideHeight]="145"></app-long-text-container>
        <app-long-text-container *ngIf="IsPolicy" [toHideText]="CartItem.Description" [hideHeight]="145"></app-long-text-container>
    </div>

    <div class="col-sm-12 col-md-4 col-lg-4 ">

        <div class="PriceCntr text-right">

            Totale  <span class="discount"  *ngIf="CartItem.FakePrice">{{CartItem.FakePrice | price}}</span><span> {{CartItem.Price | price}}</span>
        </div>
        <div class="row align-content-center align-items-center">
            <div class="col-6">
                <a *ngIf="!IsPolicy" class="remove" (click)="deleteServiceFromCart(CartItem.UserServiceID)">RIMUOVI</a>
                <a *ngIf="IsPolicy" class="remove" (click)="deletePolicyFromCart(CartItem.ID)">RIMUOVI</a>
            </div>
            <div class="col-6 p-2">
                <button *ngIf="!IsPolicy" class="new-button w-100 mt-0" (click)="buyNow()">ACQUISTA</button>
                <button *ngIf="IsPolicy" class="new-button w-100 mt-0" (click)="goToBuyPolicyPage(CartItem.ID)">ACQUISTA</button>
            </div>
        </div>
    </div>

</div> -->

<div class="cart-scheda-cntr">

  <img *ngIf="!IsPolicy" class="remove" (click)="deleteServiceFromCart(CartItem.UserServiceID)" src="../../../../assets/images/Cart/delete.svg" >
  <img *ngIf="IsPolicy" class="remove" (click)="deletePolicyFromCart(CartItem.ID)" src="../../../../assets/images/Cart/delete.svg" >


    <div class="img-cntr">

        <div class="quadrato-cntr" [ngClass]="CartItem.Title">

            <h3>{{CartItem.Title}}</h3>
            <div class="divisore"></div>
            <div class="striscia-price"><h3 *ngIf="CartItem.FakePrice" style="text-decoration:line-through; font-weight: unset!important;"> {{CartItem.FakePrice | price}}</h3> <h3> {{CartItem.Price | price}}</h3>  </div>

        </div>

        <div *ngIf="CartItem.Images" class="img-background" [ngStyle]="{'background-image': 'url('+ CartItem.Images[0] +')'}"></div>
        <div *ngIf="CartItem.ImageUrls" class="img-background" [ngStyle]="{'background-image': 'url('+ CartItem.ImageUrls[0] +')'}"></div>

    </div>

    <div class="txt-cntr">

        <h2 *ngIf="!IsPolicy">{{CartItem.Name}}</h2>
        <h2 *ngIf="IsPolicy" [innerHtml]='CartItem.TileTitle'></h2>
        <h3><span style="font-weight: initial!important;">PACCHETTO: </span>{{CartItem.Title}}</h3>
        <h3><span style="font-weight: initial!important;">CODICE POLIZZA: </span>{{CartItem.Code}}</h3>

    </div>

    <div class="separatore"></div>

    <div class="price-cntr">

        <div class="txt-cntr"><h3>TOTALE:  </h3> <h3 *ngIf="CartItem.FakePrice" style="text-decoration:line-through; font-weight: initial!important;"> {{CartItem.FakePrice | price}}  </h3> <h2> {{CartItem.Price | price}}</h2></div>
        <div *ngIf="!IsPolicy" (click)="buyNow()" class="new-button">ACQUISTA</div>
        <div *ngIf="IsPolicy" (click)="goToBuyPolicyPage(CartItem.ID)" class="new-button">ACQUISTA</div>

    </div>




</div>
