<div class="create-policy-cntr">

  <div class="img-cntr">
    <img class="absolute" src="../../../../assets/images/Configuratore/crealatuapolizza.webp">
    <img class="mobile" src="../../../../assets/images/Configuratore/crealatuapolizza.webp">

  </div>

  <div class="txt-cntr">

    <h1>Configura la tua polizza!</h1><br>
    <h3>Scegli la copertura assicurativa che desideri tra quelle selezionate e personalizzala in base alle tue esigenze!
      Configura la tua tranquillità!</h3>
    <button class="new-button" [routerLink]="'/configuratore'" >CONFIGURA</button>

  </div>

</div>
