import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CtWebviewService } from '@ctsolution/ct-webview';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { DialogHandlerComponent } from '../../shared/error-handler/error-handler.component';
import { UserProfilePolicyState } from '../enum/UserProfilePolicyState.enum';
import { LocalStorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AppGeneralService {

  constructor(
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    private storageService: LocalStorageService,
    private ctWebviewService: CtWebviewService,
    private cookieService: CookieService
  ) { }

  readonly SessionExpireDate = "WorkSessionExpireDate";

  readonly MemoryRoutes: Array<string> = [
    'login',
    'completa-registrazione',
    'email-inviata',
    'conferma-mail',
    'spid-redirect',
    'reset-password',
    'registrazione',
    'registrazione-confermata'
  ]

  readonly GenderType = [
    { name: 'Maschio', abbrev: 'M', value: 0 },
    { name: 'Femmina', abbrev: 'F', value: 1 },
    { name: 'Non Specificato', abbrev: 'N', value: 2 },
    { name: 'Altro', abbrev: 'A', value: 3 }
  ];

  private color = {
    white: "#FFF",
    red: "#FF240A",
    black: "#000",
    green: "#15F705",
    orange: "#F75905",
    grey: "#A6A19F"
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
  }

  routerBack() {
    this.location.back();
  }

  getDescColorUserProfilePolicyState(code): any {
    let objState = {
      description: '',
      background: '',
      color: ''
    }
    switch (code) {
      case UserProfilePolicyState.ACTIVE:
        objState.description = "Attiva";
        objState.background = this.color.orange;
        objState.color = this.color.black;
        break;
      case UserProfilePolicyState.EXPIRING:
        objState.description = "In scadenza";
        objState.background = this.color.orange;
        objState.color = this.color.black;
        break;
      case UserProfilePolicyState.WAITING_FOR_APPROVAL:
        objState.description = "In attesa di approvazione";
        objState.background = this.color.grey;
        objState.color = this.color.black;
        break;
      case UserProfilePolicyState.WAITING_FOR_FILE_UPLOAD:
        objState.description = "In attessa di documenti";
        objState.background = this.color.grey;
        objState.color = this.color.black;
        break;
      default:
        objState.description = "no state";
        objState.background = this.color.white;
        objState.color = this.color.black;
    }
    return objState;
  }

  GoToExternalURL(url) {
    window.open(url, '_blank');
  };

  getStatusColor(state) {
    switch (state) {
      case 1:
      case 2:
        return {
          color: '#f7931e',
          text: 'IN ATTESA',
          class : 'ATTESA'

        };
      case 3:
        return {
          color: '#00b738',
          text: 'ATTIVA',
          class : 'ATTIVA'
        };
      case 4:
        return {
          color: '#eae41f',
          text: 'IN SCADENZA',
          class : 'SCADENZA'
        };
      case 5:
        return {
          color: '#4d4d4d',
          text: 'SCADUTA',
          class : 'SCADUTA'
        };
        case 9:
          return {
            color: '#717171',
            text: 'DISDETTA',
            class : 'DISDETTA'
          };
      case 10:
        return {
          color: '#d80000',
          text: 'ATTENZIONE',
          class : 'ATTENZIONE'
        };
      default:
        return {
          color: '#000',
          text: 'N/A'
        };
    }
  }
  getServiceStatusColor(state) {
    switch (state) {
      case 5:
        return {
          color: '#00b738',
          text: 'ATTIVO'
        };
      case 10:
        return {
          color: '#4d4d4d',
          text: 'SCADUTO'
        };
      case 15:
        return {
          color: '#f7931e',
          text: 'RICHIESTA RECESSO'
        };
      case 20:
        return {
          color: '#4d4d4d',
          text: 'RECEDUTO'
        };
      case 30:
        return {
          color: '#f7931e',
          text: 'FILE RIFIUTATO'
        };
      default:
        return {
          color: '#000',
          text: 'N/A'
        }
    }
  }
  getComplaintStatusColor(state) {
    switch (state) {

      case 0:
        return {
          color: '#f7931e',
          text: 'SINISTRO SEGNALATO',
          class: 'ATTESA'
        };
      case 1:
        return {
          color: '#00b738',
          text: 'APERTO',
          class: 'ATTIVA'
        };
      case 2:
        return {
          color: '#4d4d4d',
          text: 'CHIUSO',
          class: 'SCADUTA'
        };
        case 9:
          return {
            color: '#717171',
            text: 'IN ATTESA',
            class : 'ATTESA'
          };
          case 10:
          return {
            color: '#717171',
            text: 'DISDETTA',
            class : 'ATTIVA'
          };
      default:
        return {
          color: '#000',
          text: 'N/A'
        }
    }
  }

  loadingPanel = {
    Setup() {
      $('body').append('<div class="loadingPanel"><div class="loadingSpinner"><div style="width:100%;height:100%" class="lds-interwind"><div></div><div></div></div></div>');
    },
    Show() {
      $('.loadingPanel').fadeIn();
    },
    Hide() {

      $('.loadingPanel').fadeOut();
      $('.innerLoadingPanel').remove();
    },
    ShowIn: function (jqContainer) {
      var innerPanel = $('<div class="innerLoadingPanel"><div class="loadingSpinner"><div style="width:100%;height:100%" class="lds-interwind"><div></div><div></div></div></div>');

      innerPanel.css('height', jqContainer.outerHeight() + 'px');
      innerPanel.css('width', jqContainer.outerWidth() + 'px');
      jqContainer.css('position', 'relative');

      $(window).resize(function () {
        innerPanel.css('height', jqContainer.outerHeight() + 'px');
        innerPanel.css('width', jqContainer.outerWidth() + 'px');
      });

      innerPanel.click(function (evt) {
        evt.stopPropagation();
      });

      innerPanel.prependTo(jqContainer);
    },
    HideInners: function () {
      $('.innerLoadingPanel').fadeOut(function () {
        $(this).remove();
      });
    },
    HideIn: function (jqContainer) {
      jqContainer.find('div.innerLoadingPannel').remove();
    }
  };

  /**
  * Mostra una dialog con il contenuto del messaggio che si desidera.
  *
  * @remarks Questo metodo fa parte dell'App general service
  *
  * @param messageCode - Codice per il messaggio precompilato da BackEnd
  * @param message - Messaggio che si intende mostrare
  * @param title - Titolo della dialog
  * @returns Dialog che si intende mostrare
  */
  showDialog(messageCode = null, message = null, title = null, buttons = null, disableClose = true): MatDialogRef<any> {
    if (messageCode == '511') {
      this.router.navigate(['manutenzione']);
      return null;
    } else {
      if(!this.dialog.openDialogs || this.dialog.openDialogs.length == 0 || messageCode != null) {
        return this.dialog.open(DialogHandlerComponent, {
          width: '500px',
          data: { messageCode: messageCode, message: message, title: title, buttons: buttons },
          disableClose: disableClose,
          panelClass: 'custom-dialog-container'
        });
      }
    }
  };

  refreshTokens(AuthenticationType, SessionExpireDate, SessionID) {

    if (SessionID != null) {
      this.storageService.setSessionID(SessionID);
    }

    this.storageService.setSessionExpireDate(SessionExpireDate);
    this.storageService.setAuthenticationLevel(AuthenticationType);

  };

  // è la vecchia "AddPolicyToCart" e "RemovePolicyToCart"
  UpdateAddingNumberOfItemsInCart() {

    let CartValue = parseInt(this.storageService.getItemsInCart());

    if (isNaN(CartValue)) {
      CartValue = 0;
    }

    CartValue += 1;

    this.storageService.setItemsInCart(CartValue.toString());
    // sessionStorage.setItem(this.ItemsInCart, CartValue.toString());
  }

  UpdatRemovingNumberOfItemsInCart() {

    if (parseInt(this.storageService.getItemsInCart()) > 0) {

      let newValue = parseInt(this.storageService.getItemsInCart()) - 1;
      this.storageService.setItemsInCart(newValue.toString());

    }

    // if (parseInt(sessionStorage.getItem(this.ItemsInCart)) > 0) {
    //   var newValue = parseInt(sessionStorage.getItem(this.ItemsInCart)) - 1;
    //   sessionStorage.setItem(this.ItemsInCart, newValue.toString());
    // }

  }

  detectBrowser() {
    const { detect } = require('detect-browser');
    const browser = detect();
    return browser.name;
  }

  showCookies: boolean = (this.cookieService.get("CookieConsent") == "false"
  || this.cookieService.get("CookieConsent") == null
  || this.cookieService.get("CookieConsent") == undefined
  || this.cookieService.get('CookieConsent') == '') && !this.ctWebviewService.isCtWebView();

}
