import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookie-message',
  templateUrl: './cookie-message.component.html',
  styleUrls: ['./cookie-message.component.css']
})
export class CookieMessageComponent implements OnInit {

  constructor(
    private cookieService: CookieService,
    private router: Router
  ) { }

  ngOnInit() {

  }

  ShowMoreCookieDetails() {
    if ($('.longMessage').is(':visible')) {
      $('.longMessage').slideUp(function () {
        $('.readMoreCookie span').html('LEGGI DI PI&Ugrave;');
      });
    } else {
      $('.longMessage').slideDown(function () {
        $('.readMoreCookie span').html('NASCONDI');
      });
    }
  }
  
  get CookieExpiry(){
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    return date;
  }
  acceptCookie() {
    $('.CookieMessageCntr').removeClass('slide-in-bottom').addClass('slide-out-right');
    setTimeout(() => {
      //set today + 1 year
      this.cookieService.set('CookieConsent', 'true', this.CookieExpiry, '/', null, null, 'Lax');
    }, 500);
  }
  
  acceptStatisticCookie() {
    $('.CookieMessageCntr').removeClass('slide-in-bottom').addClass('slide-out-right');
    setTimeout(() => {
      this.cookieService.set('CookieConsent', 'true', this.CookieExpiry, '/', null, null, 'Lax');
      this.cookieService.set('StatisticConsent', 'true', this.CookieExpiry, '/', null, null, 'Lax');
    }, 500);
  }

  declineCookie() {
    $('.CookieMessageCntr').removeClass('slide-in-bottom').addClass('slide-out-left');
    setTimeout(() => {
      this.cookieService.deleteAll();
    }, 500);
  }

  handleCookies() {
    this.router.navigate(['/informativa-cookies']);
  }

}
