import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-not-available-policy-dialog',
  templateUrl: './not-available-policy-dialog.component.html',
  styleUrls: ['./not-available-policy-dialog.component.scss']
})
export class NotAvailablePolicyDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<NotAvailablePolicyDialogComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }

}
