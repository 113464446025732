
<ng-container *ngIf="convention ; else defaultTmpl">
  <h1>{{convention.Label}}</h1>
  <div class="row">

      <div class="col-lg-5 col-md-5 col-sm-12 imgCntr" [ngStyle]="{'background-image': 'url('+ convention.Image +')'}">
      </div>

      <div class="col-lg-7 col-md-7 col-sm-12">{{convention.Description}}</div>

  </div>

  <div class="full-width" style="margin-top: 50px;">
      <app-general-carousel [ItemList]="agency" [CardType]="cardType" ></app-general-carousel>
  </div>
</ng-container>

<ng-template #defaultTmpl>


  Nessuna agenzia disponibile


</ng-template>
