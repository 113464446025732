
<div class="canceled-cntr">

  <div class="header-cntr">
    <div class="img-cntr">

      <img src="../../../../assets/images/Disdetta/area_disdetta.webp">

    </div>
    <div class="txt-cntr">
      <h1 class="duplicate" title="Area disdetta"></h1>
    </div>

</div>
<div class="tabs-cntr">
  <ngb-tabset [destroyOnHide]="false" class="InsuranceTabs MYI-tabs">

    <ngb-tab title="POLIZZE DA DISDIRE">
      <ng-template ngbTabContent>

        <app-canceled-policyForm></app-canceled-policyForm>

      </ng-template>
    </ngb-tab>
    <ngb-tab title="POLIZZE DISDETTE">
      <ng-template ngbTabContent>

        <app-canceled-policyCanceled></app-canceled-policyCanceled>

      </ng-template>
    </ngb-tab>

  </ngb-tabset>

</div>
</div>
