<div class="mission-cntr">

  <div class="mission-first-cntr">

    <h1 style="color: white;">Mission</h1>

    <div class="mission-detail">

      <div class="mission-detail-first">
        <img src="../.././../../../../assets/images/Chi-siamo/spunta.svg">
        <h3>La nostra missione è offrire soluzioni assicurative che promuovano la tranquillità e il benessere dei nostri clienti, mettendo sempre al centro le esigenze delle persone. </h3>
      </div>
      <br>
      <div class="mission-detail-first">
        <img src="../.././../../../../assets/images/Chi-siamo/spunta.svg">
        <h3>Vogliamo fornire servizi di alta qualità, orientati al benessere del cliente e al superamento delle sfide economiche legate alla vita quotidiana.</h3>
      </div>


    </div>

  </div>

  <div class="mission-second-cntr">

    <div class="mission-detail">

      <div class="mission-detail-first">
        <img src="../.././../../../../assets/images/Chi-siamo/spunta.svg">
        <h3>Il nostro obiettivo è costruire un mondo in cui le persone hanno la libertà effettuare scelte consapevoli nel settore assicurativo, basate sulla competenza e sulla comprensione delle proprie esigenze.</h3>
      </div>
      <br>
      <div class="mission-detail-first">
        <img src="../.././../../../../assets/images/Chi-siamo/spunta.svg">
        <h3>Cerchiamo di semplificare il processo assicurativo, offrendo prodotti accessibili e trasparenti. Miriamo a garantire che tutti siano adeguatamente assicurati secondo le proprie possibilità, contribuendo a una maggiore sicurezza finanziaria per tutti.</h3>
      </div>


    </div>
    <h1 style="color: white;">Vision</h1>

    </div>

    <img class="home-background-img dont-show-mobile" style="transform: rotate(180deg);" src="../../../../assets/images/Home/sfondo_puntini.svg">

    <div class="button-cntr">

      <button class="new-button" (click)="navigate()">VISITA IL SITO</button>

    </div>

  </div>

