import { Component, HostListener, OnInit } from '@angular/core';

import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from './../../../app/core/services/app-general.service';

import { Observable } from 'rxjs';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { CallResult } from 'src/app/core/classes/system/call-result';
import { GuideDialogComponent } from './guide-dialog/guide-dialog.component';
import { SecurityService } from 'src/app/core/services/security.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { FeedInfo } from 'src/app/core/classes/Feed/feed-info';
import { FeedCarousel } from 'src/app/core/classes/Feed/feed-carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  BackgroundListDesktop = [];
  BackgroundList= [];
  BackgroundListMobile = [];
  FeedTypes = [];
  Feeds: FeedInfo[] = [];
  FeedCarousels: FeedCarousel[] = [];
  ServiceListResult;
  dm_background_list: Array<{ path: string }> = [];
  fno: boolean = false;

  constructor(
    public dialog: MatDialog,
    private appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private securityService: SecurityService,
    private seoService: SeoService
  ) { }

  openDialog() {
    const dialogRef = this.dialog.open(GuideDialogComponent, { maxHeight: "90vh", width: "95vw", maxWidth: "95vw", panelClass: 'custom-guide-dialog' });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {

    this.securityService.fno_check.subscribe(fno => {
      this.fno = fno;
    })

    this.getSliderBgs().subscribe((result) => {
      this.BackgroundList = result["ResultData"]["homeCarouselImages"];
      this.BackgroundListDesktop = this.BackgroundList.filter(bg => bg.MacroType == 1 || bg.MacroType == 3);    // 1 = Desktop
      this.BackgroundListMobile = this.BackgroundList.filter(bg => bg.MacroType == 2 || bg.MacroType == 3); // 2 = Mobile 3 = responsive

      result["ResultData"]["homeCarouselImages"].forEach(element => { this.dm_background_list.push({ path: element.ImageUrl }) });

    });

    this.getFeedHome();

    this.getServicesList();

    this.seoService.update_image('');

  }

  getFeedHome() {
    this.FeedTypes = [0];
    const FeedTypesSent = new FeedTypeArray(this.FeedTypes);


    this.apiService.callApiProd('/api/Feed/GetPolicyFeed', new CallParameter('POST', FeedTypesSent)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.Feeds = result['ResultData']['Feed'] as FeedInfo[];
        this.Feeds.sort((a, b) => { return a.Id - b.Id });
        this.Feeds.forEach(feed => {
          this.FeedCarousels.push(new FeedCarousel(feed));
        });
      }

    });
  }

  getServicesList() {

    this.apiService.callApiProd('/api/Service/ListAvailableServices', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.ServiceListResult = result['ResultData'];
      }

    });
  }

  getSliderBgs(): Observable<CallResult> {
    return this.apiService.callApiProd('/api/SiteManagement/GetImages', new CallParameter('POST'), false);
  }

    getSliderBgsMobile(): Observable<CallResult> {
      return this.apiService.callApiProd('/api/SiteManagement/GetImages', new CallParameter('POST', {IsMobile: true}), false);
  }

  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 500;
    }
      else if(windowWidth > 800)
      {
      return 500;
      }
      else if(windowWidth > 600)
      {
      return 400;
      }
      else if(windowWidth > 500)
      {
      return 300;
      }
      else if(windowWidth > 350)
      {
      return 250;
      }
      else if(windowWidth > 0)
      {
      return 200;
      }
  }

}

export class FeedTypeArray {

  types: FeedType[];

  constructor(
    types: FeedType[]
  ) {
    this.types = types;
  }

}

export class FeedType {
  type: number;
  constructor(
    type: number
  ) {
    this.type = type
  }
}
