<div class="policy-cntr-img-cntr dont-show-mobile"
  [ngStyle]="{'background-image': 'url('+ PolicyFullDetail.PolicyGroupInfo.ImageUrls[0] +')'}">

</div>

<div class="policy-cntr-img-cntr dont-show-desktop" *ngIf="!PolicyFullDetail.PolicyGroupInfo.VideoLink"
  [ngStyle]="{'background-image': 'url('+ PolicyFullDetail.PolicyGroupInfo.ImageUrls[0] +')'}">

</div>

<div class="video-cntr-mobile" *ngIf="PolicyFullDetail.PolicyGroupInfo.VideoLink" >
  <div class="video">

    <iframe [width]="calculateWidth()" [height]="calculateHeight()" [src]="SecurityUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <h3>Tutte le curiosità con Jacopo Buzzi</h3>

  </div>
</div>
<div class="policy-group-cntr">


  <div class="title-cntr">

    <h1>{{PolicyFullDetail.PolicyGroupInfo.Title}}</h1>

  </div>

  <div class="button-cntr">

    <ng-container *ngIf="PolicyFullDetail.ProposalInfos.length > 1">

      <div>
        <h3>Scegli l'offerta che ti serve</h3>
      </div>

      <div class="carousel-cntr">
        <app-policy-group-proposal-carousel [Title]="PolicyFullDetail.PolicyGroupInfo.Title"
          [ProposalInfo]="PolicyFullDetail.ProposalInfos"></app-policy-group-proposal-carousel>
      </div>

    </ng-container>

    <div id="destination"></div>

    <ng-container *ngIf="SingleProposal != undefined && SingleProposal != null">
      <div class="proposal-cntr">
        <div class="txt-cntr">
          <h2>SCOPRI I PACCHETTI</h2>
          <h3>{{PolicyFullDetail.PolicyGroupInfo.Title}} {{SingleProposal.Label}}</h3>
        </div>
        <div class="proposal-carousel-cntr">
          <div *ngIf="SingleProposal.Description" class="txt-cntr" [innerHTML]="SingleProposal.Description"> </div>
          <div *ngIf="!SingleProposal.Description" class="txt-cntr"> Scegli quale pacchetto fa per te!</div>

          <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

          <div class="carousel-cntr">
            <app-policy-price-boxes [GroupId]="policyGroupID" [ProposalId]="proposalIdCode"></app-policy-price-boxes>
          </div>
        </div>
      </div>
    </ng-container>

  </div>


  <div class="description-cntr">


    <div class="txt-cntr">

      <h2>Cosa comprende la polizza?</h2><br>
      <h3 style="font-weight: unset!important; font-style: 15px!important;" [innerHTML]="PolicyFullDetail.PolicyGroupInfo.ExtendedDescription"></h3>

    </div>

    <div class="video-cntr" *ngIf="PolicyFullDetail.PolicyGroupInfo.VideoLink">

      <iframe [width]="calculateWidth()" [height]="calculateHeight()" [src]="SecurityUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <h3>Tutte le curiosità con Jacopo Buzzi</h3>

    </div>
    <div class="img-cntr" *ngIf="!PolicyFullDetail.PolicyGroupInfo.VideoLink">

      <img src="../../../../assets/images/Home/Generic/youtube.webp">

    </div>



  </div>



</div>

<app-create-policy></app-create-policy>
<app-feed-carousel></app-feed-carousel>
<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>


<!-- <ng-container *ngIf="PolicyFullDetail">
    <h1>{{PolicyFullDetail.PolicyGroupInfo.Title}}</h1>

    <div class="PolicyDetailCntr">

        <div class="PolicyDetailCntr">

            <div class="topCntr row ml-0 mr-0">
                <div class="imageCntr col-sm-12 col-md-4" [ngStyle]="{'background-image': 'url('+ PolicyFullDetail.PolicyGroupInfo.ImageUrls[0] +')'}">
                </div>
                <div class="offset-1"></div>
                <div class="textCntr col-sm-12 col-md-6">
                    <app-long-text-container [toHideText]="PolicyFullDetail.PolicyGroupInfo.ExtendedDescription" [hideHeight]="200"></app-long-text-container>
                </div>
                <div class="offset-1"></div>
            </div>

            <div class="botCntr">

                <ng-container *ngIf="PolicyFullDetail.ProposalInfos.length > 1">

                    <div class="col-12" style="padding:0; font-weight: bold;">
                        <h2>Scegli Offerte:</h2>
                    </div>
                    <div class="col-12">
                        <div class="row proposalBoxesCntr">
                           // <button id="ProposalBox_{{i}}" [class]="i==0 ? 'new-button selected' : 'new-button'" *ngFor="let ProposalInfo of PolicyFullDetail.ProposalInfos; let i = index" (click)="ShowProposalBoxes(i)">{{ProposalInfo.Label}}</button>
                            <button id="{{ProposalInfo.PolicyGroupID}}_{{ProposalInfo.ProposalId}}" [class]="i==0 ? 'new-button selected' : 'new-button'"
                            *ngFor="let ProposalInfo of PolicyFullDetail.ProposalInfos; let i = index" (click)="ShowProposalBoxes(ProposalInfo.PolicyGroupID + '_' + ProposalInfo.ProposalId)">
                                {{ProposalInfo.Label}}
                            </button>
                        </div>
                    </div>

                </ng-container>

                <ng-container *ngIf="SingleProposal != undefined && SingleProposal != null">

                    <div class="proposalTitle">
                        <h2>{{SingleProposal.Label}}</h2>
                    </div>

                    <div class="proposalDescription" [innerHTML]="SingleProposal.Description">
                    </div>

                     </ng-container>
                    <div class="boxesCntr">
                        <app-policy-price-boxes [GroupId]="policyGroupID" [ProposalId]="proposalIdCode"></app-policy-price-boxes>
                    </div>

                </ng-container>

            </div>

        </div>

    </div>
</ng-container> -->
