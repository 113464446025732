<!-- <div class="row">
    <div class="table-container">
        <table class="compare-table" *ngIf="comparePolicies && comparePolicies.Policies && comparePolicies.Sections">
            <tr class="first-row">
                <td>
                </td>
                <td class="column" *ngFor="let policy of comparePolicies.Policies">
                    <b>{{policy.Name}}</b>
                </td>
            </tr>
            <tr class="price-row">
                <td>
                    <b>Prezzo</b>
                </td>
                <td *ngFor="let policy of comparePolicies.Policies">
                    <div class="discount" *ngIf="policy.FakePrice">{{policy.FakePrice | price}}</div> <b> {{policy.Price | price}}</b>
                </td>
            </tr>

            <ng-container *ngFor="let item of comparePolicies.Sections">
                <tr class="section-row">
                    <td>
                        <b>{{item.Name}}</b>
                    </td>
                    <td *ngFor="let policyCheck of comparePolicies.Policies">

                    </td>
                </tr>
                <ng-container *ngFor="let option of item.OptionDTOs">
                        <tr class="sub-section-row">
                                <td>
                                    <div>
                                        <b>{{option.Title}}</b>
                                    </div>
                                    <div>
                                        <fa-icon [icon]="faInfoCircle" (click)="openInfoDialog(option.Description, option.Title)"></fa-icon>
                                    </div>
                                </td>
                                <td *ngFor="let policyCheck of comparePolicies.Policies">
                                    {{tdValue(option, policyCheck.Id) | price}}
                                </td>
                        </tr>
                </ng-container>
            </ng-container>

            <tr class="last-row">
                <td></td>
                <td *ngFor="let policyCheck of comparePolicies.Policies">
                    <button (click)="openPolicyDetail(policyCheck.Id)">scopri</button>
                </td>
            </tr>
        </table>
    </div>
</div> -->

<div class="policy-price-boxes-carousel-cntr">

  <ng-container *ngIf="comparePolicies.Policies != null && comparePolicies.Policies.length > 0">

    <div class="carousel row">

      <carousel [cellWidth]="300" [height]="calculateHeight()" [margin]="30" [cellsToShow]="cellsToShow()">

        <ng-container *ngFor="let policy of comparePolicies.Policies">
          <div class="carousel-cell">
            <app-policy-price-boxes-scheda [GroupId]="GroupId" [ProposalID]="ProposalId" [Policy]="policy" [Section]="comparePolicies.Sections" [ShowButton]="true"></app-policy-price-boxes-scheda>
          </div>
        </ng-container>
      </carousel>

    </div>


  </ng-container>

</div>
