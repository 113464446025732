import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faDownload, faFileDownload, faFileUpload, faInfoCircle, faUpload } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { RecessServiceDialogComponent } from './recess-service-dialog/recess-service-dialog.component';
@Component({
  selector: 'app-my-services',
  templateUrl: './my-services.component.html',
  styleUrls: ['./my-services.component.scss']
})
export class MyServicesComponent implements OnInit {

  UserServicesListResult;
  faFileDownload = faFileDownload;
  faFileUpload = faFileUpload;
  faDownload = faDownload;
  faUpload = faUpload;
  faInfo = faInfoCircle;
  
  @Output() childEvent = new EventEmitter();
  
  constructor(
    private apiService: ApiService,
    public dialog: MatDialog,
    public appGeneralService: AppGeneralService
  ) { }

  ngOnInit() {

    this.apiService.callApiProd('/api/Service/ListUserServices', new CallParameter('POST', {"FromCart":false })).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.UserServicesListResult = result['ResultData']['services'];
        this.UserServicesListResult.forEach(element => {
          element.isCollapsed = false;
        });
      }

    });
  }
  myNetLink(ID) {
    this.apiService.callApiProd('/api/Service/MyNetLink',new CallParameter('POST', { 'UserServiceID': ID })).subscribe(
      (data) => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          const winHtml = data.ResultData["HtmlResult"];
          const winUrl = URL.createObjectURL(
            new Blob([winHtml], { type: "text/html" })
          );
          const win = window.open(winUrl);
        }
      });
  }

  recessService(UserServiceID) {
    let deletedialog = this.dialog.open(RecessServiceDialogComponent, {
      width: '500px',
      data: UserServiceID,
      panelClass: 'custom-dialog-container'
    });

    deletedialog.afterClosed().subscribe(result => {
      if (result) {
        this.childEvent.emit();
      }
      window.location.reload();
    });
  }

  openInput(input){
    $('#'+input).click();
  }

  handleDocumentsUpload(Document, event){

    if (!Document.UserUploaded) {
      var CallData = {
        File: event.target.files[0],
        UserServiceFileId: Document.Id,
        FileName: event.target.files[0].name
      }

      
      const reader = new FileReader();
      reader.readAsDataURL(CallData.File);
      reader.onload = () => {
        CallData.File = reader.result.toString();
        var callParameter: CallParameter = new CallParameter("POST", CallData);
        var callApiProdParameter : CallApiProdParameter = CallApiProdParameter.create("/api/Service/UpdateUserServiceFile", callParameter, "POST");
  
        this.apiService.callApiProduction(callApiProdParameter).subscribe(
          data => {
            if (data.InError) {
              this.appGeneralService.showDialog(data.ErrorCode);
              return;
            }

            window.location.reload();
          }
        );
      }
    }
  }
}
