<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
    #mc_embed_signup {
        background: #fff;
        clear: left;
        font: 14px Helvetica, Arial, sans-serif;
        width: 600px;
    }

    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div class="newsletter-cntr">


        <form
            action="https://myinsurer.us5.list-manage.com/subscribe/post?u=a110d40ae69bff2bb7c5088ff&amp;id=1d64db2f17"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_blank" novalidate>
                <h2>Iscriviti alla newsletter</h2>
                <h3>Rimani aggiornato sulle nostre Iniziative e su tutte le promozioni</h3><br>
                <h3>Scrivi qui la tua email</h3>
                    <input placeholder="es. mario.rossi@myinsurer.it" type="email" value="" name="EMAIL" class="required email newsletter-form" id="mce-EMAIL">

                <div id="mce-responses" class="clear">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                </div>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text"
                        name="b_a110d40ae69bff2bb7c5088ff_1d64db2f17" tabindex="-1" value=""></div>
                <button class="new-button" name="subscribe" >ISCRIVITI</button>
        </form>
        <img class="home-background-img dont-show-mobile" style="transform: rotate(180deg);" src="../../../../assets/images/Home/sfondo_puntini.svg">
        <div class="img-cntr">
            <img class="absolute" src="../../../../assets/images/Home/Generic/newletter.webp">
            <img class="mobile" src="../../../../assets/images/Home/Generic/newletter.webp">

          </div>

</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
<script type='text/javascript'>
    (function ($) {
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0] = 'EMAIL';
        ftypes[0] = 'email';
        fnames[1] = 'FNAME';
        ftypes[1] = 'text';
        fnames[2] = 'LNAME';
        ftypes[2] = 'text';
        fnames[3] = 'ADDRESS';
        ftypes[3] = 'address';
        fnames[4] = 'PHONE';
        ftypes[4] = 'phone';
        fnames[5] = 'BIRTHDAY';
        ftypes[5] = 'birthday';
    }(jQuery));
    var $mcj = jQuery.noConflict(true);
</script>
<!--End mc_embed_signup-->
