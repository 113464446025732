<div class="my-policy-cntr">
    <ng-container *ngIf="UserPoliciesListResult">
      <ng-container *ngFor="let Policy of UserPoliciesListResultSorted; let i = index">

        <div class="policy-cntr">

          <div class="stato-polizza" [ngClass]="appGeneralService.getStatusColor(Policy.State).class">
            <h3>STATO: {{appGeneralService.getStatusColor(Policy.State).text}}</h3>
          </div>
          <div class="title">
            <h2>{{Policy.Name}}</h2>
          </div>
          <div class="info-cntr">

            <div class="img-cntr">

              <img [src]="Policy.ImageUrls[0]">

            </div>

            <div class="txt-cntr">

              <h3>Codice numero: <span class="initial">{{Policy.Code}}</span></h3>
              <h3>Data inizio validità: <span class="initial">{{Policy.ValidFrom}}</span></h3>
              <h3>Data fine validità: <span class="initial">{{Policy.ValidTo}}</span></h3>
              <h3>Totale: <span class="initial">{{Policy.Price | price}}</span></h3>
              <h3 *ngIf="Policy.RenewalPrice && Policy.RenewalPrice > 0">Prezzo nuovo per il rinnovo: <span class="initial">{{Policy.RenewalPrice | price}}</span></h3>
              <br>
              <div class="sottotitolo">
                <h3 class="initial" [innerHTML]="Policy.LegalClassification"></h3>
              </div>
            </div>

            <div class="button-cntr">

              <button class="new-button" *ngIf="Policy.IsCancelable"
                (click)="cancelPolicy(Policy.UserPolicyID)">RECEDI</button>
              <button class="new-button" *ngIf="Policy.IsDeletable && Policy.State != 2"
                (click)="deletePolicy(Policy.UserPolicyID)">ELIMINA</button>
              <!-- controllo che non sia in stato in attesa -->
              <button class="new-button" *ngIf="Policy.IsRenewable"
                (click)="renewPolicy(Policy.UserPolicyID, Policy.PolicyID)">RINNOVA</button>
              <h3>Documenti contrattuali accettati</h3>
              <button class="new-button"> <span class="document" (click)="zipDocument(Policy.InformativeDocuments)">
                  SCARICA DOCUMENTI<img
                    src="../../../../../assets/images/Configuratore/pdf_scaricabili.svg"></span></button>

              <button class="new-button ATTIVA" *ngIf="Policy.IsFimaa" (click)="redirectFimaa(Policy.FimaaUrl)">SCARICA
                RICEVUTA PAGAMENTO</button>
              <button class="new-button ATTIVA" *ngIf="Policy.IsFimaa == false"
                (click)="zipQuietanze(Policy.ActivationDocuments)">SCARICA RICEVUTA PAGAMENTO</button>

            </div>
          </div>

          <div class="docs-uploaded-policy row" style="padding: 30px;">

            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-10 docUploadedCntr row"
              style="margin-right: 0px; margin-left: 0px;">
              <h2 class="col-12 mt-3">Documenti caricati:</h2>
              <div class="doc-uploaded col-xl-6 col-sm-12 col-md-12 col-lg-12">

                <ng-container *ngFor="let RefusedDoc of Policy.RefusedDocuments">
                  <ng-container *ngIf="RefusedDoc.Type != 15">



                    <button class="new-button ATTENZIONE"
                      (click)="handleDocumentsUpload(RefusedDoc, Policy)">{{RefusedDoc.Name}}</button>

                    <div style="color: var(--myinsurer-primary); float:left">
                      <fa-icon (click)="tooltip.toggle()" [matTooltip]="RefusedDoc.ValidationError"
                        (mouseenter)="$event.stopImmediatePropagation()"
                        (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" [icon]=""></fa-icon>

                    </div>


                  </ng-container>
                </ng-container>

                <ng-container *ngFor="let ActivationDoc of Policy.ActivationDocuments">
                  <ng-container *ngIf="ActivationDoc.Type != 15">
                    <button
                      [class]="ActivationDoc.WasAlreadyUploaded && ActivationDoc.State == 3 ? 'new-button ATTIVA' : ActivationDoc.WasAlreadyUploaded ? 'new-button ATTESA' : 'new-button ATTENZIONE'"
                      (click)="ActivationDoc.WasAlreadyUploaded && ActivationDoc.State == 3 ?
                            appGeneralService.GoToExternalURL(ActivationDoc.DownloadUrl) :
                            handleDocumentsUpload(ActivationDoc, Policy)">{{ActivationDoc.Name}}</button>
                  </ng-container>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="UserPoliciesListResult == false">
      <div class="policy-cntr">
        <div class="no-result">
          <h2>Non ci sono polizze</h2><br />
          <button class="new-button" [routerLink]="['/']">TORNA ALLA HOME</button>
        </div>
      </div>
    </ng-container>
  </div>
