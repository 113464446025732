<div class="boxCntr">

  <div class="boxCntr-txt">
    <h1 title="Per te" class="duplicate"></h1>
    <h3 style="font-weight: unset!important;">Offriamo una vasta gamma di soluzioni assicurative su misura per le tue esigenze. Scegli ciò di cui hai bisogno.</h3>


  </div>

    <carousel [cellWidth]="calculateWidth()" [height]="300" *ngIf="boxes" [cellsToShow]="cellsToShow()">

    <div class="carousel-cell" *ngFor="let box of boxes ; let i = index">

      <div class="cxPart" [routerLink]="box.Url">

        <img src={{box.File}} />

        <div class="text">{{box.Text}}</div>

      </div>

    </div>

  </carousel>

</div>
