<ng-container *ngIf="ConventionResult">
  <div class="page-cntr row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <h1 class="duplicate" title="{{ ConventionResult.Label }}"></h1>
    </div>

    <div
      class="col-lg-5 col-md-5 col-sm-12 imgCntr"
      [ngStyle]="{
        'background-image': 'url(' + ConventionResult.Image + ')'
      }"
    ></div>

    <div class="col-lg-7 col-md-7 col-sm-12">
      {{ ConventionResult.Description }}

      <div class="col-lg-12 col-md-12 col-sm-12">
        <button class="new-button" (click)="Login()">
          Accedi o registrati
        </button>
      </div>
    </div>

    <img
      class="home-background-img dont-show-mobile"
      src="../../../../assets/images/Home/sfondo_puntini.svg"
    />
  </div>

  <div class="page-cntr row">
    <!-- Convention list -->
    <div class="cards-cntr col-lg-12 col-md-12 col-sm-12">
      <h2>Federazioni e associazioni convenzionate</h2>

      <!-- Desktop list -->
      <div class="dont-show-mobile">
        <div class="row d-flex">
          <ng-container
            *ngFor="let convention of ConventionListResult.conventionInfos"
          >
            <div class="col-lg-2 col-md-6 col-sm-12">
              <app-single-convention
                [SingleConvention]="convention"
              ></app-single-convention>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- End Desktop list -->

      <!-- Mobile list -->
      <div class="show-mobile">
        <div class="row d-flex">
          <div style="width: 100%">
            <ng-container
              *ngIf="
                ConventionListResult != undefined &&
                ConventionListResult != null
              "
            >
              <app-convention-carousel
                [ConventionList]="ConventionListResult"
              ></app-convention-carousel>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- End Mobile list -->
    </div>
  </div>

  <div style="margin-top: 100px">
    <app-contact-us></app-contact-us>
  </div>
  <div>
    <app-prefooter></app-prefooter>
  </div>
</ng-container>
