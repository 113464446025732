<div class="proposal-scheda-cntr" [ngClass]="{'clicked': Scheda.Clicked}" (click)="ShowProposalBoxes()">

  <div class="txt-cntr">

    <h3>{{Title}}</h3>
    <h3>{{Scheda.Label}}</h3>
    <br><br>
    <h3 style="font-weight: unset!important;">A partire da <span style="font-weight: bold!important;">{{Scheda.PolicyInfoPreviews[0].Price}}€</span></h3>

  </div>

  <img class="freccia-pacchetti" src="../../../../../../assets/images/Configuratore/freccia_tab_pacchetti_.svg">

  <div class="img-cntr" [ngStyle]="{'background-image': 'url('+ Scheda.PolicyInfoPreviews[0].ImageUrls +')'}">

  </div>


</div>
