<div class="my-product-cntr">

  <div class="img-header">

    <div class="img-cntr">

      <img src="../../../../assets/images/I-miei-acquisti/le_mie_polizze.webp">

    </div>

    <div class="title-cntr">

      <h1 class="duplicate" title="I miei acquisti"></h1>

    </div>


  </div>

<ngb-tabset [destroyOnHide]="false" class="ProductsTabs MYI-tabs">

  <ngb-tab title="LE MIE POLIZZE">
    <ng-template ngbTabContent>

      <app-my-policies></app-my-policies>

    </ng-template>
  </ngb-tab>

  <ngb-tab>
    <ng-template ngbTabTitle>I MIEI SERVIZI</ng-template>

    <ng-template ngbTabContent>

      <app-my-services></app-my-services>

    </ng-template>

  </ngb-tab>


</ngb-tabset>
</div>
