<div id="panelDown" [ngbCollapse]="isCollapsed">
    <div class="panelShowedCntr">

        <div class="panelShowedSecondaryCntr row">
            <div class="buttonClose" (click)="closePanel()">
                X
            </div>

            <div [class]="LeftPanelClass">

                <div class="row">
                    <ng-container *ngIf="PolicyFullDetail">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                            <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ PolicyFullDetail.Policy.ImageUrls[0] +')'}">
                                <div class="priceCntr">
                                    <div class="cxPart"><div class="discount" *ngIf="PolicyFullDetail.Policy.FakePrice">{{PolicyFullDetail.Policy.FakePrice | price}}</div>{{PolicyFullDetail.Policy.Price | price}}</div>
                                    <div class="dxPart"></div>
                                </div>
                            </div>
                            <div class="buttonsCntr">
                                <button class="new-button" (click)="goToPolicyDetail(PolicyFullDetail.Policy.ID, PolicyFullDetail.Policy.PolicyGroupId, PolicyFullDetail.Policy.PolicyProposalId)">Scopri</button><br>
                                <button class="new-button" (click)="BuyNow()">Acquista subito</button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8 col-sm-12 textCntr">
                            <div class="descCntr">
                                <h2>{{PolicyFullDetail.Policy.GroupTitle}}</h2>
                                <p class="desc" [innerHTML]="PolicyFullDetail.Policy.ExtendedDescription"></p>
                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>

            <ng-container *ngIf="ProposalInfo && ProposalInfo.PolicyInfoPreviews.length > 0">

                <div class="col-lg-5 col-md-12 col-sm-12 feed-right-panel">

                    <h2 class="feed-check-policies" (click)="goToPolicyDetail( 0,
                        (ProposalInfo.policyGroupID ? ProposalInfo.policyGroupID : ProposalInfo.PolicyInfoPreviews.length > 0 ? ProposalInfo.PolicyInfoPreviews[0].PolicyGroupId : 1), null
                        )">
                        Confronta le polizze
                    </h2>

                    <carousel [cellWidth]="200" [height]="300" [arrows]="ProposalInfo.PolicyInfoPreviews.length > 1">

                        <div class="carousel-cell" *ngFor="let Policy of ProposalInfo.PolicyInfoPreviews">

                            <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ Policy.ImageUrls[0] +')', 'background-position': 'center', 'background-size': 'cover'}">
                            </div>

                            <div class="priceCntr">
                                <div class="cxPart"><div class="discount" *ngIf="Policy.FakePrice">{{Policy.FakePrice | price}}</div> {{Policy.Price | price}}</div>
                                <div class="dxPart"></div>
                            </div>

                            <div class="titleCntr">
                                <h3>{{Policy.Title}}</h3>
                            </div>

                            <button class="new-button" (click)="goToPolicyDetail(Policy?.ID, Policy?.PolicyGroupId, Policy?.PolicyProposalId)">scopri</button>

                        </div>

                    </carousel>
                </div>

            </ng-container>
        </div>

    </div>
</div>
