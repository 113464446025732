import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() searchHide: boolean = true;
  @Output() searchCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();
  search: string = "";

  @ViewChild('searchInput') searchElement: ElementRef;

  constructor(private router: Router) { }

  ngOnInit() {
  }


  public Search() {
    this.router.navigate(["/assicurazioni"], { queryParams: { ricerca: this.search } });
    this.search = "";
    this.searchCompleted.emit(true);
  }

  @HostListener("window:resize")
  placeHolderDynamic(): string {
    const windowWidth = window.innerWidth;
    if (windowWidth > 900) {
      return "cerca il nome della tua assicurazione o una parola chiave";
    }
      return "cosa stai cercando?";

  }
}
