<div class="document-scheda-cntr" (click)="appGeneralService.GoToExternalURL(Document.DownloadUrl)">

  <div class="img-cntr">

    <img src="../../../../../../../assets/images/Configuratore/pdf_scaricabili.svg">

  </div>

  <div class="txt-cntr">

    <h3>{{Document.Name}}</h3>

  </div>

</div>
