export class FamilyType {
    MemberArray: Object[];
    constructor() {
        this.MemberArray = [
            {
                Id: 0,
                Name: 'Padre'
            },
            {
                Id: 1,
                Name: 'Madre'
            },
            {
                Id: 2,
                Name: 'Figlio'
            },
            {
                Id: 3,
                Name: 'Zio'
            },
            {
                Id: 4,
                Name: 'Nonno'
            },
            {
                Id: 5,
                Name: 'Nipote'
            },
            {
                Id: 6,
                Name: 'Coniuge'
            }
        ]
    }
}


