import { DefaultRegistrationComponent } from './registration-types/default-registration/default-registration.component';

import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
// MATERIAL
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { RegistrationMailSentComponent } from './registration-mail-sent/registration-mail-sent.component';
import { BusinessRegistrationComponent } from './registration-types/business-registration/business-registration.component';
import { LiteRegistrationComponent } from './registration-types/lite-registration/lite-registration.component';
import { RegistrationComponent } from './registration.component';
import { ResetPasswordComponent, ResetPasswordDialog } from './reset-password/reset-password.component';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatPasswordStrengthModule,
    RouterModule,
    MatExpansionModule,
    MatStepperModule,
  ],
  declarations: [
    RegistrationComponent,
    RegistrationMailSentComponent,
    ResetPasswordDialog,
    ResetPasswordComponent,
    LiteRegistrationComponent,
    DefaultRegistrationComponent,
    BusinessRegistrationComponent
  ],
  exports:[
    RegistrationComponent,
    LiteRegistrationComponent,
    DefaultRegistrationComponent,
    BusinessRegistrationComponent
  ]
})
export class RegistrationModule { }
