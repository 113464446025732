import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss', '../cart.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input() CartItem;
  @Input() IsPolicy;
  @Output() onPolicyDeleted = new EventEmitter();
  @Output() onServiceDeleted = new EventEmitter();
  ListCartServices;
  CallData;
  form;
  UserName;

  constructor(
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private router: Router,
    private securityService: SecurityService,
    private formBuilder: UntypedFormBuilder,
    private storageService: LocalStorageService
  ) { }

  ngOnInit() {
    let selectedSubUser = this.CartItem.SelectedSubUser;
    if (selectedSubUser == null) {
      selectedSubUser = -1;
    }
    this.form = this.formBuilder.group({
      insured: new UntypedFormControl(selectedSubUser, Validators.required)
    });

    this.UserName = this.storageService.getUserData();

  }


  buyNow(){
    if(!this.securityService.IsLogged){
      this.securityService.GetLoginErrorModal( this.CartItem.LightRegistration, ["/acquista-servizio", this.CartItem.UserServiceID]);
    }else{
      this.router.navigate(["/acquista-servizio", this.CartItem.UserServiceID]);
    }
  }

  deleteServiceFromCart(id) {
    this.apiService.callApiProd('/api/Service/RemoveServiceFromCart', new CallParameter('POST', {"userServiceID":id}),false).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      }
      this.onServiceDeleted.emit();

    });
  }

  
  deletePolicyFromCart(id) {
    this.apiService.callApiProd('/api/Policy/DeletePolicyFromCart', new CallParameter('POST', {"userPolicyID": id}),false).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      }
      this.onPolicyDeleted.emit();
    });
  }

  goToBuyPolicyPage(ID: number) {
    this.router.navigate(['/acquista-polizza/' + ID]);
  }

  save($event) {

    this.CallData = {
      SubUserId: this.form.get('insured').value,
      UserPolicyId: this.CartItem.ID
    };

    this.apiService.callApiProd('/api/Policy/UpdatePolicySubUser', new CallParameter('POST', this.CallData), false).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      }
    });
  }

}
