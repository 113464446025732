import { Component, OnInit } from '@angular/core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faPlus = faPlus;
  faMinus = faMinus;

  isCollapsed1: boolean = true;
  isCollapsed2: boolean = true;
  isCollapsed3: boolean = true;
  isCollapsed4: boolean = true;
  isCollapsed5: boolean = true;
  isCollapsed6: boolean = true;
  isCollapsed7: boolean = true;
  isCollapsed8: boolean = true;
  isCollapsed9: boolean = true;

  constructor(
    public appGeneralService: AppGeneralService
  ) {

  }

  ngOnInit() {

  }

}
