<div class="stepper-cntr">
  <mat-horizontal-stepper linear [disableRipple]="true">
    <mat-step [stepControl]="formRegistration" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistration" (ngSubmit)="this.registrationService.setCompleteStep(true, formRegistration)" autocomplete="off">
        <ng-template matStepLabel></ng-template>
        <div class="title-cntr">
          <h2>Inserisci i tuoi dati</h2>
        </div>
        <div class="row">
          <mat-form-field  class="col-sm-6">
            <div class="mat-label-txt">Nome*</div>
            <input type="text" matInput required formControlName="Name" name="user-name" class="mat-form-input"
              autocomplete="none" (keydown)="this.registrationService.generateCF(formRegistration, false);" tabindex="1">
            <mat-error *ngIf="formRegistration.controls['Name'].hasError('required')">
              Inserire <strong>nome</strong>
            </mat-error>
          </mat-form-field >
          <mat-form-field  class="col-sm-6">

            <div class="mat-label-txt">Cognome*</div>
            <input type="text" matInput required formControlName="Surname" name="surname" class="mat-form-input"
              autocomplete="none" (keydown)="this.registrationService.generateCF(formRegistration, false);" tabindex="2">
            <mat-error *ngIf="formRegistration.controls['Surname'].hasError('required')">
              Inserire <strong>cognome</strong>
            </mat-error>
          </mat-form-field >

          <mat-form-field class="col-sm-8">
            <div class="mat-label-txt">Codice Fiscale*</div>
            <input type="text" matInput #CodiceFiscale required name="fiscal-code" class="mat-form-input"
              formControlName="CF" (paste)="this.registrationService.onCfPaste($event, formRegistration, false)" autocomplete="none" tabindex="3">
            <mat-error *ngIf="formRegistration.controls['CF'].hasError('required')">
              Inserire <strong>Codice Fiscale</strong>
            </mat-error>
            <mat-error
              *ngIf="!formRegistration.controls['CF'].hasError('required') && formRegistration.controls['CF'].hasError('pattern')">
              <strong>Codice Fiscale</strong> non valido
            </mat-error>
          </mat-form-field>


          <mat-form-field class="col-sm-4">
            <div class="mat-label-txt">Numero di telefono*</div>
            <input type="tel" matInput required formControlName="Phone" class="mat-form-input"
              name="user-phone" autocomplete="none" tabindex="4">
            <mat-error *ngIf="formRegistration.controls['Phone'].hasError('required')">
              Inserire <strong>numero</strong> </mat-error>
          </mat-form-field>


          <mat-form-field class="col-sm-8">
            <div class="mat-label-txt">Email*</div>
            <input type="email" matInput required formControlName="Email" name="user-mail" class="mat-form-input"
              autocomplete="none" tabindex="5">
            <mat-error
              *ngIf="!formRegistration.controls['Email'].hasError('required') && formRegistration.controls['Email'].hasError('email')">
              <strong>E-mail</strong> non valida
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['Email'].hasError('required')">
              Inserire <strong>e-mail</strong> </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Password*</div>
            <input type="password" matInput required formControlName="Password" #password class="mat-form-input"
              name="user-password" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="6"
              [type]="this.registrationService.hide ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hide = !this.registrationService.hide" class="cursor-pointer">{{this.registrationService.hide ? 'visibility_off' :
              'visibility'}}</mat-icon>

            <mat-error *ngIf="formRegistration.controls['Password'].hasError('required')">
              Inserire <strong>password</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-sm-6">
            <div class="mat-label-txt">Conferma Password*</div>
            <input matInput required name="user-password-confirm" class="mat-form-input"
              formControlName="PasswordConfirm" autocomplete="none" (input)="this.registrationService.onPasswordInput(formRegistration)" tabindex="7"
              [type]="this.registrationService.hideConfirm ? 'password' : 'text'">
            <mat-icon matSuffix (click)="this.registrationService.hideConfirm = !this.registrationService.hideConfirm" class="cursor-pointer">{{this.registrationService.hideConfirm ?
              'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error
              *ngIf="formRegistration.controls['PasswordConfirm'].invalid && !formRegistration.controls['PasswordConfirm'].hasError('required') ">
              Le <strong>password</strong> non combaciano
            </mat-error>
            <mat-error *ngIf="formRegistration.controls['PasswordConfirm'].hasError('required')">
              Inserire <strong>conferma password</strong>
            </mat-error>
          </mat-form-field>



          <div class="col-sm-8">
            <mat-password-strength #passwordComponent [min]="6" [max]="25" [enableSpecialCharRule]="false"
              [enableLowerCaseLetterRule]="true" [enableUpperCaseLetterRule]="true" [enableDigitRule]="true"
              [password]="password.value">
            </mat-password-strength>
            <mat-password-strength-info [passwordComponent]="passwordComponent"
              minCharsCriteriaMsg="Inserire almeno 6 caratteri"
              upperCaseCriteriaMsg="Inserire almeno un carattere appartenente alle lettere maiuscole (da A a Z)"
              digitsCriteriaMsg="Inserire almeno un carattere appartenente ai primi 10 numeri di base (da 0 a 9)"
              lowerCaseCriteriaMsg="Inserire almeno un carattere minuscolo">
            </mat-password-strength-info>
          </div>
        <div class="col-sm-4 button-cntr">
          <button class="new-button" type="button" matStepperNext>Avanti</button>
        </div>
      </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="formRegistrationStep2" [completed]="this.registrationService.completeStep">
      <form [formGroup]="formRegistrationStep2" (ngSubmit)="onRegistration()" autocomplete="off" >
        <div class="row">

           <div class="col-sm-12">
          <h3>Confermo di aver preso visione del <a
            [href]="this.environmentService.Url + '/api/policy/GetPrivacySpecialDocument'" target="_blank">modulo
            sulla
            Privacy</a> e di:<br></h3>
          <mat-checkbox formControlName="GeneralPrivacyCheck" tabindex="16"
            [ngClass]="formRegistrationStep2.controls['GeneralPrivacyCheck'].hasError('required') ? 'error' : 'no-error'">
            Acconsento al trattamento dei dati per finalità assicurative e per le tecniche di comunicazione a distanza (Obbligatorio)
          </mat-checkbox>
          <br>
          <mat-checkbox formControlName="CheckBox1" tabindex="17">Acconsentire al punto 1 (Facoltativo)
          </mat-checkbox><br>
          <mat-checkbox formControlName="CheckBox2" tabindex="18">Acconsentire al punto 2 (Facoltativo)
          </mat-checkbox><br>
          <mat-checkbox formControlName="CheckBox3" tabindex="19">Acconsentire al punto 3 (Facoltativo)
          </mat-checkbox><br>
        </div>


        <div class="col-sm-12 button-cntr">
          <button class="new-button" type="submit" tabindex="20">Registrati</button>
        </div>
      </div>
    </form>
    </mat-step>
  </mat-horizontal-stepper>

</div>