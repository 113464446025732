export enum authType {
    NO_AUTHENTICATION = 0,
    //Pagine per cui non serve l'autenticazione ES. Login
    TRACE_SESSION = 1,
    //Pagine in cui ho bisogno di memorizzare i dati, Es. Carrello . Non posso comprare nulla ovviamente
    AUTHENTICATED = 2,
    //Può fare tutto tranne che vedere pagine/menu di admin
    ADMIN_AUTHENTICATED = 3,
    //Può fare tutto
    MAINTENANCE = -1
}