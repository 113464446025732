<div class="my-policy-cntr">

    <div class="row">

        <ng-container *ngIf="UserServicesListResult">
            <ng-container *ngFor="let Service of UserServicesListResult; let i = index">
                <div class="card col-12 cardCntr" style="border: 0">

                    <div class="service active-service" (click)="Service.isCollapsed = !Service.isCollapsed" [attr.aria-expanded]="Service.isCollapsed" aria-controls="collapseExample" [ngStyle]="{'background-color': appGeneralService.getServiceStatusColor(Service.State).color, 'border-color': appGeneralService.getServiceStatusColor(Service.State).color}">
                        <div class="col-lg-3">
                            Stato:<br><strong>{{appGeneralService.getServiceStatusColor(Service.State).text}}</strong>
                        </div>
                        <div class="col-lg-3">
                            Totale:<br><strong>{{Service.Price | price}}</strong>
                        </div>
                        <div class="col-lg-3">
                            Tipologia:<br><strong>{{Service.Name}}</strong>
                        </div>
                        <div class="col-lg-3">
                            Codice numero:<br><strong>{{Service.PIN}}</strong>
                        </div>
                    </div>
                    <div id="collapseExample" [ngbCollapse]="!Service.isCollapsed" [ngStyle]="{'border-color': appGeneralService.getServiceStatusColor(Service.State).color}">
                        <div class="card">
                            <div class="card-body">

                                <div class="info-service row">
                                    <div class="img-service col-lg-3 col-md-12 col-sm-12">
                                        <img *ngIf="(Service.ImageUrls != null || Service.ImageUrls != undefined) && Service.ImageUrls.lenght > 0" [src]="Service.ImageUrls[0]" width="100%" alt="I miei servizi">
                                    </div>
                                    <div class="text-service col-lg-9 col-md-12 col-sm-12">
                                        <strong>{{Service.Name}}</strong><br>
                                        <p [innerHTML]="Service.ExtendedDescription"></p>
                                        <div class="AttenctionMsg" *ngIf="Service.State == 15">
                                            ATTENZIONE: Il recesso non avverrà se si ha usufruito del servizio
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="Service.UserUploadedFiles">
                                    <h2 class="col-12">Documenti Caricati</h2>
                                    <div class="download-box col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let file of Service.UserUploadedFiles; index as i">
                                        <div class="DocumentName" [style]="file.State == 0 ? 'background-color: yellowgreen;': 'background-color: red;'" (click)="file.State == 0 ? appGeneralService.GoToExternalURL(file.Url) : openInput(i)">
                                            <strong></strong>{{file.FileName}}</div>
                                        <div class="DocumentLink" (click)="file.State == 0 ? appGeneralService.GoToExternalURL(file.Url) : openInput(i)">
                                            <a target="_blank" title="Scarica il documento">
                                                <fa-icon [icon]="file.State == 0 ? faFileDownload : faFileUpload" [size]="'2x'"></fa-icon>
                                            </a>
                                        </div>
                                        <input type="file" [id]="i" (change)="handleDocumentsUpload(file, $event)" style="display: none;" />
                                        <div style="color: var(--myinsurer-primary); float:left" *ngIf="file.State == 2">
                                            Motivo del rifiuto:
                                            <fa-icon (click)="tooltip.toggle()" [matTooltip]="file.ValidationError" (mouseenter)="$event.stopImmediatePropagation()" (mouseleave)="$event.stopImmediatePropagation()" #tooltip="matTooltip" [icon]="faInfo"></fa-icon>

                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix row">
                                    <div class="btns-service offset-lg-9 col-md-3 offset-md-9 col-md-3 offset-sm-0 col-sm-12" *ngIf="Service.State != 20 && Service.State != 10">
                                        <button class="new-button" (click)="myNetLink(Service.UserServiceID)">Utilizza servizio</button>
                                    </div>
                                </div>
                                <div class="clearfix row">

                                    <div class="btns-service offset-lg-9 col-md-3 offset-md-9 col-md-3 offset-sm-0 col-sm-12" *ngIf="Service.State != 15 && Service.State != 20 && Service.State != 10">
                                        <button class="new-button" (click)="recessService(Service.UserServiceID)">Recesso servizio</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="UserServicesListResult == false">
            <div class="w-100 text-center">
                <h2 class="mt-4">Non ci sono servizi</h2><br/>
                <button class="new-button" [routerLink]="['/home']">TORNA ALLA HOME</button>
            </div>
        </ng-container>
    </div>

</div>
