export class CompanyType {
    TypeArray: Object[];
    constructor() {
        this.TypeArray = [
            {
                Id: 0,
                Name: 'Società semplice'
            },
            {
                Id: 1,
                Name: 'S.P.A.'
            },
            {
                Id: 2,
                Name: 'S.N.C.'
            },
            {
                Id: 3,
                Name: 'S.A.S.'
            },
            {
                Id: 4,
                Name: 'S.R.L.'
            },
            {
                Id: 5,
                Name: 'S.A.P.A.'
            },
            {
                Id: 6,
                Name: 'Società Cooperativa'
            },
            {
                Id: 7,
                Name: 'Società Mutuo Soccorso'
            },
            {
                Id: 8,
                Name: 'Ditta Individuale'
            },
        ]
    }
}


