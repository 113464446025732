<div class="carousel-how-work-cntr">


  <div class="txt-cntr">
    <h1>Come funziona?</h1>
  </div>


  <div class="carousel-cntr row">
  <carousel [cellWidth]="calculateWidth()" [height]="calculateHeight()" [cellsToShow]="3">

    <ng-container *ngFor="let scheda of schede">

      <div class="carousel-cell">
      <app-scheda-how-work [title]="scheda.title" [img]="scheda.img"></app-scheda-how-work>
    </div>
    </ng-container>

  </carousel>
</div>
</div>
