import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

export class SubUserProfile {
    Id: number;
    UserId: number;
    Type: number;
    Name: string;
    Surname: string;
    BusinessName: string;
    Sex: number;
    DateOfBirth: Date;
    BirthTown: string;
    Province: string;
    Nation: string;
    Email: string;
    PhoneNumber: string;
    CompanyType: Number;
    VatNumber: string;
    City: string;
    Address: string;
    AddressNumber: number;
    FamilyType: number;
    FiscalCode: string;
    Rea : string;

    constructor (Value:  any  =  null) {
        if (Value !=  null) {
            this.Id  =  +Value["Id"];
            this.UserId  = Number(Value["UserId"]);
            this.Type = Number(Value["Type"]);
            this.Name = Value["Name"];
            this.Surname = Value["Surname"];
            this.BusinessName = Value["BusinessName"];
            this.Sex = Number(Value["Sex"]);
            this.DateOfBirth = Value["DateOfBirth"];
            this.BirthTown = Value["BirthTown"];
            this.Province = Value["Province"];
            this.Nation = Value["Nation"];
            this.Email = Value["Email"];
            this.PhoneNumber = Value["PhoneNumber"];
            this.CompanyType = Number(Value["CompanyType"]);
            this.VatNumber = Value["VatNumber"]?.toString();
            this.City = Value["City"];
            this.Address = Value["Address"];
            this.AddressNumber = Value["AddressNumber"];
            this.FamilyType = Number(Value["FamilyType"]);
            this.FiscalCode = Value["FiscalCode"];
            this.Rea = Value["Rea"];
        }
    }

     getFormGroup(): UntypedFormGroup{
        return new UntypedFormGroup({
            Id: new UntypedFormControl(this.Id),
            UserId: new UntypedFormControl(this.UserId),
            Type: new UntypedFormControl(this.Type),
            Name: new UntypedFormControl(this.Name),
            Surname: new UntypedFormControl(this.Surname),
            BusinessName: new UntypedFormControl(this.BusinessName),
            Sex: new UntypedFormControl(this.Sex),
            DateOfBirth: new UntypedFormControl(this.DateOfBirth),
            BirthTown: new UntypedFormControl(this.BirthTown),
            Province: new UntypedFormControl(this.Province),
            Nation: new UntypedFormControl(this.Nation),
            Email: new UntypedFormControl(this.Email,Validators.compose([Validators.email, Validators.required])),
            PhoneNumber: new UntypedFormControl(this.PhoneNumber),
            CompanyType: new UntypedFormControl(this.CompanyType),
            VatNumber: new UntypedFormControl(this.VatNumber),
            Rea : new UntypedFormControl(this.Rea),
            City: new UntypedFormControl(this.City),
            Address: new UntypedFormControl(this.Address),
            AddressNumber: new UntypedFormControl(this.AddressNumber),
            FamilyType: new UntypedFormControl(this.FamilyType),
            FiscalCode: new UntypedFormControl(this.FiscalCode)
          });
    }
}
