import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BoxDTO } from "src/app/core/classes/Home/ShoppingCenter/BoxDTO";
import { ShoppingCenterDTO } from "src/app/core/classes/Home/ShoppingCenter/ShoppingCenterDTO";
import { CallParameter } from "src/app/core/classes/system/call-parameter";
import { SiteType } from "src/app/core/enum/siteType.enum";
import { ApiService } from "src/app/core/services/api.service";
import { LocalStorageService } from "src/app/core/services/localstorage.service";
import { UrlBuilderService } from "src/app/core/services/url-builder.service";

@Component({
  selector: "app-home-slider-boxes",
  templateUrl: "./home-slider-boxes.component.html",
  styleUrls: ["./home-slider-boxes.component.scss"],
})
export class HomeSliderBoxesComponent implements OnInit {
  boxes: Array<BoxDTO> = [];
  siteType: string;

  constructor(
    private urlBuilderService: UrlBuilderService,
    private localService: LocalStorageService,
    private route: Router,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.siteType = this.localService.getSiteType();
    switch (this.siteType) {
      case SiteType.SHOPPINGCENTER: {
        this.getShoppingCenterConfiguration();
        break;
      }
      default: {
        this.setDefaultValue();
        break;
      }
    }
  }

  setDefaultValue() {
    this.boxes = [
      new BoxDTO(
        "/per-te-casa-salute/Casa-&-Famiglia/0",
        "../../../../../assets/images/Home/Slider/casa_salute.svg",
        "Per la tua casa & la tua salute",
        "Prima box",
        ""
      ),
      new BoxDTO(
        "/per-il-tuo-lavoro/Studio-&-attività-professionali/0",
        "../../../../../assets/images/Home/Slider/business.svg",
        "Per il tuo business",
        "Seconda box",
        ""
      ),
      new BoxDTO(
        "/convenzioni",
        "../../../../../assets/images/Home/Slider/convenzionati.svg",
        "Per i convenzionati",
        "Terza box",
        ""
      )
    ];
  }

  goToFeedPage(url: string, i) {
    if (url && url != "") {
      window.location.href = url;
      return;
    }

    switch (i) {
      case 0: {
        this.urlBuilderService.navigateToFeed(2);
        break;
      }
      case 1: {
        this.urlBuilderService.navigateToFeed(7);
        break;
      }
      case 2: {
        this.urlBuilderService.navigateToFeed(5);
        break;
      }
      case 3: {
        this.route.navigate(["/convenzioni"]);
        break;
      }
      default: {
        break;
      }
    }
  }

  getShoppingCenterConfiguration() {
    let callparam = new CallParameter("GET");
    let shoppingCenterConfig = new ShoppingCenterDTO();
    this.apiService
      .callApiProd(
        "/api/ShoppingCenter/GetShoppingCenterConfiguration",
        callparam,
        true,
        true
      )
      .subscribe((res) => {
        shoppingCenterConfig = res.ResultData as ShoppingCenterDTO;
        this.boxes = shoppingCenterConfig.BoxArray;
      });
  }

  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 250;
    } else if (windowWidth > 600 && windowWidth <= 1200) {
      return 180;
    } else {
      return 150;
    }
  }
  @HostListener("window:resize")
  cellsToShow(){
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return "";
    }
      return 2.4;

  }
}
