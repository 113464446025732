import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

@Component({
  selector: 'app-upgrade-policy-dialog',
  templateUrl: './upgrade-policy-dialog.component.html',
  styleUrls: ['./upgrade-policy-dialog.component.scss']
})
export class UpgradePolicyDialogComponent implements OnInit {

  Policies: any;
  displayArrows;
  faTimes = faTimes;
  constructor(
    public dialogRef: MatDialogRef<UpgradePolicyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.Policies = this.data["Upgrades"];
    this.Policies.find(z => z.ID == this.data["OldPolicyId"])["IsOldPolicy"] = true;
    this.calculateArrows();
  }

  close() {
    this.dialogRef.close();
  }

  renewUpgrade(ID) {
    this.dialogRef.close(ID);
  }

  @HostListener('window:resize')
  calculateArrows(){
      if(this.Policies.length < 2){
        this.displayArrows = false;
      }else{
        let a = this.Policies.length * 175;
        let b = $(".carousel-container").width();
        this.displayArrows = b - a <= 0;
      }
  }

}
