<div class="reset-pw-cntr">
  <form class="row" style="place-content: center;" autocomplete="off" [formGroup]="formUpdatePassword" (ngSubmit)="onUpdatePassword()">
    <div class="col-lg-12" style="padding-top:2vh;">
      <h3 class="text-center title"><strong>Nuova password</strong></h3>
    </div>
      <div class="myProfileContainer newPassword">

        <!-- <mat-form-field class="full-width col-lg-12">
          <input type="email" matInput placeholder="Email" formControlName="Email" autocomplete="new-email" name="email"
            [errorStateMatcher]="matcher">
          <mat-error
            *ngIf="!formUpdatePasswordControls['Email'].hasError('required') && formUpdatePasswordControls['Email'].hasError('email')">
            <strong>E-mail</strong> non valida</mat-error>
          <mat-error *ngIf="formUpdatePasswordControls['Email'].hasError('required')">
            Inserire <strong>e-mail</strong> </mat-error>
        </mat-form-field> -->

        <mat-form-field class="full-width col-lg-12" style="margin-bottom: 10px;">
          <input type="password" matInput placeholder="Inserisci nuova password" required formControlName="Password" name="password"
            #password autocomplete="new-password" [errorStateMatcher]="matcher" (input)="onPasswordInput()"  [type]="hide ? 'password' : 'text'">
           <mat-icon matSuffix (click)="hide = !hide" class="cursor-pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-hint align="end" aria-live="polite">
            {{password.value.length}} / 25
          </mat-hint>
          <mat-error *ngIf="formUpdatePasswordControls['Password'].hasError('required')">
            Inserire <strong>nuova password</strong>
          </mat-error>
        </mat-form-field>

        <mat-password-strength #passwordComponent [min]="6" [max]="25" [enableSpecialCharRule]="false"
          [enableLowerCaseLetterRule]="true" [enableUpperCaseLetterRule]="true" [enableDigitRule]="true"
          [password]="password.value">
        </mat-password-strength>
        <mat-password-strength-info [passwordComponent]="passwordComponent"
          minCharsCriteriaMsg="Inserire almeno 6 caratteri"
          upperCaseCriteriaMsg="Inserire almeno un carattere appartenente alle lettere maiuscole (da A a Z)"
          digitsCriteriaMsg="Inserire almeno un carattere appartenente ai primi 10 numeri di base (da 0 a 9)"
          lowerCaseCriteriaMsg="Inserire almeno un carattere minuscolo">

        </mat-password-strength-info>

        <mat-form-field class="full-width col-lg-12" style="margin: 15px 0">
          <input type="password" matInput placeholder="Conferma nuova password" required name="password-confirm"
            formControlName="PasswordConfirm" [errorStateMatcher]="matcher"
            (input)="onPasswordInput()"  [type]="hideNew ? 'password' : 'text'">
           <mat-icon matSuffix (click)="hideNew = !hideNew" class="cursor-pointer">{{hideNew ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-error
            *ngIf="formUpdatePasswordControls['PasswordConfirm'].invalid && !formUpdatePasswordControls['PasswordConfirm'].hasError('required') ">
            Le <strong>password</strong> non combaciano
          </mat-error>
          <mat-error *ngIf="formUpdatePasswordControls['PasswordConfirm'].hasError('required')">
            Inserire <strong>conferma nuova password</strong>
          </mat-error>
        </mat-form-field>
        <button type="submit" class="new-button">RESET PASSWORD</button>
      </div>
  </form>
</div>
