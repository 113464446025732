<h1>Documentazione contrattuale</h1>
<div class="row">

    <div class="col-12">


    </div>

</div>

<div class="row">

    <ng-container *ngFor="let File of AllFilesResult">

        <div class="col-sm-12 col-md-3 colBox">
            <div class="docCntr">
                <div class="docText">{{File.OriginalFileName}}</div>
                <div class="icons">
                    <!-- <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon> -->
                    <a href="{{File.DownloadUrl}}" target="_blank" title="{{File.OriginalFileName}}">
                        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
                    </a>
                </div>
            </div>
        </div>

    </ng-container>

</div>
<!--
  <div class="col-3">
    <div class="docCntr">
      <div class="docText">Allegato 1</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Allegato 2</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText doubleRow">Consenso invio<br>elettronico</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Modulo Privacy</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

</div>

<div class="row">

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Allegato 3</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Allegato 4</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Documento 1</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Documento 2</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

</div>

<div class="row">

  <div class="col-3">
    <div class="docCntr">
    <div class="docText doubleRow">Estensione<br>garanzia Covid</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">CGA tandem</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">Bozza polizza</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div>

  <div class="col-3">
    <div class="docCntr">
    <div class="docText">DIP tandem</div>
      <div class="icons">
        <fa-icon [icon]="faFileAlt" [size]="'2x'"></fa-icon>
        <fa-icon [icon]="faFileDownload" [size]="'2x'"></fa-icon>
      </div>
    </div>
  </div> -->
