import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-blocks-carousel',
  templateUrl: './info-blocks-carousel.component.html',
  styleUrls: ['./info-blocks-carousel.component.scss']
})
export class InfoBlocksCarouselComponent implements OnInit {

  infoBlocksArray = [
    {
      img: "./assets/images/icons/specializzati.svg",
      title: "Specializzati da oltre 20 anni",
      text: "Dal <strong>2000</strong> ci siamo specializzati nelle convenzioni assicurative, offrendo polizze con premi competitivi e assistenza di alta qualità."
    },
    {
      img: "./assets/images/icons/base-di-utenza.svg",
      title: "Ampia base di utenti",
      text: "Con oltre <strong>100.000 utenti</strong> iscritti in costante crescita, garantiamo tranquillità e sicurezza per l'attività lavorativa quotidiana."
    },
    {
      img: "./assets/images/icons/professionalita.svg",
      title: "Soddisfazione e professionalità",
      text: "Soddisferemo le esigenze delle diverse categorie in modo trasparente e professionale."
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 250;
    } else if (windowWidth > 600 && windowWidth <= 1200) {
      return 300;
    } else {
      return 150;
    }
  }

  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 400;
    } else if (windowWidth > 600 && windowWidth <= 1200) {
      return 320;
    } else {
      return 400;
    }
  }

}
