import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ProductType } from 'src/app/core/enum/product-type.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-nexi-retry-payment',
  templateUrl: './nexi-retry-payment.component.html',
  styleUrls: ['./nexi-retry-payment.component.scss']
})
export class NexiRetryPaymentComponent implements OnInit {
  TypeEnum = ProductType;

  constructor(
    private ApiService: ApiService,
    private appGen: AppGeneralService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(param => {
      let url = "/api/Service/NexiServicePayment";
      let callParam: CallParameter = new CallParameter("POST", {
        UserServiceID: param.ProductId,
        IsRecurrent: false,
        Months: 1
      });

      if (param.Type == this.TypeEnum.Policy) {
        url = "/api/Policy/NexiPolicyPayment";
        callParam = new CallParameter("POST", {
          UserPolicyId: Number(param.ProductId),
          IsRecurrent: false,
          Months: 1
        });
      }

      this.ApiService.callApiProd(url, callParam).subscribe((data) => {
        if (data.InError) {
          this.appGen.showDialog(data.ErrorCode);
        } else {
          this.appGen.showDialog(-5000);
          setTimeout(() => {
            let form = $(data.ResultData["FormRedirect"].Content);

            form.appendTo($('body'));

            if (form.attr('method') === 'get') {
              let action: string | undefined = form.attr('action');

              if (action) window.location.href = action;
            } else {
              form.trigger('submit');
            }
          }, 1000);
        }
      });
    });
  }

}
