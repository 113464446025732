import { Component, Input, OnInit } from '@angular/core';
import { ReadmoreCarouselItem } from 'src/app/core/classes/readmore-carousel';

@Component({
  selector: 'app-single-readmore',
  templateUrl: './single-readmore.component.html',
  styleUrls: ['./single-readmore.component.scss', '../../home.component.scss']
})
export class SingleReadmoreComponent implements OnInit {

  @Input() SingleReadmore: ReadmoreCarouselItem;
  
  constructor() { }

  ngOnInit() {
  }

  goOut(link){
    window.open(
      link,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
}
