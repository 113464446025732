import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nostra-azienda',
  templateUrl: './nostra-azienda.component.html',
  styleUrls: ['./nostra-azienda.component.scss']
})
export class NostraAziendaComponent implements OnInit {

  marchi : Array<any> = [

    {
      img : "../../../../../../../../assets/images/Home/Prefooter/insurance_group.svg",
      desc: "Specializzato nella creazione e nella gestione di convenzioni a livello nazionale. Attraverso i nostri marchi offriamo consulenza personalizzata a persone e microimprese."
    },
    {
      img : "../../../../../../../../assets/images/Home/Prefooter/myinsurer.svg",
      desc: "Portale online pratico e veloce dedicato alla vendita di polizza e servizi"
    },
    {
      img : "../../../../../../../../assets/images/Home/Prefooter/assicurazioni.svg",
      desc: "Servizio intermediazione assicurativa sia presso i suoi uffici, sia online sfruttando l’apposito e-commerce di MyInsurer."
    },
    {
      img : "../../../../../../../../assets/images/Home/Prefooter/insurtechservice.svg",
      desc: "Società specializzata in servizi di consulenza e sviluppo commerciale."
    }

  ]

  datiMarchi : Array<any> = [

    {
      tit : "Anni di esperienza",
      num : 20
    },
    {
      tit : "Clienti",
      num : 130000
    },
    {
      tit : "Polizze emesse",
      num : 85000
    }

  ]
  constructor() { }

  ngOnInit() {
  }

}
