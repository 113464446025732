import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { StepperComponent } from './stepper.component';

@NgModule({
  imports: [
    CommonModule,
    MatStepperModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  declarations: [
    StepperComponent
  ],
  exports: [
    StepperComponent
  ]
})
export class StepperModule { }
