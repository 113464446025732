<!-- <h2 style="text-transform: uppercase;">

  {{TitleCarousel}}

</h2> -->

<div style="width: 100%">
  <ng-container
    *ngIf="
      ConventionList.conventionInfos != undefined &&
      ConventionList.conventionInfos != null
    "
  >
    <carousel [cellWidth]="220" [height]="256">
      <div
        class="carousel-cell"
        *ngFor="let Item of ConventionList.conventionInfos"
      >
        <app-single-convention
          [SingleConvention]="Item"
        ></app-single-convention>
      </div>
    </carousel>
  </ng-container>
</div>
