<div class="mainCntr">

    <div class="clearfix headerCntr">

        <app-menu [url]="url" [routes]="childRoutes" [logged]="is_logged" [hisName]="hisName" [cartItems]="cartItems"></app-menu>

    </div>

    <div class="clearfix container routerCntr">
        <router-outlet></router-outlet>
        <ng-container>
            <app-chat></app-chat>
        </ng-container>
    </div>
    <div class="clearfix footerCntr">
        <app-footer></app-footer>
    </div>
    <app-white-footer></app-white-footer>

    <div class="cookieCntr">
      <app-cookie-message *ngIf="ShowCookiePolicy"></app-cookie-message>
    </div>
</div>