import { Component, HostListener, OnInit } from '@angular/core';
import { ReadmoreCarouselItem } from 'src/app/core/classes/readmore-carousel';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-readmore-carousel',
  templateUrl: './readmore-carousel.component.html',
  styleUrls: ['./readmore-carousel.component.scss', '../home.component.scss']
})
export class ReadmoreCarouselComponent implements OnInit {

  constructor(
    private apiService: ApiService
  ) { }

  ReadmoreCarouselItems: ReadmoreCarouselItem[];

  ngOnInit() {
    this.getReadMoreElements();
  }

  getReadMoreElements(){
    this.apiService.callApiExternal("https://www.buzziinsurancegroup.com/feed/json", "GET").subscribe((result) => {
      this.ReadmoreCarouselItems = result.items.map(item => {
        return ReadmoreCarouselItem.create(item.id, item.image, item.title, item.url);
      });
    });
  }


  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 800) {
      return 630;
    }
      return 230;

  }
}
