import { Component, OnInit } from '@angular/core';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { ApiService } from 'src/app/core/services/api.service';
import { DocumentInfo, IncidentInfo } from 'src/app/core/classes/incident-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { faChevronDown, faChevronUp, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { IncidentFileParam } from 'src/app/core/classes/incident-file-param';
import { FileData } from 'src/app/shared/file-upload/file-upload.component';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-insurance-memo',
  templateUrl: './insurance-memo.component.html',
  styleUrls: ['./insurance-memo.component.scss']
})
export class InsuranceMemoComponent implements OnInit {

  Incidents: IncidentInfo[];
  NoIncidents: boolean = false;
  faDoc = faFileDownload;
  faChevron = faChevronDown;
  CallData: any;
  FileArr : Array<any> = [];
  inputPlaceHolder: string = 'Carica qui uno o più file';
  public uploaderMemo: FileUploader = new FileUploader({ itemAlias: 'fileuploadinputmemo' });

  constructor(
    public appGeneralService: AppGeneralService,
    private apiService: ApiService
  ) { }

  ngOnInit() {

    this.getListIncidents();
  }

  fileChange(event: any){
    this.FileArr = [];
    this.inputPlaceHolder = '';

    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        let reader = new FileReader();
        let FileToUpload = new FileData();

        FileToUpload.Name = event.target.files[i].name;
        this.inputPlaceHolder += event.target.files[i].name + ', ';
        reader.onload = (event: any)  => {
          FileToUpload.FileBase64 = event.target.result.toString();
          this.FileArr.push(FileToUpload);
        }

        reader.readAsDataURL(event.target.files[i]);

      }

    }
  }

  insertFiles(incidentId: number, fileArr: Array<any>){


    if(fileArr.length == 0){

      this.appGeneralService.showDialog(null,"Devi selezionare almeno un file!","File non selezionati");
      return


    }

     let CallData = new IncidentFileParam();
      CallData.IncidentId = incidentId;
      CallData.File = fileArr;
      this.apiService.callApiProd('/api/Incident/InsertFile', new CallParameter('POST', CallData)).subscribe((result) => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        } else {

          let arrayValue = result.ResultData as Array<any>;

          arrayValue.forEach(element => {

            let incident = this.Incidents.find(f => f.Id == incidentId);
            if(incident && element.file as DocumentInfo){

              incident.DocumentInfo.push(element.file);

            }


          });

          if (this.Incidents.length <= 0) {
            this.NoIncidents = true;
          }
          this.FileArr = [];
          this.inputPlaceHolder = '';

        }
       });

  }


  dropdown(Incident: IncidentInfo) {
    Incident.IsCollapsed = !Incident.IsCollapsed
    if (Incident.IsCollapsed) {
      Incident.arrow = faChevronUp;
      return;
    }
    Incident.arrow = faChevronDown;
  }

  getListIncidents(){

    this.apiService.callApiProd('/api/Incident/ListIncidents', new CallParameter('POST', this.CallData)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.Incidents = result.ResultData["Incidents"];
        if (this.Incidents.length <= 0) {
          this.NoIncidents = true;
          return;
        }

      }
    });

  }
}
