<div class="store-home-cntr">



  <div class="txt-cntr">

    <h1>Acquista servizi e assicurazioni dove e quando vuoi!</h1><br>
    <h3>MyInsurer: l'app che ti mette al centro delle tue scelte assicurative. Acquista servizi e polizze personalizzate ovunque tu sia.</h3><br>
    <h3 style="font-weight: bold!important;">Scarica l'App</h3>
    <div class="button-cntr">
    <button class="new-button" (click)="goToApple()"> <img src="../../../../../assets/images/Store/apple.svg"> APP STORE</button>
    <button class="new-button"(click)="goToGoogle()"><img src="../../../../../assets/images/Store/google-play.svg"> GOOGLE PLAY</button>
  </div>
  </div>

  <div class="img-cntr">

    <img class="dont-show-mobile" src="../../../../../assets/images/Store/immagine-app1.webp" style="width: 600px; height: auto">
  </div>



    <img class="home-background-img dont-show-mobile" style="transform: rotate(180deg);" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>
