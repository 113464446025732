import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faFileDownload, faUpload } from '@fortawesome/free-solid-svg-icons';
import { PolicyServiceDocumentInfo } from 'src/app/core/classes/Policy/policy-document-info';
import { ServiceFileParam } from 'src/app/core/classes/ServiceClasses/service-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-info-document',
  templateUrl: './info-document.component.html',
  styleUrls: ['./info-document.component.scss']
})
export class InfoDocumentComponent implements OnInit {

  faDownload = faFileDownload;
  faUpload = faUpload;
  @Input() data: PolicyServiceDocumentInfo;
  @Input() isService : boolean | null;
  @Input() UserServiceID : number | null;
  @Output() onDocumentRead = new EventEmitter<any>();

  //Create Output property with event emitter


  constructor(
    private appGen:AppGeneralService,
    private apiService: ApiService
  ) { }

  ngOnInit() {


  }

  DocumentRead(){
    this.onDocumentRead.emit();
  }

  setDocAsReaded(InfoDoc){
    this.appGen.GoToExternalURL(InfoDoc.DownloadUrl)

    if(this.isService && this.UserServiceID){


      let param = new ServiceFileParam([InfoDoc.ID],this.UserServiceID)

      let callApiParameter =CallApiProdParameter.create('/api/Service/SetServicesRead',param,'POST').setSpinner(true).setKeepSpinner(false);
      this.apiService.callApiProduction(callApiParameter).subscribe((result) => {
        if(result.InError){
          this.appGen.showDialog(result.ErrorCode);
        }else{
          this.DocumentRead();
        }
      });


    }else{


    const CallData = {
      PolicyFileIDs: [InfoDoc.ID]
    };
    this.apiService.callApiProd('/api/Account/SetPolicyDocumentsAccepted', new CallParameter('POST', CallData)).subscribe((result) => {
      if(result.InError){
        this.appGen.showDialog(result.ErrorCode);
      }else{
        this.DocumentRead();
      }
    });

  }

  }
}
