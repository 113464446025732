import { Injectable } from '@angular/core';
import { BearerToken } from 'src/app/core/classes/system/bearer-token';
import { isNullOrUndefined } from 'util';
import * as jwt_decode from "jwt-decode";
import { LocalStorageService } from '../localstorage.service';
import { SocialAuthService } from "angularx-social-login";
import { SecurityService } from 'src/app/core/services/security.service';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private storageService: LocalStorageService,
    private socialAuthService: SocialAuthService,
    private securityService: SecurityService,
    private apiService: ApiService,
    private router: Router,
  ) { }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  getBearerToken(): BearerToken {

    return this.getDecodedAccessToken(this.storageService.getBearerCookie());
    // return this.getDecodedAccessToken(this.cookieService.get(this.appGeneralService.bearerCookie));
  }

  bearerInexistent(): boolean {
    return isNullOrUndefined(this.getBearerToken());
  }

  bearerExpired(): boolean {
    const BearerToken: BearerToken = this.getBearerToken();
    return !this.bearerInexistent() && new Date(BearerToken.exp * 1000) < new Date();
  }

  deleteBearerTKN(): void {
    this.storageService.removeBearerCookie();
  }

  logout(): void {
    this.socialAuthService.signOut();
    sessionStorage.clear();
    localStorage.clear();
    this.securityService.check_log_state();
    this.apiService.callApiProd("/api/Authentication/Logout", new CallParameter("GET")).subscribe(ret => {
      if (this.router.url.indexOf("home") > 0) {
        window.location.reload();
      } else {
        window.location.href = location.origin + "/";
      }

    })
  }
}
