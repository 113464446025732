import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
}

@Component({
  selector: 'indirect-payment-redirect-dialog',
  templateUrl: './indirect-payment-dialog.component.html',
  styleUrls: ['./indirect-payment-dialog.component.scss']
})
export class IndirectPaymentDialogComponent {
  constructor(public dialogRef: MatDialogRef<IndirectPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router) { }

  close(): void {
    window.location.href = this.data['RedirectTo'];
    this.dialogRef.close();
  }
}