import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubUserProfile } from 'src/app/core/classes/SubUserClasses/sub-user';
import { UserTypeVO } from 'src/app/core/classes/User/UserType';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { MyBusinessComponent } from './my-business/my-business.component';
import { MyFamilyComponent } from './my-family/my-family.component';
import { MyHeadquarterComponent } from './my-headquarter/my-headquarter.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public Families: SubUserProfile[] = [];
  public Business: SubUserProfile[] = [];
  public Headquarters: SubUserProfile[] = [];
  @ViewChild(MyFamilyComponent, { static: false }) private myFamilyComponent: MyFamilyComponent;
  @ViewChild(MyBusinessComponent, { static: false }) private myBusinessComponent: MyBusinessComponent;
  @ViewChild(MyHeadquarterComponent, { static: false }) private myHeadComponent: MyHeadquarterComponent;
  selectedIndex: number = 0;
  profileType : string = "default";


  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private seoService: SeoService,
    private LocalStorageService: LocalStorageService

  ) {
    this.seoService.update_title('');
    this.seoService.update_description('');

   if(this.LocalStorageService.getUserType().Code == UserTypeVO.Business)
    {
       this.profileType = "business";
    }
   }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      let tab = Number(params.get('id'));
      this.location.replaceState('/profilo');
      this.switchNgBTab(tab);
    });


    this.apiService.callApiProd("/api/SubUserProfile/List", new CallParameter("POST")).subscribe(result => {
      let arr: SubUserProfile[] = result.ResultData["subUserProfileDatas"];
      arr.filter(result => result.Type == 0).forEach(z => { this.Families.push(new SubUserProfile(z)) });
      arr.filter(result => result.Type == 1).forEach(z => { this.Business.push(new SubUserProfile(z)) });
      arr.filter(result => result.Type == 2).forEach(z => { this.Headquarters.push(new SubUserProfile(z)) });
      if(this.profileType == 'default'){
        this.myFamilyComponent.setFamilyMembers(this.Families);
        this.myBusinessComponent.setBusiness(this.Business);
      }
      else if(this.profileType == 'business'){
        this.myHeadComponent.setBusiness(this.Headquarters);
      }
    })
  }

  switchNgBTab(id: number) {
    this.selectedIndex = id;
  }
}
