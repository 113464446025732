
<div class="email-sent-cntr">

    <div class="img-cntr">

        <img src="../../../../assets/images/Email/email-inviata-desktop.webp">

    </div>

    <div class="txt-cntr">

        <h1>TI ABBIAMO INVIATO UNA MAIL</h1><br>
        <img src="../../../../assets/images/Email/email-inviata-mobile.webp" class="mobile-img">
        <h3>Aprila e clicca sul link di conferma per terminare la fase di registrazione.</h3>
        <br>
        <h3 style="font-weight: unset!important;">Ricordati di controllare anche nella cartella Spam
        La mail potrebbe impiegare fino ad un giorno per arrivare.
        Se non hai ancora ricevuto la mail <a style="cursor:pointer; color: white; font-size: 20px; font-weight: bold!important;" title="Reinvia mail"
        (click)="GetNewMailConfirm()">Clicca qui</a>.</h3>

    </div>
    <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>

<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>