import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FeedbackDTO } from 'src/app/core/classes/FeedbackDTO';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-feedback-area',
  templateUrl: './feedback-area.component.html',
  styleUrls: ['./feedback-area.component.scss']
})
export class FeedbackAreaComponent implements OnInit {

  selectedImage: number;
  form: FormGroup;
  formData : any;
  dialog : MatDialogRef<any>;


  @Input() userPolicyID : number;

  constructor(private fb : FormBuilder, private apiService : ApiService,private generalService : AppGeneralService, private route :Router) {

    this.form = this.fb.group({
      Note: new FormControl(this.formData?.Note),
    });


   }

  ngOnInit() {
  }


  sendFeedback(){

      if(this.selectedImage != null && this.userPolicyID != null){

        let feedback : FeedbackDTO = new FeedbackDTO(this.userPolicyID,this.form.get('Note').value,this.selectedImage);
        this.apiService.callApiProd('/api/Feedback/InsertFeedback', new CallParameter('POST', feedback)).subscribe((result) => {

          if(result.InError){

            this.dialog =this.generalService.showDialog(null,result.Message,"ATTENZIONE!");

          }else{

            this.dialog =this.generalService.showDialog(null,"Feedback mandato correttamente, la tua opinione per noi è molto importante","GRAZIE!");
            this.navigateTo();
          }


        })
    }

    if(this.selectedImage == null){

      this.dialog =this.generalService.showDialog(null,"Seleziona un indice di gradimento","Attenzione");

    }

  }




  changeSelected(imageNumber: number) {
    if (this.selectedImage === imageNumber) {
      this.selectedImage = null; // Deseleziona l'immagine se cliccata di nuovo
    } else {
      this.selectedImage = imageNumber; // Seleziona l'immagine cliccata
    }
  }


  navigateTo(){

    this.route.navigate(['/']);

  }
}
