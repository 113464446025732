<div class="policy-group-proposal-carousel">

  <ng-container *ngIf="ProposalInfo != null && ProposalInfo.length > 0">

    <div class="carousel row">

      <carousel [cellWidth]="calculateWidth()" [height]="calculateHeight()" [margin]="50">

        <ng-container *ngFor="let scheda of ProposalInfo">
          <div class="carousel-cell">
            <app-policy-group-proposal-scheda [Title]="Title" [Scheda]="scheda" ></app-policy-group-proposal-scheda>
          </div>
        </ng-container>
      </carousel>

    </div>


  </ng-container>

</div>
