import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadFile, FileData } from 'src/app/shared/file-upload/file-upload.component';
import { FileUploader } from 'ng2-file-upload';
import { ApiService } from 'src/app/core/services/api.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'no-policies-dialog',
  template: `<h3 mat-dialog-title>Attenzione</h3>
              <div mat-dialog-content>
                <p>Per fare una denuncia sinistro &egrave; necessario prima acquistare una polizza.</p>
              </div>
              <div mat-dialog-actions style="margin-top: 15px;">
                  <button class="new-button dialogBtn"   (click)="close()">OK</button>
              </div>`,
  styles: []
})
export class NoAvailablePoliciesDialog {
  constructor(
    public dialogRef: MatDialogRef<NoAvailablePoliciesDialog>,
    private router: Router
  ) {
  }

  close(): void {
    this.dialogRef.close();
    this.router.navigate(['/']);
  }
}

@Component({
  selector: 'incident-created-dialog',
  template: `<h3 mat-dialog-title>Denuncia creata con successo</h3>
              <div mat-dialog-content>
                <span>I nostri operatori hanno preso in carica la tua richiesta, verrai contattato al pi&ugrave; presto.
                      Se hai bisogno di informazioni qui trovi i <span style="text-decoration: underline; cursor: pointer;" (click)="navigateTo()">nostri contatti</span>
                </span>
              </div>
              <div mat-dialog-actions style="margin-top: 15px;">
                  <button class="new-button dialogBtn"   (click)="close()">OK</button>
              </div>`,
  styles: []
})
export class IncidentCreatedDialog {
  constructor(
    public dialogRef: MatDialogRef<IncidentCreatedDialog>,
    private router : Router
  ) {
  }

  close(): void {
    this.router.navigate(['area-sinistro']);
    this.dialogRef.close();
  }
  navigateTo(){

    this.dialogRef.close();
    this.router.navigate(['contatti']);

  }
}

@Component({
  selector: 'incident-confirm-dialog',
  template: `<h3 mat-dialog-title>Sei sicuro di voler creare questa denuncia sinistro?</h3>
              <div mat-dialog-content>
                <p>Proseguendo non potrai pi&ugrave; modificare i campi inseriti.</p>
              </div>
              <div mat-dialog-actions style="margin-top: 15px;" class="clearfix">
                  <button class="new-button float-left"   (click)="SendIncidentRequest()">CREA</button>
                  <button class="new-button float-right"   (click)="close()">ANNULLA</button>
              </div>`,
  styles: []
})
export class IncidentConfirmDialog {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<IncidentConfirmDialog>,
    private dialog: MatDialog,
    private appGeneralService: AppGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService
  ) {
  }

  CallData;

  SendIncidentRequest() {
    this.close();
    this.appGeneralService.loadingPanel.Show();
    this.CallData = this.data;

    this.apiService.callApiProd('/api/Incident/AddIncident', new CallParameter('POST', this.CallData)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.router.navigate(['/']);
        this.dialog.open(IncidentCreatedDialog, {
          width: '500px',
          panelClass: 'custom-dialog-container'
        });
      }
    });

  }

  close(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-insurance-complaint',
  templateUrl: './insurance-complaint.component.html',
  styleUrls: ['./insurance-complaint.component.scss']
})
export class InsuranceComplaintComponent implements OnInit {

  ReportGenericParamForm: UntypedFormGroup;
  Witnesses: UntypedFormGroup;
  DamagedPeople: UntypedFormGroup;
  DescriptionForm: UntypedFormGroup;
  public uploader: FileUploader = new FileUploader({ itemAlias: 'fileuploadinput' });
  inputPlaceHolder: string = 'Carica qui i documenti aggiuntivi';
  FileArr = [];
  Policies: any;
  reportDate = (date: Date) => date <= new Date();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appGeneralService: AppGeneralService,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {

    this.ReportGenericParamForm = this.formBuilder.group({
      PolicyReference: new UntypedFormControl('', Validators.required),
      Name: new UntypedFormControl('', Validators.required),
      Surname: new UntypedFormControl('', Validators.required),
      ReportDate: new UntypedFormControl('', Validators.required),
      ReportPlace: new UntypedFormControl('', Validators.required)
    });

    this.Witnesses = this.formBuilder.group({
      Witness: new UntypedFormArray([this.createWitnessForm()])
    });

    this.DamagedPeople = this.formBuilder.group({
      Damaged: new UntypedFormArray([this.createDamagedPersonForm()])
    })

    this.DescriptionForm = this.formBuilder.group({
      CompilationPlace: new UntypedFormControl('', Validators.required),
      Description: new UntypedFormControl('', Validators.required)
    });
  }

  get ReportGenericParamFormControls() { return this.ReportGenericParamForm.controls }
  get WitnessesControls() { return this.Witnesses.controls; }
  get WitnessControls() { return this.WitnessesControls.Witness as UntypedFormArray; }

  get DamagedPeopleControls() { return this.DamagedPeople.controls; }
  get DamagedControls() { return this.DamagedPeopleControls.Damaged as UntypedFormArray; }

  get DescriptionFormControls() { return this.DescriptionForm.controls }

  createWitnessForm() {
    return this.formBuilder.group({
      Name: new UntypedFormControl(''),
      Surname: new UntypedFormControl(''),
      CF: new UntypedFormControl('')
    });
  }

  createDamagedPersonForm() {
    return this.formBuilder.group({
      Name: new UntypedFormControl(''),// lo
      Surname: new UntypedFormControl(''),// lo
      CF: new UntypedFormControl(''),// lo
      City: new UntypedFormControl('', Validators.required),
      CAP: new UntypedFormControl('', Validators.required),
      Address: new UntypedFormControl('', Validators.required)
    });
  }

  addWitness() {
    this.WitnessControls.push(this.createWitnessForm());
  }

  addDamagedPerson() {
    this.DamagedControls.push(this.createDamagedPersonForm());
  }

  CallData;
  getAvailableUserPoliciesForIncidents() {

    this.appGeneralService.loadingPanel.Show();

    this.CallData = {};

    this.apiService.callApiProd('/api/Incident/GetAvailableUserPoliciesForIncidents', new CallParameter('POST', this.CallData)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.Policies = result.ResultData['UserPolicies'];
        if (this.Policies.length <= 0) {
          this.dialog.open(NoAvailablePoliciesDialog, {
            width: '500px',
            disableClose: true,
            panelClass: 'custom-dialog-container'
          })
        }
      }
    });

  }


  fileChange(event) {
    this.FileArr = [];
    this.inputPlaceHolder = '';
    if (event.target.files && event.target.files[0]) {
      let filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        let reader = new FileReader();
        let FileToUpload = new FileData();

        FileToUpload.Name = event.target.files[i].name;
        this.inputPlaceHolder += event.target.files[i].name + ', ';
        reader.onload = (event: any) => {
          FileToUpload.FileBase64 = event.target.result.toString();
          this.FileArr.push(FileToUpload);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  submitIncident() {

    this.WitnessControls.markAllAsTouched();
    this.DamagedControls.markAllAsTouched();
    this.ReportGenericParamForm.markAllAsTouched();
    this.DescriptionForm.markAllAsTouched();


    if (this.ReportGenericParamForm.invalid || this.WitnessControls.invalid || this.DamagedControls.invalid || this.DescriptionForm.invalid) {
      return;
    }

    let WitnessesArr = [];

    for (const Witness of this.WitnessControls.controls) {
      WitnessesArr.push({
        Name: Witness.get('Name').value,
        Surname: Witness.get('Surname').value,
        CF: Witness.get('CF').value
      });
    }
    let DamagedArr = [];

    for (const Damaged of this.DamagedControls.controls) {
      DamagedArr.push({
        Name: Damaged.get('Name').value,
        Surname: Damaged.get('Surname').value,
        CF: Damaged.get('CF').value,
        City: Damaged.get('City').value,
        ZipCode: Damaged.get('CAP').value,
        Address: Damaged.get('Address').value
      });
    }

    this.dialog.open(IncidentConfirmDialog, {
      width: '500px',
      data: {
        UserPolicyID: this.ReportGenericParamForm.get('PolicyReference').value,
        IncidentPlace: this.ReportGenericParamForm.get('ReportPlace').value,
        IncidentDate: this.appGeneralService.convertDate(this.ReportGenericParamForm.get('ReportDate').value),
        Description: this.DescriptionForm.get('Description').value,
        CompilingPlace: this.DescriptionForm.get('CompilationPlace').value,
        Witnesses: WitnessesArr,
        Damageds: DamagedArr,
        Files: this.FileArr
      },
      panelClass: 'custom-dialog-container'
    });
  }

  setUserNameOnSelect(evt) {
    this.Policies.forEach(element => {
      if (element.ID == evt.value) {
        this.ReportGenericParamForm.get('Name').setValue(element.Name);
        this.ReportGenericParamForm.get('Surname').setValue(element.Surname);
      }
    });

  }

  ngOnInit() {
    this.getAvailableUserPoliciesForIncidents();
  }
}
