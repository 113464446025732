import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CtMetaModule } from "@ctsolution/ct-meta";
import { CtWebviewModule } from "@ctsolution/ct-webview";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from "angularx-social-login";
import { CookieService } from "ngx-cookie-service";
import { environment } from './../environments/environment';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { RegistrationModule } from "./auth/registration/registration.module";
import { PipeModule } from "./core/helpers/Pipe.module";
import { PricePipe } from "./core/helpers/price.pipe";
import { LayoutModule } from "./layout/layout.module";
import { PagesModule } from "./pages/pages.module";
import { FeedService } from "./shared/feed-carousel/feed.service";
import { SurveyModalError } from "./shared/module/module.component";
import { SharedModule } from "./shared/shared.module";


@NgModule({
  declarations: [AppComponent, SurveyModalError],
  imports: [
    BrowserAnimationsModule,
    MatSidenavModule,
    SharedModule,
    RegistrationModule,
    PagesModule,
    LayoutModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    PipeModule.forRoot(),
    SocialLoginModule,
    CtWebviewModule.setup({
      Android: {
        package: "com.myinsurer.it"
      },
      Apple: {
        scheme: "",
        storeLink: ""
      }
    }),
    CtMetaModule
  ],
  providers: [
    CookieService,
    FeedService,
    PricePipe,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GoogleID
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              environment.FacebookID
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule, FontAwesomeModule, SurveyModalError],
})
export class AppModule {}
