<div class="video-cntr-mobile" *ngIf="PolicyData.Policy.VideoLink">

    <div class="video">

      <iframe [width]="calculateWidth()" [height]="calculateHeight()" [src]="SecurityUrl"  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

    </div>

</div>

<div class="img-cntr-mobile" *ngIf="!PolicyData.Policy.VideoLink" [ngStyle]="{'background-image': 'url('+ PolicyData.Policy.ImageUrls[0] +')'}"></div>


<div class="single-detail-cntr">

  <div class="title-cntr">
    <h1>{{PolicyData.Policy.GroupTitle}}</h1>
  </div>


  <div class="info-cntr">

    <div class="scheda-cntr" *ngIf="PolicyData">
      <app-policy-price-boxes-scheda [Policy]="Policy" [Section]="Sections" [ShowButton]="false"></app-policy-price-boxes-scheda>
      <div class="img-cntr"  [ngStyle]="{'background-image': 'url('+ PolicyData.Policy.ImageUrls[0] +')'}"></div>
    </div>

    <div class="txt-cntr">

        <h3 [innerHTML]="PolicyData.Policy.ExtendedDescription"></h3>

        <div class="card-cntr">

            <div class="documentazione" (click)="documentCollapse()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                <h3 style="font-weight: bold!important;">Documentazione contrattuale</h3>
                <fa-icon [icon]="faChevronDownDocuments" id="DocPanelIcon"></fa-icon>
            </div>
            <div id="collapseExample" [ngbCollapse]="isCollapsed">
                <div class="document-cntr">

                        <ng-container *ngFor="let Document of PolicyData.InformativeDocuments">

                          <app-document-scheda [Document]="Document"></app-document-scheda>

                        </ng-container>
                </div>
            </div>

        </div>

        <div class="button-cntr">

          <div class="new-button" (click)="addToCart(false)" *ngIf="policyID != 49 && policyID != 50 && policyID != 370 && policyID != 372">AGGIUNGI AL CARRELLO</div>
          <div class="new-button" ngClass="{{PolicyData.Policy.Title}}" (click)="addToCart(true)">ACQUISTA SUBITO</div>

      </div>

    </div>



  </div>

  <div class="video-cntr" *ngIf="this.PolicyData.Policy.VideoLink">


    <div class="txt-cntr">

      <h1>Tutte le curiosità con Jacopo Buzzi</h1>

    </div>

    <img class="freccia-video" src="../../../../../../assets/images/Configuratore/freccia_tab_pacchetti_.svg">


    <div class="video">

      <iframe [width]="calculateWidth()" [height]="calculateHeight()" [src]="SecurityUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>


    </div>


  </div>

  <div class="button-sticky-cntr">

    <div class="new-button" (click)="addToCart(false)" *ngIf="policyID != 49 && policyID != 50 && policyID != 370 && policyID != 372">AGGIUNGI AL CARRELLO</div>
    <div class="new-button" ngClass="{{PolicyData.Policy.Title}}" (click)="addToCart(true)">ACQUISTA SUBITO</div>


</div>



</div>
<app-create-policy></app-create-policy>
<app-feed-carousel></app-feed-carousel>
<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>
