<!-- <div class="row">
  <div class="table-container">
      <table class="compare-table" *ngIf="comparePolicies && comparePolicies.Policies && comparePolicies.Sections">
          <tr class="first-row">
              <td>
              </td>
              <td class="column" *ngFor="let policy of comparePolicies.Policies">
                  <b>{{policy.Name}}</b>
              </td>
          </tr>
          <tr class="price-row">
              <td>
                  <b>Prezzo</b>
              </td>
              <td *ngFor="let policy of comparePolicies.Policies">
                  <div class="discount" *ngIf="policy.FakePrice">{{policy.FakePrice | price}}</div> <b> {{policy.Price | price}}</b>
              </td>
          </tr>

          <ng-container *ngFor="let item of comparePolicies.Sections">
              <tr class="section-row">
                  <td>
                      <b>{{item.Name}}</b>
                  </td>
                  <td *ngFor="let policyCheck of comparePolicies.Policies">

                  </td>
              </tr>
              <ng-container *ngFor="let option of item.OptionDTOs">
                      <tr class="sub-section-row">
                              <td>
                                  <div>
                                      <b>{{option.Title}}</b>
                                  </div>
                                  <div>
                                      <fa-icon [icon]="faInfoCircle" (click)="openInfoDialog(option.Description, option.Title)"></fa-icon>
                                  </div>
                              </td>
                              <td *ngFor="let policyCheck of comparePolicies.Policies">
                                  {{tdValue(option, policyCheck.Id) | price}}
                              </td>
                      </tr>
              </ng-container>
          </ng-container>

          <tr class="last-row">
              <td></td>
              <td *ngFor="let policyCheck of comparePolicies.Policies">
                  <button (click)="openPolicyDetail(policyCheck.Id)">scopri</button>
              </td>
          </tr>
      </table>
  </div>
</div> -->

<div class="policy-price-boxes-scheda-cntr" ngClass="{{PolicyClass}}">

  <div class="title-cntr">

    <h3>{{Policy.Name}}</h3>

  </div>

  <div class="price-cntr">

    <h3>DA {{Policy.Price}} €</h3>

  </div>

  <div class="garanzie-cntr">

    <ng-container *ngFor="let section of Section">

      <h3>{{section.Name}}</h3>

      <ng-container *ngFor="let garanzia of section.OptionDTOs">

        <div class="garanzia">
          <div class="txt-cntr">
            <img style="cursor: pointer;" src="../../../../../../assets/images/Configuratore/i_info.svg" (click)="openInfoDialog(garanzia.Description, garanzia.Title)">
            <h3>{{garanzia.Title}}</h3>
          </div>

            <div class="bold">{{tdValue(garanzia, Policy.Id) | price}}</div>
        </div>

      </ng-container>

    </ng-container>

  </div>


  <div class="button-cntr" *ngIf="ShowButton">

    <button class="new-button" (click)="openPolicyDetail(Policy.Id)">SCOPRI</button>

  </div>



</div>
