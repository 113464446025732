<ng-template #InfoDocuments>
  <app-info-document [data]="Service.InformativeUrls" [isService]="true" [UserServiceID]="Service.UserServiceID" (onDocumentRead)="getService()"></app-info-document>
</ng-template>

<ng-template #UploadDocuments>
  <h2>Carica i documenti</h2>
  <p>Per andare allo step successivo carica la documentazione richiesta</p>
  <ng-container *ngFor="let Doc of Service.ToBeUploadedFiles; index as i">
    <input type="file" [id]="i" (change)="handleDocumentsUpload(Doc, $event)" style="display: none;" />
    <div class="docTitle" [ngClass]="{'alreadyUploaded': Doc.UserUploaded}"
      (click)="Doc.UserUploaded ? openDeleteModal(UserServiceId, Doc.ID) : openInput(i)">
      <strong>{{Doc.OriginalFileName}}</strong>
    </div>
    <div class="docLink" (click)="Doc.UserUploaded ? openDeleteModal(UserServiceId, Doc.ID) : openInput(i)">
      <a>
        <fa-icon [icon]="Doc.UserUploaded ? faTimes : faUpload" [size]="'2x'"></fa-icon>
      </a>
    </div>
  </ng-container>
</ng-template>

<ng-template #Payment>
  <app-payment [ProductId]="Service.UserServiceID" [Product]="Service" [Type]="ProductType.Service" [User]="User">
  </app-payment>
</ng-template>


<h1>DETTAGLIO ACQUISTO</h1>
<ng-container *ngIf="Service">
  <div class="ServiceDetailCntr">
    <div class="row">

      <div class="leftCntr col-lg-4 col-md-12 col-md-12 float-left text-center">

        <div class="imageCntr"*ngIf="Service.ImageUrls" [ngStyle]="{'background-image': 'url('+ Service.ImageUrls[0] +')'}">
          <div class="priceCntr">
            <div class="cxPart"><div class="discount">{{Service.FakePrice | price}}</div>{{Service.Price | price}}</div>
            <div class="dxPart"></div>
        </div>
        </div>

        <div class="PriceBoxCntr mt-4">
          <h2 class="title m-auto" (click)="documentCollapse()" [attr.aria-expanded]="!isCollapsed" aria-controls="collapsibleGrid">
              <strong>Descrizione <fa-icon class="fa-icon-cntr" [icon]="faChevronDownDocuments"></fa-icon></strong>
          </h2>
          <div id="collapsibleGrid" [ngbCollapse]="isCollapsed">
            <p class="lg-extended-descr" [innerHTML]="Service.ExtendedDescription"></p>
          </div>

      </div>
      </div>
      <div class="rightCntr col-lg-8 col-md-12 col-md-12 float-left">
        <h1>
          {{Service.Name}}
        </h1>
        <p [innerHTML]="Service.Description"></p>
        <ng-container *ngIf="User && Service">
          <app-stepper [Steps]="Stepper" [isHorizontal]="isHorizontal"></app-stepper>
        </ng-container>

      </div>

    </div>


  </div>
</ng-container>
