<div class="all-policies-cntr">
<div class="all-policy-header">
    <h1 class="duplicate" title="LE NOSTRE ASSICURAZIONI"></h1>
    <div class="search-all-policy-container">
        <app-search></app-search>
    </div>
</div>

<div class="filter-container">
    <div class="filter-all-policy">
        <carousel [cellWidth]="130" [height]="180" [arrows]="false" [cellsToShow]="cellsToShow()">
            <div class="carousel-cell" *ngFor="let Item of FeedFilters" (click)="filterPolicy(Item)">
                <div [ngClass]="Item.IsSelected ? 'carousel-cell-content selected' : 'carousel-cell-content'">
                    <img src="{{Item.ImageUrl}}" alt="{{Item.Label}}" title="{{Item.Label}}" width="100">
                    <span>{{Item.Label}}</span>
                </div>
            </div>
        </carousel>
    </div>
</div>


<ng-container *ngIf="Feeds.length > 0 && selectedFilter != allpolicyFilter">

    <app-home-policies-feed [Feeds]="Feeds" [label]="getSelectedFilter().Label"></app-home-policies-feed>

</ng-container>

<ng-container *ngIf="!fno && Policies.length > 0 && selectedFilter == allpolicyFilter">

    <div class="all-policy-section" *ngFor="let PolicyGroup of GroupedPolicies; let i = index">

        <ng-container *ngIf="i == 0">
            <app-home-policies-feed [Feeds]="PolicyGroup" [label]="'Tutte Le Polizze'"></app-home-policies-feed>
        </ng-container>

        <ng-container *ngIf="i > 0">
            <div class="feed-cntr">
                <div class="txt-cntr">
                </div>
                <div class="carousel-cntr">
                    <app-general-carousel [ItemList]="PolicyGroup" [CardType]="cardType"></app-general-carousel>
                </div>
            </div>
        </ng-container>
    </div>

</ng-container>


<div class="row">
    <div class="col-12 col-sm col-md col-lg" *ngIf="Policies.length <= 0 && isWithoutFeed">
        La tua ricerca non ha prodotto risultati
    </div>
</div>
</div>
<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>
