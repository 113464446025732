import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import CodiceFiscale from 'codice-fiscale-js';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SplitRegistrationService } from '../../registration.service';

export const passwordMatchValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  if (formGroup.get('Password').value === formGroup.get('PasswordConfirm').value)
    return null;
  else
    return { passwordMismatch: true };
}

@Component({
  selector: 'app-lite-registration',
  templateUrl: './lite-registration.component.html',
  styleUrls: ['./lite-registration.component.scss'],
  providers: [

    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class LiteRegistrationComponent implements OnInit {

  formRegistration: FormGroup;
  formRegistrationStep2: FormGroup;
  @ViewChild('CodiceFiscale', { static: false }) CodiceFiscaleRef;
  LiteRegistration = true;
  CallBackUrl;
  
  constructor(
    public appGeneralService: AppGeneralService,
    public environmentService: EnvironmentService,
    public registrationService: SplitRegistrationService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private storageService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { 

    let cfValidation = Validators.pattern('^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$');
    this.formRegistration = this.formBuilder.group({
      Name: new UntypedFormControl('', Validators.required),
      Surname: new UntypedFormControl('', Validators.required),
      Phone: new UntypedFormControl('', Validators.required),
      CF: new UntypedFormControl('', [Validators.required, cfValidation]),
      Email: new UntypedFormControl('', [Validators.required, Validators.email]),
      Password: new UntypedFormControl('', [Validators.required, Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')]),
      PasswordConfirm: new UntypedFormControl('', [Validators.required, Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$')]),
    }, { validator: passwordMatchValidator });


    this.formRegistrationStep2 = this.formBuilder.group({
      CheckBox1: new UntypedFormControl(''),
      CheckBox2: new UntypedFormControl(''),
      CheckBox3: new UntypedFormControl(''),
      GeneralPrivacyCheck: new UntypedFormControl('', Validators.required),
    });

  }

  ngOnInit() {
    
    this.activatedRoute.queryParams.subscribe(param => {
        this.CallBackUrl = param["callbackUrl"];
    });

    this.activatedRoute.queryParams.subscribe(params => {


      if (params["convention"] != null || params["convention"] != undefined) {

        this.storageService.setRegistrationConvention(params["convention"]);

      }

      if (params["pin"] != null || params["pin"] != undefined) {

        this.apiService.callApiProd("/api/Registration/getStep1FromPin", new CallParameter("POST", { Pin: params["pin"], Convention: Number(params["convention"]) })).subscribe(ret => {

          if (ret.InError) {
            this.appGeneralService.showDialog(ret.ErrorCode);
            return;
          }

          this.registrationService.setupForm(ret, this.formRegistration);
        })

      } else {
        this.apiService.callApiProd("/api/Registration/getStep1", new CallParameter("POST")).subscribe(ret => {

          if (ret.InError) {
            this.appGeneralService.showDialog(ret.ErrorCode);
            return;
          }

          this.registrationService.setupForm(ret, this.formRegistration);
        })

      }

      if (params["socialLoginName"] != null || params["socialLoginName"] != undefined) {
        this.formRegistration.controls["Name"].setValue(params["socialLoginName"]);
      }

      if (params["socialLoginSurname"] != null || params["socialLoginSurname"] != undefined) {
        this.formRegistration.controls["Surname"].setValue(params["socialLoginSurname"]);
      }

      if (params["socialLoginMail"] != null || params["socialLoginMail"] != undefined) {
        this.formRegistration.controls["Email"].setValue(params["socialLoginMail"]);
        this.formRegistration.controls["Email"].disable();
      }

      return;
    }); 
  }
  

  onRegistration() {
    if (this.formRegistration.invalid || this.formRegistrationStep2.invalid) {
      return;
    }

    var CallData = {
      Email: this.formRegistration.get('Email').value,
      Password: this.formRegistration.get('Password').value,
      CF: this.formRegistration.get('CF').value,
      Phone: this.formRegistration.get('Phone').value.toString(),
      Name: this.formRegistration.get('Name').value,
      Surname: this.formRegistration.get('Surname').value,
      CheckBox1: this.formRegistrationStep2.get('CheckBox1').value == "" ? false : true,
      CheckBox2: this.formRegistrationStep2.get('CheckBox2').value == "" ? false : true,
      CheckBox3: this.formRegistrationStep2.get('CheckBox3').value == "" ? false : true,
      GeneralPrivacyCheck: this.formRegistrationStep2.get('GeneralPrivacyCheck').value == "" ? false : true,
      City: '',
      Province: '',
      Address: '',
      BuildingNumber: '',
      Sex: 0,
      BirthDate: this.appGeneralService.convertDate(new Date()),
      BirthState: '',
      BirthCity: '',
      BirthProvince: '',
      SocialUser: this.registrationService.SocialUser,
      LiteRegistration: true,
      UserTypeVO: this.storageService.getUserType().Code
    };

    let newCF: CodiceFiscale;
    var reversedCF;

    try {
      newCF = new CodiceFiscale(this.formRegistration.get('CF').value);
    } catch (error) {
      this.formRegistration.get('CF').setErrors([{ 'pattern': true }, { 'required': true }]);
    }

    if(newCF){
      reversedCF = newCF.reverse();
      CallData.BirthCity = reversedCF.birthplace;
      CallData.BirthProvince = reversedCF.birthplaceProvincia;
      CallData.BirthDate = this.appGeneralService.convertDate(reversedCF.birthday);
      CallData.Sex = this.registrationService.getGenderEnum(reversedCF.gender);
    }

    this.apiService.callApiProd('/api/Registration/PutStep1', new CallParameter('POST', CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.appGeneralService.showDialog(null, 'Registazione completata', 'Complimenti');
        if(this.CallBackUrl){

          this.router.navigateByUrl(this.CallBackUrl);
        }else{

          this.router.navigateByUrl(this.storageService.getLastPageSeen());
        }
      }

    });
  }


}
