import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { JsonLoaderService } from 'src/app/core/services/json-loader.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SeoService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-convention-detail',
  templateUrl: './convention-detail.component.html',
  styleUrls: ['./convention-detail.component.scss']
})
export class ConventionDetailComponent implements OnInit {

  ConventionResult;
  ConventionListResult;
  ConventionIDUrl: string;
  ConventionID: number;

  constructor(
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private storageService: LocalStorageService,
    private seoService: SeoService,
    private jsonLoaderService: JsonLoaderService
  ) {

    this.activatedRoute.queryParams.subscribe((params) => {

      if (params["ConventionID"]) {
        this.ConventionID = +params["ConventionID"];
      }

    });

    this.getSEOData();

  }

  ngOnInit() {
    this.getConvention(this.ConventionID);
    this.getConventions();
  }

  getConvention(id: number) {
    const conventionParams = new Convention(id);
    this.apiService.callApiProd('/api/Profile/GetConvention', new CallParameter('POST', conventionParams)).subscribe((result) => {


      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.ConventionResult = result['ResultData']['Convention'];
        this.seoService.update_image(this.ConventionResult.Image);
      }

    });
  }

  getConventions() {
    this.apiService.callApiProd('/api/Profile/ListAvailableConventions', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.ConventionListResult = result['ResultData'];
      }

    });
  }

  getSEOData() {
    // carico il json con i dati per la SEO e imposto Title e Description
    this.jsonLoaderService
      .getJsonData("../../../../assets/conventions-seo.json")
      .subscribe((r) => {
        let ConventionsList: any = r;

        let currentConvention = ConventionsList.find(
          (elm) => elm.ID === this.ConventionID
        );

        if (currentConvention) {
          this.seoService.update_title(currentConvention.title);
          this.seoService.update_description(currentConvention.description);
        }
      });
  }

  Registrazione() {
    this.storageService.setRegistrationConvention(this.ConventionID.toString());
    this.router.navigate(["registrazione"]);
  }

  Login() {
    this.storageService.setLoginConvention(this.ConventionID.toString());
    this.router.navigate(["login"]);
  }
}


export class Convention {
  id: number;
  constructor(
    id: number
  ) {
    this.id = id;
  }
}
