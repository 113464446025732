import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { faFileDownload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'ng2-file-upload';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { UserPolicyStateEnum } from 'src/app/core/enum/policy-state.enum';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { FileData } from 'src/app/shared/file-upload/file-upload.component';
import { PolicyInfo, formCanceledInfo } from '../canceled-policyForm/canceled-policyForm.component';

@Component({
  selector: 'app-canceled-policyCanceled',
  templateUrl: './canceled-policyCanceled.component.html',
  styleUrls: ['./canceled-policyCanceled.component.scss']
})
export class CanceledPolicyCanceledComponent implements OnInit {

  form: FormGroup;
  dialog : any;
  formData : any;
  faDoc = faFileDownload;
  faChevron = faChevronDown;
  FileArr : Array<any> = [];
  UserPoliciesListResult : Array<PolicyInfo> =[];
  UserPoliciesListResultSorted : Array<PolicyInfo> =[];
  inputPlaceHolder: string = 'Carica qui uno o più file';
  policyState = "";

  public uploaderCanceled: FileUploader = new FileUploader({ itemAlias: 'fileuploadinputcanceled' });

  constructor(public appGeneralService : AppGeneralService, private apiService : ApiService, private fb : FormBuilder) {

    this.getUserPoliciesList();
    this.form = this.fb.group({
      Name: new FormControl(this.formData?.Name, [Validators.required]),
      Surname: new FormControl(this.formData?.Surname, [Validators.required]),
      Reason: new FormControl(this.formData?.Reason),

    });


  }

  ngOnInit() {



  }





  dropdown(policy : any){

    policy.IsCollapsed = !policy.IsCollapsed;

  }



   getUserPoliciesList() {


    this.apiService.callApiProd('/api/Cancellation/GetUserPoliciesList', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.UserPoliciesListResult = result['ResultData'] as Array<PolicyInfo>;

        this.UserPoliciesListResult.forEach(element => {
          element.IsCollapsed = false;
        });
        const orderedIDList = [9,10];
        this.UserPoliciesListResultSorted = [];
        orderedIDList.forEach(element => {
          this.UserPoliciesListResultSorted = this.UserPoliciesListResultSorted.concat(this.UserPoliciesListResult.filter(x => x.State == element));
        })

        };

    });
  }
}
