<div class="nostra-azienda-cntr">

  <div class="marchi-cntr">

    <h1>La nostra Azienda</h1>
    <app-marchi [img]="marchi[0].img" [desc]="marchi[0].desc"></app-marchi> <br><br>
    <h1>I nostri marchi</h1>
    <app-marchi style="margin-bottom: 60px;" [img]="marchi[1].img" [desc]="marchi[1].desc"></app-marchi>
    <app-marchi style="margin-bottom: 60px;" [img]="marchi[2].img" [desc]="marchi[2].desc"></app-marchi>
    <app-marchi [img]="marchi[3].img" [desc]="marchi[3].desc"></app-marchi>


  </div>

  <div class="dati-marchi-cntr">

    <ng-container *ngFor="let dati of datiMarchi">
      <div class="dato-marchi">
        <app-dati-marchi [tit]="dati.tit" [num]="dati.num"></app-dati-marchi>
      </div>
  </ng-container>

  </div>


</div>
