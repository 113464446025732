import { Component, Input, OnInit } from '@angular/core';
import { FeedCarousel } from 'src/app/core/classes/Feed/feed-carousel';
import { FeedTypeArray } from '../home/home.component';
import { ApiService } from 'src/app/core/services/api.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { FeedInfo } from 'src/app/core/classes/Feed/feed-info';
import { ActivatedRoute } from '@angular/router';

declare var require: any;


@Component({
  selector: 'app-home-policies',
  templateUrl: './home-policies.component.html',
  styleUrls: ['./home-policies.component.scss']
})
export class HomePoliciesComponent implements OnInit {

  Data: JsonPoliciesHome;
  PolicyHome : PolicyHomeDTO;
  FeedTypes = [];
  Feeds: FeedInfo[] = [];
  FeedCarousels: FeedCarousel[] = [];
  keyValue;
  feedID: number;
  subTitleSelected : string;

  constructor(private apiService : ApiService, private appGeneralService : AppGeneralService,private route: ActivatedRoute) {
     this.keyValue = this.route.snapshot.data;
     this.feedID = Number(this.route.snapshot.paramMap.get("id"));
  }



  ngOnInit() {
    this.setData();
    this.getFeedHome();

    setTimeout(() => {
      const element = document.getElementById("destination");
      if (element) element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }, 2000);
  }

  async getFeedHome() {
    this.FeedTypes = this.PolicyHome.carouselButtons.find(d=> d.Clicked == true).feed;
    const FeedTypesSent = new FeedTypeArray(this.FeedTypes);


   await this.apiService.callApiProd('/api/Feed/GetPolicyFeed', new CallParameter('POST', FeedTypesSent)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.Feeds = result['ResultData']['Feed'] as FeedInfo[];
        this.Feeds.sort((a, b) => { return a.Id - b.Id });
        this.Feeds.forEach(feed => {
          this.FeedCarousels.push(new FeedCarousel(feed));
        });
      }
    });
  }

  setData(){
    this.Data = require('../../../assets/json/policies-home.json');
    this.PolicyHome = this.Data.policies.find(d => d.id === this.keyValue.key).data;
    this.PolicyHome.carouselButtons.forEach(d => d.Clicked = false);
    this.PolicyHome.carouselButtons.find(f => f.indexFeed == this.feedID).Clicked = true;
    this.subTitleSelected = this.PolicyHome.carouselButtons.find(f => f.indexFeed == this.feedID).subTitle;
    this.PolicyHome.carouselButtons.sort((a, b) => {
      if (a.Clicked === b.Clicked) {
        return 0;
      }
      if (a.Clicked) {
        return -1;
      }
      return 1;
    });

  }

}

export class JsonPoliciesHome{

  policies : Array<PoliciesHomeDTO>;

}

export class PoliciesHomeDTO{

  id: string;
  data : PolicyHomeDTO;

}
export class PolicyHomeDTO  {

  title : string;
  description : string;
  imgUrl : string;
  iconUrl: string;
  carouselButtons: HeaderBackgroundFilter[]

}
export class HeaderBackgroundFilter{

  title : string;
  imgUrl : string;
  feed : Array<number>;
  url: string;
  indexFeed: number;
  Clicked: boolean;
  subTitle: string;
  positionCarousel: number;



}

