<div class="singleFeedCntr" (click)="goOut(SingleReadmore.Link)">

  <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ SingleReadmore.CoverImage +')'}">
  </div>

  <div class="textCntr">
      <h3 style="font-weight: bold!important;">Lo sapevi che?</h3> <br>
      <h3> {{SingleReadmore.Title}}</h3>
  </div>
  <div class="linkCntr">
    <div class="cxPart">
    <img src="../../../../../assets/images/Home/Generic/freccia.svg">
    </div>
</div>
</div>
