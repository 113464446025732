import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { SharedModule } from '../shared/shared.module';
// MATERIAL
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPasswordStrengthModule } from 'src/app/shared/mat-password-strenght-module/mat-password-strength.module';
import { GuideDialogComponent } from './home/guide-dialog/guide-dialog.component';
import { HomeComponent } from './home/home.component';
import { HomeSliderBoxesComponent } from './home/image-slider/home-slider-boxes/home-slider-boxes.component';
import { ImageSliderComponent } from './home/image-slider/image-slider.component';
import { ReadmoreCarouselComponent } from './home/readmore-carousel/readmore-carousel.component';
import { SingleReadmoreComponent } from './home/readmore-carousel/single-readmore/single-readmore.component';
import { ServiceCarouselComponent } from './home/service-carousel/service-carousel.component';
import { SingleServiceCarouselComponent } from './home/service-carousel/single-service-carousel/single-service-carousel.component';

import { ConventionCarouselComponent } from './conventions/convention-carousel/convention-carousel.component';
import { InfoBlocksCarouselComponent } from './conventions/info-blocks-carousel/info-blocks-carousel.component';

import { ConventionDetailComponent } from './conventions/convention-carousel/convention-detail/convention-detail.component';
import { SingleConventionComponent } from './conventions/convention-carousel/single-convention/single-convention.component';
import { ConventionsComponent } from './conventions/conventions.component';

import { ConfiguratorComponent } from './configurator/configurator.component';

import { PoliciesComponent } from './policies/policies.component';
import { PolicyGroupComponent } from './policies/policy-group/policy-group.component';
import { PolicyPriceBoxesComponent } from './policies/policy-group/policy-price-boxes/policy-price-boxes.component';
import { SinglePackageDetailPageComponent } from './policies/policy-group/policy-price-boxes/single-package-detail-page/single-package-detail-page.component';

import { ServiceDetailComponent } from './services/service-detail/service-detail.component';
import { ServicesComponent } from './services/services.component';

import { EditDataComponent } from './profile/edit-data/edit-data.component';
import { EditJobprofileComponent } from './profile/edit-jobprofile/edit-jobprofile.component';
import { EditPasswordComponent } from './profile/edit-password/edit-password.component';
import { InfoComponent } from './profile/info/info.component';
import { InsuranceAreaComponent } from './profile/insurance-area/insurance-area.component';
import { IncidentConfirmDialog, IncidentCreatedDialog, InsuranceComplaintComponent, NoAvailablePoliciesDialog } from './profile/insurance-area/insurance-complaint/insurance-complaint.component';
import { InsuranceMemoComponent } from './profile/insurance-area/insurance-memo/insurance-memo.component';
import { MailConfirmComponent } from './profile/mail-confirm/mail-confirm.component';
import { MyPoliciesComponent } from './profile/my-products/my-policies/my-policies.component';
import { MyProductsComponent } from './profile/my-products/my-products.component';
import { MyServicesComponent } from './profile/my-products/my-services/my-services.component';
import { ProfileComponent } from './profile/profile.component';

import { BuyPolicyComponent } from './cart/buy-policy/buy-policy.component';
import { CartItemComponent } from './cart/cart-item/cart-item.component';
import { CartComponent } from './cart/cart.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { ContractsConditionComponent } from '../auth/contracts-condition/contracts-condition.component';
import { LoginModule } from '../auth/login/login.module';
import { CompleteRegistrationComponent } from '../auth/registration/complete-registration/complete-registration.component';
import { RegistrationConfirmComponent } from '../auth/registration/registration-confirm/registration-confirm.component';
import { PipeModule } from '../core/helpers/Pipe.module';
import { FeedCarouselModule } from '../shared/feed-carousel/feed-carousel.module';
import { GeneralCardModule } from '../shared/general-card/general-card.module';
import { GeneralCarouselModule } from '../shared/general-carousel/general-carousel.module';
import { NewsletterMailchimpModule } from '../shared/newsletter-mailchimp/newsletter-mailchimp.module';
import { PaypalButtonModule } from '../shared/PayPal/paypal-button/paypal-button.module';
import { StepperModule } from '../shared/stepper/stepper.module';
import { AdhesionComponent } from './cart/buy-policy/adhesion/adhesion.component';
import { FileDeleteComponent } from './cart/buy-policy/file-delete/file-delete.component';
import { NotAvailablePolicyDialogComponent } from './cart/buy-policy/not-available-policy-dialog/not-available-policy-dialog.component';
import { BuyServiceComponent } from './cart/buy-service/buy-service.component';
import { InfoDocumentComponent } from './cart/info-document/info-document.component';
import { IndirectPaymentRedirectDialogComponent } from './cart/payment/indirect-payment-redirect-dialog/indirect-payment-redirect-dialog.component';
import { PaymentComponent } from './cart/payment/payment.component';
import { SuccessfullPaymentDialogComponent } from './cart/payment/successfull-payment-dialog/successfull-payment-dialog.component';
import { AboutUsComponent } from './chi-siamo/chi-siamo/about-us/about-us/about-us.component';
import { ChiSiamoComponent } from './chi-siamo/chi-siamo/chi-siamo.component';
import { MissionComponent } from './chi-siamo/chi-siamo/mission/mission/mission.component';
import { DatiMarchiComponent } from './chi-siamo/chi-siamo/nostra-azienda/nostra-azienda/dati-marchi/dati-marchi.component';
import { MarchiComponent } from './chi-siamo/chi-siamo/nostra-azienda/nostra-azienda/marchi/marchi/marchi.component';
import { NostraAziendaComponent } from './chi-siamo/chi-siamo/nostra-azienda/nostra-azienda/nostra-azienda.component';
import { CarouselHowWorkComponent } from './configurator/carousel-how-work/carousel-how-work.component';
import { SchedaHowWorkComponent } from './configurator/carousel-how-work/scheda-how-work/scheda-how-work.component';
import { ConfiguratorHeaderComponent } from './configurator/configurator-header/configurator-header.component';
import { ConfiguratorSchedaPacchettoComponent } from './configurator/configurator-scheda-pacchetto/configurator-scheda-pacchetto.component';
import { ConfiguratorSchedaPolizzaComponent } from './configurator/configurator-scheda-polizza/configurator-scheda-polizza.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HeaderContactComponent } from './contacts/header-contact/header-contact.component';
import { MainContactComponent } from './contacts/main-contact/main-contact.component';
import { CookieComponent } from './cookie/cookie.component';
import { FaqComponent } from './faq/faq.component';
import { HeaderBackgroundCarouselComponent } from './home-policies/header-background/header-background-carousel/header-background-carousel.component';
import { HeaderBackgroundSchedaComponent } from './home-policies/header-background/header-background-carousel/header-background-scheda/header-background-scheda.component';
import { HeaderBackgroundComponent } from './home-policies/header-background/header-background.component';
import { HomePoliciesFeedComponent } from './home-policies/home-policies-feed/home-policies-feed.component';
import { HomePoliciesComponent } from './home-policies/home-policies.component';
import { CreatePolicyComponent } from './home/create-policy/create-policy.component';
import { FeedbackStoryComponent } from './home/feedback-story/feedback-story/feedback-story.component';
import { SigleStoryComponent } from './home/feedback-story/feedback-story/sigle-story/sigle-story/sigle-story.component';
import { FiveQualityComponent } from './home/five-quality/five-quality/five-quality.component';
import { StoreHomeComponent } from './home/store-home/store-home/store-home.component';
import { TreedomComponent } from './home/treedom/treedom/treedom.component';
import { NexiRetryPaymentComponent } from './nexi-retry-payment/nexi-retry-payment.component';
import { PaymentConfirmedComponent } from './payment-confirmed/payment-confirmed.component';
import { PolicyGroupProposalCarouselComponent } from './policies/policy-group/policy-group-proposal-carousel/policy-group-proposal-carousel.component';
import { PolicyGroupProposalSchedaComponent } from './policies/policy-group/policy-group-proposal-carousel/policy-group-proposal-scheda/policy-group-proposal-scheda.component';
import { OptionInfoDialogComponent } from './policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';
import { PolicyPriceBoxesSchedaComponent } from './policies/policy-group/policy-price-boxes/policy-price-boxes-scheda/policy-price-boxes-scheda.component';
import { DocumentSchedaComponent } from './policies/policy-group/policy-price-boxes/single-package-detail-page/document-scheda/document-scheda.component';
import { CanceledAreaComponent } from './profile/canceled-area/canceled-area/canceled-area.component';
import { CanceledPolicyCanceledComponent } from './profile/canceled-area/canceled-area/canceled-policyCanceled/canceled-policyCanceled.component';
import { CanceledPolicyFormComponent } from './profile/canceled-area/canceled-area/canceled-policyForm/canceled-policyForm.component';
import { DeleteAccountComponent } from './profile/delete-account/delete-account.component';
import { EditDataBusinessComponent } from './profile/edit-data-business/edit-data-business.component';
import { MyBusinessComponent } from './profile/my-business/my-business.component';
import { MyFamilyComponent } from './profile/my-family/my-family.component';
import { MyHeadquarterComponent } from './profile/my-headquarter/my-headquarter.component';
import { CancelPolicyDialogComponent } from './profile/my-products/my-policies/cancel-policy-dialog/cancel-policy-dialog.component';
import { DeletePolicyDialogComponent } from './profile/my-products/my-policies/delete-policy-dialog/delete-policy-dialog.component';
import { IndirectPaymentDialogComponent } from './profile/my-products/my-policies/indirect-payment-dialog/indirect-payment-dialog.component';
import { SelectProfilePolicyUpgradeDialogComponent } from './profile/my-products/my-policies/select-profile-policy-upgrade-dialog/select-profile-policy-upgrade-dialog.component';
import { UpgradePolicyDialogComponent } from './profile/my-products/my-policies/upgrade-policy-dialog/upgrade-policy-dialog.component';
import { RecessServiceDialogComponent } from './profile/my-products/my-services/recess-service-dialog/recess-service-dialog.component';
import { PersonalProfileAccordionComponent } from './profile/personal-profile-accordion/personal-profile-accordion.component';
import { ShoppingCenterAgencyDetailComponent } from './shoppingCenter/shopping-center-agency-detail/shopping-center-agency-detail.component';
import { SpidRedirectComponent } from './spid-redirect/spid-redirect.component';
import { TreedomPageComponent } from './treedom-page/treedom-page.component';
@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    RouterModule,
    IvyCarouselModule,
    MatDialogModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPasswordStrengthModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    PipeModule,
    MatTabsModule,
    MatStepperModule,
    LoginModule,
    GeneralCarouselModule,
    GeneralCardModule,
    FeedCarouselModule,
    NewsletterMailchimpModule,
    StepperModule,
    PaypalButtonModule
  ],
  declarations: [
    HomeComponent,
    ImageSliderComponent,
    HomeSliderBoxesComponent,
    ServiceCarouselComponent,
    SingleServiceCarouselComponent,
    ReadmoreCarouselComponent,
    SingleReadmoreComponent,
    GuideDialogComponent,
    ConventionsComponent,
    ConventionCarouselComponent,
    InfoBlocksCarouselComponent,
    ConventionDetailComponent,
    ConfiguratorComponent,
    SingleConventionComponent,
    PoliciesComponent,
    PolicyGroupComponent,
    PolicyPriceBoxesComponent,
    SinglePackageDetailPageComponent,
    ServicesComponent,
    ServiceDetailComponent,
    ContactsComponent,
    ProfileComponent,
    EditDataComponent,
    EditDataBusinessComponent,
    EditPasswordComponent,
    MailConfirmComponent,
    EditJobprofileComponent,
    FaqComponent,
    MyHeadquarterComponent,
    MyProductsComponent,
    MyPoliciesComponent,
    MyServicesComponent,
    InsuranceAreaComponent,
    CanceledAreaComponent,
    CanceledPolicyFormComponent,
    CanceledPolicyCanceledComponent,
    InsuranceComplaintComponent,
    IncidentCreatedDialog, IncidentConfirmDialog, NoAvailablePoliciesDialog,
    InsuranceMemoComponent,
    InfoComponent,
    CartComponent,
    CartItemComponent,
    BuyPolicyComponent,
    SelectProfilePolicyUpgradeDialogComponent,
    UpgradePolicyDialogComponent,
    CancelPolicyDialogComponent,
    DeletePolicyDialogComponent,
    RegistrationConfirmComponent,
    AdhesionComponent,
    SuccessfullPaymentDialogComponent,
    IndirectPaymentRedirectDialogComponent,
    NotAvailablePolicyDialogComponent,
    FileDeleteComponent,
    ContractsConditionComponent,
    RecessServiceDialogComponent,
    CompleteRegistrationComponent,
    OptionInfoDialogComponent,
    PersonalProfileAccordionComponent,
    MyFamilyComponent,
    MyBusinessComponent,
    CookieComponent,
    PaymentConfirmedComponent,
    IndirectPaymentDialogComponent,
    BuyServiceComponent,
    SpidRedirectComponent,
    PaymentComponent,
    InfoDocumentComponent,
    DeleteAccountComponent,
    ShoppingCenterAgencyDetailComponent,
    CreatePolicyComponent,
    StoreHomeComponent,
    FiveQualityComponent,
    TreedomComponent,
    FeedbackStoryComponent,
    SigleStoryComponent,
    AboutUsComponent,
    ChiSiamoComponent,
    NostraAziendaComponent,
    MarchiComponent,
    DatiMarchiComponent,
    MissionComponent,
    HeaderContactComponent,
    MainContactComponent,
    ConfiguratorHeaderComponent,
    CarouselHowWorkComponent,
    SchedaHowWorkComponent,
    ConfiguratorSchedaPolizzaComponent,
    ConfiguratorSchedaPacchettoComponent,
    HomePoliciesComponent,
    HeaderBackgroundComponent,
    HeaderBackgroundCarouselComponent,
    HeaderBackgroundSchedaComponent,
    HomePoliciesFeedComponent,
    PolicyGroupProposalCarouselComponent,
    PolicyGroupProposalSchedaComponent,
    PolicyPriceBoxesSchedaComponent,
    DocumentSchedaComponent,
    TreedomPageComponent,
    NexiRetryPaymentComponent

  ],
})
export class PagesModule { }
