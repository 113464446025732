<div class="configuratore-scheda-pacchetto-cntr" ngClass="{{SingleFeed.Title}}"  >


  <div class="txt-cntr">

    <h2 [innerHTML]="[SingleFeed.TileTitle ?? SingleFeed.Title]"></h2>
    <!-- <h3 style="font-weight: unset!important;" [innerHTML]="[SingleFeed.ExtendedDescription]"></h3> -->
  </div>

  <div class="divisor"></div>

  <div class="button-cntr">

    <button class="new-button" (click)="cardAction()">CONFIGURA</button>

  </div>

</div>
