import { ServiceFileInfo } from "./service-file-info";
export class ServiceInfo {
    ID: number;
    Name: string;
    Description: string;
    ExtendedDescription: string;
    Price: number;
    FakePrice: number | null;
    Duration: number | null;
    ExpireDate: string | null;
    AutomaticRenewal: boolean;
    LightRegistration: boolean;
    PIN: string;
    State: number;
    ImageUrls: string[];
    InformativeUrls: ServiceFileInfo[];
    UserServiceID: number;
    ToBeUploadedFiles: ServiceFileInfo[];
    UploadedFiles: string[];
}

export class ServiceFileParam{

  serviceFileIds :Array<number> = [];
  userServiceId : Number;

  constructor(serviceFileIds: Array<number>,userServiceID : Number){

    this.serviceFileIds = serviceFileIds;
    this.userServiceId = userServiceID;

  }
}
