import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-carousel',
  templateUrl: './service-carousel.component.html',
  styleUrls: ['./service-carousel.component.scss', '../home.component.scss']
})
export class ServiceCarouselComponent implements OnInit {

  constructor() { }

  @Input() ServiceCarousel;

  ngOnInit() {
  }

}
