import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-confirm',
  templateUrl: './mail-confirm.component.html',
  styleUrls: ['./mail-confirm.component.scss']
})
export class MailConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  logout(){
    sessionStorage.clear();
  }

}
