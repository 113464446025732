
<div class="delete-account-cntr">


  <h3>La cancellazione dell'account non comporta la cancellazione dei dati della polizza.</h3>



<div class="col-12">
  <div>

  </div>
  <div class="buttonArea">
      <button class="new-button ATTENZIONE float-right"   (click)="deleteAccount()">Cancella account</button>
  </div>

</div>
</div>
