import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractsConditionService } from 'src/app/auth/contracts-condition/contracts-condition.service';
import { authType } from 'src/app/core/enum/authType.enum';
import { RegistrationStep } from 'src/app/core/enum/registration-step.enum';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { RegistrationStepService } from 'src/app/core/services/registration.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-spid-redirect',
  templateUrl: './spid-redirect.component.html',
  styleUrls: ['./spid-redirect.component.scss']
})
export class SpidRedirectComponent implements OnInit {
  Message: string = "";
  UrlRedirect: string = "";
  LoginResult: any = null;
  RegistrationStep: RegistrationStep;
  IsError: boolean;
  IsRegistration: boolean;
  Pin: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private appGeneralService: AppGeneralService,
    private contractsConditionService: ContractsConditionService,
    private securityService: SecurityService,
    private router: Router,
    private storageService: LocalStorageService,
    private registrationService: RegistrationStepService
  ) { }


  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.IsError = params["IsError"].toLowerCase() == "true";
      this.IsRegistration = params["IsRegistration"].toLowerCase() == "true";
      this.Pin = params["Pin"];
      this.Message = params["Message"];
      this.RegistrationStep = params["RegistrationStep"];
      this.location.replaceState('/spid-return');

      if (this.IsError) {
        return;
      }

      this.appGeneralService.refreshTokens(authType.AUTHENTICATED, params['SessionExpireDate'], params['SessionID']);

      this.storageService.setRegistrationStep(this.RegistrationStep.toString());

      if (this.IsRegistration) {
        this.router.navigate(['/registrazione'], { queryParams: { pin: this.Pin } });
        return;
      }

      let a: Number = Number(this.RegistrationStep);

      if (!this.registrationService.checkRegistrationCompleted(a)) {
        return;
      }


      if (this.RegistrationStep == RegistrationStep.STEP3) {
        this.contractsConditionService.CheckConfirm(false, false, null, null);
      }

      this.storageService.setUserData(params["Name"]);
      this.appGeneralService.loadingPanel.Hide();

      let cartItems = params["CartItems"];
      this.storageService.setCartItems(cartItems);

      let fno = params["Fno"].toLowerCase();

      if (!this.storageService.getQueryFnoFlag()) {
        this.storageService.setFnoFlag(fno);
      }

      this.securityService.check_log_state();

      let path = this.storageService.getOrSetDefaultLastPageSeen('/');

      this.router.navigate([path]);
    });
  }
}
