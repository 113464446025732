export enum FeedPolicyType {

    EVIDENZA = 0,
    EVIDENZA_BUSINESS = 1,
    PER_LA_CASA = 2,
    PER_IL_LAVORO = 3,
    PER_LA_FAMIGLIA = 4,
    PER_IL_BUSINESS = 5,
    PER_LA_MACCHINA = 6,
    PER_LA_SALUTE = 7,
    ALTRO = 8

}
