import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, AfterViewInit {

  @Input() public isHorizontal: boolean = false;
  @Input() public Steps: Array<Step>;
  @Input() public selectedStep: number = 0;
  @ViewChild ('stepper') stepTemplate: any;

  isLinear: boolean = true;
  formArray: UntypedFormArray = new UntypedFormArray([]);

  constructor() { }

  ngOnInit() {
    if(this.Steps.every(z => z.GetOptional())){
      this.isLinear = false;
    }
  }

  ngAfterViewInit(): void {
    this.Steps.forEach((z, i) => {
      this.stepTemplate.steps._results[i].completed = z.GetCompleted();
    });

    this.stepTemplate.selectedIndex = this.stepTemplate.steps._results.findIndex(z => z.completed == false);
  }

  /**
   * If the button function returns true, then set the step to completed and move to the next step.
   * @param evt - The event that is triggered when the button is clicked.
   */
  nextMiddleware(evt){
    if(evt.selectedIndex > evt.previouslySelectedIndex){
      let step: Step = this.Steps[evt.previouslySelectedIndex];
      if(step.ButtonFunction()){
        step.SetCompleted(true);
      }
    }
  }

}

/**
*Defining the class for the Step object.
*@property {number} Code - The Code of the step. Is used to identify the step and must start from 0 if used on arrays.
*@property {string} Name - The Name of the step.
*@property {string} ButtonText - Text of the button for the next step.
*@property {Func} ButtonFunction - Function that get executed before changing step the next.
*@property {TemplateRef<any>} Template - The content of the step as a Template.
*@property {boolean} Optional - boolean that defines if the step is optional or not.
*@property {boolean} Completed - boolean that defines if the step is completed or not.
*/
export class Step {
  Code: number;
  Name: string;
  ButtonText: string;
  ButtonFunction: Func;
  Template: TemplateRef<any>;
  StepControl: UntypedFormGroup;
  private Completed: boolean;

  private constructor(code: number, name: string,  buttonText: string, template: TemplateRef<any>, Optional: boolean = false, completed: boolean = false, buttonFunction: Func = () => true){
    this.Code = code;
    this.Name = name;
    this.Template = template;
    this.ButtonText = buttonText;
    this.ButtonFunction = buttonFunction;
    this.Completed = completed;
    this.StepControl = new UntypedFormGroup({
      a: new UntypedFormControl("", Optional ? Validators.nullValidator : Validators.required)
    });
  }


  /**
   * This function creates a new Step object and returns it.
   * @param {number} code - The code of the step.
   * @param {string} name - The name of the step
   * @param {string} buttonText - The text that will be displayed on the button
   * @param {TemplateRef<any>} template - this is the template that will be used to render the step
   * @param {boolean} [Optional=false] - boolean = false - If the step is optional or not
   * @param {Func } [buttonFunction=() => true] - The function that will be executed before changing the step if the function returns true the step will be changed. This function can be used like this () => {return true;}
   * @returns A new instance of the Step class.
   */
  static create(code: number, name: string,  buttonText: string, template: TemplateRef<any>, Optional: boolean = false, Completed: boolean = false, buttonFunction: Func = () => true): Step{
    return new Step(code, name, buttonText, template, Optional, Completed, buttonFunction);
  }

  /**
   * If the control is valid, return true, otherwise return false.
   * @returns The validity of the control named 'a' in the StepControl form group.
   */
  GetOptional(){
    return this.StepControl.controls['a'].hasValidator(Validators.nullValidator);
  }

  /**
   * If the step is completed, then set the validators to null and set the errors to null.
   * @param {boolean} completed - boolean - this is a boolean that is set to true when the step is
   * completed.
   */
  SetCompleted(completed: boolean){
    this.Completed = completed;
    if(this.Completed || this.GetOptional()){
      this.StepControl.controls['a'].setValidators(Validators.nullValidator);
      this.StepControl.controls['a'].setErrors(null);
    }else{
      this.StepControl.controls['a'].setValidators(Validators.required);
      this.StepControl.controls['a'].setErrors({'required': true});
    }
  }

  /**
   * If the form is valid, and the user has completed the form, then return true.
   * @returns The Completed property and the valid property of the a control.
   */
  GetCompleted() {
    return this.Completed;
  }


}

export interface Func {
  (): boolean;
}
