import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-facebook-like',
  templateUrl: './facebook-like.component.html',
  styleUrls: ['./facebook-like.component.scss']
})
export class FacebookLikeComponent {
  isApple: boolean = false;
  FaTimes = faTimesCircle;
  constructor(public dialogRef: MatDialogRef<FacebookLikeComponent>,
  ) {

    this.isApple = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'] || !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

  }

  close(): void {
    this.dialogRef.close();
  }

  OpenFacebookPage() {
    window.open('https://www.facebook.com/buzziassicurazioni', '_blank');
    this.close();
  }

}
