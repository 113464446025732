import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/catch';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonLoaderService {

  constructor(private http: HttpClient) { }

  getJsonData(JsonFile: string) {
    return this.http.get(JsonFile).pipe(map((response) => {
      return response;
    }))
  }

}
// .catch((error: HttpErrorResponse) => {
//   return throwError(error.message || 'server Error');
// });