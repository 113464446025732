import { Component, Input, OnInit } from '@angular/core';
import { OptionDTO } from 'src/app/core/classes/Policy/compare';
import { OptionInfoDialogComponent } from '../option-info-dialog/option-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';

@Component({
  selector: 'app-policy-price-boxes-scheda',
  templateUrl: './policy-price-boxes-scheda.component.html',
  styleUrls: ['./policy-price-boxes-scheda.component.scss']
})
export class PolicyPriceBoxesSchedaComponent implements OnInit {

  @Input() Policy;
  @Input() Section;
  @Input() ProposalID;
  @Input() GroupId;
  @Input() ShowButton;
  PolicyClass;

  constructor(public dialog: MatDialog, public urlBuilderService : UrlBuilderService) { }

  ngOnInit() {

    this.PolicyClass = this.Policy.Name.toUpperCase();

  }

  returnPrice(section : any , garanzia : any) : number{

    const foundValue = section.Values.find(s => s.Text.toUpperCase().includes(garanzia.Name.toUpperCase()) );
    if (foundValue) {
      return foundValue.SubValue[0].Maximal || 0;
    } else {
      return 0; // O qualsiasi altro valore predefinito che preferisci
    }

  }

  tdValue(option: OptionDTO, policyCheckId : number):string {

    var optionFind = option.Values.find(x => x.PoliciesData.find(f => f.Id == policyCheckId));
    if(optionFind)
    {
      let allMaximal = optionFind.SubValue.map(x => x.Maximal);
      if(allMaximal.length > 0)
        return allMaximal.join(' | ');
      // let allPrice = optionFind.SubValue.map(x => x.Price);
      // if(allPrice.length > 0)
      //   return allPrice.join(' | ');
    }
    return " - ";
  }

  openInfoDialog(Description: string, Title: string) {
    this.dialog.open(OptionInfoDialogComponent, {
      panelClass: 'SurveyModalCntr',
      width: '400px',
      maxHeight: '90vh',
      data: {Description, Title},
      autoFocus: false
    });
  }

  openPolicyDetail(policyId: number) {
    this.urlBuilderService.navigateToPath(this.GroupId, policyId, this.ProposalID);
  }

}
