import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FileUploadModule } from "ng2-file-upload";
import { PipeModule } from '../core/helpers/Pipe.module';
import { PaypalButtonModule } from './PayPal/paypal-button/paypal-button.module';
import { BoughtTogetherComponent } from './bought-together/bought-together.component';
import { ChatContentComponent } from './chat/chat-content/chat-content.component';
import { ChatComponent } from './chat/chat.component';
import { ConfiguratorGridComponent } from './configurator-grid/configurator-grid.component';
import { ConfiguratorPriceComponent } from './configurator-grid/configurator-price/configurator-price.component';
import { ContactUsComponent } from './contact-us/contact-us/contact-us.component';
import { CookieMessageComponent } from './cookie-message/cookie-message.component';
import { AddedToCartComponent } from './dialogs/added-to-cart/added-to-cart.component';
import { ChooseProfilePolicyComponent } from './dialogs/choose-profile-policy/choose-profile-policy.component';
import { FacebookLikeComponent } from './dialogs/facebook-like/facebook-like.component';
import { DialogHandlerComponent } from './error-handler/error-handler.component';
import { FeedCarouselModule } from './feed-carousel/feed-carousel.module';
import { FeedbackAreaComponent } from './feedback-area/feedback-area.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { LongTextContainerComponent } from './long-text-container/long-text-container.component';
import { ModuleComponent } from './module/module.component';
import { PrefooterComponent } from './prefooter/prefooter.component';
import { SearchComponent } from './search/search.component';
import { SpidButtonDevComponent } from './spid-button-dev/spid-button-dev.component';
import { SpidButtonComponent } from './spid-button/spid-button.component';
import { StepperModule } from './stepper/stepper.module';

const matModul = [MatButtonModule, MatIconModule, MatCardModule, MatInputModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    matModul,
    IvyCarouselModule,
    FontAwesomeModule,
    FileUploadModule,
    PipeModule,
    MatSelectModule,
    MatButtonModule,
    FeedCarouselModule,
    PaypalButtonModule,
  ],
  declarations: [
    DialogHandlerComponent,
    AddedToCartComponent,
    FileUploadComponent,
    ModuleComponent,
    ConfiguratorGridComponent,
    CookieMessageComponent,
    ChooseProfilePolicyComponent,
    ConfiguratorPriceComponent,
    ChatComponent,
    ChatContentComponent,
    LongTextContainerComponent,
    FacebookLikeComponent,
    SpidButtonComponent,
    SpidButtonDevComponent,
    BoughtTogetherComponent,
    FeedbackAreaComponent,
    ContactUsComponent,
    PrefooterComponent,
    SearchComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    matModul,
    AddedToCartComponent,
    ConfiguratorGridComponent,
    CookieMessageComponent,
    ConfiguratorPriceComponent,
    ChatComponent,
    ChatContentComponent,
    LongTextContainerComponent,
    FacebookLikeComponent,
    SpidButtonComponent,
    SpidButtonDevComponent,
    BoughtTogetherComponent,
    StepperModule,
    FeedbackAreaComponent,
    ContactUsComponent,
    PrefooterComponent,
    SearchComponent
  ]
})
export class SharedModule { }
