<div class="container profile-single-accordion family-tab">
    <ng-container *ngIf="formFamilyMembers != null">
        <div *ngFor="let formFamily of formFamilyMembers.controls;  let i = index">
            <form [formGroup]="formFamily" class="row" autocomplete="off">
                <div class="col-md-12">
                    <div class="EditDataPnl data">

                        <input type="hidden" formControlName="Id">


                        <div class="col-md-12   text-center">
                            <mat-form-field floatLabel="always" class="col-sm-12 col-md-6 col-lg-6 familyOption">
                                <mat-select class="mat-form-input text-center" matNativeControl required formControlName="FamilyType" tabindex="3" placeholder="Seleziona Familiare">
                                    <mat-option *ngFor="let familiar of FamilyType.MemberArray" [value]="familiar.Id">
                                        {{ familiar.Name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formFamily.controls['FamilyType'].hasError('required')">
                                    Selezionare <strong>familiare</strong>
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-md-12  ">

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" type="text" matInput placeholder="Nome" required formControlName="Name" name="name" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['Name'].hasError('required')">
                                    Inserire <strong>nome</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" type="text" matInput placeholder="Cognome" required formControlName="Surname" name="surname" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['Surname'].hasError('required')">
                                    Inserire <strong>cognome</strong>
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-md-12  ">

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <mat-label for="Gender">Sesso</mat-label>
                                <mat-select class="mat-form-input" matNativeControl required formControlName="Sex" tabindex="3">
                                    <mat-option *ngFor="let gender of appGeneralService.GenderType" [value]="gender.value">
                                        {{ gender.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="formFamily.controls['Sex'].hasError('required')">
                                    Selezionare <strong>sesso</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <mat-label>Data di nascita</mat-label>
                                <input class="mat-form-input" matInput type="text" [max]="calendarStartDate" [matDatepicker]="BirthDatePicker" required formControlName="DateOfBirth" (click)="_openCalendar(BirthDatePicker)" placeholder="Data di nascita" name="birth-date" autocomplete="none" tabindex="4">
                                <mat-datepicker-toggle matSuffix [for]="BirthDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker [startView]="'multi-year'" #BirthDatePicker [startAt]="calendarStartDate">
                                </mat-datepicker>
                                <mat-error *ngIf="formFamily.controls['DateOfBirth'].hasError('required')">
                                    Inserire <strong>data di nascita</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" type="text" matInput placeholder="Città di nascita" required formControlName="BirthTown" name="birthcity" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['BirthTown'].hasError('required')">
                                    Inserire <strong>città di nascita</strong> </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" matInput type="text" placeholder="Provincia" required formControlName="Province" name="birth-province" autocomplete="none" [matAutocomplete]="auto" maxlength="2" tabindex="6">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let province of filteredProvinces | async" [value]="province">
                                        {{province}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="formFamily.controls['Province'].hasError('required')">
                                    Selezionare <strong>provincia</strong> dalla lista proposta
                                </mat-error>
                                <mat-error *ngIf="!formFamily.controls['Province'].hasError('required') || formFamily.controls['Province'].hasError('minLength') || formFamily.controls['Province'].hasError('maxLength')">
                                    Selezionare <strong>provincia</strong> dalla lista proposta
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-md-12  ">

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" matInput placeholder="Stato di nascita" required formControlName="Nation" name="birthstate" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['Nation'].hasError('required')">
                                    Inserire <strong>stato di nascita</strong> </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" matInput type="text" placeholder="Codice fiscale" required formControlName="FiscalCode" name="cf" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['FiscalCode'].hasError('required')">
                                    Inserire <strong>codice fiscale</strong>
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div class="col-md-12  ">

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" matInput type="text" placeholder="Mail" required formControlName="Email" name="email" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['Email'].hasError('required')">
                                    Inserire <strong>via</strong>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                                <input class="mat-form-input" matInput type="text" placeholder="Telefono" required formControlName="PhoneNumber" name="phone" autocomplete="none">
                                <mat-error *ngIf="formFamily.controls['PhoneNumber'].hasError('required')">
                                    Inserire <strong>numero di telefono</strong>
                                </mat-error>
                            </mat-form-field>

                        </div>

                    </div>
                </div>

                <div class="col-12  ">
                    <div class="buttonArea float-right">
                        <button class="new-button"   (click)="saveData(i)">SALVA</button>
                        <button class="new-button mt-3"   (click)="deleteData(i)">RIMUOVI</button>
                    </div>
                </div>

            </form>
            <hr>
            <br>
        </div>
    </ng-container>

    <div class="col-12  ">
        <div class="buttonArea">
            <button class="new-button float-right mt-5"   (click)="addNewFamilyMember()">AGGIUNGI FAMILIARE</button>
        </div>
    </div>
</div>
