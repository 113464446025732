import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CtWebviewService } from '@ctsolution/ct-webview';
import { UserData } from 'src/app/core/classes/User/UserData';
import { UserTypeVO } from 'src/app/core/classes/User/UserType';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { AdhesionComponent } from 'src/app/pages/cart/buy-policy/adhesion/adhesion.component';
import { FileUploadComponent } from 'src/app/shared/file-upload/file-upload.component';
import { isNullOrUndefined } from 'util';
import { CancelPolicyDialogComponent } from './cancel-policy-dialog/cancel-policy-dialog.component';
import { DeletePolicyDialogComponent } from './delete-policy-dialog/delete-policy-dialog.component';
import { IndirectPaymentDialogComponent } from './indirect-payment-dialog/indirect-payment-dialog.component';
import { UpgradePolicyDialogComponent } from './upgrade-policy-dialog/upgrade-policy-dialog.component';

@Component({
  selector: 'app-my-policies',
  templateUrl: './my-policies.component.html',
  styleUrls: ['./my-policies.component.scss']
})
export class MyPoliciesComponent implements OnInit {

  UserPoliciesListResult;
  UserPoliciesListResultSorted = [];
  availableProfiles: any = [];

  PolicyID;

  UserData: UserData;
  userTypes = UserTypeVO;

  @Output() childEvent = new EventEmitter();

  constructor(
    private apiService: ApiService,
    public appGeneralService: AppGeneralService,
    private router: Router,
    public dialog: MatDialog,
    private ctWebviewService: CtWebviewService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    this.getUserPoliciesList();
    this.getUserData();


  }


  getUserData() {
    this.apiService.callApiProd('/api/Account/GetUserData', new CallParameter('POST')).subscribe((result) => {
      this.UserData = result.ResultData["UserData"];
    });
  }

  checkIfAnyQuietanza(Policy) {
    return Policy.ActivationDocuments.some(z => z.Type == 15)
  }

  getUserPoliciesList() {


    this.apiService.callApiProd('/api/Policy/GetUserPoliciesList', new CallParameter('POST')).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.UserPoliciesListResult = result['ResultData']['PurchasedPolicies'];
        this.UserPoliciesListResult.forEach(element => {
          if (element.UserProfiles.length > 0) {
            this.availableProfiles.push({ "id": element.Code, "Profiles": element.UserProfiles })
          }
          element.isCollapsed = false;
        });

        const orderedIDList = [10, 1, 3, 4, 2, 5];
        this.UserPoliciesListResultSorted = [];
        orderedIDList.forEach(element => {
          this.UserPoliciesListResultSorted = this.UserPoliciesListResultSorted.concat(this.UserPoliciesListResult.filter(x => x.State == element));
        })
        debugger
        this.UserPoliciesListResultSorted
      }

    });
  }

  CheckPolicyUpgrade(ID, UserProfileID, PolicyID) {

    this.apiService.callApiProd("/api/Policy/CheckUpgrade", new CallParameter("POST", { UserPolicyId: ID })).subscribe(res => {
      if (res.InError) {
        this.appGeneralService.showDialog(res.ErrorCode);
      }

      if (res.ResultData["Upgrades"].length > 0) {
        var upgradeDialog = this.dialog.open(UpgradePolicyDialogComponent, {
          width: '90vw',
          data: {
            OldPolicyId: PolicyID,
            Upgrades: res.ResultData["Upgrades"]
          },
          disableClose: false
        });

        upgradeDialog.afterClosed().subscribe(PolicyID => {
          if (PolicyID == null || PolicyID == undefined) {
            return;
          }
          this.addPolicyToCart(PolicyID, UserProfileID);
        })
        return;
      }

      this.addPolicyToCart(ID, UserProfileID);
    })
  }

  renewPolicy(ID, PolicyID) {
    //ROLLBACK FNO
    if (PolicyID == 49 || PolicyID == 50 || PolicyID == 370 || PolicyID == 372) {
      window.location.href = "https://www.tsrm.org/"
      return;
    }

    this.CheckPolicyUpgrade(ID, -1, PolicyID);

  }

  CheckTransactionID(TransactionID) {
    return !isNullOrUndefined(TransactionID) && TransactionID.length > 0;
  }

  download_tsrm_mav(TransactionID) {
    this.dialog.open(IndirectPaymentDialogComponent, {
      width: '500px',
      data: {
        RedirectTo: TransactionID
      },
      disableClose: false,
      panelClass: 'custom-dialog-container'
    });
    return;
  }

  cancelPolicy(UserPolicyID) {
    let deletedialog = this.dialog.open(CancelPolicyDialogComponent, {
      width: '500px',
      data: UserPolicyID,
      panelClass: 'custom-dialog-container'
    });

    deletedialog.afterClosed().subscribe(result => {
      if (result) {
        this.getUserPoliciesList();
      }
    });
  }

  deletePolicy(UserPolicyID) {
    let deleteDialog = this.dialog.open(DeletePolicyDialogComponent, {
      width: '500px',
      data: UserPolicyID,
      panelClass: 'custom-dialog-container'
    });

    deleteDialog.afterClosed().subscribe(result => {
      if (result) {
        this.getUserPoliciesList();
      }
    });
  }



  addPolicyToCart(ID, UserProfileID) {
    this.appGeneralService.loadingPanel.Show();

    let param = new CallParameter('POST');
    param.CallData = {
      PolicyID: ID,
      UserProfileID: UserProfileID
    };

    this.apiService.callApiProd("/api/Policy/AddPolicyToCart/", param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode, data.ResultData);
        } else {
          this.router.navigate(["/carrello"]);
        }
      });
  }


  handleDocumentsUpload(document, Policy) {
    if (!document.WasAlreadyUploaded && document.Type == 12) {
      this.openAdhesionModuleModal(null, null, Policy)
    } else if (!document.WasAlreadyUploaded && document.Type === null && document.Code !== null) {
      this.openAdhesionModuleModal(document.Code, document.ID, Policy)
    } else {
      this.openUploadFileModal(document, Policy)
    }
  }

  openUploadFileModal(fileData, Policy) {
    let dialogRef = this.dialog.open(FileUploadComponent, {
      width: '500px',
      data: {
        file: fileData,
        id: parseInt(Policy.ID),
        price: parseInt(Policy.Price),
        Code: Policy.Code,
        isAifi: (Policy.Name != null || Policy.Name != undefined) && Policy.Name.indexOf("AIFI") > 0
      },
      disableClose: true,
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!isNullOrUndefined(result['refresh']) && result['refresh']) {
        window.location.reload();
      }
    });

  }

  NeedsAdhesionModule: boolean = false;
  CBAfterAdhesionModule: () => void;

  openAdhesionModuleModal(DocumentModuleTypeCode: DocumentType = null, DocumentID: number = null, Policy = null) {

    this.appGeneralService.loadingPanel.Show();

    this.apiService.callApiProd('/api/Policy/GetAdhesionModuleData', new CallParameter('POST', { "UserPolicyId": Number(Policy.ID) })).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        let dialogRef = this.dialog.open(AdhesionComponent, {
          width: '800px',
          height: '900px',
          panelClass: 'responsiveCntr',
          data: {
            PolicyID: parseInt(Policy.ID),
            PolicyData: result.ResultData,
            DocumentModuleTypeCode: DocumentModuleTypeCode,
            DocumentID: DocumentID
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            window.location.reload();
          }
        });
      }
    });

  }

  zipDocument(infoDocument: any) {

    if (this.ctWebviewService.isAppleDevice()) {

      var element = document.createElement('a');
      element.setAttribute('href', this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?' + infoDocument.map(document => 'PolicyFileIds=' + document['ID']).join('&'));
      element.setAttribute('download', '');
      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } else {

      let path: string = this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?' + infoDocument.map(document => 'PolicyFileIds=' + document['ID']).join('&');

      window.open(path);
    }
  }

  zipQuietanze(infoDocument: any) {

    let documents: Array<any> = [];
    infoDocument.forEach(element => {

      if (element.Type == 15) {

        documents.push(element);

      }

    });

    if (documents.length > 0) {

      if (this.ctWebviewService.isAppleDevice()) {

        var element = document.createElement('a');
        element.setAttribute('href', this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?useUserPolicyFile=true&' + documents.map(document => 'PolicyFileIds=' + document['UserPolicyFileID']).join('&'));
        element.setAttribute('download', '');
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {

        let path: string = this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?useUserPolicyFile=true&' + documents.map(document => 'PolicyFileIds=' + document['UserPolicyFileID']).join('&');

        window.open(path);
      }
    }
  }

  redirectFimaa(url: any){
    window.open(url);
  }
}
