import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
// MATERIAL
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPasswordStrengthModule } from 'src/app/shared/mat-password-strenght-module/mat-password-strength.module';

import { PasswordRecoveryDialogComponent } from './../login/password-recovery-dialog/password-recovery-dialog.component';
import { LoginComponent, LoginController } from './../login/login.component';
import { RegistrationModule } from '../registration/registration.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatPasswordStrengthModule,
    MatCheckboxModule,
    MatExpansionModule,
    RouterModule,
    RegistrationModule
  ],
  declarations: [
    LoginComponent,
    PasswordRecoveryDialogComponent
  ],
  providers: [LoginController],
  exports:[
    LoginComponent
  ]
})
export class LoginModule { }