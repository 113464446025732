import { Component, Input, OnInit } from '@angular/core';
import { BoughtTogether } from 'src/app/core/classes/BoughtTogether/bought-together';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';

@Component({
  selector: 'app-bought-together',
  templateUrl: './bought-together.component.html',
  styleUrls: ['./bought-together.component.scss']
})
export class BoughtTogetherComponent implements OnInit {

  @Input() BoughtTogether: BoughtTogether[];  

  constructor(
    private urlBuilderService: UrlBuilderService
  ) { }

  ngOnInit() {
  }

  goToPolicyDetail(ID: number) {
    
    this.urlBuilderService.navigateToPath(ID, 0, null);

  }
}
