import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { Chat } from 'src/app/core/classes/chat';
import * as $ from 'jquery';
import { PolicySelectElement } from 'src/app/core/classes/Policy/policy-select';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-chat-content',
  templateUrl: './chat-content.component.html',
  styleUrls: ['./chat-content.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '500px',
        width: '25vw',
        opacity: 1
      })),
      state('closed', style({
        height: '0px',
        width: '0px',
        opacity: 0
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
})
export class ChatContentComponent implements OnInit {

  @Input() visible: boolean;

  @Input() messages: Chat;

  @Input() userPolicies: PolicySelectElement[];

  @Output() sendFAQ = new EventEmitter<string>();
  @Output() onWriting = new EventEmitter<ChatMessage>();

  @ViewChild('NewMessage') messageInput: ElementRef;

  hideFaqMessage: boolean = false;
  selectedUserPolicy: number;

  constructor() { }


  ngOnInit() { }

  newFaq(id) {
    this.sendFAQ.emit(id);
  }

  setHideFaqMessage(message) {
    let chatMessage: ChatMessage = {
      message: message,
      userPolicyId: this.selectedUserPolicy
    }
    
    this.onWriting.emit(chatMessage);

    if (message.length > 0 || this.messages.Messages.length > 0) {
      this.hideFaqMessage = true;
      return;
    }
    this.hideFaqMessage = false;
  }

  scrollBottom() {
    setTimeout(function () {

      var d = $('.ChatBody');
      d.scrollTop(d.prop("scrollHeight"));
    }, 0);
  }

}


export interface ChatMessage {
  message: string;
  userPolicyId: number;
}