<ng-container *ngIf="!IsShoppingCenter">
<div class="container">
    <form [formGroup]="formRegistrationComplete" class="row" autocomplete="off">
        <div class="col-md-12 col-lg">
            <div class="registrationAreaPnl credentials">
                <p>Inserisci profilo lavorativo</p>
                <div style="padding-bottom: 7px;">
                    <mat-checkbox (change)="HideProfile($event)">Il mio profilo lavorativo non è in elenco
                    </mat-checkbox>
                </div>
                <div class="profile row">

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input matInput class="mat-form-input" type="text" placeholder="Digita o cerca la professione" required formControlName="JobProfileID" name="job-profile" [matAutocomplete]="auto" (input)="onJobProfileChange($event)" (focus)="onJobProfileChange($event)" (blur)="availableBulletins_Conventions($event)">
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let profile of filteredJobProfiles | async" [value]="profile.label">
                                {{profile.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12" *ngIf="Bulletins?.length > 0">
                        <mat-label for="BulletinCode">Albo</mat-label>
                        <mat-select class="mat-form-input" matNativeControl formControlName="BulletinID">
                            <mat-option *ngFor="let bulletin of Bulletins" [value]="bulletin.id">
                                {{ bulletin.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12" *ngIf="Conventions?.length > 0">
                        <mat-label for="ConventionID">{{this.storageService.getBrokerManagementLabel(true,true)}}</mat-label>
                        <mat-select class="mat-form-input" matNativeControl formControlName="ConventionID" [required]="fno">
                            <mat-option *ngFor="let convention of Conventions" [value]="convention.id" [disabled]="!convention.available">
                                {{ convention.label }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formRegistrationComplete.get('ConventionID').hasError('required')">
                            Selezionare <span *ngIf="formRegistrationCompleteControls['JobProfileID'].hasError('required')"><strong>profilo
                  lavorativo</strong> e </span><strong>{{this.storageService.getBrokerManagementLabel(true,false)}}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input class="mat-form-input" type="text" matInput placeholder="Partita IVA" formControlName="PIva" name="piva" autocomplete="none" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="(formRegistrationCompleteControls['PIva'].hasError('minlength') || formRegistrationCompleteControls['PIva'].hasError('maxlength') || formRegistrationCompleteControls['PIva'].hasError('invalidVAT'))">
                            <strong>Partita IVA</strong> non valida
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input class="mat-form-input" type="email" matInput placeholder="PEC" formControlName="Pec" name="pec" autocomplete="none" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="formRegistrationCompleteControls['Pec'].hasError('email')">
                            <strong>PEC</strong> non valida
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="checkboxArea col-lg-3 col-md-4 col-sm-12" appearance="none">
                        <input class="mat-form-input" matInput style="display: none">
                        <mat-checkbox class="col-12" formControlName="Employed">Sei un dipendente?</mat-checkbox>
                        <mat-checkbox class="col-12" formControlName="Freelance">Sei un libero professionista?</mat-checkbox>
                    </mat-form-field>
                    <div class="col-12" style="padding-bottom: 7px;">
                        <mat-checkbox (change)="ShowAdditionalProfile($event)">Clicca qui per aggiungere un altro profilo lavorativo
                        </mat-checkbox>
                    </div>
                </div>
                <div class="additionalProfile row">
                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input class="mat-form-input" matInput type="text" placeholder="Digita o cerca la professione" required formControlName="JobProfileIDAddictional" name="job-profile" [matAutocomplete]="autoAddictional" (input)="onJobProfileChangeAddictional($event)" (focus)="onJobProfileChangeAddictional($event)" (blur)="availableBulletins_ConventionsAddictional($event)">
                        <mat-autocomplete #autoAddictional="matAutocomplete">
                            <mat-option *ngFor="let profile of filteredJobProfilesAddictional | async" [value]="profile.label">
                                {{profile.label}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12" *ngIf="Bulletins?.length > 0">
                        <mat-label for="BulletinIDAdditional">Albo</mat-label>
                        <mat-select class="mat-form-input" matNativeControl formControlName="BulletinIDAdditional">
                            <mat-option *ngFor="let bulletin of Bulletins" [value]="bulletin.id">
                                {{ bulletin.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12" *ngIf="AddictionalConventions?.length > 0">
                        <mat-label for="ConventionIDAdditional">{{this.storageService.getBrokerManagementLabel(true,true)}}</mat-label>
                        <mat-select class="mat-form-input" matNativeControl formControlName="ConventionIDAdditional">
                            <mat-option *ngFor="let convention of AddictionalConventions" [value]="convention.id" [disabled]="!convention.available">
                                {{ convention.label }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input class="mat-form-input" type="text" matInput placeholder="Partita IVA" formControlName="PIvaAdditional" name="piva-add" autocomplete="none" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="SecondFormVisible && (formRegistrationCompleteControls['PIvaAdditional'].hasError('minlength') || formRegistrationCompleteControls['PIvaAdditional'].hasError('maxlength') || formRegistrationCompleteControls['PIvaAdditional'].hasError('invalidVAT'))">
                            <strong>Partita IVA</strong> non valida
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="full-width col-lg-3 col-md-4 col-sm-12">
                        <input class="mat-form-input" type="email" matInput placeholder="PEC" formControlName="PecAdditional" name="pec-add" autocomplete="none" [errorStateMatcher]="matcher">
                        <mat-error *ngIf="SecondFormVisible && formRegistrationCompleteControls['PecAdditional'].hasError('email')">
                            <strong>PEC</strong> non valida
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="checkboxArea col-lg-3 col-md-4 col-sm-12" appearance="none">
                        <input class="mat-form-input" matInput style="display: none">
                        <mat-checkbox class="col-12" formControlName="EmployedAdditional">Sei un dipendente?</mat-checkbox>
                        <mat-checkbox class="col-12" formControlName="FreelanceAdditional">Sei un libero professionista?
                        </mat-checkbox>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="buttonArea">
                <button class="new-button float-right" style="margin: 15px 0;" (click)="onRegistrationComplete()">Completa
          Registrazione</button>
            </div>
        </div>
    </form>
</div>
</ng-container>
