import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { faFileDownload, faFileAlt, faInfoCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AddedToCartComponent } from 'src/app/shared/dialogs/added-to-cart/added-to-cart.component';
import { OptionInfoDialogComponent } from '../option-info-dialog/option-info-dialog.component';
import * as $ from 'jquery';
import { ChooseProfilePolicyComponent } from 'src/app/shared/dialogs/choose-profile-policy/choose-profile-policy.component';
import { JsonLoaderService } from 'src/app/core/services/json-loader.service';
import { SeoService } from 'src/app/core/services/seo.service';
import { Compare } from 'src/app/core/classes/Policy/compare';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-single-package-detail-page',
  templateUrl: './single-package-detail-page.component.html',
  styleUrls: ['./single-package-detail-page.component.scss']
})
export class SinglePackageDetailPageComponent implements OnInit {

  public isCollapsed = true;
  faInfoCircle = faInfoCircle;
  faFileDownload = faFileDownload;
  faFileAlt = faFileAlt;
  faChevronDownDescription = faChevronDown;
  faChevronDownDocuments = faChevronDown;
  longEnough;
  comparePolicies : Compare;

  policyGroupID: number;
  policyProposalID: string;
  policyID: number;

  Sections;
  Policy;
  PolicyData: any;

  PoliciesList: any;

  ListBoughtTogether;
  VariableHeight = '500px';

  SecurityUrl;

  constructor(
    public appGeneralService: AppGeneralService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private jsonLoaderService: JsonLoaderService,
    private _sanitizer : DomSanitizer
  ) {


    this.activatedRoute.queryParams.subscribe((params) => {

      if (params["GroupID"]) {
        this.policyGroupID = +params["GroupID"];
      }

      if (params["ProposalID"]) {
        this.policyProposalID = params["ProposalID"];
      }

      if (params["PolicyID"]) {
        this.policyID = +params["PolicyID"];
      }

    });

  }

  // @Input() SinglePolicyPriceBox: SingleProposalBox;

  ngOnInit() {

    // carico il json con i dati per la SEO e imposto Title e Description
    this.jsonLoaderService.getJsonData('../../../../assets/policies-seo.json')
      .subscribe(r => {
        this.PoliciesList = r;

        let currentPolicy = this.PoliciesList.find(elm => elm.ID === this.policyID);

        this.seoService.update_title(currentPolicy.title);
        this.seoService.update_description(currentPolicy.description);

      });



    this.getPolicyDetail(this.policyID);

    this.getListBoughtTogether();
  }

   async getPolicyDetail(id: number) {
    const policyParams = new Policy(id);
   await this.apiService.callApiProd('/api/Policy/AvailablePolicyDetail', new CallParameter('POST', policyParams)).subscribe((result) => {
      this.PolicyData = result['ResultData'];
      this.setLink();
      this.seoService.update_image(this.PolicyData.Policy.ImageUrls[0]);
    });

    await this.apiService.callApiProd("/api/Policy/GetCompare", new CallParameter("POST", { PolicyGroupId: this.policyGroupID, ProposalId: Number(this.policyProposalID.split('_')[1]) })).subscribe(res => {
      this.comparePolicies = res.ResultData as Compare ;
      this.Sections = this.comparePolicies.Sections;
      this.Policy = this.comparePolicies.Policies.find(p => p.Id == this.policyID);
    });
  }

  addToCart(BuyNow) {
    //ROLLBACK FNO
    if (this.policyID == 49 || this.policyID == 50 || this.policyID == 370 || this.policyID == 372) {
      window.location.href = "https://www.tsrm.org/";
      return;
    }

    let ChooseProfileDialog: MatDialogRef<ChooseProfilePolicyComponent>;
    let profileID = -1;
    if (this.PolicyData.Profiles.length == 1) {
      profileID = this.PolicyData.Profiles[0].ProfileID;
      this.postCart(profileID, BuyNow);
    } else {
      ChooseProfileDialog = this.dialog.open(ChooseProfilePolicyComponent, {
        panelClass: 'custom-dialog-container',
        width: '500px',
        data: {
          Profiles: this.PolicyData.Profiles
        }
      });
      ChooseProfileDialog.afterClosed().subscribe(res => {
        if (res.choice) {
          this.postCart(res.profileID, BuyNow);
        }
      });
    }
  }

  postCart(profileID, BuyNow) {
    const policyToCartParams = new PolicyToCart(profileID, this.policyID, -1);
    this.apiService.callApiProd('/api/Policy/AddPolicyToCart', new CallParameter('POST', policyToCartParams)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        if (BuyNow) {
          this.router.navigate(["/acquista-polizza", result.ResultData['UserPolicyID']]);
        } else {
          this.dialog.open(AddedToCartComponent, { panelClass: 'custom-dialog-container', width: '500px' });
        }
      }
    });
  }

  getListBoughtTogether() {
    const IDPolicy = new boughtTogether([this.policyID]);
    this.apiService.callApiProd('/api/Policy/ListBoughtTogether', new CallParameter('POST', IDPolicy)).subscribe((result) => {
      this.ListBoughtTogether = result['ResultData']['BoughtTogetherInfos'];
    });
  }

  openInfoDialog(Description: string, Title: string) {

    var _this = this;
    let dialogRef = _this.dialog.open(OptionInfoDialogComponent, {
      panelClass: 'SurveyModalCntr',
      minWidth: '400px',
      width: '400px',
      maxHeight: '90vh',
      data: {Description, Title},
      autoFocus: false,

    });

  }

  toggleLenght() {

    if (this.VariableHeight == 'none') {
      this.VariableHeight = '500px';
      this.faChevronDownDescription = faChevronDown;
      setTimeout(function () {

        var $GoTo = $('html, body');
        $GoTo.animate({
          scrollTop: $(".scroll-touch").offset().top
        }, 500);
      }, 0);
    } else {
      this.VariableHeight = 'none';
      this.faChevronDownDescription = faChevronUp;
    }
  }

  documentCollapse() {
    this.isCollapsed = !this.isCollapsed
    if (this.isCollapsed) {
      this.faChevronDownDocuments = faChevronDown;
      return;
    }
    this.faChevronDownDocuments = faChevronUp;
  }

  @HostListener('window:resize')
  IsLongEnough() {
    let height = $("#ExtendedDescriptionCntr").height();
    if (height > 499) {
      this.longEnough = true;
    }
    this.longEnough = false;
  }

  @HostListener("window:resize")
  calculateWidth(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 660;
    }
      return windowWidth ;

  }


  @HostListener("window:resize")
  calculateHeight(): number {
    const windowWidth = window.innerWidth;
    if (windowWidth > 1200) {
      return 355;
    }
      return (window.innerHeight / 100) * 30 ;

  }

  setLink() {
    this.SecurityUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.PolicyData.Policy.VideoLink);
  }



}

export class Policy {
  policyID: number;
  constructor(
    policyID: number
  ) {
    this.policyID = policyID;
  }
}

export class PolicyToCart {
  profileID: number;
  policyID: number;
  userProfileID: number;
  constructor(
    profileID: number,
    policyID: number,
    userProfileID: number
  ) {
    this.profileID = profileID;
    this.policyID = policyID;
    this.userProfileID = userProfileID;
  }
}

export class boughtTogether {
  policyIds: number[];
  constructor(
    policyIds: number[]
  ) {
    this.policyIds = policyIds;
  }
}
