import { Component, OnInit } from '@angular/core';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private appGeneralService: AppGeneralService
  ) { }

  ngOnInit() {
  }


  deleteAccount() {
    var checkDialog = this.appGeneralService.showDialog(null, '<h3>Sei sicuro di voler cancellare l\'account?</h3> <br> Dopo la cancellazione i tuoi dati non saranno recuperabili, ' +
      'verranno mantenuti i documenti di polizza e se ne potrà fare richiesta per via telefonica o email',
      "Attenzione",
      [{ name: 'Elimina Account', action: () => { checkDialog.close(true) } }, { name: 'Non Eliminare', action: () => { checkDialog.close(false); } }]
    )

    checkDialog.afterClosed().subscribe(result => {
      if(result){
        this.apiService.callApiProd('/api/Account/DeleteUser', new CallParameter('POST')).subscribe((result) => {

          if (result.InError) {
            this.appGeneralService.showDialog(result.ErrorCode);
            return;
          }

          this.authService.logout();

        });
        this.appGeneralService.showDialog( null, "Account eliminato", "Esito");
      }
    })

  }
}
