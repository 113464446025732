import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { Chat } from 'src/app/core/classes/chat';
import { PolicySelectElement } from 'src/app/core/classes/Policy/policy-select';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { ChatContentComponent, ChatMessage } from './chat-content/chat-content.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit {

  @ViewChild('chatContent') chatContent: ChatContentComponent;
  FaComment = faCommentAlt;
  FaPlane = faPaperPlane;
  hidden = true;
  disabled = false;
  messages: Chat = new Chat();
  notify: boolean = false;
  userPolicies: PolicySelectElement[] = [];
  Interval;
  hideToggle: boolean = false;
  message: ChatMessage = {
    message: '',
    userPolicyId: 0
  }

  constructor(
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    this.setMessageReloader(60000);
    this.getUserPolicies();
  }

  ngAfterViewInit(){
    setInterval(function() {
      if(this.appGeneralService && this.appGeneralService.showCookies){
        $('#chatBt').addClass('slideUp')
      }else{
        $('#chatBt').removeClass('slideUp');
      }
    }, 500);
  }

  setMessageReloader(milliseconds: number){
    if(this.Interval != null || this.Interval != undefined){
      clearInterval(this.Interval);
    }

    this.Interval = setInterval(() => {this.getMessages()}, milliseconds);
  }

  toggleChat(){
    this.notify = false;
    this.disabled = true;
    this.FaComment = faSpinner;
    $("#icon").addClass('Spin');
    let Icon = faTimes;
    
    if(this.hidden == true){

      this.hidden = false;
      Icon = faTimes;

      this.setMessageReloader(2000);
      
      this.apiService.callApiProd('/api/Chat/GetMessages', new CallParameter("POST", {'NewMessages': false}), false).subscribe(res => {
        
        $("#icon").removeClass('Spin');
        this.disabled = false;
        this.FaComment = Icon;
        
        if(res.InError){
          this.appGeneralService.showDialog(res.ErrorCode);
          return;
        }
  
        this.messages = res.ResultData as Chat;
        this.chatContent.scrollBottom();
        this.chatContent.setHideFaqMessage('');
        
      });
      
    }else{
      this.hidden = true;
      this.FaComment = faCommentAlt;
      this.disabled = false;
      this.setMessageReloader(60000);
    }
  
  }

  lastMessage;
  getMessages(){
    this.apiService.callApiProd('/api/Chat/GetMessages', new CallParameter("POST", {'NewMessages': true}), false).subscribe(ret => {
      let results:Chat = ret.ResultData as Chat;
      
      if(ret.InError){
        this.appGeneralService.showDialog(ret.ErrorCode);
        return;
      }
      
      if(results.Messages.length > 0 && this.lastMessage != results.Messages){
        this.messages.Messages = this.messages.Messages.concat(results.Messages);
        if(this.hidden == false){
          this.apiService.callApiProd('/api/Chat/MarkAllAsRead', new CallParameter("POST"), false).subscribe();
          return;
        }
        this.notify = true;
        this.chatContent.scrollBottom();
      }

    })
  }

  sendMessage(){
    
    this.apiService.callApiProd('/api/Chat/SendMessage', new CallParameter("POST", { "MessageContent":this.message.message, "UserPolicyID": this.message.userPolicyId }), false).subscribe(ret => {
      if (ret.InError){
        this.appGeneralService.showDialog(ret.ErrorCode);
        return;
      }
      
      this.messages.Messages.push({
        UserRead: true,
        AdminRead: false,
        Date: new Date(),
        MessageContent: this.message.message,
        SentByUser: true
      });
      this.chatContent.scrollBottom();
      this.chatContent.messageInput.nativeElement.value = '';
      this.hideToggle = false;
    })
  }

  setFaq(messageID){
    this.apiService.callApiProd('/api/Chat/SendMessage', new CallParameter("POST", { "AutomaticReplyMessageId":messageID }), false).subscribe(ret => {
      if (ret.InError){
        this.appGeneralService.showDialog(ret.ErrorCode);
        return;
      }
      
      this.messages.Messages.push({
        UserRead: true,
        AdminRead: false,
        Date: new Date(),
        MessageContent: this.messages.AutomaticReplyMessages.find(z => z.Id == messageID)?.Message,
        SentByUser: true
      });
      this.chatContent.scrollBottom();
    })
  }


  getUserPolicies(){

    this.apiService.callApiProd("/api/Chat/UserPolicies", new CallParameter("POST", {})).subscribe(ret => {

      if(ret.ResultData["userPolicySelects"].length > 0){
        this.userPolicies = ret.ResultData["userPolicySelects"];
      }

    });
  }

  checkForMessage(event: ChatMessage){
    this.message = event;
    if(event.message.length > 0){
      this.hideToggle = true;
      return;
    }
    this.hideToggle = false;
  }
}
