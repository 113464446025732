import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { FacebookLikeComponent } from 'src/app/shared/dialogs/facebook-like/facebook-like.component';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-registration-mail-sent',
  templateUrl: './registration-mail-sent.component.html',
  styleUrls: ['./registration-mail-sent.component.scss']
})
export class RegistrationMailSentComponent implements OnInit {

  sendMail = true;

  constructor(
    private appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private storageService: LocalStorageService
  ) { }

  ngOnInit() {

    if (this.storageService.getFacebookLike() == false) {

      let FacebookDialog = this.dialog.open(FacebookLikeComponent, {
        width: '400px',
        panelClass: 'custom-dialog-container'
      });

      let today = new Date();
      // var expires = new Date(today.setMonth(today.getMonth() + 1));

      FacebookDialog.afterClosed().subscribe(() => this.storageService.setFacebookLike(today));

    }

    this.activatedRoute.queryParams.subscribe(queryParam => {
      if (queryParam['sendMail']) {
        this.sendMail = JSON.parse(queryParam['sendMail']);
      }
    })

    if (this.sendMail) {
      this.GetNewMailConfirm();
    }

  }

  GetNewMailConfirm() {
    let param = new CallParameter("POST");
    param.CallData = {};
    this.apiService.callApiProd("/api/Registration/GetNewMailConfirm", param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        }
      });
  }
}
