

//CLASSE CAROUSEL DI PACCHETTI IN EVIDENZA

export class ReadmoreCarouselItem {
    
    constructor() {}
    
    static create(id: string, CoverImage: string, Title: string, Link: string) : ReadmoreCarouselItem {
        let readmore = new ReadmoreCarouselItem();
        readmore.ID = id;
        readmore.CoverImage = CoverImage;
        readmore.Title = Title;
        readmore.Link = Link;
        return readmore;
    }

    ID: string;
    CoverImage: string;
    Title: string;
    Link: string;
}