<div class="feedback-story-cntr">
  <h1 title="Le vostre storie" class="duplicate"></h1>
    <div class="general-carousel-cntr row">

        <carousel [cellWidth]="350" [height]="400">
    
    
                <div class="carousel-cell" *ngFor="let Item of Stories">
    
                    <app-sigle-story [item]="Item"></app-sigle-story>
    
                </div>
        
        </carousel>
        
    </div>
</div>
