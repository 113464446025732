import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CtWebviewService } from '@ctsolution/ct-webview';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ServiceFileParam } from 'src/app/core/classes/ServiceClasses/service-info';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { SecurityService } from 'src/app/core/services/security.service';
import { ModuleComponent } from 'src/app/shared/module/module.component';


@Component({
  selector: 'app-contracts-condition',
  templateUrl: './contracts-condition.component.html',
  styleUrls: ['./contracts-condition.component.scss']
})
export class ContractsConditionComponent implements OnInit {


  isRegistrationCompleted: boolean = false;
  isUser: boolean = false;
  isAdmin: boolean = false;
  bypassAccepted: boolean = false;
  href: string = '';
  isToCompleteRegistration: boolean = false;
  NeedsToAcceptSpecialPrivacyPolicy: boolean = false;
  SpecialPrivacyFileDownloadUrl: string = '';
  cart: boolean = false;
  faTimes = faTimes;


  infoDocument : ContractsConditionDTO;


  disableClose: boolean = true;
  apiCaller: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ContractsConditionComponent>,
    private appGeneralService: AppGeneralService,
    private securityService: SecurityService,
    private http: HttpClient,
    private router: Router,
    private apiService: ApiService,
    private environmentService: EnvironmentService,
    private storageService: LocalStorageService,
    private ctwebviewService:  CtWebviewService
  ) {

    this.isUser = this.securityService.isUser();
    this.isAdmin = this.securityService.isAdmin();
    this.apiCaller = environmentService.Url;
    if (this.data['disableClose'] !== undefined && this.data['disableClose'] !== null) {
      this.disableClose = this.data['disableClose'];
    }
    if (this.data['BypassAccepted'] !== undefined && this.data['BypassAccepted'] !== null) {
      this.bypassAccepted = this.data['BypassAccepted'];
    }
    if (this.router.url.indexOf('/acquista-polizza/') > -1) {
      this.cart = true;
    }

  }

  ngOnInit() {

    this.setValue();

  }


  setValue(){

    this.infoDocument = ContractsConditionDTO.create();


    if(this.data['UserPolicyID'] && this.data['PolicyGroupId']){

      this.infoDocument._UserPolicyId = this.data['UserPolicyID'];
      this.infoDocument._PolicyGroupId = this.data['PolicyGroupId'];
      this.infoDocument._Text = "Per tutelare i Vostri interessi e garantire massima trasparenza, in linea con le normative <b><a href='https://www.ivass.it/' target='_blank'>IVASS</a> </b>, forniamo di seguito la documentazione informativa:";

    }

    else  if(this.data['ServiceID'] && this.data['UserServiceID']){

      this.infoDocument._UserServiceId = this.data['UserServiceID'];
      this.infoDocument._ServiceID = this.data['ServiceID'];
      this.infoDocument._IsService = true;
      this.infoDocument._Text ="Scarica questi documenti prima di poter acquistare il servizio";
      this.GetServiceDocuments();
      return;
    }
    this.GetDocuments();

  }

  close(allow?) {
    this.dialogRef.close(allow);
  }

  GetDocuments() {
    const CallData = {
      PolicyGroupId: this.infoDocument._PolicyGroupId,
      BypassAccepted: this.bypassAccepted.valueOf()
    };
    this.apiService.callApiProd('/api/Account/GetPolicyAcceptanceDocuments', new CallParameter('POST', CallData)).subscribe((result) => {
      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.NeedsToAcceptSpecialPrivacyPolicy = result.ResultData['NeedsToAcceptSpecialPrivacyPolicy'];
        this.SpecialPrivacyFileDownloadUrl = result.ResultData['SpecialPrivacyFileDownloadUrl'];

        if (result.ResultData['PolicyDocuments'].length > 0
          || result.ResultData['Modules'].length > 0) {
          this.infoDocument._Documents = result.ResultData['PolicyDocuments'];
          this.infoDocument._Modules = result.ResultData['Modules'];

        } else {
          this.close(true);
        }

      }

    });

  }

  GetServiceDocuments() {
    const CallData = {
      ServiceID: this.infoDocument._ServiceID,
      UserServiceID: this.infoDocument._UserServiceId
    };




    this.apiService.callApiProd('/api/Service/GetUserServiceDocumentNotRead', new CallParameter('POST', CallData)).subscribe((result) => {


      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.infoDocument._Documents = result.ResultData as Array<any>;

      }

    });

  }

  openModuleModal(Url: string) {


    this.http.get<any>(Url).subscribe(data => {
      let dialogRef = this.dialog.open(ModuleComponent, {
        panelClass: 'SurveyModalCntr',
        maxHeight: '90vh',
        data: {
          contenuto: data
        },
        autoFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
         this.GetDocuments();
      });
    })
  }

  onAccept() {

    if(this.infoDocument._IsService){
      this.onAcceptService();
      return;
    }

    const CallData = {
      PolicyFileIDs: this.infoDocument._Documents.map(document => document['ID']),
      ModuleIDs: this.infoDocument._Modules.map(module => module['ModuleID'])
    };

    this.apiService.callApiProd('/api/Account/SetPolicyDocumentsAccepted', new CallParameter('POST', CallData)).subscribe((result) => {

      if (result.InError) {
        this.close(false);
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {
        this.close(true);

        if (this.ctwebviewService.isAppleDevice()) {

          var element = document.createElement('a');
          element.setAttribute('href', this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?' + this.infoDocument._Documents.map(document => 'PolicyFileIds=' + document['ID']).join('&'));
          element.setAttribute('download', '');
          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        } else {

          let path: string = this.environmentService.Url + '/api/Policy/GetZippedPolicyDocuments?' + this.infoDocument._Documents.map(document => 'PolicyFileIds=' + document['ID']).join('&');

          window.open(path);
        }

        if ((this.data != null || this.data != undefined) && (this.infoDocument._UserPolicyId != undefined || this.infoDocument._UserPolicyId != null)) {
          this.router.navigate(['acquista-polizza/' + this.infoDocument._UserPolicyId]);
        } else if (this.securityService.isUser()) {
          let path = this.storageService.getOrSetDefaultLastPageSeen('assicurazioni');
          this.router.navigate([path]);
        }
      }
    });

  }
  onAcceptService() {


    let serviceFileIds :Array<number> = [];
    this.infoDocument._Documents.forEach(file => {

      serviceFileIds.push(file.ID);

    });

    let param = new ServiceFileParam(serviceFileIds,this.infoDocument._UserServiceId);


    let callApiParameter =CallApiProdParameter.create('/api/Service/SetServicesRead',param,'POST').setSpinner(true).setKeepSpinner(false);

    this.apiService.callApiProduction(callApiParameter).subscribe((result) => {


       if (result.InError) {
          this.close(false);
          this.appGeneralService.showDialog(result.ErrorCode);
       } else {
          this.close(true);


         if (this.ctwebviewService.isAppleDevice()) {

          var element = document.createElement('a');
          element.setAttribute('href', this.environmentService.Url + '/api/Service/GetZippedServiceDocuments?' + this.infoDocument._Documents.map(file => 'serviceFileIds=' + file['ID']).join('&'));
          element.setAttribute('download', '');
          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);

        } else if(this.infoDocument._Documents.length >1){

          let path: string = this.environmentService.Url + '/api/Service/GetZippedServiceDocuments?' + this.infoDocument._Documents.map(file => 'serviceFileIds=' + file['ID']).join('&');

          window.open(path);
        }else{

          this.appGeneralService.GoToExternalURL(this.infoDocument._Documents[0].DownloadUrl)

        }
      }


    });
  }

}

export class ContractsConditionDTO{

  private Documents?: Array<any> = [];
  public get _Documents(): Array<any> {
    return this.Documents;
  }
  public set _Documents(value: Array<any>) {
    this.Documents = value;
  }
  private Modules?: Array<any> = [];
  public get _Modules(): Array<any> {
    return this.Modules;
  }
  public set _Modules(value: Array<any>) {
    this.Modules = value;
  }
  private PolicyGroupId?: number;
  public get _PolicyGroupId(): number {
    return this.PolicyGroupId;
  }
  public set _PolicyGroupId(value: number) {
    this.PolicyGroupId = value;
  }
  private UserPolicyId?: number;
  public get _UserPolicyId(): number {
    return this.UserPolicyId;
  }
  public set _UserPolicyId(value: number) {
    this.UserPolicyId = value;
  }
  private IsService?: boolean;
  public get _IsService(): boolean {
    return this.IsService;
  }
  public set _IsService(value: boolean) {
    this.IsService = value;
  }
  private UserServiceId?: number;
  public get _UserServiceId(): number {
    return this.UserServiceId;
  }
  public set _UserServiceId(value: number) {
    this.UserServiceId = value;
  }
  private ServiceID?: number;
  public get _ServiceID(): number {
    return this.ServiceID;
  }
  public set _ServiceID(value: number) {
    this.ServiceID = value;
  }

  private Text?: string;
  public get _Text(): string {
    return this.Text;
  }
  public set _Text(value: string) {
    this.Text = value;
  }

  private constructor(documents?: Array<any>,modules?: Array<any>,policyGroupId?: number,userPolicyId?: number,isService?: boolean,userServiceId? :number,serviceID?: number, text?: string){

    this.Documents = documents;
    this.Modules = modules;
    this.PolicyGroupId = policyGroupId;
    this.UserPolicyId = userPolicyId;
    this.IsService = isService ?? false;
    this.UserServiceId = userServiceId;
    this.ServiceID = serviceID;
    this.Text = text;

  }

  public static create(documents?: Array<any>,modules?: Array<any>,policyGroupId?: number,userPolicyId?: number,isService?: boolean,userServiceId? :number,serviceID?: number,text?: string) : ContractsConditionDTO {


    return new ContractsConditionDTO(documents,modules,policyGroupId,userPolicyId,isService,userServiceId,serviceID,text);

  }



}


