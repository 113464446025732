<ng-container *ngIf="Filtri != null && Filtri.length > 0">

  <div class="carousel row">

    <carousel [cellWidth]="280" [height]="340" [cellsToShow]="cellsToShow()">

      <ng-container *ngFor="let scheda of Filtri">
        <div class="carousel-cell">
          <app-header-background-scheda [Scheda]="scheda"></app-header-background-scheda>
        </div>
      </ng-container>
    </carousel>
  </div>


</ng-container>
