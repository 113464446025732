<h3 mat-dialog-title class="dialogTitle">Seleziona Profilo</h3>

<div mat-dialog-content class="d-block">
  <p>Seleziona il profilo lavorativo per cui comprare la polizza.</p>
  <form [formGroup]="chooseProfileForm">

    <mat-form-field color='accent' class="w-100 mb-3">
      <mat-select matNativeControl required [formControl]="ProfilesForm" placeholder="Seleziona Profilo">
        <mat-option *ngFor="let profile of Profiles" [value]="profile.ProfileID">
          {{ profile.Name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ProfilesForm.hasError('required')">
        Selezionare <strong>profilo</strong>
      </mat-error>
    </mat-form-field>

  </form>
</div>
<div mat-dialog-actions class="text-center">
  <button class="new-button" (click)="Save()">Ok</button>
  <button class="new-button" (click)="close()">Annulla</button>
</div>
