import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileDTO } from 'src/app/core/classes/Files/fileDTO';
import { CallResult } from 'src/app/core/classes/system/call-result';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';

@Component({
  selector: 'recess-info-dialog',
  template: `<h3 mat-dialog-title>Informativa Recesso</h3>
              <div mat-dialog-content class="recessInformativeContainer">
                <p>Nei contratti stipulati a distanza è previsto il diritto del contraente di recedere unilateralmente dal contratto senza incorrere in penali e senza alcun obbligo di spiegazione dei propri motivi nei confronti dell’intermediario: nel caso delle polizze danni, tale diritto va esercitato entro 14 giorni dalla data di conclusione del contratto o dalla comunicazione al cliente dell’avvenuta conclusione.
Per le polizze vita, nel caso in cui il contraente e l’intermediario si scambiano una proposta-polizza che produce immediatamente gli effetti del contatto non appena viene sottoscritta, il contraente può esercitare il diritto di recesso, fino ai 30 giorni successivi a quando è stato informato della stipula.
Ti ricordiamo che nella sezione prodotti acquistati è presente il tasto per recedere automaticamente dal contratto. </p>
              </div>
              <div mat-dialog-actions>
                  <button class="new-button float-right"   (click)="close()">ok</button>
              </div>`,
  styles: [`
          .recessInformativeContainer {
            max-height: 405px;
          }

            @media screen and (max-width: 540px){
              .recessInformativeContainer {
                padding-right: 15px;
              overflow-y: scroll;
            }
            }

          @media screen and (max-width:360px){
            .recessInformativeContainer{
              max-height:305px;
            }
          }
          `]
})
export class RecessInfoComponent {
  constructor(
    public dialogRef: MatDialogRef<RecessInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  close() {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  CopyYear = new Date().getFullYear();
  Files: FileDTO[] = [];

  constructor(
    public dialog: MatDialog,
    public environmentService: EnvironmentService,
    private ApiService: ApiService,
    private appGeneralService: AppGeneralService
    ) {


  }

  ngOnInit() {
    var callApiProdParameter: CallApiProdParameter = CallApiProdParameter.create("/api/Policy/GetSitePolicyDocuments", null, "GET");
    this.ApiService.callApiProduction(callApiProdParameter).subscribe((data: CallResult) => {
      if(data.InError){
        this.appGeneralService.showDialog(data.ErrorCode);
      }
      this.Files = data.ResultData as FileDTO[];
    });
  }

/**
 * Open a dialog box that displays the Recess Info form
 */
  RecessInfo() {
    let deletedialog = this.dialog.open(RecessInfoComponent, {
      width: '600px',
      panelClass: 'custom-dialog-container'
    });
  }
}
