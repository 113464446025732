import { ModuleWithProviders, NgModule } from "@angular/core";
import { PricePipe } from "./price.pipe";
import { SafePipe } from "./safe.pipe";

@NgModule({
  imports:        [],
  declarations:   [SafePipe, PricePipe],
  exports:        [SafePipe, PricePipe],
})

export class PipeModule {

  static forRoot(): ModuleWithProviders<PipeModule> {
    return {
        ngModule: PipeModule,
        providers: [],
    };
}
} 