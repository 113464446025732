<form [formGroup]="formPasswordRecover" (ngSubmit)="passwordRecovery()" autocomplete="off">

  <mat-form-field color='accent'>
      <mat-label>Inserisci E-mail o Codice Fiscale e clicca Recupera Password</mat-label>
      <input type="text" matInput required formControlName="Email"
          name="CForEmail" autocomplete="new-cf-mail">
          <!-- name="CForEmail" autocomplete="new-cf-mail" [errorStateMatcher]="matcher"> -->
      <!-- <mat-error *ngIf="formPasswordRecover['Email'].hasError('required')">
          Inserire <strong>e-mail</strong> o <strong>codice fiscale</strong></mat-error> -->
  </mat-form-field>
  <br>
  <button class="new-button" style="width:100%; margin:0;">Recupera Password</button>
</form>
