import { Component, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { CardType } from 'src/app/core/enum/card-type.enum';
import { UrlBuilderService } from 'src/app/core/services/url-builder.service';
import { FeedService } from '../feed-carousel/feed.service';

@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: ['./general-card.component.scss']
})
export class GeneralCardComponent implements OnInit {

  public isCollapsed = true;

  icons = {
    faChevron: faChevronDown,
    faElipsis: faEllipsisH
  }

  @Input() SingleFeed: any;
  @Input() FeedCarouselID;
  @Input() cardType: CardType;
  @Input() cardWidth: number;
  @Input() cardAction: ICardAction;
  @Input() buttonText: string;
  @Output() collapsed: boolean;
  @Input() showPrice: boolean = true;


  viewModel: any = {

    homeCard: false,
    policyCard: false,
    serviceCard: false,
    genericCard: false
  }

  constructor(
    private feedService: FeedService,
    private urlBuilderService: UrlBuilderService
  ) { }

  ngOnInit() {
    if (this.cardType) {

      this.viewModel.homeCard = this.cardType == CardType.Home;
      this.viewModel.policyCard = this.cardType == CardType.Policy;
      this.viewModel.serviceCard = this.cardType == CardType.Service;
      this.viewModel.genericCard = this.cardType == CardType.Generic;
    }

    if(this.SingleFeed?.PolicyInfoPreview?.PolicyGroupId){
      this.viewModel.policyCard = true;
      this.viewModel.homeCard = false;
      this.viewModel.serviceCard = false;
      this.viewModel.genericCard = false;
    }

    if(this.SingleFeed.ProposalId){
      this.SingleFeed = this.SingleFeed.PolicyInfoPreview;
    }

  }

  goToDetail(element?: any) {
    if (this.viewModel.homeCard) {
      this.urlBuilderService.navigateToPath(element.ID, 0, null);
    } else if (this.viewModel.serviceCard) {
      this.urlBuilderService.navigateToServicePath(element.ID);
    } else if (this.viewModel.policyCard) {
      this.urlBuilderService.navigateToPath(element.PolicyGroupId, element.ID, element.PolicyProposalId);
    }

  }

}


export interface ICardAction {
  (): void;
}
