<div class="about-us-cntr">

  <div class="txt-cntr">

    <h1>Chi siamo</h1>
    <h3 style="font-weight: unset !important;">La nostra azienda, fondata da Fabrizio Buzzi nel 1992 con radici storiche nel settore assicurativo, ha sempre avuto uno spirito intraprendente. Fin dall'infanzia, Fabrizio ha coinvolto suo figlio Jacopo nell'azienda, consentendogli di crescere e apprendere il mestiere. Jacopo ha iniziato a lavorare con mansioni semplici, come rispondere al telefono e gestire il calendario delle cadenze delle polizze, ma con il passare del tempo, ha sviluppato una visione aziendale centrata sulla semplicità. La nostra azienda funziona come una macchina ben oliata, con collaboratori affidabili e sicuri come il motore, una filosofia aziendale consolidata come la carrozzeria e l'elettronica, e Fabrizio e Jacopo come i piloti al volante, tutti diretti verso un unico obiettivo: la semplicità.</h3>
      <button class="new-button" (click)="navigate()">VISITA IL SITO</button>

  </div>

  <!-- <div class="img-cntr">

    <img src="../../../../../../assets/images/Chi-siamo/buzzi.webp">

  </div> -->

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>
