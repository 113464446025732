<div name="spid_idp_access" action="#" method="post">
  <a href="#" class="italia-it-button italia-it-button-size-l button-spid" spid-idp-button="#spid-idp-button-large-post"
    aria-haspopup="true" aria-expanded="false">
    <span class="italia-it-button-icon"><img src="/assets/images/SPID/spid-ico-circle-bb.svg"
        onerror="this.src='/assets/images/SPID/spid-ico-circle-bb.png'; this.onerror=null;" alt="" /></span>
    <span class="italia-it-button-text">Entra con SPID</span>
  </a>
  <a class="infoContainer" (click)="spid.openInfoDialog()" title="Info">
    <fa-icon class="info" [icon]="faInfo"></fa-icon>
  </a>
  <div id="spid-idp-button-large-post" class="spid-idp-button spid-idp-button-tip spid-idp-button-relative">
    <ul id="spid-idp-list-large-root-post" class="spid-idp-button-menu" aria-labelledby="spid-idp">
      <li class="spid-idp-button-link" data-idp="arubaid">
        <button (click)="callSpidLogin('Aruba')" class="idp-button-idp-logo" name="aruba_id" type="submit"><span
            class="spid-sr-only">Aruba ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-arubaid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-arubaid.png'; this.onerror=null;" alt="Aruba ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="infocertid">
        <button (click)="callSpidLogin('Infocert')" class="idp-button-idp-logo" name="infocert_id" type="submit"><span
            class="spid-sr-only">Infocert
            ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-infocertid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-infocertid.png'; this.onerror=null;"
            alt="Infocert ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="intesaid">
        <button (click)="callSpidLogin('Intesa')" class="idp-button-idp-logo" name="intesa_id" type="submit"><span
            class="spid-sr-only">Intesa
            ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-intesaid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-intesaid.png'; this.onerror=null;"
            alt="Intesa ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="lepidaid">
        <button (click)="callSpidLogin('Lepida')" class="idp-button-idp-logo" name="lepida_id" type="submit"><span
            class="spid-sr-only">Lepida
            ID</span><img class="spid-idp-button-logo" src="https://id.lepida.it/idm/app/pubblica/lepida_spid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-lepidaid.png'; this.onerror=null;"
            alt="Lepida ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="namirialid">
        <button (click)="callSpidLogin('Namirial')" class="idp-button-idp-logo" name="namirial_id" type="submit"><span
            class="spid-sr-only">Namirial
            ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-namirialid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-namirialid.png'; this.onerror=null;"
            alt="Namirial ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="posteid">
        <button (click)="callSpidLogin('Poste')" class="idp-button-idp-logo" name="poste_id" type="submit">
          <span class="spid-sr-only">Poste ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-posteid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-posteid.png'; this.onerror=null;" alt="Poste ID" />
        </button>
      </li>
      <li class="spid-idp-button-link" data-idp="Register">
        <button (click)="callSpidLogin('Register')" class="idp-button-idp-logo" name="Register_id" type="submit">
          <span class="spid-sr-only">Register</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-spiditalia.png"
            onerror="this.src='/assets/images/SPID/spid-idp-spiditalia.svg'; this.onerror=null;" alt="Register" />
        </button>
      </li>
      <li class="spid-idp-button-link" data-idp="sielteid">
        <button (click)="callSpidLogin('Sielte')" class="idp-button-idp-logo" name="sielte_id" type="submit"><span
            class="spid-sr-only">Sielte
            ID</span><img class="spid-idp-button-logo"
            src="https://raw.githubusercontent.com/italia/spid-graphics/master/idp-logos/spid-idp-sielteid.png"
            onerror="this.src='/assets/images/SPID/spid-idp-sielteid.png'; this.onerror=null;"
            alt="Sielte ID" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="spiditalia">
        <button (click)="callSpidLogin('Validator')" class="idp-button-idp-logo" name="spiditalia" type="submit"><span
            class="spid-sr-only">SPIDItalia
            Register.it</span><img class="spid-idp-button-logo" src="https://validator.spid.gov.it/img/idp-logo.png"
            onerror="this.src='/assets/images/SPID/spid-idp-spiditalia.png'; this.onerror=null;"
            alt="SpidItalia" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="spiditalia">
        <button (click)="callSpidLogin('WSO2')" class="idp-button-idp-logo" name="WSO2" type="submit"><span
            class="spid-sr-only">WSO2</span><img class="spid-idp-button-logo" src="/assets/images/SPID/spid-idp-localwso2id.png"
            onerror="this.src='/assets/images/SPID/spid-idp-localwso2id.png'; this.onerror=null;"
            alt="WSO2" /></button>
      </li>
      <li class="spid-idp-button-link" data-idp="timid">
        <button (click)="callSpidLogin('Tim')" class="idp-button-idp-logo" name="tim_id" type="submit"><span
            class="spid-sr-only">Tim ID</span><img class="spid-idp-button-logo"
            src="/assets/images/SPID/spid-idp-timid.svg"
            onerror="this.src='/assets/images/SPID/spid-idp-timid.png'; this.onerror=null;" alt="Tim ID" /></button>
      </li>
      <li class="spid-idp-support-link" data-spidlink="info">
        <a href="https://www.spid.gov.it">Maggiori informazioni</a>
      </li>
      <li class="spid-idp-support-link" data-spidlink="rich">
        <a href="https://www.spid.gov.it/richiedi-spid">Non hai SPID?</a>
      </li>
      <li class="spid-idp-support-link" data-spidlink="help">
        <a href="https://www.spid.gov.it/serve-aiuto">Serve aiuto?</a>
      </li>
    </ul>
  </div>
</div>