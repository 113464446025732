<app-treedom [isPage]="true"></app-treedom>

<div class="container treedom-page-cntr">

  <div class="txt-cntr">

    <div class="info">

      <h1 style="margin-bottom: 30px;">MyInsurer, insieme a treedom per un impatto positivo sull'ambiente</h1>
      <h3>Grazie all'impegno di Buzzi Insurance Group ogni polizza acquistata su Myinsurer contribuisce alla
        piantumazione di alberi tramite il progetto Treedom.<br> <br>
        Scegli MyInsurer e unisciti a noi nell'azione per un pianeta più verde e sostenibile!</h3>

      <button class="new-button" (click)="goToTreedom()">Scopri Treedom</button>

    </div>

    <div class="icons">

      <div class="icon">

        <img src="../../../assets/images/Treedom/alberi.svg">
        <h1>65</h1>
        <h3>Alberi</h3>

      </div>
      <div class="icon">

        <img src="../../../assets/images/Treedom/co2.svg">
        <h1>-53,83 t*</h1>
        <h3>CO₂</h3>

      </div>
      <div class="icon">

        <img src="../../../assets/images/Treedom/paesi.svg">
        <h1>6</h1>
        <h3>Paesi</h3>

      </div>

    </div>


  </div>

  <div class="mappa-cntr row">

    <div class="img-cntr col-lg-6 col-md-6 col-sm-12">

      <img src="../../../assets/images/Treedom/mappa.webp" alt="">

    </div>

    <div class="nazioni col-lg-6 col-md-6 col-sm-12">

      <div class="row">

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Malawi</div>
            <div class="trees">10 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/malawi.svg" alt=""></div>
          </div>

        </div>

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Ghana</div>
            <div class="trees">20 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/ghana.svg" alt=""></div>
          </div>

        </div>

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Madagascar</div>
            <div class="trees">10 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/madagascar.svg" alt=""></div>
          </div>

        </div>

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Kenya</div>
            <div class="trees">10 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/kenya.svg" alt=""></div>
          </div>

        </div>

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Ecuador</div>
            <div class="trees">10 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/ecuador.svg" alt=""></div>
          </div>

        </div>

        <div class="nazione-cntr col-lg-4 col-md-4 col-sm-4">

          <div class="nazione">
            <div class="title">Colombia</div>
            <div class="trees">10 alberi</div>
            <div class="flag"><img src="../../../assets/images/Treedom/colombia.svg" alt=""></div>
          </div>

        </div>

      </div>

    </div>

  </div>

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

</div>

<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>