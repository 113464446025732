<ng-template #InfoDocuments>
  <app-info-document [data]="CartPolicyDetail.InformativeDocuments"
    (onDocumentRead)="getCartPolicyDetail()"></app-info-document>
</ng-template>

<ng-template #UploadDocuments>
  <h3 style="font-weight: initial!important; margin-left: 10px;">Carica i documenti</h3>
  <ng-container *ngFor="let Doc of CartPolicyDetail.Documents">
    <ng-container *ngIf="Doc.Type != 7">

      <div class="document-cntr" [ngClass]="{'readed': Doc.WasAlreadyUploaded}">

        <div class="txt-cntr">

          <h3>{{Doc.Name}}</h3>

        </div>

        <div class="button-cntr">

          <button *ngIf="Doc.WasAlreadyUploaded" class="new-button"
            (click)="openDeleteModal(UserPolicyID, Doc.ID)">ELIMINA</button>
          <button *ngIf="!Doc.WasAlreadyUploaded" class="new-button"
            (click)="handleDocumentsUpload(Doc)">CARICA</button>

        </div>

      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let Module of  CartPolicyDetail.Modules">
    <div class="document-cntr" [ngClass]="{'readed': Module.WasAlreadyUploaded}">

      <div class="txt-cntr">

        <h3>Questionario di adeguatezza</h3>

      </div>

      <div class="button-cntr">

        <button *ngIf="Module.WasAlreadyUploaded" class="new-button"
          (click)="openDeleteModal(UserPolicyID, Module.ModuleID, Module.CompiledModuleID)">ELIMINA</button>
        <button *ngIf="!Module.WasAlreadyUploaded" class="new-button"
          (click)="openModuleModal(Module.Url)">COMPILA</button>

      </div>

    </div>

  </ng-container>
</ng-template>

<ng-template #Payment>
  <app-payment [ProductId]="CartPolicyDetail.ID" [Product]="CartPolicyDetail" [Type]="productType.Policy" [User]="User"
    [TransferPayment]="externalCaller()"></app-payment>
</ng-template>



<div class="buy-policy-cntr">

  <h1 class="duplicate" title="ACQUISTO"></h1>
  <ng-container *ngIf="CartPolicyDetail">

    <div class="buy-policy">

      <div class="info-cntr">
      <div class="img-cntr">

        <!-- <div class="quadrato-cntr" [ngClass]="CartPolicyDetail.CartPolicy.Title">

          <h3>{{CartPolicyDetail.CartPolicy.Title}}</h3>
          <div class="divisore"></div>
          <div class="striscia-price">
            <h3 *ngIf="CartPolicyDetail.CartPolicy.FakePrice"
              style="text-decoration:line-through; font-weight: unset!important;">
              {{CartPolicyDetail.CartPolicy.FakePrice | price}}</h3>
            <h3> {{CartPolicyDetail.CartPolicy.Price | price}}</h3>
          </div>

        </div>-->
          <div class="policy-price-boxes-scheda-cntr" ngClass="{{CartPolicyDetail.CartPolicy.Title}}">

            <div class="title-cntr">

              <h3>{{CartPolicyDetail.CartPolicy.Title}}</h3>

            </div>

            <div class="price-cntr">

              <h3>{{CartPolicyDetail.CartPolicy.Price}} €</h3>

            </div>

            <div class="accordion" (click)="toggleAccordion()"><h3 style="color: white;">Dettagli</h3> <fa-icon style="font-size:15px; position:sticky!important;padding: revert-layer!important; margin-top: 5px!important;margin-left: 10px; color: white;" [icon]="faChevronDownDocuments" id="DocPanelIcon"></fa-icon></div>

            <div class="garanzie-cntr closeAccordion" [ngClass]="{'openAccordion': isAccordionOpen}">

              <ng-container *ngFor="let section of CartPolicyDetail.ConfiguratorSections">

                <h3>{{section.SectionName}}</h3>

                <ng-container *ngFor="let garanzia of section.Options">

                  <div class="garanzia">
                    <div class="txt-cntr">
                      <img src="../../../../../../assets/images/Configuratore/i_info.svg" (click)="openInfoDialog(garanzia.Description, garanzia.Title)">
                      <h3>{{garanzia.Title}}</h3>
                    </div>
                      <ng-container *ngIf="garanzia.SubValueDatas && garanzia.SubValueDatas.length > 0">
                        <div class="bold">{{garanzia.SubValueDatas[0].Maximal | price}}</div>
                    </ng-container>
                    <ng-container *ngIf="!garanzia.SubValueDatas && garanzia.SubValueDatas.length > 0 && garanzia.ChosenValue != null && garanzia.ChosenValue != undefined ">
                      <div class="bold">{{garanzia.ChosenValue}} &euro;</div>
                    </ng-container>
                    <ng-container *ngIf="garanzia.ChosenValue == null && garanzia.ChosenValue == undefined && garanzia.SubValueDatas.length <= 0">
                      <div class="bold">0 &euro;</div>
                    </ng-container>
                  </div>

                </ng-container>

              </ng-container>

            </div>

          </div>
          <div class="img-background"
          [ngStyle]="{'background-image': 'url('+ CartPolicyDetail.CartPolicy.ImageUrls[0] +')'}"></div>

      </div>

      <div class="txt-cntr" *ngIf="!CartPolicyDetail.ConfiguratorSections" >

        <span [innerHTML]="CartPolicyDetail.CartPolicy.ExtendedDescription"></span>

      </div>
    </div>

      <div class="stepper-cntr">

        <h2>{{CartPolicyDetail.CartPolicy.Title}}</h2>

        <ng-container [formGroup]="form">

          <ng-container
            *ngIf="CartPolicyDetail.CartPolicy.SubUserAllowed && CartPolicyDetail.CartPolicy.SubUsers.length > 0">

            <mat-form-field>

              <mat-label>Assicurato:</mat-label>
              <mat-select class="mat-form-input" formControlName="insured" (selectionChange)="save()">

                <mat-option [value]="-1">{{UserName}}</mat-option>

                <ng-container *ngFor="let subUser of CartPolicyDetail.CartPolicy.SubUsers">

                  <mat-option [value]="subUser.Id">
                    {{subUser.Name}} {{subUser.Surname}} {{subUser.BusinessName}}</mat-option>

                </ng-container>

              </mat-select>
            </mat-form-field>

          </ng-container>

        </ng-container>

        <ng-container *ngIf="User && CartPolicyDetail">
          <app-stepper [Steps]="Steps" [isHorizontal]="isHorizontal" [selectedStep]="selectedStep"></app-stepper>
        </ng-container>

      </div>
    </div>

  </ng-container>

</div>
<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>
