import { Component, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SpidService } from 'src/app/core/services/spid.service';

@Component({
  selector: 'app-spid-button',
  templateUrl: './spid-button.component.html',
  styleUrls: ['./spid-button.component.scss']
})
export class SpidButtonComponent implements OnInit {

  faInfo = faInfoCircle; 

  constructor(
    public spid:SpidService
  ) { }

  ngOnInit() {
    this.spid.loadSPIDScript();
  }

  callSpidLogin(url){
    this.spid.callSpidLogin(url)
  }

}
