
<div class="configuratore-scheda-polizza-cntr" (click)="toggleButton()">

  <div class="img-cntr">

      <img src="{{SingleFeed.ImageUrls}}">

  </div>

  <div class="txt-cntr">

    <h3 [innerHTML]="[SingleFeed.TileTitle ?? SingleFeed.Title]"></h3>
    <!-- <h3 style="font-weight: unset!important;" [innerHTML]="[SingleFeed.ExtendedDescription]"></h3> -->
    <h3 style="font-weight: unset!important;"> Configura la tua polizza!</h3>

  </div>

  <div class="button-cntr">

    <button class="new-button" [class.clicked]="isSelected" >CONFIGURA</button>

  </div>

  <img src="../../../../assets/images/Configuratore/INFO.svg" class="info-img" (click)="openInfoDialog(SingleFeed.ExtendedDescription,'Info')" />

</div>
