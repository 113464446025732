import { DocumentType } from 'src/app/core/enum/document-type.enum';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-adhesion',
  templateUrl: './adhesion.component.html',
  styleUrls: ['./adhesion.component.scss']
})
export class AdhesionComponent implements OnInit {

  ModuloDiAdesione: UntypedFormGroup;

  DocumentType = DocumentType;

  isSubmitted: boolean = false;

  JobRequired: boolean = false;

  FoodTechnologistsModule: boolean = false;
  DefaultAdhesionModule: boolean = false;

  happenedDamageDescriptionNeeded: boolean = false;

  param: CallParameter = null;

  adhesionModuleUrl: string = "";

  constructor(
    public dialogRef: MatDialogRef<AdhesionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private appGeneralService: AppGeneralService,
    private apiService: ApiService
  ) {

    this.param = new CallParameter("POST");
    this.adhesionModuleUrl = "/api/Policy/CreateAdhesionModule";
    if (data['DocumentModuleTypeCode'] !== undefined && data['DocumentModuleTypeCode'] !== null) {
      switch (data['DocumentModuleTypeCode']) {
        case DocumentType.TECNOLOGI_ALIMENTARI_ADHESION_MODULE:
          this.FoodTechnologistsModule = true;
          this.adhesionModuleUrl = '/api/Policy/CreateTecnologiAdhesionModule';
          break;
        default:
          this.DefaultAdhesionModule = true;
          break;
      }
    } else {
      this.DefaultAdhesionModule = true;
    }

    this.ModuloDiAdesione = this.formBuilder.group({
      Freelance: new UntypedFormControl(false),
      Employed: new UntypedFormControl(false),
      StudioProfessionale: new UntypedFormControl(''),
      RichiesteDiRisarcimento: new UntypedFormControl('', Validators.required),
      ConoscenzaDanni: new UntypedFormControl('', Validators.required),
      TutelaPatrimonioATerzi: new UntypedFormControl('', Validators.required),
      TutelaPatrimonioResponsabilitaCivile: new UntypedFormControl('', Validators.required),
      TutelaPatrimonioATerziInAttivita: new UntypedFormControl('', Validators.required),
      PrimoConsenso: new UntypedFormControl(false)
      // SecondoConsenso: new FormControl('', [Validators.required, Validators.pattern('true')]),
      // TerzoConsenso: new FormControl('', Validators.required),
      // QuartoConsenso: new FormControl('', Validators.required)
    });

    if (this.FoodTechnologistsModule) {
      this.ModuloDiAdesione.addControl('Revenue', new UntypedFormControl('', Validators.required));
      this.ModuloDiAdesione.addControl('Albo', new UntypedFormControl('', Validators.required));
      this.ModuloDiAdesione.addControl('ActivityDescription', new UntypedFormControl('', Validators.required));
      this.ModuloDiAdesione.addControl('DamageDescription', new UntypedFormControl(''));
    }
  }

  CookieBanner: boolean = false;

  ngOnInit() {

    if ($('.cookieCntr .AcceptancePolicyBannerCntr').is(':visible') || $('.cookieCntr .CookieMessageCntr').is(':visible')) {
      this.CookieBanner = true;
      $('.cookieCntr').css('z-index', '0');
    }

  }

  ngOnDestroy() {
    if (this.CookieBanner) {
      $('.cookieCntr').css('z-index', '');
    }
  }

  /**
   * * If the Food Technologists Module is selected, then the Happened Damage Description is required
   */
  handleHappenedDamageDescription() {

    if (this.FoodTechnologistsModule) {

      this.happenedDamageDescriptionNeeded = this.ModuloDiAdesione.get('RichiesteDiRisarcimento').value || this.ModuloDiAdesione.get('ConoscenzaDanni').value;

      if (this.happenedDamageDescriptionNeeded) {
        this.ModuloDiAdesione.get('DamageDescription').setValidators([Validators.required]);
        this.ModuloDiAdesione.get('DamageDescription').updateValueAndValidity();
      } else {
        this.ModuloDiAdesione.get('DamageDescription').clearValidators();
        this.ModuloDiAdesione.get('DamageDescription').updateValueAndValidity();
      }

    }

  }

  /**
   * * If the checkbox is checked, clear the validators for the three fields.
   * * If the checkbox is unchecked, set the validators for the three fields to required
   * @param input - The checkbox that was clicked.
   */
  CheckNextValidators(input) {
    if (JSON.parse(input.checked)) {
      this.ModuloDiAdesione.get('TutelaPatrimonioATerzi').clearValidators();
      this.ModuloDiAdesione.get('TutelaPatrimonioATerzi').updateValueAndValidity();

      this.ModuloDiAdesione.get('TutelaPatrimonioResponsabilitaCivile').clearValidators();
      this.ModuloDiAdesione.get('TutelaPatrimonioResponsabilitaCivile').updateValueAndValidity();

      this.ModuloDiAdesione.get('TutelaPatrimonioATerziInAttivita').clearValidators();
      this.ModuloDiAdesione.get('TutelaPatrimonioATerziInAttivita').updateValueAndValidity();
    } else {
      this.ModuloDiAdesione.get('TutelaPatrimonioATerzi').setValidators([Validators.required]);
      this.ModuloDiAdesione.get('TutelaPatrimonioATerzi').updateValueAndValidity();

      this.ModuloDiAdesione.get('TutelaPatrimonioResponsabilitaCivile').setValidators([Validators.required]);
      this.ModuloDiAdesione.get('TutelaPatrimonioResponsabilitaCivile').updateValueAndValidity();

      this.ModuloDiAdesione.get('TutelaPatrimonioATerziInAttivita').setValidators([Validators.required]);
      this.ModuloDiAdesione.get('TutelaPatrimonioATerziInAttivita').updateValueAndValidity();
    }
  }

  get formAdesionControls() { return this.ModuloDiAdesione.controls }

  myDate = new Date();

  close(param): void {
    this.dialogRef.close(param);
  }

  /**
   * It calls the API to save the data.
   * @returns The adhesion module is a module that is used to collect data from the user. The data is
   * then used to create a policy.
   */
  onSubmit(): void {

    this.isSubmitted = true;
    this.JobRequired = false;

    if (this.ModuloDiAdesione.invalid || (!Boolean(this.ModuloDiAdesione.get('Employed').value) && !Boolean(this.ModuloDiAdesione.get('Freelance').value))) {
      setTimeout(function () {

        var $GoTo = $('.mat-error:visible:first');
        var $DialogCntr = $(".mat-dialog-container");

        $DialogCntr.scrollTop(0);
        var offset = $DialogCntr.offset().top;

        $DialogCntr.animate({
          scrollTop: $GoTo.position().top - offset - 100
        }, 500);

      }, 0);

      if ((!Boolean(this.ModuloDiAdesione.get('Employed').value) && !Boolean(this.ModuloDiAdesione.get('Freelance').value))) {
        this.JobRequired = true;
      }
      return;
    }

    this.appGeneralService.loadingPanel.ShowIn($('.cdk-overlay-pane'));

    this.isSubmitted = false;
    this.JobRequired = false;

    this.param.CallData = {
      UserPolicyID: this.data['PolicyID'],
      Employed: Boolean(this.ModuloDiAdesione.get('Employed').value),
      FreeLance: Boolean(this.ModuloDiAdesione.get('Freelance').value),
      AddressJob: this.ModuloDiAdesione.get('StudioProfessionale').value,
      Risarcimento: Boolean(this.ModuloDiAdesione.get('RichiesteDiRisarcimento').value),
      Situazioni: Boolean(this.ModuloDiAdesione.get('ConoscenzaDanni').value),
      Patrimonio: Boolean(this.ModuloDiAdesione.get('TutelaPatrimonioATerzi').value),
      Responsability: Boolean(this.ModuloDiAdesione.get('TutelaPatrimonioResponsabilitaCivile').value),
      Terzi: Boolean(this.ModuloDiAdesione.get('TutelaPatrimonioATerziInAttivita').value),
      GeneralAccept: Boolean(this.ModuloDiAdesione.get('PrimoConsenso').value)
      // AcceptSecond: Boolean(this.ModuloDiAdesione.get('SecondoConsenso').value),
      // Privacy: Boolean(this.ModuloDiAdesione.get('TerzoConsenso').value),
      // Mailing: Boolean(this.ModuloDiAdesione.get('QuartoConsenso').value)
    };

    if (this.FoodTechnologistsModule) {
      this.param.CallData['DocumentID'] = this.data['DocumentID'];
      this.param.CallData['Revenue'] = this.ModuloDiAdesione.get('Revenue').value;
      this.param.CallData['Albo'] = this.ModuloDiAdesione.get('Albo').value;
      this.param.CallData['ActivityDescription'] = this.ModuloDiAdesione.get('ActivityDescription').value;

      if (this.happenedDamageDescriptionNeeded) {
        this.param.CallData['DamageDescription'] = this.ModuloDiAdesione.get('DamageDescription').value;
      }

    }

    this.apiService.callApiProd(this.adhesionModuleUrl, this.param).subscribe(
      data => {
        if (data.InError) {
          this.appGeneralService.showDialog(data.ErrorCode);
        } else {
          this.close(true);
        }
      });

  }

}
