import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-profile-policy',
  templateUrl: './choose-profile-policy.component.html',
  styleUrls: ['./choose-profile-policy.component.scss']
})
export class ChooseProfilePolicyComponent implements OnInit {

  chooseProfileForm: UntypedFormGroup;
  ProfilesForm: UntypedFormControl = new UntypedFormControl("", Validators.required);
  Profiles;
  constructor(
    public dialogRef: MatDialogRef<ChooseProfilePolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public fromBuilder: UntypedFormBuilder
    ) {
    }

  ngOnInit() {

    this.chooseProfileForm = this.fromBuilder.group({
      ProfilesForm: this.ProfilesForm
    });
    this.Profiles = this.data["Profiles"];
  }

  Save(){
    if(this.ProfilesForm.valid){
      this.dialogRef.close({choice: true, profileID: this.ProfilesForm.value})
    }
  }

  close(){
    this.dialogRef.close({choice: false, profileID: null});
  }

}
