<div class="home-policies-feed-cntr">

  <div class="title-cntr">
    <h1 class="title-shadow">{{label}}</h1>
    <img src="../../../../assets/images/Home-policies/virgolette.svg">
  </div>

  <ng-container *ngIf="Feeds != null">

    <ng-container *ngFor="let Feed of Feeds">

      <ng-container *ngIf="Feed.Items.length >0">

      <div class="feed-cntr">

        <div class="txt-cntr">
          <h1 style="line-height: normal!important;">{{Feed.Label}}</h1>
          <span [innerHTML]="Feed.Description"></span>
        </div>
        <div class="carousel-cntr">
        <app-general-carousel [ItemList]="Feed.Items" [FeedId]="Feed.Id" [CardType]="cardType">
        </app-general-carousel>
    </div>
    </div>
    </ng-container>
  </ng-container>
  </ng-container>

  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">


</div>
