import { Component, OnInit } from "@angular/core";
import { AppGeneralService } from "src/app/core/services/app-general.service";

@Component({
  selector: "app-five-quality",
  templateUrl: "./five-quality.component.html",
  styleUrls: ["./five-quality.component.scss"],
})
export class FiveQualityComponent implements OnInit {
  constructor(private appGeneralService: AppGeneralService) {}

  ngOnInit(): void {}

  openInfoDialog(id: number) {
    let messaggio: string;
    let titolo: string;

    switch (id) {
      case 1:
        titolo = "qualità";
        messaggio = "Coperture adeguate per ogni tipologia di cliente.";
        break;
      case 2:
        titolo = "velocità";
        messaggio =
          "Semplifichiamo il processo di acquisto e gestione delle polizze, consentendo agli utenti di ottenere rapidamente le informazioni di cui hanno bisogno e di effettuare transazioni in modo efficiente.";
        break;
      case 3:
        titolo = "semplicità";
        messaggio =
          "Un’interfaccia intuitiva e l'esperienza utente semplificata, con informazioni chiare e precise.";
        break;
      case 4:
        titolo = "affidabilità";
        messaggio =
          "Gli utenti possono contare su MyInsurer un portale assicurativo affidabile sviluppato grazie ad un’esperienza più che ventennale nel settore assicurativo.";
        break;
        case 5:
          titolo = "sostenibilità";
          messaggio =
            "Offriamo opzioni assicurative che possono contribuire a un futuro più sostenibile unitamente a programmi di ecosostenibilità come Treedom.";
          break;
      default:
        messaggio = "";
        titolo ="Errore"

    }

    this.appGeneralService.showDialog(null, messaggio, titolo);
  }
}
