<div class="container faq-page">
  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12 header-cntr">

      <div class="header-img-mobile">
        <img
          class="header-icon"
          src="./assets/images/icons/icona_faq.svg"
        />
        <h1 class="duplicate" title="F.A.Q."></h1>
      </div>


      <div class="header-img">
        <div class="header-title">
          <img
            class="header-icon"
            src="./assets/images/icons/icona_faq.svg"
          />
          <h1 class="duplicate" title="F.A.Q."></h1>
        </div>
        <img class="dots-bg" src="./assets/images/dots-bg.svg" />
      </div>

    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 accordion-cntr padded-cntr">

        <div class="faq-accordion" (click)="isCollapsed1 = !isCollapsed1" [attr.aria-expanded]="isCollapsed1" [class.faq-accordion-active]="!isCollapsed1" aria-controls="collapseExample">
            Come posso visionare la mia polizza?
            <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed1"></fa-icon>
            <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed1"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed1">
          Accedendo alla tua area personale, nella sezione dei prodotti acquistati, potrai trovare le polizze attive e visionare la relativa documentazione.
        </div>

        <div class="faq-accordion" (click)="isCollapsed2 = !isCollapsed2" [attr.aria-expanded]="isCollapsed2" [class.faq-accordion-active]="!isCollapsed2" aria-controls="collapseExample">
            Cos'è la quietanza di pagamento?
            <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed2"></fa-icon>
            <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed2"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed2">
          La quietanza è un certificato che attesta l’avvenuto pagamento della polizza assicurativa. Questo documento riporta i riferimenti del contratto (numero di polizza, massimali, decorrenza e scadenza della polizza).
        </div>

        <div class="faq-accordion" (click)="isCollapsed3 = !isCollapsed3" [attr.aria-expanded]="isCollapsed3" [class.faq-accordion-active]="!isCollapsed3" aria-controls="collapseExample">
          Dove posso trovare la mia quietanza?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed3"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed3"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed3">
          Accedendo alla tua area personale di MyInsurer nella sezione dei prodotti acquistati, all’interno della polizza troverai la relativa documentazione; in basso a destra è presente il certificato assicurativo da scaricare (quietanza).
        </div>

        <div class="faq-accordion" (click)="isCollapsed4 = !isCollapsed4" [attr.aria-expanded]="isCollapsed4" [class.faq-accordion-active]="!isCollapsed4" aria-controls="collapseExample">
          Come posso disdire la mia polizza?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed4"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed4"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed4">
          Se vuoi procedere alla disdetta della tua polizza, accedi alla tua area personale e, dal menù, clicca "area disdetta".  Dovrai inserire i dati del contratto che vuoi disdire e confermare l’invio.
          Ti ricordiamo che la disdetta deve essere inviata almeno 30 giorni prima della scadenza della polizza.
         </div>

        <div class="faq-accordion" (click)="isCollapsed5 = !isCollapsed5" [attr.aria-expanded]="isCollapsed5" [class.faq-accordion-active]="!isCollapsed5" aria-controls="collapseExample">
          Come posso contattarvi?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed5"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed5"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed5">
          Puoi contattarci telefonicamente al n. 010/565582 nei seguenti orari:<br>
          dal Lunedì al Giovedì dalle 8,30 alle 12,30 e dalle 14,30 alle 18,30.<br>
          il Venerdì dalle 8,30 alle 14,00.<br>
          Ti ricordiamo inoltre che puoi contattarci anche inviando un’e-mail all’indirizzo <a href="mailto:info@buzziinsurancegroup.com">info@buzziinsurancegroup.com</a>, scriverci tramite chat o contattarci tramite le nostre pagina social presenti in fondo alla pagina.
        </div>

        <div class="faq-accordion" (click)="isCollapsed6 = !isCollapsed6" [attr.aria-expanded]="isCollapsed6" [class.faq-accordion-active]="!isCollapsed6" aria-controls="collapseExample">
          Come posso effettuare il pagamento di una polizza?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed6"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed6"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed6">
          Accedi alla tua area personale del portale MyInsurer e, dopo aver cliccato sulla polizza di tua preferenza, procedere all’attivazione cliccando il tasto “aggiungi al carrello” oppure “acquista subito”. Quest’ultimo ti porterà alla pagina per procedere all’acquisto, dove dovrai caricare un documento di identità valido e compilare un veloce questionario, necessario alla stipula della polizza. Infine, potrai scegliere la modalità di pagamento tra bonifico o pagamento on line.<br>
          Quando hai terminato la procedura attendi che la polizza venga attivata dall’Agenzia.
        </div>

        <div class="faq-accordion" (click)="isCollapsed7 = !isCollapsed7" [attr.aria-expanded]="isCollapsed7" [class.faq-accordion-active]="!isCollapsed7" aria-controls="collapseExample">
          Come posso rinnovare la mia polizza in scadenza?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed7"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed7"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed7">
          Per rinnovare la polizza in scadenza, accedi alla tua area personale, nella sezione dei prodotti acquistati, clicca sulla polizza in scadenza e il tasto -rinnova- potrai seguire l’iter di acquisto scegliendo la modalità di pagamento tra bonifico o pagamento on line.<br>
          Quando hai terminato la procedura attendi che la polizza venga attivata dall’Agenzia.
        </div>

        <div class="faq-accordion" (click)="isCollapsed8 = !isCollapsed8" [attr.aria-expanded]="isCollapsed8" [class.faq-accordion-active]="!isCollapsed8" aria-controls="collapseExample">
          Dove trovo le condizioni contrattuali della mia polizza?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed8"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed8"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed8">
          Nella sezione dei prodotti acquistati è presente la documentazione relativa alla tua polizza, a disposizione in qualsiasi momento.
        </div>

        <div class="faq-accordion" (click)="isCollapsed9 = !isCollapsed9" [attr.aria-expanded]="isCollapsed9" [class.faq-accordion-active]="!isCollapsed9" aria-controls="collapseExample">
          Quali sono i metodi di pagamento supportati su MyInsurer?
          <fa-icon [icon]="faMinus" [size]="'2x'" *ngIf="!isCollapsed9"></fa-icon>
          <fa-icon [icon]="faPlus" [size]="'2x'" *ngIf="isCollapsed9"></fa-icon>
        </div>
        <div id="collapseExample" class="faq-accordion-collapsable" [ngbCollapse]="isCollapsed9">
          Il portale supporta i seguenti metodi di pagamento: bonifico, carte di debito/credito, prepagate, Paypal, American Express, Visa e MAstercard. Puoi decidere quello a te più comodo.
        </div>

    </div>

  </div>
</div>
