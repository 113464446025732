import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { timer } from 'rxjs';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ProductType } from 'src/app/core/enum/product-type.enum';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';



@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss']
})

export class PaypalButtonComponent implements OnInit {

  @Input() price : string;
  @Input() ID : number;
  @Input() policyDescription : string;
  @Input() type : ProductType;


  constructor(private apiService : ApiService,private generalService : AppGeneralService, private route : Router,private envioromentService : EnvironmentService) {
  }

   public payPalConfig ? : IPayPalConfig;
   dialog : MatDialogRef<any>;

    ngOnInit(): void {
        this.initConfig();
    }

    private initConfig(): void {


      if(this.envioromentService.fakePrice){

        this.price = "0.01";

      }

        this.payPalConfig = {

            currency: 'EUR',
            clientId: this.envioromentService.paypalKey,
            createOrderOnClient: (data) => < ICreateOrderRequest > {

                intent: 'CAPTURE',
                purchase_units: [{
                    amount: {
                        currency_code: 'EUR',
                        value: this.price,
                        breakdown: {
                            item_total: {
                                currency_code: 'EUR',
                                value: this.price
                            }
                        }
                    },

                    description: this.policyDescription

                }]
            },
            advanced: {
                commit: 'true',
                extraQueryParams : [ { name: "disable-funding", value:"credit,card,bancontact,mybank"} ] ,

            },
            style: {
              shape: 'rect',
              height: 40,
              label : "paypal"
            },
            onApprove: async (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
               await actions.order.get().then(details => {
                    let param : CallParameter = new CallParameter("POST",JSON.stringify(details))
                    this.apiService.callApiProduction(CallApiProdParameter.create('/api/PayPal/OnApprove',param, "POST").setSpinner(true)).subscribe(res => {
                      if(res.InError){

                        this.dialog =this.generalService.showDialog(null,res.Message,"ATTENZIONE!");

                      }

                     });
                });

            },
            authorizeOnServer: async (auth, actions) => {
                console.log('authorize - you should probably inform your server about completed transaction at this point', auth, actions);

                  await actions.order.get().then(async details => {


                    let param : CallParameter = new CallParameter("POST",{
                      Data : JSON.stringify(details),
                      ID : this.ID,
                    })

                    let type = this.type == ProductType.Policy ? "Policy" : "Service";

                   await this.apiService.callApiProduction(CallApiProdParameter.create("/api/PayPal/Authorize"+type, param, "POST").setSpinner(true)).subscribe(res =>{

                        if(!res.InError){

                          this.navigateTo();
                          this.dialog =this.generalService.showDialog(null,"Attendi che i nostri operatori convalidino i documenti che hai caricato.","Polizza in fase di verifica",[{ name: 'OK', action: () => { this.dialog.close() } }]);

                        }
                        else{
                          if(res.InError){

                            this.dialog =this.generalService.showDialog(null,res.Message,"ATTENZIONE!");

                          }
                        }

                     });
                    });




            },
            onCancel: (data, actions) => {
              this.dialog =this.generalService.showDialog(null,"Transazione annullata","ATTENZIONE");

            },
            onError: err => {
            },
            onClick: (data, actions) => {
            }
        };

    }

    navigateTo(){

      this.route.navigate(['conferma-pagamento/' + "paypal" +"/"+ this.ID]);

    }
}
