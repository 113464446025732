import { Injectable } from '@angular/core';
import * as $ from 'jquery';
import { AppGeneralService } from './app-general.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class SpidService {

  constructor(
    private appGeneralService: AppGeneralService,
    private environmentService: EnvironmentService
  ) { }

  loadSPIDScript(){
    $&&function(t){function i(i,n){var d=i?t(this):n,o=t(d.attr("spid-idp-button")),r=d.hasClass("spid-idp-button-open")
    if(i){if(t(i.target).hasClass("spid-idp-button-ignore"))return
    i.preventDefault(),i.stopPropagation()}else if(d!==n.target&&t(n.target).hasClass("spid-idp-button-ignore"))return
    s(null),r||d.hasClass("spid-idp-button-disabled")||(d.addClass("spid-idp-button-open"),o.data("spid-idp-button-trigger",d).show(),e(),o.trigger("show",{spidIDPButton:o,trigger:d}))}function s(i){var s=i?t(i.target).parents().addBack():null
    if(s&&s.is(".spid-idp-button")){if(!s.is(".spid-idp-button-menu"))return
    if(!s.is("A"))return}t(document).find(".spid-idp-button:visible").each(function(){var i=t(this)
    i.hide().removeData("spid-idp-button-trigger").trigger("hide",{spidIDPButton:i})}),t(document).find(".spid-idp-button-open").removeClass("spid-idp-button-open")}function e(){var i=t(".spid-idp-button:visible").eq(0),s=i.data("spid-idp-button-trigger"),e=s?parseInt(s.attr("data-horizontal-offset")||0,10):null,n=s?parseInt(s.attr("data-vertical-offset")||0,10):null
    0!==i.length&&s&&(i.hasClass("spid-idp-button-relative")?i.css({left:i.hasClass("spid-idp-button-anchor-right")?s.position().left-(i.outerWidth(!0)-s.outerWidth(!0))-parseInt(s.css("margin-right"),10)+e:s.position().left+parseInt(s.css("margin-left"),10)+e,top:s.position().top+s.outerHeight(!0)-parseInt(s.css("margin-top"),10)+n}):i.css({left:i.hasClass("spid-idp-button-anchor-right")?s.offset().left-(i.outerWidth()-s.outerWidth())+e:s.offset().left+e,top:s.offset().top+s.outerHeight()+n}))}t.extend(t.fn,{spidIDPButton:function(e,n){switch(e){case"show":return i(null,t(this)),t(this)
    case"hide":return s(null),t(this)
    case"attach":return t(this).attr("spid-idp-button",n)
    case"detach":return s(null),t(this).removeAttr("spid-idp-button")
    case"disable":return t(this).addClass("spid-idp-button-disabled")
    case"enable":return s(null),t(this).removeClass("spid-idp-button-disabled")}}}),t(document).on("click.spid-idp-button","[spid-idp-button]",i),t(document).on("click.spid-idp-button",s),t(window).on("resize",e)}($);

    var rootList = $("#spid-idp-list-large-root-post");
    var idpList = rootList.children(".spid-idp-button-link");
    var lnkList = rootList.children(".spid-idp-support-link");
    while (idpList.length) {
        rootList.append(idpList.splice(Math.floor(Math.random() * idpList.length), 1)[0]);
    }
    rootList.append(lnkList);
  }

  callSpidLogin(url){
    window.location.href = `${this.environmentService.Url}/api/Authentication/ExternalLoginSPID?idpname=${url}`
  }


  openInfoDialog(){
    this.appGeneralService.showDialog(null,
      `SPID è il sistema di accesso che consente di utilizzare, con un'identità digitale unica, i servizi online della Pubblica Amministrazione e dei privati accreditati. <br>
      Se sei già in possesso di un'identità digitale, accedi con le credenziali del tuo gestore.<br>
      Se non hai ancora un'identità digitale, richiedila ad uno dei gestori. <br> <br>
      <a href="https://www.spid.gov.it/">Maggiori informazioni</a><br>
      <a href="https://www.spid.gov.it/cos-e-spid/come-attivare-spid/">Non hai SPID?</a><br>
      `,"Accesso con SPID")
  }
}

