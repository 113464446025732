import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FeedPanel } from 'src/app/core/classes/Feed/feed-carousel';
import { CardType } from 'src/app/core/enum/card-type.enum';

@Component({
  selector: 'app-general-carousel',
  templateUrl: './general-carousel.component.html',
  styleUrls: ['./general-carousel.component.scss']
})
export class GeneralCarouselComponent implements OnInit, AfterViewInit {

  @Input() ItemList: any;
  @Input() FeedId: number;
  @Input() CardType: CardType;

  FeedPanel: FeedPanel

  viewModel: any = {

    cardWidth: 265,
    displayArrows: true

  }

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.calculateArrows();

  }

  @HostListener('window:resize')
  calculateArrows() {
    let a: number;
    if (this.ItemList && this.ItemList.length) {
      a = this.ItemList.length * this.viewModel.cardWidth;
    }

    let b = $(".carousel-container").width();
    this.viewModel.displayArrows = b - a <= 0;
  }

  @HostListener("window:resize")
  cellsToShow(){
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return "";
    }
      return 1.4;

  }
}