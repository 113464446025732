import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GeneralCardModule } from '../general-card/general-card.module';
import { GeneralCarouselModule } from '../general-carousel/general-carousel.module';
import { FeedCarouselComponent } from './feed-carousel.component';
import { FeedPanelModule } from './feed-panel/feed-panel.module';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    GeneralCarouselModule,
    GeneralCardModule,
    FeedPanelModule
  ],
  declarations: [
    FeedCarouselComponent
  ],
  exports: [
    FeedCarouselComponent
  ]
})
export class FeedCarouselModule { }