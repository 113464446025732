import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-recess-service-dialog',
  templateUrl: './recess-service-dialog.component.html',
  styleUrls: ['./recess-service-dialog.component.scss']
})
export class RecessServiceDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<RecessServiceDialogComponent>,
    private apiService: ApiService,
    private appGeneralService: AppGeneralService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  close() {
    this.dialogRef.close();
  }

  recessService() {

    const UserServiceID = new ToBeRecessedService(this.data);

    this.apiService.callApiProd('/api/Service/ServiceRecess', new CallParameter('POST', UserServiceID)).subscribe((result) => {
        if (result.InError) {
          this.appGeneralService.showDialog(result.ErrorCode);
        } else {
          this.dialogRef.close(true);
        }
      });
  }

}

export class ToBeRecessedService {
  UserServiceID: number;
  constructor(
    UserServiceID: number
    ) {
    this.UserServiceID = UserServiceID;
  }
}