import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CallParameter, Utils } from '../classes/system/call-parameter';
import { CallResult } from '../classes/system/call-result';
import { AppGeneralService } from './app-general.service';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './localstorage.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Accept',
      'Content-Disposition': 'attachment',
      'content-encoding': 'gzip'
    })
  };

  constructor(
    private httpClient: HttpClient,
    public appGeneralService: AppGeneralService,
    private securityService: SecurityService,
    public environmentService: EnvironmentService,
    private storageService: LocalStorageService
  ) {

    const BearerToken = this.storageService.getBearerCookie();
    this.HttpOptions.headers.set('Authentication', BearerToken);

  }

  private callServiceProd(method: string, controller: string, data: object, keepSpinner: boolean, headers: HttpHeaders): Observable<CallResult> {
    method = method.toUpperCase();
    this.HttpOptions.headers = headers;
    switch (method) {
      case 'GET':
        return this.httpClient.get<CallResult>(`${this.environmentService.Url}` + controller, this.HttpOptions).pipe(
          tap(result => {
            this.postCallFunction(result, keepSpinner)
          }
          ),
          catchError(this.handleError));
      case 'POST':
        return this.httpClient.post<CallResult>(`${this.environmentService.Url}` + controller, data, this.HttpOptions).pipe(
          tap(
            result => {
              this.postCallFunction(result, keepSpinner)
            }
          ),
          catchError(this.handleError));
      case 'PUT':
        return this.httpClient.put<CallResult>(`${this.environmentService.Url}` + controller, data, this.HttpOptions).pipe(
          tap(result => {
            this.postCallFunction(result, keepSpinner)
          }
          ),
          catchError(this.handleError));
      case 'DELETE':
        return this.httpClient.delete<CallResult>(`${this.environmentService.Url}` + controller, this.HttpOptions).pipe(
          tap(result => {
            this.postCallFunction(result, keepSpinner)
          }
          ),
          catchError(this.handleError));
    }

  }

  private callExternalService(method: string, url: string, data: object): Observable<any> {
    method = method.toUpperCase();
    switch (method) {
      case 'GET':
        return this.httpClient.get<any>(url, this.HttpOptions).pipe(
          tap(
            result => {
            }
          ),
          catchError(this.handleError));
      case 'POST':
        return this.httpClient.post<any>(url, data, this.HttpOptions).pipe(
          tap(
            result => {
            }
          ),
          catchError(this.handleError));
      case 'PUT':
        return this.httpClient.put<any>(url, data, this.HttpOptions).pipe(
          tap(
            result => {
            }
          ),
          catchError(this.handleError));
      case 'DELETE':
        return this.httpClient.delete<any>(url, this.HttpOptions).pipe(
          tap(
            result => {
            }
          ),
          catchError(this.handleError));
    }
  }

  postCallFunction(result: CallResult, keepSpinner: boolean) {
    if (keepSpinner == false) {
      this.appGeneralService.loadingPanel.Hide();
    }
    this.appGeneralService.refreshTokens(result['AuthenticationType'], result['SessionExpireDate'], result['SessionID']);
    let cartItems = result["CartItems"];

    this.storageService.setCartItems(cartItems);
    this.storageService.setRegistrationStep(result["RegistrationStep"]);

    if((result.UserType != null && result.UserType != undefined)){
      this.storageService.setUserType(JSON.stringify(result.UserType));
    }

    if(result.UserName){
      this.storageService.setUserData(result.UserName);
    }
    let fno = result["Fno"];

    if (!this.storageService.getQueryFnoFlag()) {
      this.storageService.setFnoFlag(fno);
    }

    if (result["RegistrationStep"] == null || result["RegistrationStep"] == 0 || result["RegistrationStep"] == undefined){
      this.storageService.setUserData("");
    }

    this.securityService.check_log_state();
  }

  /**
  * WARNING: This method is deprecated. Use callApiProduction instead.
  * @deprecated Use callApiProduction instead.
  */
  callApiProd(controller: string, param: CallParameter, spinner: boolean = true, keepSpinner: boolean = false): Observable<CallResult> {
    return this.callApiProduction(CallApiProdParameter.create(controller, param, param.method).setSpinner(spinner).setKeepSpinner(keepSpinner));
  }

callApiProduction(callApiProdParameter: CallApiProdParameter): Observable<CallResult> {

    if (callApiProdParameter.spinner) {
      this.appGeneralService.loadingPanel.Show();
    }

    return this.callServiceProd(callApiProdParameter.method, callApiProdParameter.controller, callApiProdParameter.param, callApiProdParameter.keepSpinner, callApiProdParameter.headers);
  }


  callApiExternal(controller: string, method: string): Observable<any> {

    this.appGeneralService.loadingPanel.Show();

    return this.callExternalService(method, controller, null);
  }

  private handleError(error) {

    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }


    if(window.location.href.match("localhost")){
      window.alert(errorMessage);
    }
    return throwError(errorMessage);

  }

}


export class CallApiProdParameter {
  private _method: string;
  public get method(): string {
    return this._method;
  }
  private set method(value: string) {
    this._method = value;
  }

  private _controller: string;
  public get controller(): string {
    return this._controller;
  }
  private set controller(value: string) {
    this._controller = value;
  }

  private _param: CallParameter| FormData;
  public get param(): CallParameter| FormData {
    return this._param;
  }
  private set param(value: CallParameter| FormData) {
    this._param = value;
  }

  private _spinner: boolean;
  public get spinner(): boolean {
    return this._spinner;
  }
  private set spinner(value: boolean) {
    this._spinner = value;
  }

  private _keepSpinner: boolean;
  public get keepSpinner(): boolean {
    return this._keepSpinner;
  }
  private set keepSpinner(value: boolean) {
    this._keepSpinner = value;
  }

  private _headers: HttpHeaders;
  public get headers(): HttpHeaders {
    return this._headers;
  }
  private set headers(value: HttpHeaders) {
    this._headers = value;
  }

  private constructor(method: string, controller: string, param: any) {
    this.method = method;
    this.controller = controller;
    this.param = param;
    this.spinner = true;
    this.keepSpinner = false;
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Accept',
      'Content-Disposition': 'attachment',
      'content-encoding': 'gzip'
    });
  }

  public static create(controller: string, param: any, method: string): CallApiProdParameter {
    return new CallApiProdParameter(method, controller, param);
  }

  public setSpinner(spinner: boolean): CallApiProdParameter {
    this.spinner = spinner;
    return this;
  }

  public setKeepSpinner(keepSpinner: boolean): CallApiProdParameter {
    this.keepSpinner = keepSpinner;
    return this;
  }

  public overrideOptions (headers: HttpHeaders): CallApiProdParameter {

    headers.keys().forEach(key => {
      this.headers = this.headers.set(key, headers.get(key));
    });
    return this;
  }

  public jsonToFormData(): CallApiProdParameter {
    this.param = Utils.jsonToFormData(this.param);
    return this;
  }
}
