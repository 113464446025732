import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserTypeVO } from 'src/app/core/classes/User/UserType';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService, CallApiProdParameter } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']

})
export class RegistrationComponent implements OnInit {

  registrationType: string;
  typeFound: boolean = false;

  constructor(
    public appGeneralService: AppGeneralService,
    public environmentService: EnvironmentService,
    private activatedRoute: ActivatedRoute,
    private LocalStorageService: LocalStorageService,
    private apiService: ApiService,
  ) { 
    
    this.activatedRoute.queryParams.subscribe(param => {

      if(param["token"] == null && param["token"] == undefined && param["pin"] == null && param["pin"] == undefined){
        this.registrationType = "default";
        this.typeFound = true;
      }

      if(param["pin"] != null && param["pin"] != undefined)
      {
        this.apiService.callApiProd("/api/Registration/CheckUserConfigurationByPin", new CallParameter("POST", { Pin: param["pin"] })).subscribe(res => {
          if(res.ResultData["Code"] == UserTypeVO.Business)
          {
            this.registrationType = "business";
            this.typeFound = true;
          }else{
            this.registrationType = "default";
            this.typeFound = true;
          }
        });
      }

      if (param["LiteRegistration"] != null && param["LiteRegistration"] != undefined && param["LiteRegistration"] == "true") {
        this.registrationType = "lite";
        this.typeFound = true;  
      }

      if(param["token"] != null && param["token"] != undefined)
      {
        this.apiService.callApiProduction(CallApiProdParameter.create("/api/Registration/External", { Token: param["token"] }, "POST")).subscribe(res => {
          if(res.InError){
            this.registrationType = "default";
            this.typeFound = true;
            this.appGeneralService.showDialog(null, res.Message, "Utente non trovato");
          }
          if(res.UserType.Code == UserTypeVO.Business)
          {
            this.registrationType = "business";
            this.typeFound = true;
          }
        });
      }

      if(this.LocalStorageService.getUserType() != null && this.LocalStorageService.getUserType() != undefined){
        if(this.LocalStorageService.getUserType().Code == UserTypeVO.Business)
        {
          this.registrationType = "business";
          this.typeFound = true;
        }else{
          this.registrationType = "default";
          this.typeFound = true;
        }
      }
    });
  }

  ngOnInit() {
  }
}
