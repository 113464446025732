<div class="Slides" [ngStyle]="{'background-image': 'url('+ Background.ImageUrl +')'}">
    <div class="dynamic_cntr">
        <div class="container">
            <div class="text-background"></div>
            <div class="row" style="margin: 15px;">
                <div class="{{Background.Position}} MobileTextCenter">
                    <div class="d-flex" style="margin-left: 3%;">
                        <div style="align-self: center; height: auto;">
                            <div class="m-auto textConstrain" [innerHTML]="Background.Text | safe:'html'"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="{{Background.Decoration}} MobileDecorationCenter"></div>
</div>
