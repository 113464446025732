<h4 mat-dialog-title class="dialogTitle">
    Compila tutti i campi richiesti e conferma in fondo alla pagina.
</h4>
<div id="CompilazioneContent_2381234" class="CompilazioneContent">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=8">
    <title>MODULO ADESIONE</title>
    <style type="text/css">
        .cssBody {
            margin: 20px;
            font-size: 15px;
            font-family: arial;
            text-align: justify;
        }

        h1,
        h2 {
            font-size: 17px;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            margin-bottom: 20px;
            font-family: Arial, sans-serif;
            font-size: 15px;
        }

        table td,
        table th {
            padding: 10px 5px;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            word-break: normal;
        }

        .EmployState input[type="checkbox"] {
            margin-top: -0.5%;
            vertical-align: middle;
        }

        input[type="checkbox"] {
            transform: scale(1.2);
        }

        input[type="radio"] {
            margin-right: 5px;
            margin-top: 5px;
            height: 1em;
        }

        .PrimaDichiarazione li {
            margin: 8px 0;
        }

        .text-underline {
            text-decoration: underline;
        }

        .text-center {
            text-align: center !important;
        }

        .text-right {
            text-align: right !important;
        }

        .ml-1 {
            margin-left: .25rem !important;
        }

        .mt-3 {
            margin-top: 1rem !important;
        }

        .mb-3 {
            margin-bottom: 1rem !important;
        }

        .mr-5 {
            margin-right: 3rem !important;
        }

        .ml-5 {
            margin-left: 3rem !important;
        }

        .m-auto {
            margin: auto !important;
        }

        .d-flex {
            display: -webkit-box !important;
            display: flex !important;
        }

        .clearfix::after {
            content: "";
            clear: both;
            display: table;
        }

        .float-left {
            float: left !important;
        }

        .float-right {
            float: right !important;
        }
    </style>
    <form [formGroup]="ModuloDiAdesione">
        <div class="cssBody">
            <ng-container *ngIf="DefaultAdhesionModule; else CustomAdhesionModuleTitleTmplt">
                <h1 class="text-center">
                    Modulo di adesione alla polizza Convenzione RC Professionale
                    <br> {{data['PolicyData']['ConventionName']}} - Italiana Assicurazioni S.p.A.
                </h1>
            </ng-container>
            <ng-template #CustomAdhesionModuleTitleTmplt>
                <div class="clearfix">
                    <div class="float-left">
                        <img width="150px" src="assets/images/reale_group.png" alt="RealeGroup Logo">
                    </div>
                    <div class="float-right">
                        <img width="150px" src="assets/images/AON.png" alt="AON Logo">
                    </div>
                    <h1 class="text-center">
                        Modulo di adesione alla polizza Convenzione RC Professionale
                        <br> {{data['PolicyData']['ConventionName']}} - Italiana Assicurazioni S.p.A.
                    </h1>
                </div>
            </ng-template>
            <h2 class="text-underline text-center">
                Rinnovi e/o nuove adesioni anno 2021
                <!--{{ myDate.getFullYear()}}-->
            </h2>
            <p>Io sottoscritto (Richiedente):</p>
            <table>
                <tbody>
                    <tr>
                        <td>Nome Cognome</td>
                        <td>{{data['PolicyData']['NameSurname']}}</td>
                    </tr>
                    <tr>
                        <td>Nato a {{data['PolicyData']['BirthCity']}}</td>
                        <td>Prov. {{data['PolicyData']['BirthProvince']}}</td>
                    </tr>
                    <tr>
                        <td>Residente a {{data['PolicyData']['City']}}</td>
                        <td>Prov. {{data['PolicyData']['Province']}}</td>
                    </tr>
                    <tr>
                        <td>{{data['PolicyData']['Address']}}</td>
                        <td>Nº {{data['PolicyData']['BuildingNumber']}}</td>
                    </tr>
                    <tr>
                        <td>Codice fiscale / P. IVA {{data['PolicyData']['CF_PIVA']}}</td>
                    </tr>
                </tbody>
            </table>
            <p>
                Richiedo a <strong>{{data['PolicyData']['ConventionName']}}</strong> l’inserimento del mio nominativo fra gli assicurati ai sensi della Polizza-Convenzione di Responsabilità Civile da voi stipulata con Italiana Assicurazioni S.p.A.
            </p>
            <p>
                <strong>{{data['PolicyData']['LegalClassification']}}</strong>
            </p>
            <div class="PrimaDichiarazione">
                Dichiaro, anche ai sensi degli art. 1892, 1893 del Codice Civile quanto segue:
                <ul>
                    <li>
                        di svolgere la professione di {{data['PolicyData']['JobProfileName']}} e di essere regolarmente iscritto ad {{data['PolicyData']['ConventionName']}}
                    </li>
                    <li class="EmployState">
                        di essere
                        <span class="ml-1">
                          <input type="checkbox" formControlName="Employed" [value]="false" id="Employed">
                          <label for="Employed">&nbsp;Dipendente</label>
                      </span>
                        <span class="ml-1">
                          <input type="checkbox" formControlName="Freelance" [value]="false" id="Freelance">
                          <label for="Freelance">&nbsp;Libero Professionista</label>
                      </span>
                        <span *ngIf="isSubmitted && JobRequired">
                          &nbsp;
                          <mat-error class="text-underline">
                              *Selezionare lo <strong>stato occupazionale</strong>
                          </mat-error>
                      </span>
                    </li>
                    <li>
                        eventuale studio professionale privato sito in <input type="text" formControlName="StudioProfessionale" value="">
                    </li>
                    <ng-container *ngIf="FoodTechnologistsModule">
                        <li>
                            <div>Fatturato/volume di affari per &euro;</div>
                            <div class="d-flex"><input type="radio" formControlName="Revenue" [value]="'fino a 10.000'" id="RevenueThousand">
                                <label for="RevenueThousand">fino a 10.000</label></div>
                            <div class="d-flex"><input type="radio" formControlName="Revenue" [value]="'da 10.001 fino a 35.000'" id="RevenueThreeThousand">
                                <label for="RevenueThreeThousand">da 10.001 fino a 35.000</label></div>
                            <div class="d-flex"><input type="radio" formControlName="Revenue" [value]="'da 35.001 fino a 60.000'" id="RevenueSixThousand">
                                <label for="RevenueSixThousand">da 35.001 fino a 60.000</label></div>
                            <div class="d-flex"><input type="radio" formControlName="Revenue" [value]="'da 60.001 fino a 100.000'" id="RevenueHundredThousand">
                                <label for="RevenueHundredThousand">da 60.001 fino a 100.000</label></div>
                            <div *ngIf="isSubmitted && formAdesionControls['Revenue'].hasError('required')">
                                <mat-error class="text-underline">
                                    *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                                </mat-error>
                            </div>
                        </li>
                        <li>
                            <div>
                                di essere iscritto all'Albo dei Tecnologi Alimentari di <input type="text" formControlName="Albo" value="">
                            </div>
                            <div *ngIf="isSubmitted && formAdesionControls['Albo'].hasError('required')">
                                <mat-error class="text-underline">
                                    *Per proseguire <strong>&egrave; necessario</strong> inserire una provincia
                                </mat-error>
                            </div>
                        </li>
                        <li>
                            attività svolta:<br />
                            <textarea formControlName="ActivityDescription" rows="4" style="width: 100%"></textarea>
                            <br />
                            <div *ngIf="isSubmitted && formAdesionControls['ActivityDescription'].hasError('required')">
                                <mat-error class="text-underline">
                                    *Per proseguire <strong>&egrave; necessario</strong> inserire una descrizione dell'attivit&agrave;
                                </mat-error>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
            <div class="mt-3">
                Dichiaro di:
                <div class="d-flex">
                    <input type="radio" formControlName="RichiesteDiRisarcimento" [value]="false" id="AcceptRichiesteDiRisarcimento" (change)="handleHappenedDamageDescription()">
                    <label for="AcceptRichiesteDiRisarcimento">2.1 - di NON aver ricevuto, negli ultimi 5 anni,
                      richieste di risarcimento per danni cagionati a terzi in relazione allo svolgimento della
                      professione di {{data['PolicyData']['JobProfileName']}}</label>
                </div>
                <div class="d-flex">
                    <input type="radio" formControlName="RichiesteDiRisarcimento" [value]="true" id="DisagreeRichiesteDiRisarcimento" (change)="handleHappenedDamageDescription()">
                    <label for="DisagreeRichiesteDiRisarcimento">2.2 - di AVER ricevuto, negli ultimi 5 anni, richieste
                      di risarcimento per danni cagionati a terzi in relazione allo svolgimento della professione di
                      {{data['PolicyData']['JobProfileName']}}</label>
                </div>
                <div *ngIf="isSubmitted && formAdesionControls['RichiesteDiRisarcimento'].hasError('required')" class="text-center">
                    <mat-error class="text-underline">
                        *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                    </mat-error>
                </div>
            </div>

            <div class="mt-3">
                Dichiaro di:
                <div class="d-flex">
                    <input type="radio" formControlName="ConoscenzaDanni" (change)="handleHappenedDamageDescription()" [value]="false" id="AcceptConoscenzaDanni">
                    <label for="AcceptConoscenzaDanni">3.1 - di NON essere a conoscenza, alla data di compilazione del
                      presente Modulo di Adesione, di fatti o situazioni che possano determinare un danno, o comunque
                      una richiesta di risarcimento elativa alla mia professione di
                      {{data['PolicyData']['JobProfileName']}}</label>
                </div>
                <div class="d-flex">
                    <input type="radio" (change)="handleHappenedDamageDescription()" formControlName="ConoscenzaDanni" [value]="true" id="DisagreeConoscenzaDanni">
                    <label for="DisagreeConoscenzaDanni">3.2 - di ESSERE a conoscenza, alla data di compilazione del
                      presente Modulo di Adesione, di fatti o situazioni che possano determinare un danno, o comunque
                      una richiesta di risarcimento relativa alla mia professione di
                      {{data['PolicyData']['JobProfileName']}}</label>
                </div>
                <div *ngIf="isSubmitted && formAdesionControls['RichiesteDiRisarcimento'].hasError('required')" class="text-center">
                    <mat-error class="text-underline">
                        *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                    </mat-error>
                </div>
            </div>
            <p class="text-underline mt-3">
                Si rammenta al {{data['PolicyData']['JobProfileName']}} che intende sottoscrivere la copertura assicurativa, che condizione essenziale per il rilascio della quietanza, e quindi dell’attivazione delle garanzie assicurative, &egrave; la regolare iscrizione
                ad {{data['PolicyData']['ConventionName']}}
            </p>
            <ng-container *ngIf="FoodTechnologistsModule">
                <div *ngIf="happenedDamageDescriptionNeeded">
                    Descrizione dell'accaduto:<br />
                    <textarea formControlName="DamageDescription" rows="4" style="width: 100%"></textarea>
                    <br />
                    <div *ngIf="isSubmitted && formAdesionControls['DamageDescription'].hasError('required')">
                        <mat-error class="text-underline">
                            *Per proseguire <strong>&egrave; necessario</strong> inserire una descrizione dell'accaduto
                        </mat-error>
                    </div>
                </div>
            </ng-container>
            <p>
                Questionario di adeguatezza dell’offerta
            </p>
            <p>
                Così come disposto dal Regolamento ISVAP n° 5/2006 art. 52, il presente questionario ha lo scopo di acquisire, nel Suo interesse, le informazioni necessarie a valutare l’adeguatezza del contratto che intende sottoscrivere rispetto alle Sue esigenze, tenendo
                presente che la mancanza di queste informazioni potrebbe ostacolarne la valutazione.
            </p>
            <ol>
                <li>
                    <div>
                        Desidero tutelare il mio patrimonio per i danni provocati a terzi (a cose, a persone o puramente di natura pecuniaria) nello svolgimento delle prestazioni professionali da me eseguita
                    </div>
                    <div>
                        <span class="mr-5">
                          <input type="radio" formControlName="TutelaPatrimonioATerzi" [value]="true"
                              id="AcceptTutelaPatrimonioATerzi">
                          <label for="AcceptTutelaPatrimonioATerzi">SI</label>
                      </span>
                        <span class="mr-5">
                          <input formControlName="TutelaPatrimonioATerzi" type="radio" [value]="false"
                              id="DisagreeTutelaPatrimonioATerzi">
                          <label for="DisagreeTutelaPatrimonioATerzi">NO</label>
                      </span>
                        <span *ngIf="isSubmitted && formAdesionControls['TutelaPatrimonioATerzi'].hasError('required')">
                          <mat-error class="text-underline">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                      </span>
                    </div>
                </li>
                <li>
                    <div>
                        Desidero tutelare il mio patrimonio dalle azioni conseguenti a rivalsa del datore di lavoro, o di Responsabilit&agrave; Amministrativa (Corte dei Conti)
                    </div>
                    <div>
                        <span class="mr-5">
                          <input type="radio" formControlName="TutelaPatrimonioResponsabilitaCivile" [value]="true"
                              id="AcceptTutelaPatrimonioResponsabilitaCivile">
                          <label for="AcceptTutelaPatrimonioResponsabilitaCivile">SI</label>
                      </span>
                        <span class="mr-5">
                          <input formControlName="TutelaPatrimonioResponsabilitaCivile" type="radio" [value]="false"
                              id="DisagreeTutelaPatrimonioResponsabilitaCivile">
                          <label for="DisagreeTutelaPatrimonioResponsabilitaCivile">NO</label>
                      </span>
                        <span *ngIf="isSubmitted && formAdesionControls['TutelaPatrimonioResponsabilitaCivile'].hasError('required')">
                          <mat-error class="text-underline">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                      </span>
                    </div>
                </li>
                <li>
                    <div>
                        Desidero tutelare il mio patrimonio per i danni provocati a terzi, qualunque sia l’epoca del fatto, azione od omissione, che abbia dato origine alla richiesta di risarcimento
                    </div>
                    <div>
                        <span class="mr-5">
                          <input type="radio" formControlName="TutelaPatrimonioATerziInAttivita" [value]="true"
                              id="AcceptTutelaPatrimonioATerziInAttivita">
                          <label for="AcceptTutelaPatrimonioATerziInAttivita">SI</label>
                      </span>
                        <span class="mr-5">
                          <input formControlName="TutelaPatrimonioATerziInAttivita" type="radio" [value]="false"
                              id="DisagreeTutelaPatrimonioATerziInAttivita">
                          <label for="DisagreeTutelaPatrimonioATerziInAttivita">NO</label>
                      </span>
                        <span *ngIf="isSubmitted && formAdesionControls['TutelaPatrimonioATerziInAttivita'].hasError('required')">
                          <mat-error class="text-underline">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                      </span>
                    </div>
                </li>
            </ol>
            <p>
                N.B. alcune garanzie prevedono franchigie/scoperti in caso di sinistro e limitazioni/esclusioni della prestazione. Le raccomandiamo di leggere attentamente le condizioni di assicurazione con specifico riguardo alle parti evidenziate in grassetto.
            </p>
            <p>
                Tenuto conto che l’evento dannoso può svilupparsi/manifestarsi su un arco temporale prolungato ed anche superiore alla durata della polizza, raccomandiamo l‘attenta lettura delle condizioni assicurative inerenti l’efficacia della garanzia nel tempo.
            </p>
            <p>
                Nel caso di risposta negativa alla domanda n. 1 oppure nel caso di mancata risposta ad una o più domande da parte del dichiarante, non sarà possibile procedere alla prestazione della copertura tramite adesione alla convenzione {{data['PolicyData']['ConventionName']}}.
                <!-- oppure in caso di mancata compilazione: -->
            </p>
            <div>
                <!-- <div class="d-flex">
                    <input type="checkbox" (change)="CheckNextValidators(FirstConsent)" #FirstConsent style="margin: 6px 5px 0px 0px;" formControlName="PrimoConsenso" [value]="false" id="PrimoConsenso">
                    <label for="PrimoConsenso">Dichiaro di non voler fornire una o più delle informazioni
                      richieste, nella consapevolezza che ciò pregiudica la valutazione dell’adeguatezza del contratto
                      alle
                      mie esigenze assicurative.</label>
                </div> -->
                <div class="clearfix mt-3 mb-3">
                    <div class="float-left">
                        Data {{ myDate | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </div>
            <!-- <p class="text-underline">
              <strong>
                  Informativa ai sensi dell’art. 13 D.Lgs n. 196/2003 - Codice in materia di protezione dei dati
                  personali
              </strong>
          </p>
          <p>
              <strong>A) Trattamento dei dati personali per finalità assicurative -</strong>
              La informiamo che i dati
              personali che La riguardano richiesti o acquisiti, eventualmente anche sensibili e/o giudiziari, saranno
              trattati da Italiana Assicurazioni
              S.p.A. al fine di fornirLe i servizi e/o i prodotti assicurativi da Lei richiesti.
          </p>
          <p>
              <strong>B) Modalità di trattamento dei dati personali -</strong>
              I dati sono trattati dalla nostra
              Società – titolare del trattamento – solo con modalità e procedure, anche con l’ausilio di mezzi
              elettronici o comunque automatizzati, necessarie
              per fornirLe i servizi e/o prodotti assicurativi richiesti. Nella nostra Società i dati sono trattati da
              tutti i dipendenti e collaboratori esterni nell’ambito delle rispettive funzioni e in conformità delle
              istruzioni ricevute, sempre
              e solo per il conseguimento delle specifiche finalità indicate nella presente informativa.
          </p>
          <p>
              <strong>C) Comunicazione dei dati -</strong>
              I dati, secondo i casi, limitatamente allo svolgimento
              delle finalità sopra indicate, potranno essere comunicati ad altri soggetti che si configurano come
              Responsabili o Titolari autonomi per
              specifici ambiti di trattamento.
          </p>
          <p>
              <strong>D) Diffusione dei dati -</strong>
              I dati personali non sono soggetti a diffusione.
          </p>
          <p>
              <strong>E) Conferimento dei dati -</strong>
              Il conferimento dei Suoi dati personali è facoltativo, tuttavia, in mancanza di comunicazione non
              potremo dar seguito alle finalità descritte al punto A) della presente informativa.

          </p>
          <p>
              <strong>F) Diritti dell’interessato -</strong>
              Lei ha il diritto di conoscere, in ogni momento, quali
              sono i Suoi dati presso i singoli titolari di trattamento, cioè presso la nostra Società o presso i
              soggetti sopra indicati a cui li
              comunichiamo, e come vengono utilizzati; ha inoltre il diritto di farli aggiornare, integrare,
              rettificare o cancellare, di chiederne il blocco e di opporsi in ogni momento al loro trattamento, anche
              mediante l'utilizzo della posta elettronica,
              del fax o del telefono e di ricevere immediato riscontro che confermi l'interruzione di tale trattamento
              (art. 7 del D.Lgs. 196/2003). Per l’esercizio dei Suoi diritti, nonché per informazioni più dettagliate
              circa i soggetti o le categorie
              di soggetti ai quali i dati sono comunicati o che ne vengono a conoscenza in qualità di responsabili o
              incaricati, Lei può rivolgersi al nostro Responsabile per il riscontro con gli interessati,
              <strong>Benvenuti in Italiana</strong>” Italiana Assicurazioni, Via M. U. Traiano, 18 – 20149 Milano,
              <strong>Numero Verde 800–101313</strong> (attivo dal lunedì al sabato, dalle 8 alle 20), fax 02
              39717001,

              <strong>e-mail: benvenutinitaliana@italiana.it</strong>
          </p>
          <p>
              <strong>G) Titolare del trattamento -</strong>
              Italiana Assicurazioni S.p.A.
          </p>
          <p>
              <strong>H) Responsabile del trattamento -</strong>
              Il Dirigente pro tempore della Direzione Tecnica Rami Elementari.
          </p>
          <div>
              <strong>
                  Sulla base di quanto sopra, Lei può esprimere il suo consenso:
              </strong>
              <div class="clearfix mt-3 mb-3">
                  <div class="float-left">
                      Data {{ myDate | date:'dd/MM/yyyy'}}
                  </div>
                  <div class="float-right">
                      <div class="text-right">
                          <strong>Acconsento</strong>
                          <span class="ml-5">
                              <input type="radio" formControlName="SecondoConsenso" [value]="true"
                                  id="AcceptSecondoConsenso">
                              <label for="AcceptSecondoConsenso">SI</label>
                          </span>
                          <span class="ml-5">
                              <input formControlName="SecondoConsenso" type="radio" [value]="false"
                                  id="DisagreeSecondoConsenso">
                              <label for="DisagreeSecondoConsenso">NO</label>
                          </span>
                      </div>
                      <div
                          *ngIf="isSubmitted && (formAdesionControls['SecondoConsenso'].hasError('required') || formAdesionControls['SecondoConsenso'].hasError('pattern'))">
                          <mat-error class="text-underline"
                              *ngIf="formAdesionControls['SecondoConsenso'].hasError('required')">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                          <mat-error class="text-underline"
                              *ngIf="!formAdesionControls['SecondoConsenso'].hasError('required') && formAdesionControls['SecondoConsenso'].hasError('pattern')">
                              *Per proseguire <strong>&egrave; necessario</strong> acconsentire
                          </mat-error>
                      </div>
                  </div>
              </div>
          </div>
          <div>
              <strong>I) Trattamento dei dati personali per ricerche di mercato e/o finalità promozionali -</strong>
              Le chiediamo inoltre di esprimere il consenso per il trattamento dei Suoi dati da parte della nostra
              Società al fine di rilevare la
              qualità dei servizi o i bisogni della clientela e di effettuare ricerche di mercato e indagini
              statistiche, nonché di svolgere attività promozionali di servizi e/o prodotti propri o di terzi.
              Inoltre, esclusivamente per le medesime finalità,
              i dati possono essere comunicati ad altri soggetti che operano - in Italia o all’estero - come autonomi
              titolari: il consenso riguarda, pertanto, anche gli specifici trattamenti e le comunicazioni e
              trasferimenti effettuati da tali soggetti.

              <div class="clearfix mt-3 mb-3">
                  <div class="float-left">
                      Data {{ myDate | date:'dd/MM/yyyy'}}
                  </div>
                  <div class="float-right">
                      <div class="text-right">
                          <strong>Acconsento</strong>
                          <span class="ml-5">
                              <input type="radio" formControlName="TerzoConsenso" [value]="true"
                                  id="AcceptTerzoConsenso">
                              <label for="AcceptTerzoConsenso">SI</label>
                          </span>
                          <span class="ml-5">
                              <input formControlName="TerzoConsenso" type="radio" [value]="false"
                                  id="DisagreeTerzoConsenso">
                              <label for="DisagreeTerzoConsenso">NO</label>
                          </span>
                      </div>
                      <div *ngIf="isSubmitted && formAdesionControls['TerzoConsenso'].hasError('required')">
                          <mat-error class="text-underline"
                              *ngIf="formAdesionControls['TerzoConsenso'].hasError('required')">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                      </div>
                  </div>
              </div>
          </div>
          <div>
              <strong>L) Relativamente alle finalità di cui alla lettera I)</strong>,
              chiediamo altresì il Suo
              consenso al fine di permettere alla nostra Società ed ai soggetti sopra indicati di effettuare il
              trattamento dei Suoi dati anche per il tramite
              di strumenti di comunicazione elettronica quali e-mail, telefax, messaggi tipo Mms (Multimedia Messaging
              Service), o Sms (Short Message Service) o di altro tipo.
              <div class="clearfix mt-3 mb-3">
                  <div class="float-left">
                      Data {{ myDate | date:'dd/MM/yyyy'}}
                  </div>
                  <div class="float-right">
                      <div class="text-right">
                          <strong>Acconsento</strong>
                          <span class="ml-5">
                              <input type="radio" formControlName="QuartoConsenso" [value]="true"
                                  id="AcceptQuartoConsenso">
                              <label for="AcceptQuartoConsenso">SI</label>
                          </span>
                          <span class="ml-5">
                              <input formControlName="QuartoConsenso" type="radio" [value]="false"
                                  id="DisagreeQuartoConsenso">
                              <label for="DisagreeQuartoConsenso">NO</label>
                          </span>
                      </div>
                      <div *ngIf="isSubmitted && formAdesionControls['QuartoConsenso'].hasError('required')">
                          <mat-error class="text-underline"
                              *ngIf="formAdesionControls['QuartoConsenso'].hasError('required')">
                              *Per proseguire <strong>&egrave; necessario</strong> selezionare un opzione
                          </mat-error>
                      </div>
                  </div>
              </div>
          </div>
          <p>
              <strong>Precisiamo che il consenso è, nei casi previsti alle lettere I) e L), del tutto facoltativo e
                  che il Suo
                  eventuale rifiuto non produrrà alcun effetto circa la fornitura dei servizi e/o prodotti
                  assicurativi indicati
                  nella presente informativa.</strong>
          </p> -->
        </div>
        <div mat-dialog-actions class="clearfix">
            <button class="new-button" (click)="close(false)">ANNULLA</button>
            <button class="new-button" (click)="onSubmit()">CONFERMA
          </button>
        </div>
    </form>
</div>
