import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sigle-story',
  templateUrl: './sigle-story.component.html',
  styleUrls: ['./sigle-story.component.scss']
})
export class SigleStoryComponent implements OnInit {

  @Input() item;
  stars : Array<any> = [];

  constructor() { }

  ngOnInit(): void {

    this.stars = Array.from({ length: this.item.Star }, (_, index) => index);

  }

}
