<div class="header-background-cntr">

  <div class="txt-cntr">

    <div class="title-cntr">

      <img src="{{PolicyHome.iconUrl}}">
      <h1 class="duplicate" [title]="PolicyHome.title"></h1>
    </div>
  </div>

  <div class="end-cntr">

    <div class="title-cntr">

      <h1>Tutte le categorie</h1>
      <h3>{{PolicyHome.description}}</h3>
    </div>
  </div>

  <img class="img-absolute" src="{{PolicyHome.imgUrl}}">
  <img class="home-background-img dont-show-mobile" src="../../../../assets/images/Home/sfondo_puntini.svg">

  <div class="carousel-header-cntr">
    <app-header-background-carousel [Filtri]="PolicyHome.carouselButtons"></app-header-background-carousel>
  </div>

</div>
