<div class="singleFeedCntr" [ngStyle]="{'width': cardWidth + 'px'}" (click)="goToDetail(SingleFeed)">

    <div class="imageCntr" [ngStyle]="{'background-image': 'url('+ SingleFeed.ImageUrls[0] +')', 'background-position': 'center', 'background-size': 'cover'}">
    </div>

    <div class="offertCntr" *ngIf="SingleFeed.FakePrice">
        <div class="cxPart">
            OFFERTA
        </div>
    </div>

    <div class="textCntr">
        <h3 [innerHTML]="[SingleFeed.TileTitle ?? SingleFeed.Title]"></h3>
    </div>

    <div class="price-cntr">

      <div class="fake-price" *ngIf="SingleFeed.FakePrice">DA {{SingleFeed.FakePrice}} €</div>
      <div class="price" *ngIf="SingleFeed.Price">DA {{SingleFeed.Price}} €</div>
      <div class="link">confronta offerte</div>
    </div>


    <div class="linkCntr">
      <div class="cxPart">
      <img src="../../../assets/images/Home/Generic/freccia.svg">
      </div>
  </div>
</div>
