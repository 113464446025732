import { Component, HostListener, Input, OnInit } from '@angular/core';
import { HeaderBackgroundFilter } from '../../home-policies.component';

@Component({
  selector: 'app-header-background-carousel',
  templateUrl: './header-background-carousel.component.html',
  styleUrls: ['./header-background-carousel.component.scss']
})
export class HeaderBackgroundCarouselComponent implements OnInit {

  @Input() Filtri : Array<HeaderBackgroundFilter>;

  constructor() { }

  ngOnInit() {
  }
  @HostListener("window:resize")
  cellsToShow() {
    const windowWidth = window.innerWidth;
    if (windowWidth > 400) {
      return "";
    }
      return 1.5;

  }


}
