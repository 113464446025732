<ng-container *ngIf="User && Product">
<div class="payment-cntr">

  <div class="dati-cntr">

    <div class="dati-personali">

      <h2>RIEPILOGO DATI</h2>
      <h3>{{User.Nome}} {{User.Cognome}}</h3>
      <h3>{{User.Cf}}</h3>
      <h3>{{User.Telefono}}</h3>
      <h3>{{User.Email}}</h3>

    </div>

    <div class="dati-polizza">

      <div class="txt-cntr">
      <h2 *ngIf="Type == TypeEnum.Service">IL TUO SERVIZIO</h2>
      <h2 *ngIf="Type == TypeEnum.Service">{{Product.Name}}</h2>
      <h2 *ngIf="Type == TypeEnum.Policy">LA TUA POLIZZA</h2>
      <h2 class="policy-name" *ngIf="Type == TypeEnum.Policy">{{Product.CartPolicy.Title}}</h2>
    </div>

    <div class="price-cntr">

      <h2 *ngIf="Type == TypeEnum.Policy">TOTALE: <span *ngIf="Product.CartPolicy.FakePrice" style="text-decoration: line-through; font-weight: initial!important;">{{Product.CartPolicy.FakePrice | price}}</span> {{Product.CartPolicy.Price | price}}</h2>
      <h2 *ngIf="Type == TypeEnum.Service && Product.Price">TOTALE: {{Product.Price | price}}</h2>

    </div>


    </div>

  </div>

  <div class="divisore"></div>

  <div  class="dati-pagamento-cntr">

    <ng-container>
    <div class="pagamento-bonifico">
    <h2 class="font-weight-bold mt-2">Metodi di pagamento</h2>
    <div class="payment-methods">
      <div>
        <img alt="nexi-logo" src="./assets/images/icons/nexi_logo.png" class="master-image">
      </div>
      <div class="vertical-separator"></div>
      <div>
        <img alt="visa-logo" src="./assets/images/icons/visa_logo.png" class="child-image">
      </div>
      <div>
        <img alt="maestro-logo" src="./assets/images/icons/maestro-logo.png" class="child-image">
      </div>
      <div>
        <img alt="mastercard-logo" src="./assets/images/icons/mastercardlogo.png" class="child-image">
      </div>
      <div>
        <img alt="pagobancomat-logo" src="./assets/images/icons/pagobancomat-logo.png" class="child-image">
      </div>
      <div>
        <img alt="americanexpress-logo" src="./assets/images/icons/american_express.png" class="child-image">
      </div>
    </div>
  </div>
  </ng-container>

    <div *ngIf="Product.PaymentMethods.Transfer || Product.isAifiPolicy" class="divisore"></div>

    <div class="pagamento-carta">

      <ng-container *ngIf="Type == TypeEnum.Policy">
      <button class="new-button primary" *ngIf="Product.PaymentMethods.CreditCard" (click)="CreditCardPayment()">Paga con Carta</button>
      <div class="new-button primary" style="margin-top: 1em;" *ngIf="Product.PaymentMethods.Transfer || Product.isAifiPolicy" (click)="TransferPayment()">Paga con bonifico</div>

          <div class="payPalButton">
          <app-paypal-button *ngIf="Product.PaymentMethods.CreditCard && Product.CartPolicy.Price > 0" [type]="TypeEnum.Policy" [price]="Product.CartPolicy.Price" [ID]="this.Product.CartPolicy.ID" [policyDescription]="this.Product.CartPolicy.BackOfficeName"></app-paypal-button>
          </div>
          <img *ngIf="Product.PaymentMethods.CreditCard && Product.CartPolicy.Price > 0" src="../../../../assets/images/pagamenti2/satispay.png" style="height: 80px; cursor: pointer;" (click)="CreatePaymentSatispay()"/>
          <button class="new-button" *ngIf="Product.PaymentMethods.IndirectPayment" (click)="IndirectPayment()">Conferma polizza</button>
      </ng-container>
        <button *ngIf="Type == TypeEnum.Service" class="new-button primary" (click)="CreditCardPayment()">effettua l'acquisto</button>
    </div>


  </div>

  <div class="sottotitoli">

    N.B. pagando con Carta di credito la polizza avrà valenza dalle ore 24 del giorno di pagamento.<br>
    Se si effettua il pagamento tramite bonifico, la polizza avrà valenza dalle ore 24 del giorno di accredito sul c/c dell’assicuratore.<br>
    Ti ricordiamo di caricare tutti i documenti necessari prima di effettuare il pagamento.

  </div>

</div>
</ng-container>
