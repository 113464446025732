<div class="general-carousel-cntr row ml-0 mr-0">
  <carousel [cellWidth]="calculateWidth()" [height]="calculateHeight()">
    <div class="carousel-cell" *ngFor="let infoBlock of infoBlocksArray">
      <div class="boxes-img">
        <img src="{{ infoBlock.img }}" />
      </div>
      <div class="boxes-text">
        <h3>{{ infoBlock.title }}</h3>
        <p [innerHTML]="infoBlock.text"></p>
      </div>
    </div>
  </carousel>
</div>
