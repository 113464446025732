<div class="profile-cntr">
  <div class="header-cntr">
    <div class="img-cntr">

      <img src="../../../../assets/images/account/account.webp">

    </div>
    <div class="txt-cntr">
      <h1 class="duplicate" title="Account"></h1>
    </div>

</div>
  <mat-tab-group
    mat-stretch-tabs
    color="transparent"
    [selectedIndex]="selectedIndex"
  >
    <mat-tab label="IL TUO PROFILO">
      <br />
      <app-personal-profile-accordion></app-personal-profile-accordion>
    </mat-tab>
    <mat-tab label="LA TUA FAMIGLIA" *ngIf="this.profileType == 'default'">
      <br />
      <app-my-family></app-my-family>
    </mat-tab>
    <mat-tab label="LA TUA AZIENDA" *ngIf="this.profileType == 'default'">
      <br />
      <app-my-business></app-my-business>
    </mat-tab>
    <mat-tab label="LE TUE SEDI" *ngIf="this.profileType == 'business'">
      <br />
      <app-my-headquarter></app-my-headquarter>

    </mat-tab>
  </mat-tab-group>
</div>

<app-contact-us></app-contact-us>
<app-prefooter></app-prefooter>
