<div class="container profile-single-accordion business-tab">
    <ng-container *ngIf="formMyBusiness != null">
        <div *ngFor="let formBusiness of formMyBusiness.controls;  let i = index">
        <form [formGroup]="formBusiness" class="row" autocomplete="off">
            <div class="col-md-12">
                <div class="EditDataPnl data row">
                    <input type="hidden" formControlName="Id">

                    <div class="col-md-12   text-center">
                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12 text-center">
                            <input class="mat-form-input" type="text" matInput placeholder="Ragione sociale" required
                                formControlName="BusinessName" name="ragione-sociale" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['BusinessName'].hasError('required')">
                                Inserire <strong>ragione sociale</strong>
                            </mat-error>
                        </mat-form-field>

                    </div>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <mat-label for="CompanyType">Tipo società</mat-label>
                            <mat-select class="mat-form-input" matNativeControl required formControlName="CompanyType">
                                <mat-option [value]="company.Id" *ngFor="let company of CompanyType.TypeArray">
                                    {{company.Name}}</mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="formRegistrationCompleteControls['JobProfileID'].hasError('required')">
                            Selezionare <strong>profilo lavorativo</strong>
                        </mat-error> -->
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" type="number" matInput placeholder="Partita iva" required formControlName="VatNumber"
                                name="piva" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['VatNumber'].hasError('required')">
                                Inserire <strong>partita iva</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" type="text" matInput placeholder="Codice fiscale" required formControlName="FiscalCode"
                                name="cf" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['FiscalCode'].hasError('required')">
                                Inserire <strong>codice fiscale</strong> </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" type="tel" matInput placeholder="Telefono" required formControlName="PhoneNumber"
                                name="user-phone" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['PhoneNumber'].hasError('required')">
                                Inserire <strong>numero</strong> </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" matInput type="text" placeholder="Citt&agrave;" required formControlName="City"
                                name="city" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['City'].hasError('required')">
                                Inserire <strong>citt&agrave;</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" matInput type="text" placeholder="Via" required formControlName="Address"
                                name="street" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['Address'].hasError('required')">
                                Inserire <strong>via</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" matInput type="text" placeholder="Civ" required formControlName="AddressNumber"
                                name="streetnumber" autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['AddressNumber'].hasError('required')">
                                Inserire <strong>civico</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field floatLabel="always" class="col-lg-6 col-md-6 col-sm-12">
                            <input class="mat-form-input" matInput type="text" placeholder="E-mail" required formControlName="Email" name="mail"
                                autocomplete="none">
                            <mat-error *ngIf="formBusiness.controls['Email'].hasError('required')">
                                Inserire <strong>email</strong>
                            </mat-error>
                        </mat-form-field>

                </div>
            </div>

            <div class="col-12  ">
                <div class="buttonArea float-right">
                    <button class="new-button"   (click)="saveData(i)">REGISTRA AZIENDA</button>
                    <button class="new-button mt-3"   (click)="deleteData(i)">RIMUOVI AZIENDA</button>
                </div>
            </div>
        </form>
        <hr>
        <br>
    </div>

    <div class="col-12  ">
        <div class="buttonArea">
            <button class="new-button float-right mt-5"   (click)="addNewBusiness()">AGGIUNGI AZIENDA</button>
        </div>
    </div>
</ng-container>
</div>
