import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContractsConditionService } from 'src/app/auth/contracts-condition/contracts-condition.service';
import { RegistrationStep } from '../enum/registration-step.enum';

@Injectable({
  providedIn: 'root'
})
export class RegistrationStepService {

constructor(
  private router: Router,
  private contractsConditionService: ContractsConditionService
) { }

checkRegistrationCompleted(registrationStep: RegistrationStep | Number, step0callback : boolean = true): boolean {
  switch (registrationStep) {
    case RegistrationStep.STEP0 | 0:
    if(step0callback){
      this.router.navigate(["registrazione"]);
    }
      return false;
    case RegistrationStep.STEP1 | 1:
      this.router.navigate(["email-inviata"]);
      return false;
    case RegistrationStep.STEP2 | 2:
      this.router.navigate(["completa-registrazione"]);
      return false;
    case RegistrationStep.STEP3 | 3:
      this.contractsConditionService.CheckConfirm(false, true, null, null);
      return true;
    case RegistrationStep.STEP4 | 4:
    default:
      return true;
  }
}

}
