import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralCardComponent } from './general-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PipeModule } from 'src/app/core/helpers/Pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    PipeModule
  ],
  declarations: [GeneralCardComponent],
  exports: [GeneralCardComponent]
})
export class GeneralCardModule { }
