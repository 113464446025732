import { Component, OnInit } from '@angular/core';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-treedom-page',
  templateUrl: './treedom-page.component.html',
  styleUrls: ['./treedom-page.component.scss']
})
export class TreedomPageComponent implements OnInit {

  constructor(
    private appGeneralService: AppGeneralService
  ) { }

  ngOnInit() {
  }

  goToTreedom(){
    this.appGeneralService.GoToExternalURL("https://www.treedom.net/it/organization/buzzi-insurance-group-s.r.l.")
  }

}
