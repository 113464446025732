<!-- <h1>{{this.siteManagementService.getLabel(true,false)}}</h1> -->
<div class="container">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 header-cntr">
      <div class="header-img-mobile">
        <img
          class="header-icon"
          src="./assets/images/icons/per i convenzionati.svg"
        />
        <h1 class="duplicate" title="Per i convenzionati"></h1>
      </div>

      <div class="header-img">
        <div class="header-title">
          <img
            class="header-icon"
            src="./assets/images/icons/per i convenzionati.svg"
          />
          <h1 class="duplicate" title="Per i convenzionati"></h1>
        </div>
        <img class="dots-bg" src="./assets/images/dots-bg.svg" />
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 boxes-cntr dont-show-mobile">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="boxes-img">
            <img src="./assets/images/icons/specializzati.svg" />
          </div>
          <div class="boxes-text">
            <h3>Specializzati da oltre 20 anni</h3>
            <p>
              Dal <strong>2000</strong> ci siamo specializzati nelle convenzioni
              assicurative, offrendo polizze con premi competitivi e assistenza
              di alta qualità.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="boxes-img">
            <img src="./assets/images/icons/base-di-utenza.svg" />
          </div>
          <div class="boxes-text">
            <h3>Ampia base di utenti</h3>
            <p>
              Con oltre <strong>100.000 utenti</strong> iscritti in costante
              crescita, garantiamo tranquillità e sicurezza per l'attività
              lavorativa quotidiana.
            </p>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="boxes-img">
            <img src="./assets/images/icons/professionalita.svg" />
          </div>
          <div class="boxes-text">
            <h3>Soddisfazione e professionalità</h3>
            <p>
              Soddisferemo le esigenze delle diverse categorie in modo
              trasparente e professionale.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm col-md col-lg show-mobile">
      <app-info-blocks-carousel></app-info-blocks-carousel>
    </div>

    <!-- Convention list -->
    <div class="cards-cntr padded-cntr col-lg-12 col-md-12 col-sm-12">
      <h2>Federazioni e associazioni convenzionate</h2>

      <!-- Desktop list -->
      <div class="dont-show-mobile">
        <div class="row d-flex">
          <ng-container
            *ngFor="let convention of ConventionListResult.conventionInfos"
          >
            <div class="col-lg-2 col-md-6 col-sm-12">
              <app-single-convention
                [SingleConvention]="convention"
              ></app-single-convention>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- End Desktop list -->

      <!-- Mobile list -->
      <div class="show-mobile">
        <div class="row d-flex">
          <div style="width: 100%">
            <ng-container
              *ngIf="
                ConventionListResult != undefined &&
                ConventionListResult != null
              "
            >
              <app-convention-carousel
                [ConventionList]="ConventionListResult"
              ></app-convention-carousel>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- End Mobile list -->
    </div>

  </div>

  <div style="margin-top: 100px">
    <app-contact-us></app-contact-us>
  </div>
  <div>
    <app-prefooter></app-prefooter>
  </div>
</div>
