import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';

@Component({
  selector: 'app-option-info-dialog',
  templateUrl: './option-info-dialog.component.html',
  styleUrls: ['./option-info-dialog.component.scss']
})
export class OptionInfoDialogComponent implements OnInit {

  Description;
  Title;
constructor(
  public dialogRef: MatDialogRef<OptionInfoDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) {
  this.Description = data.Description;
  this.Title = "Info Garanzia";
  if(data.Title){
    this.Title = data.Title;
  }
}

  ngOnInit() {
    //this.dialogRef.updateSize('300px');
   }

  close() {
    this.dialogRef.close();
  }

}
