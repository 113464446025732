import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as ITProvinces from '../../../core/helpers/provinces.json';

import { MatDatepicker } from '@angular/material/datepicker';
import { Observable } from 'rxjs';
import { CompanyType } from 'src/app/core/classes/SubUserClasses/company-type';
import { CallParameter } from 'src/app/core/classes/system/call-parameter';
import { ApiService } from 'src/app/core/services/api.service';
import { AppGeneralService } from 'src/app/core/services/app-general.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';


@Component({
  selector: 'app-edit-data-business',
  templateUrl: './edit-data-business.component.html',
  styleUrls: ['./edit-data-business.component.scss']
})
export class EditDataBusinessComponent implements OnInit {

  formEditData: UntypedFormGroup;
  @ViewChild('CodiceFiscale', { static: false }) CodiceFiscaleRef;

  today: Date = new Date();
  CompanyType: CompanyType = new CompanyType();

  calendarStartDate: Date = new Date(this.today.getFullYear() - 18, this.today.getMonth(), this.today.getDate());

  availableProvinces: string[] = ITProvinces['default'].map(province => province.sigla);
  filteredProvinces: Observable<string[]>;

  UpdateCF: boolean = true;
  UserDataResult;
  CallData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public appGeneralService: AppGeneralService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {

    this.formEditData = this.formBuilder.group({
      Name: new UntypedFormControl('', Validators.required),
      Surname: new UntypedFormControl('', Validators.required),
      Iva :  new UntypedFormControl('', Validators.required),
      REA :  new UntypedFormControl('', Validators.required),
      CompanyType :  new UntypedFormControl('', Validators.required),
      Phone: new UntypedFormControl('', Validators.required),
      CF: new UntypedFormControl('', [Validators.required, Validators.pattern('^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$')]),
      ResidenceCity: new UntypedFormControl('', Validators.required),
      ResidenceProvince: new UntypedFormControl('', Validators.required),
      Address: new UntypedFormControl('', Validators.required),
      AddressNumber: new UntypedFormControl('', Validators.required),
      CompanyName: new UntypedFormControl('', Validators.required),
      CheckBox1: new UntypedFormControl(Boolean),
      CheckBox2: new UntypedFormControl(Boolean),
      CheckBox3: new UntypedFormControl(Boolean)
      //partita iva, REA, tipo società
    });
  }

  ngOnInit() {

    this.getUserData();
  }

  onCfPaste(event: ClipboardEvent): void {
    event.preventDefault(); //Prevents the default action
    event.clipboardData
      .getData('Text') //Gets the text pasted
      .split(/;|,|\n/) //Splits it when a SEMICOLON or COMMA or NEWLINE
      .forEach(value => {
        if (value.trim()) {
          this.formEditData.get('CF').setValue(value.trim().toUpperCase());
          this.UpdateCF = false;
        }
      })
  }

  get formEditDataControls() { return this.formEditData.controls }

  private _filter(value: string): string[] {
    if (value != null && value.length > 0) {
      const filterValue = value.toLowerCase();
      return this.availableProvinces.filter(province => province.toLowerCase().includes(filterValue));
    }
  }

  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
  }

  getGenderEnum(gender: string): number {
    switch (gender) {
      case 'M':
        return 0;
      case 'F':
        return 1;
      case 'NS':
        return 2;
      default:
        return 3;
    }
  }

  convertGenderEnum(gender: number): string {
    switch (gender) {
      case 0:
        return 'M';
      case 1:
        return 'F';
      case 2:
        return 'NS';
    }
  }
  informativePrivacyCheck = {
    RequirePaperPolicy: {
      label: 'Voglio ricevere le polizze cartacee',
      checked: false
    },
    CheckBox1: {
      label: 'Acconsentire al punto 1',
      checked: false
    },
    CheckBox2: {
      label: 'Acconsentire al punto 2',
      checked: false
    },
    CheckBox3: {
      label: 'Acconsentire al punto 3',
      checked: false
    }
  };

  CheckExistingProvince() {
    if (!this.availableProvinces.includes(this.formEditData.get('BirthProvince').value.toUpperCase())) {
      this.formEditData.get('BirthProvince').setValue('');
    }
  }

  getUserData() {
    this.apiService.callApiProd('/api/Account/GetUserData', new CallParameter('POST')).subscribe((result) => {


      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
      } else {

        this.UserDataResult = result['ResultData']['UserData'];

        this.formEditData.get('Name').setValue(this.UserDataResult.Name);
        this.formEditData.get('CF').setValue(this.UserDataResult.Cf);
        this.formEditData.get('Iva').setValue(this.UserDataResult.Piva);
        this.formEditData.get('REA').setValue(this.UserDataResult.Rea);
        this.formEditData.get('CompanyType').setValue(this.UserDataResult.CompanyType);
        this.formEditData.get('CompanyName').setValue(this.UserDataResult.CompanyName);
        this.formEditData.get('Surname').setValue(this.UserDataResult.Surname);
        this.formEditData.get('Phone').setValue(this.UserDataResult.Phone);
        this.formEditData.get('Address').setValue(this.UserDataResult.Address);
        this.formEditData.get('AddressNumber').setValue(this.UserDataResult.BuildingNumber);
        this.formEditData.get('ResidenceCity').setValue(this.UserDataResult.City);
        this.formEditData.get('ResidenceProvince').setValue(this.UserDataResult.Province);
        this.formEditData.get('CheckBox1').setValue(this.UserDataResult.CheckBox1);
        this.formEditData.get('CheckBox2').setValue(this.UserDataResult.CheckBox2);
        this.formEditData.get('CheckBox3').setValue(this.UserDataResult.CheckBox3);
      }

    });
  }

  saveEditedData() {
    if (this.formEditData.invalid) {
      return;
    }

    this.CallData = {
      Cf: this.formEditData.get('CF').value,
      Piva : this.formEditData.get('Iva').value,
      Rea : this.formEditData.get('REA').value,
      CompanyType : Number(this.formEditData.get('CompanyType').value),
      Phone: this.formEditData.get('Phone').value.toString(),
      Name: this.formEditData.get('Name').value,
      Surname: this.formEditData.get('Surname').value,
      Sex: this.getGenderEnum(this.formEditData.get('Gender').value),
      City: this.formEditData.get('ResidenceCity').value,
      Province: this.formEditData.get('ResidenceProvince').value,
      Address: this.formEditData.get('Address').value,
      BuildingNumber: this.formEditData.get('AddressNumber').value,
      CompanyName: this.formEditData.get('CompanyName').value,
      CheckBox1: this.formEditData.get('CheckBox1').value,
      CheckBox2: this.formEditData.get('CheckBox2').value,
      CheckBox3: this.formEditData.get('CheckBox3').value
    };

    this.apiService.callApiProd('/api/Account/UpdateUserData', new CallParameter('POST', this.CallData)).subscribe((result) => {

      if (result.InError) {
        this.appGeneralService.showDialog(result.ErrorCode);
        return;
      }

      this.appGeneralService.showDialog( null, "Salvataggio completato", "Esito")
    });

  }
}
