import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PolicyGroupInfo } from 'src/app/core/classes/PolicyGroup/PolicyGroupInfo';
import { ICardAction } from 'src/app/shared/general-card/general-card.component';
import { OptionInfoDialogComponent } from '../../policies/policy-group/policy-price-boxes/option-info-dialog/option-info-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-configurator-scheda-polizza',
  templateUrl: './configurator-scheda-polizza.component.html',
  styleUrls: ['./configurator-scheda-polizza.component.scss']
})
export class ConfiguratorSchedaPolizzaComponent implements OnInit {

  @Input() SingleFeed: PolicyGroupInfo;
  @Input() cardAction : ICardAction;
  @Input() isSelected : boolean = false;

  @Output() onSelection = new EventEmitter<number>();

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  toggleButton(){

    this.isSelected = !this.isSelected;
    this.onSelection.emit(this.SingleFeed.ID);
    this.cardAction();
  }

  openInfoDialog(Description: string, Title: string) {
    this.dialog.open(OptionInfoDialogComponent, {
      panelClass: 'SurveyModalCntr',
      width: '400px',
      maxHeight: '90vh',
      data: {Description, Title},
      autoFocus: false
    });
  }

}
